import React, { Component } from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import axios from "axios";
import { toast } from 'react-toastify';

export default class ContactUs extends Component {

    state = {
        contactId: '',
        contactTitle: '',
        contactDescription: '',
    }

    componentDidMount() {
        axios.post(`${process.env.REACT_APP_API_URL}/cms/list`, {tag: 'ContactUs'})
            .then(response => {
                this.setState({
                    contactId: response.data[0]._id,
                    contactTitle: response.data[0].title,
                    contactDescription: response.data[0].description,
                })
            })
            .catch(error => {
                console.log(error);
            })
    }

    onChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleEditorChange = (event, editor) => {
        const data = editor.getData();
        this.setState({
            contactDescription: data
        })
    }

    updateContents = async (e) => {
        e.preventDefault();
        const { contactId, contactTitle, contactDescription } = this.state;

        var contentsForm = new FormData();
        contentsForm.set('title', contactTitle);
        contentsForm.set('description', contactDescription);
        let token = await localStorage.getItem('token');

        await axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}/cms/update/${contactId}`,
            data: contentsForm,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then(response => {
                toast.success('Contents Updated Successfully', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch(error => {
                console.log(error);
            })
    }

    render() {
        const { history } = this.props
        const { contactTitle, contactDescription } = this.state;
        return (
            <div>
                <div className="header">
                    <h2><strong>Website</strong> Contents</h2>
                    <div className="breadcrumb-wrapper">
                        <ol className="breadcrumb">
                            <li>
                                <a href={() => null} onClick={() => history.push("/dashboard")}>TimeFlyz</a>
                            </li> 
                            <li className="active">Contact Us</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 portlets">
                        <form onSubmit={this.updateContents}>
                            <div className="panel">
                                <div className="panel-content">
                                    <div className="form-group">
                                        <h5>Title</h5>
                                        <input type="text" name="contactTitle" className="form-control" placeholder="Enter the title here" aria-required="true" onChange={this.onChange} defaultValue={contactTitle} />
                                    </div>
                                    <div className="form-group">
                                        <h5>Description</h5>
                                        <CKEditor
                                            className="summernote bg-white"
                                            editor={ClassicEditor}
                                            data={contactDescription}
                                            onInit={editor => {
                                                editor.editing.view.change(writer => {
                                                    writer.setStyle(
                                                        "height",
                                                        "300px",
                                                        editor.editing.view.document.getRoot()
                                                    );
                                                });
                                            }}
                                            onChange={this.handleEditorChange}
                                        />
                                    </div>
                                </div>

                                <div className="text-center  m-t-20">
                                    <button type="submit" className="btn btn-embossed btn-danger" onClick={this.updateContents}>Update</button>
                                    <button type="reset" className="cancel btn btn-embossed btn-primary m-b-10 m-r-0">Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}