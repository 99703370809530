import * as React from 'react';
import axios from '../../../utils/axios';
import moment from 'moment';
import Box from '@mui/material/Box';
import { Breadcrumbs, Grid, Link, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import CouponForm from '../Components/CouponForm';
import { useHistory } from 'react-router-dom';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  let history = useHistory();
  const onSubmit = async (values, { resetForm }) => {
    try {
      await axios.post('/coupons', values);
      resetForm();
      toast.success('Coupon created successfully');
      history.goBack();
    } catch (error) {
      toast.error(error);
    }
  }

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Typography variant='h4'>New Coupon</Typography>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            color="inherit"
            onClick={() => history.push("/dashboard")}
          >
            Coupons
          </Link>
          <Typography color="text.primary">New Coupon</Typography>
        </Breadcrumbs>
      </Grid>
      <CouponForm onSubmit={onSubmit} initialValues={{
        code: '',
        value: '',
        startDate: moment().startOf('day').utcOffset(0, true),
        endDate: moment().endOf('day').utcOffset(0, true),
        appliedTo: 'hotel',
        scope: 'all',
        scopeChain: [],
        scopeHotel: [],
      }} />
    </Box>
  );
}