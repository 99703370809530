import React from 'react';
import DataTable from 'react-data-table-component';
import 'styled-components';
import Loader from '../Loader';

export default function DataTables(props){
    const {columns, data, loading, filterDefaultText, searchBox, pagination} = props;
    const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
	const filteredItems = data.filter( item => {
        let returnType = false;
        if(item.clientTypeName && item.clientTypeName.toLowerCase().includes(filterText.toLowerCase())){
            returnType = true;
        }
        if(item.name && item.name.toLowerCase().includes(filterText.toLowerCase())){
            returnType = true;
        }
        if(item.propertyId && item.propertyId.toLowerCase().includes(filterText.toLowerCase())){
            returnType = true;
        }
        if(item.fullName && item.fullName.toLowerCase().includes(filterText.toLowerCase())){
            returnType = true;
        }
        if(item.email && item.email.toLowerCase().includes(filterText.toLowerCase())){
            returnType = true;
        }
        if(item.userType && item.userType.toLowerCase().includes(filterText.toLowerCase())){
            returnType = true;
        }
        if(item.contactNo && item.contactNo.toLowerCase().includes(filterText.toLowerCase())){
            returnType = true;
        }
        if(item.hotelType && item.hotelType.toLowerCase().includes(filterText.toLowerCase())){
            returnType = true;
        }
        if(typeof(item.status) === 'string' && item.status && item.status.toLowerCase().includes(filterText.toLowerCase())){
            returnType = true;
        }
        if(item.three && item.three.toLowerCase().includes(filterText.toLowerCase())){
            returnType = true;
        }
        if(item.six && item.six.toLowerCase().includes(filterText.toLowerCase())){
            returnType = true;
        }
        if(item.twelve && item.twelve.toLowerCase().includes(filterText.toLowerCase())){
            returnType = true;
        }
        if(item.bookingId && item.bookingId === filterText){
            returnType = true;
        }
        if(item.lastName && item.lastName.toLowerCase().includes(filterText.toLowerCase())){
            returnType = true;
        }
        if(item.transDate && item.transDate.toLowerCase().includes(filterText.toLowerCase())){
            returnType = true;
        }
        if(item.invId && item.invId.toLowerCase().includes(filterText.toLowerCase())){
            returnType = true;
        }
        if(item.category && item.category.toLowerCase().includes(filterText.toLowerCase())){
            returnType = true;
        }
        if(item.statementName && item.statementName.toLowerCase().includes(filterText.toLowerCase())){
            returnType = true;
        }
        if(item.score && item.score === filterText){
            returnType = true;
        }
        return returnType;
    });
    const FilterComponent = ({ filterText, onFilter, onClear }) => (
        <div className="input-group datatable-search">
            <input
                id="search"
                type="text"
                placeholder={filterDefaultText}
                aria-label="Search Input"
                value={filterText}
                onChange={onFilter}
                autoFocus
            />
            <span className="input-group-addon" onClick={onClear}>X</span>
        </div>
    );

    /********************************************************** EXPORT BUTTON STARTS ********************************************************************/
    /*function convertArrayOfObjectsToCSV(array) {
        let result;
    
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(data[0]);
    
        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;
    
        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;
    
                result += item[key];
                
                ctr++;
            });
            result += lineDelimiter;
        });
    
        return result;
    }*/
    
    /*function downloadCSV(array) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;
    
        const filename = 'export.csv';
    
        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }
    
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }*/

    //const Export = ({ onExport }) => <button onClick={e => onExport(e.target.value)} className="btn btn-danger btn-md"><i className="fa fa-download"></i>Export CSV</button>;
    /********************************************************** EXPORT BUTTON ENDS ********************************************************************/

	const subHeaderComponentMemo = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
		);
	}, [filterText, resetPaginationToggle]);


    return(
        <DataTable
            columns={columns}
            data={filteredItems}
            //pagination={pagination || pagination === undefined}
            //paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            subHeader={searchBox || searchBox === undefined}
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            fixedHeader
            //fixedHeaderScrollHeight="400px"
            progressPending={loading}
            progressComponent={<Loader />}
            //actions={<Export onExport={() => downloadCSV(filteredItems)} />}
            className="table table-hover"
        />
    )
}