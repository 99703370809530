import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import DataTable from "../../../components/DataTable";
import DatePicker from "react-datepicker";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import moment from "moment";

const columns = [
  {
    name: 'PROPERTY ID',
    selector: row => row.propertyId,
    sortable: true,
  }, {
    name: 'PROPERTY NAME',
    selector: row => row.name,
    sortable: true,
    grow: 2,
  },
  {
    name: 'PROPERTY CHAIN',
    selector: row => row.propChain,
    sortable: true,
  }, {
    name: 'PROPERTY STATUS',
    selector: row => row.status,
  }, {
    name: 'BILLING STATUS',
    selector: row => row.billingStatus,
  }, {
    name: 'ACTION',
    selector: row => row.action,
    grow: 5,
    wrap: true,
  },
];

const scoreColumn = [
  {
    name: 'Category',
    selector: row => row.category,
    sortable: true,
  }, {
    name: 'Score',
    selector: row => row.score,
    sortable: true,
  },
];

export default class PropertyListing extends Component {
  state = {
    checked: false,
    loading: true,
    records: [],
    scoreModal: false,
    selectedHotelId: null,
    date: new Date(),
    location: '',
    latitude: null,
    longitude: null,
    dateError: null,
    locationError: null,
    scoreArray: [],
    rank: null,
    scoreLoading: false,
  }

  handleChange(checked) {
    this.setState({ checked });
  }

  componentDidMount() {
    const fetchPropertyType = this.props.match.params.hotelAdminID;
    if (fetchPropertyType === 'all') {
      this.getPropertyList();
    } else {
      this.getPropertyListHotelAdminWise(fetchPropertyType);
    }
  }

  async getPropertyList() {
    let token = await localStorage.getItem('token');
    await axios.get(`${process.env.REACT_APP_API_URL}/hotel/list`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
      .then(response => {
        const array = [];
        const query = new URLSearchParams(this.props.location.search);
        const commissionID = query.get('commissionID') || 'all'
        response.data.forEach(element => {
          if(commissionID === "all" || element?.commission?._id === commissionID) {
          array.push({
            propChain: element.commission?.clientTypeName || 'Other',
            propertyId: element.propertyId,
            name: element.name,
            status:
              <select className="form-control pointer" onChange={(val) => this.hotelInactive(element._id, val)}>
                <option value="Pending" selected={element.isActive === 'Pending'}>Pending</option>
                <option value="Live" selected={element.isActive === 'Live'}>Live</option>
                <option value="Inactive" selected={element.isActive === 'Inactive'}>Inactive</option>
              </select>,
            billingStatus:
              element.paymentMethodID ?
                <h6 className="c-green bold" style={{ textTransform: 'uppercase' }}>Complete</h6>
                :
                <h6 className="c-red bold" style={{ textTransform: 'uppercase' }}>In Complete</h6>,
            action:
              <div className="text-center">
                <a href={`/editHotel/${element._id}`} className="btn btn-embossed btn-primary" >Edit</a>
                <a href={`/billing/${element._id}`} className="cancel btn btn-embossed btn-danger" >Billing</a>
                <a href={`/reviews/${element._id}`} className="cancel btn btn-embossed btn-primary">Review</a>
                <button className="cancel btn btn-embossed btn-primary" onClick={() => this.showScoreModal(element._id)}>Score</button>
              </div>
          })
          }
        });
        this.setState({
          records: array,
          loading: false
        })
      })
      .catch(error => {
        console.log(error);
      })
  }

  showScoreModal = (hotelId) => {
    this.setState({
      scoreModal: true,
      selectedHotelId: hotelId
    });
  }

  async getPropertyListHotelAdminWise(adminID) {
    let token = await localStorage.getItem('token');
    await axios.get(`${process.env.REACT_APP_API_URL}/hotel/getHotel/${adminID}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
      .then(response => {
        const array = [];
        response.data.forEach(element => {
          array.push({
            propChain: element.commission?.clientTypeName || 'Other',
            propertyId: element.propertyId,
            name: element.name,
            status:
              <select className="form-control pointer" onChange={(val) => this.hotelInactive(element._id, val)}>
                <option value="Pending" selected={element.isActive === 'Pending'}>Pending</option>
                <option value="Live" selected={element.isActive === 'Live'}>Live</option>
                <option value="Inactive" selected={element.isActive === 'Inactive'}>Inactive</option>
              </select>,
            action:
              <div className="text-center">
                <a href={`/editHotel/${element._id}`} className="btn btn-embossed btn-primary" style={{ width: 100 }}>Edit</a>
                <a href={`/billing/${element._id}`} className="cancel btn btn-embossed btn-danger" style={{ width: 100 }}>Billing</a>
              </div>
          })
        });
        this.setState({
          records: array,
          loading: false
        })
      })
      .catch(error => {
        console.log(error);
      })
  }

  hotelInactive = async (hotelId, e) => {
    let token = await localStorage.getItem('token');
    let parameters = {
      "isActive": e.target.value,
      "id": hotelId,
    };
    await axios.put(`${process.env.REACT_APP_API_URL}/hotel/update`, parameters, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
      .then(response => {
        if (response.status === 200) {
          this.componentDidMount();
          toast.success('Hotel Status Changed Successfully', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch(error => {
        toast.error('Something went wrong', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(error);
      })
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    })
  }


  handleChange = address => {
    this.setState({ location: address });
  };

  handleSelect = address => {
    this.setState({ location: address });
    geocodeByAddress(address)
      .then(async (results) => {
        const latLng = await getLatLng(results[0]);
        this.setState({
          latitude: latLng.lat,
          longitude: latLng.lng
        })
      })
      .catch(error => console.error('Error', error));
  };

  renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions }) => (
    <div className="autocomplete-root">
      <input {...getInputProps()} className="form-control" placeholder="Enter the street address here" />
      <div className="autocomplete-dropdown-container">
        {suggestions.map((suggestion, index) => (
          <option className="hover-options" {...getSuggestionItemProps(suggestion)} key={index}>
            {suggestion.description}
          </option>
        ))}
      </div>
    </div>
  )

  closeScoreModal = () => {
    this.setState({
      date: new Date(),
      location: null,
      scoreModal: false,
      dateError: null,
      locationError: null,
      selectedHotelId: null,
      scoreArray: [],
      rank: null,
      scoreLoading: false,
    })
  }

  updateScore = async (e) => {
    e.preventDefault();
    let token = await localStorage.getItem('token');
    const { date, latitude, longitude, selectedHotelId } = this.state;
    const formatDate = moment(date).format("YYYY-MM-DD")
    const time = "12:30";
    const duration = "3";
    const types = "";
    const name = "";
    this.setState({ scoreLoading: true });
    await axios.get(`${process.env.REACT_APP_API_URL}/hotel/rank?lon=${longitude}&lat=${latitude}&date=${formatDate}&time=${time}&duration=${duration}&types=${types}&name=${name}&hotelId=${selectedHotelId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
    })
      .then(response => {
        if (response.status === 200) {
          const array = [];
          Object.keys(response.data.points).forEach((element, index) => {
            array.push({
              category: element,
              score: Object.values(response.data.points)[index],
            })
          });
          this.setState({
            scoreArray: array,
            rank: response.data.Rank,
            scoreLoading: false,
          })
        }
      })
      .catch(error => {
        toast.error(error.response.data.error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.setState({
          scoreLoading: false,
          scoreArray: [],
          rank: null
        })
        console.log(error);
      })
  }
  render() {
    const { history } = this.props
    const { loading, records, date, location, dateError, locationError, scoreModal, scoreArray, rank, scoreLoading } = this.state;
    return (
      <div>
        <div className="header">
          <h2><strong>Manage</strong> Properties</h2>
          <div className="breadcrumb-wrapper">
            <ol className="breadcrumb">
              <li>
                <a href={() => null} onClick={() => history.push("/dashboard")}>TimeFlyz</a>
              </li>
              <li className="active">Property Management</li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="panel">
              <div className="panel-header ">
                <div className="row">
                  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <h3><strong>Property Listing</strong> </h3>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" style={{ textAlign: 'end' }}>
                    <a href={() => null} onClick={() => history.push("/addHotel")} className="btn btn-primary" rel="noreferrer"><i className="fa fa-plus c-warning" /> Add Hotel</a>
                  </div>
                </div>
              </div>
              <div className="panel-content pagination2 table-responsive">
                <DataTable columns={columns} data={records} loading={loading} filterDefaultText="Filter By ID, Name" />
              </div>
            </div>
          </div>
        </div>
        <div className={scoreModal ? "modal fade in" : "modal fade"} aria-hidden="true" style={scoreModal ? { display: 'block' } : { display: 'none' }}>
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <form onSubmit={this.updateScore}>
                <div className="modal-header" style={{ background: '#ef4a23', color: '#fff' }}>
                  <button type="button" className="close" aria-hidden="true" onClick={this.closeScoreModal}><i className="fa fa-times" style={{ fontSize: 18 }} /></button>
                  <h4 className="modal-title"><strong>Score</strong></h4>
                </div>
                <div className="modal-body" style={{ minHeight: 220 }}>
                  <label className="control-label col-md-3">Date</label>
                  <div className="col-md-9">
                    <div className="form-group">
                      <DatePicker selected={date} onChange={(date) => this.setState({ date })} />
                      {dateError ? (<div className="text-danger">{dateError}</div>) : null}
                    </div>
                  </div>
                  <label className="control-label col-md-3">Location</label>
                  <div className="col-md-9">
                    <div className="form-group">
                      <PlacesAutocomplete value={location} onChange={this.handleChange} onSelect={this.handleSelect}>
                        {this.renderFunc}
                      </PlacesAutocomplete>
                      {locationError ? (<div className="text-danger">{locationError}</div>) : null}
                    </div>
                  </div>
                  <label className="control-label col-md-3">Rank: </label>
                  <div className="col-md-9">
                    <div className="form-group"><strong>{rank}</strong></div>
                  </div>
                  <div className="col-md-12 panel-content pagination2 table-responsive">
                    <DataTable columns={scoreColumn} data={scoreArray} loading={scoreLoading} searchBox={false} pagination={false} />
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="reset" className="btn btn-primary btn-embossed bnt-square" onClick={this.closeScoreModal}> Cancel</button>
                  <button type="submit" className="btn btn-danger btn-embossed bnt-square"> Update</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}