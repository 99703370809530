import React, {Component} from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export default class commonLoader extends Component{
    render(){
        return(
            <div className="body-block">
                <div style={{textAlign:'center', justifyContent:'center'}}>
                    <Loader
                        type="ThreeDots"
                        color="#ff4500"
                        height={150}
                        width={150}
                        style={{textAlignLast:'center'}}
                    />
                </div>
            </div>
        )
    }
}