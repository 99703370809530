import React, { Component } from "react";
import $api from "../../utils/axios";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Pagination from "react-js-pagination";
import Autocomplete from "@mui/material/Autocomplete";
import { jwtDecode } from "jwt-decode";
import { toast } from 'react-toastify';
import {
    MenuItem,
    Select,
    InputLabel,
    FormControl
} from "@mui/material";

export default class Customers extends Component {
    state = {
        checked: false,
        propertyArray: [],
        currentList: [],
        pageLimit: 10,
        pageCount: 0,
        totalCount: 0,
        showingFrom: 0,
        showingTo: 0,
        propertyId: '',
        propertyName: '',
        options: [],
        hotelname: '',
        ticketText: '',
        addedAtBeginning: false,
        statusOptions: ['View All', 'Open', 'Pending', 'Close'],
        activePage: 1
    }
    async componentDidMount() {
        let token = await localStorage.getItem('token');
        //this.getDepartments();
        this.getBillingData(token);
        this.getRole();
        //this.getPropertyName(propertyId,token);
    }
    getDepartments = async () => {
            //const res = await $api.get(`/tickets/${options[0]._id}`);}
            const res = await $api.post(`/user/adminList`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                },
            });
            // console.log(res);
            // return;
            //setHotelname(options[0].name);
            this.setState({
                currentList: res.data.department.slice(0, this.state.pageLimit),
               // hotelname: this.state.options[0].name
            });
        // console.log('bbbbbbbbbbbbbbbbbbbbb');
        // console.log(this.state.options);      
    }

    getBillingData = async (token) => {
        await axios.post(`${process.env.REACT_APP_API_URL}/user/adminList`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
            .then(response => {
                console.log(response)
                const data = response.data.filter(res => res)
                data.sort((a, b) => b.created - a.created)

                this.setState({
                    propertyArray: data,
                    pageCount: Math.ceil(data.length / this.state.pageLimit),
                    totalCount: data.length,
                    currentList: data.slice(0, this.state.pageLimit),
                    showingFrom: data.length > 0 ? 1 : 0,
                    showingTo: data.length > 10 ? 10 : data.length
                })
            })
            .catch(error => {
                console.log(error);
            })
    }

    handleOptionChange = async (event, selectedOption) => {
        // console.log(selectedOption)
        // return;
        // if (selectedOption !== null) {
        //     let resnew = await $api.get(`/tickets/${selectedOption._id}`);
        //     //setRows(resnew.data);
        //     this.setState({
        //         currentList: resnew.data.slice(0, this.state.pageLimit),
        //     });
        //     if (resnew.data.length === 0) {
        //         //setticketText('Sorry, no records found.');
        //         this.setState({
        //             ticketText: 'Sorry, no records found.',
        //         });
        //     }
        // }
        // if (selectedOption === null) {
        //     let resnew = await $api.get(`/tickets/0`);
        //     this.setState({
        //         currentList: resnew.data.slice(0, this.state.pageLimit),
        //     });
        //     if (resnew.data.length === 0) {
        //         this.setState({
        //             ticketText: 'Sorry, no records found.',
        //         });
        //     }
        // }
        // setCurrentTab(res.data[0]._id);


        const res = await $api.post(`/user/adminList`, {
            name: selectedOption.name === 'View All' ? '' : selectedOption.name
        });
        this.setState({
            currentList: res.data.department.slice(0, this.state.pageLimit),
            hotelname: this.state.options[0].name
        });
        //   this.setState({
        //     options: newElement,
        //     addedAtBeginning: true
        //   });

    };

    handleFilterStatusChange = async (event, selectedOption) => {
        const res = await $api.post(`/user/adminList`, {
            name: selectedOption === 'View All' ? '' : selectedOption.toUpperCase()
        });
        this.setState({
            currentList: res.data.department.slice(0, this.state.pageLimit),
            hotelname: selectedOption
        });
    }

    // handleStatusChange = async (data, selectedData, myData) => {

    //     try {
    //         await axios.put(`${process.env.REACT_APP_API_URL}/ticket/change-ticket-status`, {
    //             ticketId: data._id,
    //             ticketStatus: selectedData.target.value
    //         }, {
    //             headers: {
    //                 Authorization: `Bearer ${localStorage.getItem('token')}`
    //             },
    //         }).then(res => {
    //             // this.getBillingData(localStorage.getItem('token'));
    //             toast.success("Ticket status updated successfully!", {
    //                 position: "top-right",
    //                 autoClose: 3000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //             });
    //         })
    //     } catch (error) {
    //         console.log(error)
    //     }

    // }

    /*getPropertyName = async(propertyId,token) => {
        await axios.get(`${process.env.REACT_APP_API_URL}/hotel/list`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then(response => {
            let getPropertyDetails = response.data.filter((eachProperty) => {
                return eachProperty._id === propertyId
            })
            this.setState({
                propertyName: getPropertyDetails[0].name
            })
        })
        .catch(error => {
            console.log(error);
        })
    }*/

    handleChange(checked) {
        this.setState({ checked });
    }

    handlePageChange(pageNumber) {
        const { propertyArray, pageLimit } = this.state;
        const offset = (pageNumber - 1) * pageLimit;
        const currentList = propertyArray.slice(offset, offset + pageLimit);
        const showingFrom = offset + 1;
        const showingTo = offset + pageLimit;
        this.setState({ propertyArray, currentList, activePage: pageNumber, showingFrom, showingTo });
    }

    getRole() {
        const token = localStorage.getItem('token');
        const decoded = jwtDecode(token);
        this.state.role = decoded.role

    }

    async hotelAdminRedirectLink(userId) {
        this.props.history.push(`/edit-department/${userId}`);
    }

    render() {
        const { history } = this.props
        const { currentList, activePage, totalCount, pageLimit, showingFrom, showingTo, options, hotelname, statusOptions } = this.state;
        return (
            <div>

                <div className="header">
                    <h2><strong>Manage</strong> Admins</h2>
                    <div className="breadcrumb-wrapper">
                        <ol className="breadcrumb">
                            <li>
                                <a href={() => null} onClick={() => history.push("/dashboard")}>HelpDesk</a>
                            </li>
                            <li className="active">Admins</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="panel">
                            <div className="panel-header ">
                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                    <a href={() => null} onClick={() => history.push("/add-useradmins")} className="btn btn-primary" rel="noreferrer"><i className="fa fa-plus c-warning" /> Add Admin Email</a>
                                </div>
                                <div className="row">
                                    {/*(this.state.role === 'SuperAdmin' || this.state.role === 'Employee') && <div className="autoselect" style={{ position: 'right' }}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={options}
                                            getOptionLabel={(option) => option.name}
                                            sx={{ width: 300 }}
                                            onChange={this.handleOptionChange.bind(this)}
                                            renderInput={(params) => <TextField {...params} label={hotelname} />}
                                        />
                                    </div>*/}

                                    {/*this.state.role === 'Department' && <div className="autoselect" style={{ position: 'right' }}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={statusOptions}
                                            getOptionLabel={(statusOptions) => statusOptions}
                                            sx={{ width: 300 }}
                                            onChange={this.handleFilterStatusChange.bind(this)}
                                            renderInput={(params) => <TextField {...params} label={hotelname} />}
                                        />
                                    </div>*/}
                                </div>
                            </div>
                            <div className="panel-content pagination2 table-responsive">
                                <table className="table table-hover table-dynamic dataTable no-footer">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '20%' }}>Sequence No.</th>
                                            <th style={{ width: '35%' }}>Admin Email</th>
                                            {/*<th style={{ width: '35%' }}>Action</th>*/}
                                            {/* <th style={{ width: '45%' }}>Ticket Description</th> */}
                                            {/* <th style={{ width: '20%', textAlign: 'center' }}>Status</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            currentList.map((data, index) => {
                                                return (
                                                    data && <tr key={index}>
                                                        <td data-label="Sequence No.">
                                                            {(index + 1) + ((activePage - 1) * pageLimit)}
                                                        </td>
                                                        <td data-label="Department Name">{data.email}</td>
                                                        {/*<td data-label="Action">
                                                         
                                                                <button className="btn btn-danger" onClick={() => this.hotelAdminRedirectLink(data._id)}>Details</button>
                                                                
                                                        </td>*/}
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                <div className="row">
                                    <div className="col-md-5" style={{ marginTop: 20 }}>
                                        <div className="dataTables_info" role="status" aria-live="polite">Showing {showingFrom} to {showingTo} of {totalCount} entries</div>
                                    </div>
                                    <div className="col-md-7">
                                        <Pagination
                                            prevPageText='prev'
                                            nextPageText='next'
                                            firstPageText='first'
                                            lastPageText='last'
                                            activePage={activePage}
                                            itemsCountPerPage={pageLimit}
                                            totalItemsCount={totalCount}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange.bind(this)}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}