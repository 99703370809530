import React, { Component } from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import axios from "axios";
import Dialog from '../../components/Dialog';
import { toast } from 'react-toastify';
import { isEmpty } from "lodash";
export default class FaqDetails extends Component {

    state = {
        faq: {},
        isAddQAModalOpen: false,
        isUpdateModalOpen: false,
        addQnAQuestionInput: '',
        addQnAAnswerInput: '',
        updatingQnAId: '',
        updateQnAQuestionInput: '',
        updateQnAAnswerInput: '',
    }

    componentDidMount() {
        let fqaId = this.props.match.params.fqaID;
        axios.get(`${process.env.REACT_APP_API_URL}/faq/get/${fqaId}`, {tag: 'Faq'})
        .then(response => {
            this.setState({
                faq: response.data,
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    deleteFAQ = async (e, qa) => {
        e.preventDefault();

        let token = await localStorage.getItem('token');
        await axios({
            method: "DELETE",
            url: `${process.env.REACT_APP_API_URL}/faq/${qa._id}`,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            if (response.status === 200) {
                toast.success("Deleted successfully", {
                    onClose: () => {
                        this.props.history.goBack();
                    },
                    autoClose: 2000,
                });
            }
        }).catch(error => 
            toast.success("Deleted successfully", {
                autoClose: 2000,
            })
        ).finally(() => {
           
        });
    }
    
    updateFAQ = async (e) => {
        e.preventDefault();
        if (isEmpty(this.state.updateQnAQuestionInput) ||  isEmpty(this.state.updateQnAAnswerInput)){
            toast.error('Your question and answer is required');
            return;
        }
        let token = await localStorage.getItem('token');

        await axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}/faq`,
            data: {
                q: this.state.updateQnAQuestionInput,
                a: this.state.updateQnAAnswerInput,
                _id: this.state.updatingQnAId
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
          
            const {message} = response.data;
            toast.success(message);
            this.setState({
                isAddQAModalOpen: false,
                updateQnAQuestionInput: '',
                updateQnAAnswerInput: '',
                updatingQnAId: '',
                isUpdateModalOpen: false,
                faq: {
                        q: this.state.updateQnAQuestionInput,
                        a: this.state.updateQnAAnswerInput,
                        _id: this.state.updatingQnAId
                }
            });

        }).catch(error => 
            toast.error('Failed to update FAQ')
        );
    }

    onAddFAQClick = async (e) => {
        e.preventDefault();
        
        this.setState({
            addQnAQuestionInput: '',
            addQnAAnswerInput: '',
            isAddQAModalOpen: true
        });
    }

    handleAddedQuestionChange = (event) => {
        event.preventDefault();

        const { value } = event.target;
        this.setState({
            addQnAQuestionInput: value
        });
    }

    handleAddedAnswerChange = (event, editor) => {
        const data = editor.getData();
        this.setState({
            addQnAAnswerInput: data
        });
    }

    handleUpdatedQuestionChange = (event) => {
        event.preventDefault();

        const { value } = event.target;
        this.setState({
            updateQnAQuestionInput: value
        });
    }

    handleUpdatedAnswerChange = (event, editor) => {
        const data = editor.getData();
        this.setState({
            updateQnAAnswerInput: data
        });
    }

    onUpdateFAQClick = async (e, faq) => {
        e.preventDefault();

        this.setState({
            updatingQnAId: faq._id,
            updateQnAQuestionInput: faq.q,
            updateQnAAnswerInput: faq.a,
            isUpdateModalOpen: true
        });
    }

    render() {
        const { history } = this.props
        const { faq, addQnAQuestionInput, addQnAAnswerInput, updateQnAQuestionInput, updateQnAAnswerInput } = this.state;
        return (
            <div>
                <div className="header">
                    <h2><strong>Manage</strong> FAQ Details</h2>
                    <div className="breadcrumb-wrapper">
                        <ol className="breadcrumb">
                            <li>
                                <a href={() => null} onClick={() => history.push("/dashboard")}>TimeFlyz</a>
                            </li>
                            <li>
                                <a href={() => null} onClick={() => history.push("/faq")}>FAQ</a>
                            </li>
                            <li className="active">FAQ Details</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    {
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 portlets" style={{backgroundColor: 'white', marginBottom: 20, borderRadius: 10}}>
                            <div className="row" style={{padding: 12}}>
                                <p style={{fontWeight:'bold'}}>{faq.q}</p>
                                <div dangerouslySetInnerHTML={{__html: faq.a}} />
                            </div>
                            <button className="btn btn-embossed btn-danger" onClick={(event) => this.onUpdateFAQClick(event, faq)}><i className="fa fa-edit" style={{fontSize: 18, color: '#FFFFFF'}}/></button>
                            <button className="cancel btn btn-embossed btn-primary m-b-10 m-r-0" onClick={(event) => this.deleteFAQ(event, faq) }><i className="fa fa-trash" style={{color: '#FFFFFF'}}/></button>
                        </div>
                    }
                </div>
                <Dialog isOpen={this.state.isAddQAModalOpen} onClose={(e) => this.setState({ isAddQAModalOpen: false })}>
                    <form onSubmit={this.addFAQ}>
                        <div className="form-group">
                            <input type="text" name='addQnAQuestionInput' value={addQnAQuestionInput} className="form-control" aria-required="true" onChange={this.handleAddedQuestionChange} placeholder="Enter your question" />
                        </div>
                        <div className="form-group">
                            <CKEditor
                                className="summernote bg-white"
                                editor={ClassicEditor}
                                data={addQnAAnswerInput}
                                onInit={editor => {
                                    editor.editing.view.change(writer => {
                                        writer.setStyle(
                                            "height",
                                            "300px",
                                            editor.editing.view.document.getRoot()
                                        );
                                    });
                                }}
                                onChange={this.handleAddedAnswerChange}
                            />
                        </div>
                        <button type="submit" className="btn btn-embossed btn-danger" onClick={this.addFAQ} style={{marginTop: '12px', width: '100%'}}>Add FAQ</button>
                    </form>
                </Dialog>
                <Dialog isOpen={this.state.isUpdateModalOpen} onClose={(e) => this.setState({ isUpdateModalOpen: false })}>
                    <form onSubmit={this.updateFAQ}>
                        <div className="form-group">
                            <input type="text" name='updateQnAQuestionInput' value={updateQnAQuestionInput} className="form-control" aria-required="true" onChange={this.handleUpdatedQuestionChange} placeholder="Enter your question" />
                        </div>
                        <div className="form-group">
                            <CKEditor
                                className="summernote bg-white"
                                editor={ClassicEditor}
                                data={updateQnAAnswerInput}
                                onInit={editor => {
                                    editor.editing.view.change(writer => {
                                        writer.setStyle(
                                            "height",
                                            "300px",
                                            editor.editing.view.document.getRoot()
                                        );
                                    });
                                }}
                                onChange={this.handleUpdatedAnswerChange}
                            />
                        </div>
                        <button type="submit" className="btn btn-embossed btn-danger" onClick={this.updateFAQ} style={{marginTop: '12px', width: '100%'}}>Update FAQ</button>
                    </form>
                </Dialog>
            </div>
        )
    }
}