import React, { Component } from "react";
import { addDays } from 'date-fns'
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from "../../components/DataTable";
import moment from 'moment';

const columns = [
    {
        name: 'HOTEL NAME',
        selector: row => row.name,
        sortable: true,
    },
    {
        name: 'BOOKINGS',
        selector: row => row.bookings,
        sortable: true,
    },
    {
        name: 'CONFIRMED BOOKINGS',
        selector: row => row.confirmedBooking,
        sortable: true,
    },
    {
        name: 'COMPLETED STAYS',
        selector: row => row.bookingsStay,
        sortable: true,
    },
    {
        name: 'COMPLETED STAY AVERAGE RATE',
        selector: row => row.avgRate,
        sortable: true,
    },
    {
        name: 'BOOKING REVENUE',
        selector: row => row.totalRevenue,
    },
    {
        name: 'COMPLETED STAY REVENUE',
        selector: row => row.stayRevenue,
    },
    {
        name: 'CANCELLATIONS',
        selector: row => row.cancellation,
    },
    {
        name: 'NO SHOWS',
        selector: row => row.noShow,
    },
];

export default class Reporting extends Component {
    state = {
        dateRange: [{
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: 'selection'
        }],
        records: [],
        loading: true,
    }

    componentDidMount() {
        this.fetchReporting();
    }

    fetchReporting = async () => {
        const { dateRange } = this.state;
        let startDate = dateRange[0].startDate;
        let endDate = dateRange[0].endDate;
        let parsedDateStart = moment(startDate, "ddd MMM DD YYYY HH:mm:ss ZZ");
        let formattedDateStart = parsedDateStart.format("YYYY-MM-DD");
        let parsedDateEnd = moment(endDate, "ddd MMM DD YYYY HH:mm:ss ZZ");
        let formattedDateEnd = parsedDateEnd.format("YYYY-MM-DD");
        let token = await localStorage.getItem('token');
        await axios.post(`${process.env.REACT_APP_API_URL}/reservation/reporting`, {
            "from": formattedDateStart,
            "to": formattedDateEnd
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
            .then(response => {
                if (response.status === 200) {
                    const array = [];
                    response.data.forEach(element => {
                        array.push({
                            _id: element._id,
                            name: element.name,
                            bookings: element.bookings,
                            bookingsStay: element.bookingsStay,
                            avgRate: parseFloat(element.avgRate).toFixed(2),
                            totalRevenue: parseFloat(element.totalRevenue).toFixed(2),
                            stayRevenue: parseFloat(element.stayRevenue).toFixed(2),
                            cancellation: element.cancellation,
                            noShow: element.noShow,
                            confirmedBooking: element.confirmedBooking
                        })
                    });
                    this.setState({
                        records: array,
                        loading: false
                    })
                }
            })
            .catch(error => {
                console.log(error);
            })
    }
    dateChange = (item, dateType) => {
        const { dateRange } = this.state;
        if (item) {
            let date = item.getTime();
            if (dateType === 'start') {
                this.setState({
                    dateRange: [{
                        startDate: new Date(date),
                        endDate: new Date(dateRange[0].endDate)
                    }],
                }, () => {
                    this.fetchReporting();
                })
            } else {
                if (dateType === 'end') {
                    this.setState({
                        dateRange: [{
                            startDate: new Date(dateRange[0].startDate),
                            endDate: new Date(date)
                        }],
                    }, () => {
                        this.fetchReporting();
                    })
                }
            }
        }
    }

    render() {
        const { history } = this.props
        const { dateRange, loading, records } = this.state;
        return (
            <div>
                <div className="header">
                    <h2><strong>Reporting</strong></h2>
                    <div className="breadcrumb-wrapper">
                        <ol className="breadcrumb">
                            <li>
                                <a href={() => null} onClick={() => history.push("/dashboard")}>TimeFlyz</a>
                            </li>
                            <li className="active">Report Listing</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="panel">
                            <div className="panel-header ">
                                <div className="row" style={{ padding: 10 }}>
                                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                                        <h3><strong>Report Listing</strong> </h3>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                        <div className="row">
                                            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                <DatePicker
                                                    selected={dateRange[0].startDate}
                                                    onChange={date => this.dateChange(date, 'start')}
                                                    selectsStart
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    startDate={dateRange[0].startDate}
                                                    endDate={dateRange[0].endDate}
                                                    dateFormat="dd/MM/yyyy"
                                                />
                                            </div>
                                            <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1">
                                                <strong> to </strong>
                                            </div>
                                            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
                                                <DatePicker
                                                    selected={dateRange[0].endDate}
                                                    onChange={date => this.dateChange(date, 'end')}
                                                    selectsEnd
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    startDate={dateRange[0].startDate}
                                                    endDate={dateRange[0].endDate}
                                                    minDate={dateRange[0].startDate}
                                                    dateFormat="dd/MM/yyyy"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-content pagination2 table-responsive">
                                <DataTable columns={columns} data={records} loading={loading} filterDefaultText="Filter By Name" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}