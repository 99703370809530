import React, { useState, memo, useEffect, useCallback } from "react";
import axios from "../../utils/axios";
import moment from "moment";
import * as yup from "yup";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { Container } from "@mui/system";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Divider,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataGrid } from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers";
import HotelAutoComplete from "../../components/Hotel/HotelAutoComplete";

const yesterdayEndUTC = moment().utc().subtract(1, 'days').endOf('day').toDate();
console.log(yesterdayEndUTC);

const ExecuteBilling = () => {
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statements, setStatements] = useState([]);

  const initialValues = {
    periodName: "",
    startDate: null,
    endDate: null,
    scope: "all",
    scopeHotel: [],
  };

  const columns = [
    {
      headerName: "BILLING PERIOD NAME",
      field: "statementName",
      type: "string",
      width: 300,
      renderCell: (params) => {
        if (!params.value) {
          return "";
        }
        return <a href={`/execute-billing/${params.id}`}>{params.value}</a>;
      },
    },
    {
      headerName: "BILLING START DATE",
      field: "statementStart",
      type: "date",
      width: 200,
      valueFormatter: (params) => {
        if (!params.value) {
          return "";
        }
        return moment(params.value).format("DD/MM/YYYY");
      },
    },
    {
      headerName: "BILLING END DATE",
      field: "statementEnd",
      type: "date",
      width: 200,
      valueFormatter: (params) => {
        if (!params.value) {
          return "";
        }
        return moment(params.value).format("DD/MM/YYYY");
      },
    },
    {
      headerName: "BILLING HOTELS",
      field: "statementGenerated",
      type: "string",
      width: 200,
      valueFormatter: (params) => {
        if (!params.value) {
          return "";
        }
        return params.value.length;
      },
    },
    {
        headerName: "GENERATED DATE",
        field: "createdAt",
        type: "date",
        width: 200,
        valueFormatter: (params) => {
          if (!params.value) {
            return "";
          }
          return moment(params.value).format("DD/MM/YYYY");
        },
      },
  ];

  const getReservationStatements = useCallback(async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/reservationStatement/get`
      );
      setLoading(false);
      setStatements(res.data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const onSubmit = async (values) => {
    try {
      setLoad(true);
      const res = await axios.post("/reservation/generateReport", values);
      setLoad(false);
      if (res.status === 200) {
        if (res.data === "empty") {
          toast.warning("Records already processed.");
        } else {
          await getReservationStatements();
          toast.success("Reservation Statement generated successfully");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validationSchema = yup.object().shape({
    periodName: yup.string().required(),
    startDate: yup.date().required(),
    endDate: yup
      .date()
      .min(yup.ref("startDate"), "End date must be greater than start date")
      .required(),
    scope: yup.string().oneOf(["all", "hotel"]).required(),
    scopeHotel: yup.array().when("scope", {
      is: "hotel",
      then: (schema) => schema.min(1, "Select hotel"),
    }),
  });

  useEffect(() => {
    getReservationStatements();
  }, [getReservationStatements]);

  return (
    <>
      <Typography variant="h4" color="initial">
        Execute Billing
      </Typography>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnBlur={true}
        validateOnChange={true}
      >
        {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
          <Container
            sx={{
              mt: 2,
              py: 2,
              borderRadius: 1,
              backgroundColor: "white",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <FormControl
              sx={{ m: 1, minWidth: "30ch", flex: 1 }}
              variant="outlined"
            >
              <TextField
                variant="outlined"
                type="text"
                label="Billing Period Name"
                value={values.periodName || ""}
                onChange={handleChange("periodName")}
                error={!!errors.periodName}
                helperText={errors.periodName}
                autoFocus
              />
            </FormControl>
            <Divider sx={{ my: 1 }} />
            <div>
              <FormControl sx={{ m: 1, width: "30ch" }} variant="outlined">
                <DatePicker
                  showToolbar
                  label="Start date"
                  value={values.startDate}
                  inputFormat="DD/MM/YYYY"
                  onChange={(_date) => {
                    setFieldValue(
                      "startDate",
                      _date.startOf("day").utcOffset(0, true)
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.startDate}
                      helperText={errors.startDate}
                    />
                  )}
                />
              </FormControl>
              <FormControl sx={{ m: 1, width: "30ch" }} variant="outlined">
                <DatePicker
                  showToolbar
                  inputFormat="DD/MM/YYYY"
                  value={values.endDate}
                  label="End date"
                  onChange={(_date) => {
                    setFieldValue(
                      "endDate",
                      _date.utc().endOf('day').toDate()
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.endDate}
                      helperText={errors.endDate}
                    />
                  )}
                  maxDate={yesterdayEndUTC}
                />
              </FormControl>
            </div>
            <div>
              <FormControl
                sx={{ m: 1, width: "30ch" }}
                variant="outlined"
                error={!!errors.scope}
              >
                <InputLabel id="demo-simple-select-scope-label">
                  Scope
                </InputLabel>
                <Select
                  labelId="demo-simple-select-scope-label"
                  id="demo-simple-select-scope"
                  label="Scope"
                  value={values.scope}
                  onChange={handleChange("scope")}
                >
                  <MenuItem value={"all"}>All Hotels</MenuItem>
                  <MenuItem value={"hotel"}>Hotel</MenuItem>
                </Select>
                <FormHelperText>{errors.scope}</FormHelperText>
              </FormControl>
            </div>
            <div>
              {values.scope === "hotel" && (
                <FormControl
                  sx={{ m: 1, width: "25ch" }}
                  variant="outlined"
                  error={!!errors.scopeHotel}
                >
                  <HotelAutoComplete
                    value={values.scopeHotel}
                    onChange={(value) => setFieldValue("scopeHotel", value)}
                  />
                  <FormHelperText>{errors.scopeHotel}</FormHelperText>
                </FormControl>
              )}
            </div>
            <div style={{ marginTop: "15px", padding: "7px" }}>
              <LoadingButton
                loading={load}
                variant="contained"
                onClick={handleSubmit}
              >
                Generate Reservation Statement
              </LoadingButton>
            </div>
          </Container>
        )}
      </Formik>
      <Container
        sx={{
          mt: 2,
          py: 2,
          borderRadius: 1,
          backgroundColor: "white",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography variant="h5" color="initial">
          Billing Period
        </Typography>
        <DataGrid
          sx={{ border: 0 }}
          getRowId={(row) => row._id}
          autoHeight
          columns={columns}
          rows={statements}
          loading={loading}
        />
      </Container>
    </>
  );
};

export default memo(ExecuteBilling);
