import React, { Component } from 'react';
import axios from "axios";
import Logo from "../../assets/images/TimeFlyz.png";
import Loader from "../../components/Loader";
import moment from 'moment';

export default class BillingDetails extends Component {
    state = {
        detailsArray: [],
        propertyId: '',
        loading: true
    }
    componentDidMount() {
        let propertyId = this.props.match.params.propertyId;
        let invoiceDetailsId = this.props.match.params.invoiceId;
        this.fetchInvoiceDetails(invoiceDetailsId, propertyId);
    }

    fetchInvoiceDetails = async (invoiceDetailsId, propertyId) => {
        let token = await localStorage.getItem('token');
        await axios.post(`${process.env.REACT_APP_API_URL}/invoice`, { "hotelId": propertyId }, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then(response => {
            let filterDetails = response.data.filter((data) => {
                return data?.id === invoiceDetailsId
            })
            this.setState({
                propertyId,
                detailsArray: filterDetails[0],
                loading: false
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    downloadPDF = (pdfFile) => {
        window.location.href = `${pdfFile}`
    }

    render() {
        const { history } = this.props
        const { detailsArray, propertyId, loading } = this.state;
        if(loading){
            return(
                <div className="panel" style={{minHeight:'inherit'}}>
                    <div className="panel-content">
                        <div className="row invoice-page">
                            <div className="col-md-12 p-t-0" style={{textAlignLast: 'center'}}>
                                <Loader />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }else{
            return (
                <div>
                    <div className="header">
                        <h2><strong>Invoice</strong> {detailsArray.number}</h2>
                        <div className="breadcrumb-wrapper">
                            <ol className="breadcrumb">
                                <li>
                                    <a href={() => null} onClick={() => history.push("/dashboard")}>TimeFlyz</a>
                                </li> 
                                <li>
                                    <a href={() => null} onClick={() => history.push("/property/all")}>Property Management</a>
                                </li>
                                <li>
                                    <a href={() => null} onClick={() => history.push(`/billing/${propertyId}`)}>Billing</a>
                                </li>
                                <li className="active">Invoice Details</li>
                            </ol>
                        </div>
                    </div>
                    <div className="panel">
                        <div className="panel-content">
                            <div className="m-t-10 m-b-10" style={{textAlign:'end'}}>
                                <button type="button" onClick={() => this.downloadPDF(detailsArray.invoice_pdf)} className="btn btn-white m-r-10 m-b-10"><i className="fa fa-print m-r-10" /> Print Invoice</button>
                                <button type="button" onClick={() => this.downloadPDF(detailsArray.invoice_pdf)} className="btn btn-white m-r-10 m-b-10"><i className="fa fa-file-text m-r-10" /> Export as PDF</button>
                                <button type="button" className="btn btn-white m-r-10 m-b-10"><i className="fa fa-envelope m-r-10" /> Send by email</button>
                            </div>
                            <div className="row invoice-page">
                                <div className="col-md-12 p-t-0">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="pull-left">
                                                <img src={Logo} className="img-responsive" alt="Timeflyz" style={{ height: 50 }} />
                                                <address>
                                                    <p className="width-300">NAB House Ground Floor 255 George St</p>
                                                    <p className="width-300">Sydney NSW 2000</p>
                                                    <p className="width-300">Australia</p>
                                                    <p className="width-300">+61 448 884 488</p>
                                                    <p className="width-300">sachin.sabharwal@gmail.com</p>
                                                    <p className="width-300 m-t-30" style={{fontWeight:'bold'}}>Bill to</p>
                                                    <p className="width-300">{detailsArray.customer_name}</p>
                                                    <p className="width-300">{detailsArray.customer_email}</p>
                                                    <p className="width-300">{detailsArray.customer_address}</p>
                                                </address>
                                            </div>
                                            <div className="pull-right" style={{marginTop:-7}}>
                                                <h2 style={{textAlign:'end'}}>Invoice</h2>
                                                <address>
                                                    <p className="width-300">Invoice Number <strong style={{float:'right'}}>{detailsArray.number}</strong></p>
                                                    <p className="width-300">Date of issue <strong style={{float:'right'}}>{moment(detailsArray.created * 1000).utc().format("MMM D YYYY")}</strong></p>
                                                    {
                                                        detailsArray.paid ? 
                                                        <p className="width-300">Date Paid <strong style={{float:'right'}}>{moment(detailsArray.status_transitions.paid_at * 1000).utc().format("MMM D YYYY")}</strong></p>
                                                        :
                                                        <p className="width-300">Date Due <strong style={{float:'right'}}>{moment(detailsArray.due_date * 1000).utc().format("MMM D YYYY")}</strong></p>
                                                    }
                                                </address>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 m-t-20 m-b-20">
                                            <h1 style={{marginLeft:10, fontWeight:'bold'}}>${parseFloat(detailsArray.total/100).toFixed(2)} {detailsArray.paid ? ' paid on ' : ' due '} {moment(detailsArray.due_date ? detailsArray.due_date : detailsArray.status_transitions.paid_at * 1000).utc().format("MMM D YYYY")}</h1>
                                            <p style={{marginLeft:10}}>{detailsArray.description}</p>
                                            <table className="table table-striped m-t-30">
                                                <thead>
                                                    <tr>
                                                        <th className="text-left">Description</th>
                                                        <th className="text-left">Qty</th>
                                                        <th className="text-right">Unit price </th>
                                                        <th className="text-right">Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        detailsArray.lines !== undefined && detailsArray.lines.data.map((eachData,index) => {
                                                            return(
                                                                <tr className="item-row" key={index}>
                                                                    <td className="delete-wpr">
                                                                        <p className="qty text-left">{eachData.description}</p>
                                                                    </td>
                                                                    <td className="text-center price">{eachData.quantity}</td>
                                                                    <td>
                                                                        <p className="text-right cost">${parseFloat(eachData.price.unit_amount/100).toFixed(2)}</p>
                                                                    </td>
                                                                    <td className="text-right price">${parseFloat(eachData.amount/100).toFixed(2)}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    <tr>
                                                        <td colSpan={2} />
                                                        <td className="text-right"><strong>Subtotal</strong></td>
                                                        <td className="text-right" id="subtotal">${detailsArray.subtotal !== undefined ? parseFloat(detailsArray.subtotal/100).toFixed(2) : 0.00}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2} />
                                                        <td className="text-right"><strong>Amount {detailsArray.paid ? 'Paid' : 'Due'}</strong></td>
                                                        <td className="text-right" id="subtotal">${detailsArray.total !== undefined ? parseFloat(detailsArray.total/100).toFixed(2) : 0.00}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className="bg-white" style={{borderTop:'1px solid #000'}}></div>
                                            {
                                                detailsArray.paid ? 
                                                null : 
                                                <div className="bg-white" style={{marginTop:20}}>
                                                    <strong>Pay ${detailsArray.total !== undefined ? parseFloat(detailsArray.total/100).toFixed(2) : 0.00} with card </strong> <br />
                                                    Visit <a href={detailsArray.hosted_invoice_url} target="_blank" rel="noreferrer">{detailsArray.hosted_invoice_url}</a>
                                                </div>
                                            }
                                            <div className="bg-white" style={{marginTop:20}}>
                                            <strong>Questions?</strong> Contact TimeFlyz at sachin.sabharwal@gmail.com or call at <br /> +61 448 884 488. 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
            )
        }
    }
}