import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";

import Login from "../pages/Login";
import Signup from "../pages/Signup";
import OTPVerification from "../pages/OTPVerification";
import LockScreen from "../pages/LockScreen";

import contentManageHome from "../pages/ContentManage";
import ContactUs from "../pages/ContactUs";
import ContactUsSEO from "../pages/ContactUs/seo";
import Subscription from "../pages/ContactUs/listSubscription";
import ContactUsList from "../pages/ContactUs/listContact";
import TermsOfUSe from "../pages/TermsOfUse";
import TermsOfUseSEO from "../pages/TermsOfUse/seo";
import TermsOfUseHotel from "../pages/TermsOfUseHotel";
import TermsOfUseSEOHotel from "../pages/TermsOfUseHotel/seo";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import PrivacyPolicySEO from "../pages/PrivacyPolicy/seo";
import Faq from "../pages/FAQ";
import FAQDetails from "../pages/FAQDetails";
import FaqSEO from "../pages/FAQ/seo";
import AboutTimeflyz from "../pages/AboutTimeflyz";
import AboutTimeflyzSEO from "../pages/AboutTimeflyz/seo";
import PressRoom from "../pages/PressRoom";
import PressRoomSEO from "../pages/PressRoom/seo";
import Dashboard from "../pages/Dashboard";
import AddHotel from "../pages/Hotels/AddHotel";
import EditHotel from "../pages/Hotels/EditHotel";
import HotelsListing from "../pages/Hotels/HotelsListing";
import AddProperty from "../pages/Property/AddProperty";
import EditProperty from "../pages/Property/EditProperty";
import PropertyListing from "../pages/Property/PropertyListing";
import Billing from "../pages/Billing";
import BillingDetails from "../pages/BillingDetails";
import Reporting from "../pages/Reporting";
import MonthlyFailedPayments from "../pages/ReportingMonthlyFailedPayments";
import PropertyType from "../pages/PropertyType";
import GroupRegionalManager from "../pages/GroupRegionalManagers";
import ExpediaHotelURLError from "../pages/ExpediaHotelURLError";
import Customers from "../pages/Customers";
import CustomersAdd from "../pages/Customers/addAdmin";
import CustomerReservations from "../pages/Customers/customerReservations";
import Reservations from "../pages/Reservations";
import ReservationDetails from "../pages/ReservationDetails";
import Pricing from "../pages/Pricing";
import NoShowApproved from "../pages/NoShow/approved";
import NoShowUnapproved from "../pages/NoShow/unapproved";
import UserListing from "../pages/Users/index";
import Users from "../pages/Users/all";
import UsersForm from "../pages/Users/UserForm";
import AddUser from "../pages/Users/AddUser";
import changePassword from "../pages/Users/changePassword";
import ReportMissingPropertyID from "../pages/ReportMissingPropertyID";
import ReportingPropertyType from "../pages/ReportingPropertyType";
import Reviews from "../pages/Reviews";
import Coupons from "../pages/Coupons";
import AddCoupon from "../pages/Coupons/AddCoupon";
import UpdateCoupon from "../pages/Coupons/UpdateCoupon";
import RateGain from "../pages/Logs/RateGain";
import CancellationPeriod from "../pages/Logs/CancellationPeriod";
import ExchangeRateGain from "../pages/Logs/ExchangeRateGain";
import Archives from "../pages/Archives";
import Vatgst from "../pages/Vatgst/index";
import AddTicket from "../pages/Tickets/AddTicket";
import AddMyTicket from "../pages/Tickets/AddMyTicket";
import AddVatGst from "../pages/Vatgst/AddVatGst";
import EditVatgst from "../pages/Vatgst/EditVatgst";
import ExchangeRateHistory from "../pages/Logs/ExchangeRateHistory"
import TicketListing from "../pages/Tickets/index";
import MyTicketListing from "../pages/Tickets/myTickets";
import ImageListing from "../pages/Images/index";
import RoleEditForm from "../pages/Users/RoleEditForm";
import ExecuteBilling from "../pages/ExecuteBilling";
import ExecuteBillingDetail from "../pages/ExecuteBilling/detail";
import AddDepartment from "../pages/Department/AddDepartment";
import EditDepartment from "../pages/Department/EditDepartment";
import DepartmentListing from "../pages/Department";
import ViewTicket from "../pages/Tickets/ViewTicket";
import ViewMyTicket from "../pages/Tickets/ViewMyTicket";
import MyUserList from "../pages/MyUsers";

export default function Routes() {
  return (
    <Switch>
      <Route path={`/dashboard`} exact component={Dashboard} isPrivate />
      <Route
        path={`/content-home`}
        exact
        component={contentManageHome}
        isPrivate
      />
      <Route path={`/contactUs`} exact component={ContactUs} isPrivate />
      <Route path={`/contactUs/seo`} exact component={ContactUsSEO} isPrivate />
      <Route
        path={`/contactUs/list`}
        exact
        component={ContactUsList}
        isPrivate
      />
      <Route
        path={`/contactUs/subscription`}
        exact
        component={Subscription}
        isPrivate
      />
      <Route path={`/terms`} exact component={TermsOfUSe} isPrivate />
      <Route path={`/terms/seo`} exact component={TermsOfUseSEO} isPrivate />
      <Route
        path={`/terms-hotel`}
        exact
        component={TermsOfUseHotel}
        isPrivate
      />
      <Route
        path={`/terms-hotel/seo`}
        exact
        component={TermsOfUseSEOHotel}
        isPrivate
      />
      <Route path={`/privacy`} exact component={PrivacyPolicy} isPrivate />
      <Route
        path={`/privacy/seo`}
        exact
        component={PrivacyPolicySEO}
        isPrivate
      />
      <Route path={`/faq`} exact component={Faq} isPrivate />
      <Route path={`/faq/seo`} exact component={FaqSEO} isPrivate />
      <Route path={`/faq/:fqaID`} exact component={FAQDetails} isPrivate />
      <Route path={`/about`} exact component={AboutTimeflyz} isPrivate />
      <Route path={`/about/seo`} exact component={AboutTimeflyzSEO} isPrivate />
      <Route path={`/pressRoom`} exact component={PressRoom} isPrivate />
      <Route path={`/pressRoom/seo`} exact component={PressRoomSEO} isPrivate />
      <Route path={`/addHotel`} exact component={AddHotel} isPrivate />
      <Route
        path={`/editHotel/:hotelId`}
        exact
        component={EditHotel}
        isPrivate
      />
      <Route path={`/hotels`} exact component={HotelsListing} isPrivate />
      <Route path={`/addProperty`} exact component={AddProperty} isPrivate />
      <Route
        path={`/editProperty/:propertyId`}
        exact
        component={EditProperty}
        isPrivate
      />
      <Route
        path={`/property/:hotelAdminID`}
        exact
        component={PropertyListing}
        isPrivate
      />
      <Route
        path={`/billing/:propertyId`}
        exact
        component={Billing}
        isPrivate
      />
      <Route
        path={`/billingDetails/:propertyId/:invoiceId`}
        exact
        component={BillingDetails}
        isPrivate
      />

      <Route
        path={`/monthlyBillingPaymentFails`}
        exact
        component={MonthlyFailedPayments}
        isPrivate
      />
      <Route
        path={`/missingPropertyID`}
        exact
        component={ReportMissingPropertyID}
        isPrivate
      />
      <Route path={`/coupons`} exact component={Coupons} isPrivate />
      <Route path={`/coupons/create`} exact component={AddCoupon} isPrivate />
      <Route path={`/coupons/:id`} exact component={UpdateCoupon} isPrivate />
      <Route path={`/reporting`} exact component={Reporting} isPrivate />
      <Route
        path={`/expediaURLError`}
        exact
        component={ExpediaHotelURLError}
        isPrivate
      />
      <Route path={`/propertyType`} exact component={PropertyType} isPrivate />
      <Route
        path={`/reportingPropertyType`}
        exact
        component={ReportingPropertyType}
        isPrivate
      />
      <Route
        path={`/groupRegionalManagers`}
        exact
        component={GroupRegionalManager}
        isPrivate
      />
      <Route path={`/all-useradmins`} exact component={Customers} isPrivate />
      <Route path={`/add-useradmins`} exact component={CustomersAdd} isPrivate />
      <Route
        path={`/customerRecords/:customerID`}
        exact
        component={CustomerReservations}
        isPrivate
      />
      <Route path={`/reservations`} exact component={Reservations} isPrivate />
      <Route
        path={`/reservationDetails/:reserveID`}
        exact
        component={ReservationDetails}
        isPrivate
      />
      <Route
        path={`/noShow/approved`}
        exact
        component={NoShowApproved}
        isPrivate
      />
      <Route
        path={`/noShow/unapproved`}
        exact
        component={NoShowUnapproved}
        isPrivate
      />
      <Route path={`/pricing`} exact component={Pricing} isPrivate />
      <Route path={`/reviews/:hotelId`} exact component={Reviews} isPrivate />
      <Route path={`/users`} exact component={UserListing} isPrivate />
      <Route path={`/add-ticket`} exact component={AddTicket} isPrivate />
      <Route path={`/add-myticket`} exact component={AddMyTicket} isPrivate />
      <Route path={`/all-tickets`} exact component={TicketListing} isPrivate />
      <Route path={`/all-mytickets`} exact component={MyTicketListing} isPrivate />
      <Route path={`/view-ticket`} exact component={ViewTicket} isPrivate />
      <Route path={`/view-myticket`} exact component={ViewMyTicket} isPrivate />

      <Route path={`/edit-department/:departmentId`} exact component={EditDepartment} isPrivate />
      <Route path={`/add-department`} exact component={AddDepartment} isPrivate />
      <Route path={`/all-departments`} exact component={DepartmentListing} isPrivate />

      <Route path={`/all-images`} exact component={ImageListing} isPrivate />
      <Route path={`/users-all`} exact component={Users} isPrivate />
      <Route path={`/users/create`} exact component={AddUser} isPrivate />
      <Route path={`/users/change-password`} exact component={changePassword} isPrivate />
      <Route
        path={`/users-all/:userId`}
        exact
        component={UsersForm}
        isPrivate
      />
      <Route
        path={`/users-all/:userId/role-management`}
        exact
        component={RoleEditForm}
        isPrivate
      />
      <Route path={`/logs/rate-gain`} exact component={RateGain} isPrivate />
      <Route path={`/logs/exchange-rate-gain`} exact component={ExchangeRateGain} isPrivate />
      <Route
        path={`/logs/cancellation-period`}
        exact
        component={CancellationPeriod}
        isPrivate
      />
      <Route path={`/vatgst`} exact component={Vatgst} isPrivate />
      <Route path={`/add-vat`} exact component={AddVatGst} isPrivate />
      <Route path={`/edit-vat/:vatId`} exact component={EditVatgst} isPrivate />
      <Route path={`/exchange-rate-history`} exact component={ExchangeRateHistory} isPrivate />
      <Route path={`/archives`} exact component={Archives} isPrivate />
      <Route
        path={`/execute-billing`}
        exact
        component={ExecuteBilling}
        isPrivate
      />
      <Route
        path={`/execute-billing/:billingId`}
        exact
        component={ExecuteBillingDetail}
        isPrivate
      />

      <Route path={`/login`} exact component={Login} />
      <Route path={`/signup`} exact component={Signup} />
      <Route path={`/otpVerify`} exact component={OTPVerification} />
      <Route path={`/lock`} exact component={LockScreen} />
      <Route component={Login} />
      <Route path={`/all-useradmins`} exact component={MyUserList} isPrivate />
    </Switch>
  );
}
