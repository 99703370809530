import React, { Component } from 'react';
import axios from 'axios';
import Loader from '../../components/Loader';
import moment from 'moment';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { toast } from 'react-toastify';
import SweetAlert from 'react-bootstrap-sweetalert';
import DataTable from "../../components/DataTable";

const columns = [
    {
        name: 'RESERVATION NUMBER',
        selector: row => row.bookingID,
        sortable: true,
    },
    {
        name: 'PROPERTY',
        selector: row => row.propertyName,
        sortable: true,
        grow: 2,
    },
    {
        name: 'LENGTH OF STAY',
        selector: row => row.duration,
        sortable: true,
    },
    {
        name: 'DATE OF RESERVATION',
        selector: row => row.createdAt,
        sortable: true,
    },
    {
        name: 'REVENUE',
        selector: row => row.amount,
        sortable: true,
    },
    {
        name: 'STATUS',
        selector: row => row.status,
        sortable: true,
    }
];

export default class CustomerReservations extends Component {
    state = {
        records: [],
        loading: true,
        blockAllProperties: false,
        dataList: {},
        alertShow: false,
        alertBoxTitle: '',
        alertBoxType: '',
        alertBoxData: null,
    }
    componentDidMount() {
        this.fetchCustomer();
        this.fetchBlockList();
    }
    onCancel = () => {
        this.setState({
            alertShow: false,
            alertBoxTitle: '',
            alertBoxType: '',
            alertBoxData: null,
        })
    }
    async fetchCustomer() {
        let token = await localStorage.getItem('token');
        await axios.get(`${process.env.REACT_APP_API_URL}/user/customer`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then(response => {
            if (response.status === 200) {
                let findIndex = Object.keys(response.data).indexOf(this.props.match.params.customerID);
                let array = [];
                if(Object.values(response.data)[findIndex][0].reserve.charge_details.length > 0){
                    Object.values(response.data)[findIndex].forEach(element => {
                        array.push({
                            _id: element._id,
                            bookingId: element.reserve.bookingId,
                            bookingID: <a href={`/reservationDetails/${element.reserve._id}`}>{element.reserve.bookingId}</a>,
                            name: element.reserve.hotel[0].name,
                            propertyName: <a href={`/editHotel/${element.reserve.hotel[0]._id}`}>{element.reserve.hotel[0].name}</a>,
                            duration: element.reserve.duration + 'h',
                            createdAt: moment(element.reserve.charge_details[0].createdAt).utc().format("MMM D YYYY"),
                            amount: '$' + parseFloat(element.reserve.charge_details[0].amount).toFixed(2),
                            status: element.reserve.status,
                        })
                    });
                }

                this.setState({
                    customerRecords: Object.values(response.data)[findIndex],
                    records: array,
                    loading: false,
                }, () => {
                    this.fetchBannedFromAllProperties();
                })
            }
        })
    }

    fetchBlockList = async() => {
        let token = await localStorage.getItem('token');
        await axios.post(`${process.env.REACT_APP_API_URL}/hotel/blockList`, {
            userId: this.props.match.params.customerID,
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then(response => {
            if (response.status === 200) {
                this.setState({
                    dataList: response.data,
                })
            }
        })
    }

    fetchBannedFromAllProperties = async() => {
        let token = await localStorage.getItem('token');  
        const {customerRecords} = this.state;
        await axios.post(`${process.env.REACT_APP_API_URL}/hotel/getblockInfo`, {
            "userId": customerRecords[0]._id
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then(response => {

            if(response.status === 200){
                this.setState({
                    blockAllProperties: response.data
                })
            }
            
        })
    }

    changeStatus = (e) => {
        const { value } = e.target;
        if(value === 'Ban guest from all properties'){
            this.blockAllProperties(true);
        }
    }

    handlePageChange(pageNumber) {
        const { customerRecords, pageLimit } = this.state;
        const offset = (pageNumber - 1) * pageLimit;
        const currentList = Object.values(customerRecords).slice(offset, offset + pageLimit);
        const showingFrom = offset + 1;
        const showingTo = offset + pageLimit;
        this.setState({ customerRecords, currentList, activePage: pageNumber, showingFrom, showingTo });
    }

    onChangeAllProperties = (e) => {
        const {checked} = e.target;
        this.blockAllProperties(checked);
    }

    async blockAllProperties(bool){
        const {customerRecords} = this.state;
        let token = await localStorage.getItem('token');
        await axios.put(`${process.env.REACT_APP_API_URL}/hotel/blockAll`, {blockUserId: customerRecords[0]._id, ban: bool}, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then(response => {
            if(response.data === 'OK'){
                let message = bool ? 'Banned Successfully From All Properties' : 'Banned Removed Successfully From All Properties'
                toast.success(message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.componentDidMount();
            } else {
                toast.error('Something went wrong, Please try again...', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            
        })
        .catch(error => {
            console.log(error);
        })
    }

    blockSingle = async(array, e) => {
        const {checked} = e.target;
        const details = {
            "blockUserId": array.bookedBy,
            "hotel": array.hotelId._id,
            "ban": checked,
        };
        let token = await localStorage.getItem('token');
        await axios.put(`${process.env.REACT_APP_API_URL}/hotel/blockSingle`, details, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then(response => {
            if(response.data === 'OK'){
                toast.success('Banned Successfully From This Property', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.componentDidMount();
            } else {
                toast.error('Something went wrong, Please try again...', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            
        })
        .catch(error => {
            console.log(error);
        })
    }
    
    showAlertBox = (value,alertType) => {
        let title = alertType === 'Reset' ? `Please confirm you wish to reset guest password for ${value.firstName} ${value.lastName}` : `${alertType} ?`;
        this.setState({
            alertBoxData: value,
            alertBoxType: alertType,
            alertBoxTitle: title,
            alertShow: true,
        })
    }
    

    confirmFunc(){
        const {alertBoxData} = this.state;
        this.setState({
            alertShow: false
        }, async () => {
            const details = {
                "email": alertBoxData.email,
                "userId": alertBoxData._id
            };
            let token = await localStorage.getItem('token');
            await axios.post(`${process.env.REACT_APP_API_URL}/user/resetPassword`, details, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })
            .then(response => {
                if(response.data === 'OK'){
                    toast.success('Password Reset Email sent Successfully', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.componentDidMount();
                } else {
                    toast.error('Something went wrong, Please try again...', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                
            })
            .catch(error => {
                console.log(error);
            })
        })
    }

    render() {
        const { customerRecords, loading, blockAllProperties, dataList, alertShow, alertBoxTitle, records } = this.state;
        if (loading) {
            return (
                <div>
                    <div className="header">
                        <h2><strong>Manage</strong> Customer Records</h2>
                        <div className="breadcrumb-wrapper">
                            <ol className="breadcrumb">
                                <li><a href="/dashboard">TimeFlyz</a></li>
                                <li><a href="/customers">Customers</a></li>
                                <li className="active">Customer Records</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlignLast: 'center' }}>
                            <Loader />
                        </div>
                    </div>
                </div>
            )
        } else {
            let totalRevenue = 0;
            let totalBookings = 0;
            if(customerRecords[0].reserve.charge_details.length > 0){
                customerRecords.forEach(data => {
                    totalRevenue += data.reserve.charge_details[0].amount;
                    totalBookings++ ;
                })
            } else {
                totalRevenue = 0;
                totalBookings = 0;
            }
            return (
                <div>
                    <SweetAlert
                        show={alertShow}
                        warning
                        showCancel
                        confirmBtnText="Confirm"
                        confirmBtnBsStyle="danger"
                        title={alertBoxTitle}
                        onConfirm={() => this.confirmFunc()}
                        onCancel={() => this.onCancel()}
                        focusCancelBtn
                    />
                    <div className="header">
                        <h2><strong>Manage</strong> Customer Records</h2>
                        <div className="breadcrumb-wrapper">
                            <ol className="breadcrumb">
                                <li><a href="/dashboard">TimeFlyz</a></li>
                                <li><a href="/customers">Customers</a></li>
                                <li className="active">Customer Records</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="panel">
                                <div className="panel-header ">
                                    <div className="row">
                                        <div className="col-sm-12 col-xs-12 col-md-8 col-lg-8">
                                            <h3><strong>Customer Information</strong> </h3>
                                        </div>
                                        <div className="col-sm-12 col-xs-12 col-md-4 col-lg-4" style={{textAlign:'end'}}>
                                            <div className="row">
                                                <div className="col-sm-12 col-xs-12 col-md-12 col-lg-5">
                                                    <b>Ban guest from all properties</b>
                                                    <label className="switch switch-green"> 
                                                        <input type="checkbox" className="switch-input" checked={blockAllProperties} onChange={this.onChangeAllProperties} />
                                                        <span className="switch-label" data-on="On" data-off="Off" ></span>
                                                        <span className="switch-handle" />
                                                    </label>
                                                </div>
                                                <div className="col-sm-12 col-xs-12 col-md-12 col-lg-7">
                                                    <button className="btn btn-danger btn-block" style={{height:'50px'}} onClick={() => this.showAlertBox(customerRecords[0],'Reset')}>Reset Guest Password</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-content">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <h5>First Name</h5>
                                            <p>{customerRecords[0].firstName ? customerRecords[0].firstName : 'N/A'}</p>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <h5>Last Name</h5>
                                            <p>{customerRecords[0].lastName ? customerRecords[0].lastName : 'N/A'}</p>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <h5>Email</h5>
                                            <p>{customerRecords[0].email}</p>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <h5>Phone</h5>
                                            <p>{customerRecords[0].contactNo ? customerRecords[0].contactNo : 'N/A'}</p>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <h5>Total Bookings</h5>
                                            <p>{totalBookings}</p>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <h5>Account Type</h5>
                                            <p>{customerRecords[0].provider === 'Guest' ? 'Guest' : 'Customer'}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-header">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <h3><strong>Bookings</strong></h3>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{ textAlign: 'end' }}>
                                            <h5><strong>Total Revenue: $ {parseFloat(totalRevenue).toFixed(2)}</strong></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-content pagination2 table-responsive">
                                    <DataTable columns={columns} data={records} loading={loading} filterDefaultText="Filter By Reservation Number, Name" />
                                </div>
                                <div className="panel-header">
                                    <h3><strong>Banned Properties</strong></h3>
                                    <span>Customer Banned From: </span>
                                </div>
                                {
                                    customerRecords[0].reserve.charge_details.length > 0 ? 
                                    <div className="panel-content">
                                        {/*<div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                <strong>All Properties</strong>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{ textAlign: 'end' }}>
                                                <label className="switch switch-green">
                                                    <input type="checkbox" className="switch-input" checked={blockAllProperties} onChange={this.onChangeAllProperties} />
                                                    <span className="switch-label" data-on="On" data-off="Off" />
                                                    <span className="switch-handle" />
                                                </label>
                                            </div>
                                        </div>*/}
                                        <Accordion className="panel-accordion dark-accordion">
                                            {
                                                Object.keys(dataList).map((data, index) => {
                                                    let displayData = Object.values(dataList[data]);
                                                    let array = [];
                                                    return (
                                                        <AccordionItem className="panel panel-default" key={index}>
                                                            <AccordionItemHeading className="panel-heading">
                                                                <AccordionItemButton>
                                                                    {Object.keys(dataList)[index]}
                                                                </AccordionItemButton>
                                                            </AccordionItemHeading>
                                                            <AccordionItemPanel>
                                                                <div className="table-responsive">
                                                                    <table className="table table-hover table-dynamic dataTable">
                                                                        <tbody>
                                                                            {
                                                                                displayData.map((eachData, eachIndex) => {
                                                                                    if(!array.includes(eachData.hotelId._id)){
                                                                                        array.push(eachData.hotelId._id);
                                                                                        return (
                                                                                            <tr key={eachIndex}>
                                                                                                <td><strong>{eachData.hotelId.name}</strong></td>
                                                                                                <td>
                                                                                                    <label className="switch switch-green">
                                                                                                        <input type="checkbox" className="switch-input" checked={eachData.block} onChange={(e) => this.blockSingle(eachData,e)} />
                                                                                                        <span className="switch-label" data-on="On" data-off="Off" />
                                                                                                        <span className="switch-handle" />
                                                                                                    </label>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    }
                                                                                    return null;
                                                                                })
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </AccordionItemPanel>
                                                        </AccordionItem>
                                                    )                                
                                                })
                                            }
                                        </Accordion>
                                    </div>
                                    :
                                    <div className="panel-content" style={{textAlign: 'center'}}>
                                        <h5 style={{fontWeight:'bold'}}>No Records</h5>
                                    </div>
                                }
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}