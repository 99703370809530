import React from "react";
import PropTypes from "prop-types";
  
import "../../assets/global/css/style.css";
import "../../assets/global/css/ui.css";
import "./style.css";

export default function DefaultLayout({ children }) {
  return(
    <>
      <div className="account separate-inputs" data-page="login">{children}</div>
    </>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired
};
