import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import { Container } from "@mui/system";
import { Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataGrid } from "@mui/x-data-grid";
import ConfirmModal from "../../components/atoms/ConfirmModal";

const ExecuteBillingDetail = () => {
    const { billingId } = useParams();
    const [load, setLoad] = useState(false);
    const [loading, setLoading] = useState(false);
    const [statement, setStatement] = useState();
    const [selectedRow, setSelectedRow] = useState("");
    const [openConfirmModal, setOpenConfirmModal] = useState(false);

    const columns = [
        {
            headerName: "HOTEL NAME",
            field: "hotelId",
            type: "string",
            width: 300,
            valueFormatter: (params) => {
                if (!params.value) {
                    return "";
                }
                return params.value.name;
            },
        },
        {
            headerName: "RESERVATION STATEMENT STATUS",
            field: "statementUrl",
            type: "string",
            width: 250,
            renderCell: (params) => {
                if (params.row.emailToHotel) {
                    return (
                        <a target="_blank" rel="noreferrer" href={params.value}>
                            Sent to hotel
                        </a>
                    );
                }
                return (
                    <a target="_blank" rel="noreferrer" href={params.value}>
                        Not Sent
                    </a>
                );
            },
        },
        {
            headerName: "INVOICED STATUS",
            field: "invoiceDownload",
            type: "string",
            width: 150,
            valueGetter: (params) => params.row.invoiceId?.downloadUrl,
            renderCell: (params) => {
                if (!params.value) {
                    return "Not Invoiced";
                }
                return (
                    <a target="_blank" rel="noreferrer" href={params.value}>
                        Invoiced to hotel
                    </a>
                );
            },
        },
        {
            headerName: "INVOICE DATE",
            field: "invoiceDate",
            type: "string",
            width: 110,
            valueGetter: (params) => params.row.invoiceId?.createdAt,
            valueFormatter: (params) => {
                if (!params.value) {
                    return "";
                }
                return moment(params.value).format("DD/MM/YYYY");
            },
        },
        {
            headerName: "BILLING STATUS",
            field: "billingStatus",
            type: "string",
            width: 120,
            valueGetter: (params) => params.row.invoiceId,
            valueFormatter: (params) => {
                if (!params.value) {
                    return "Not Paid";
                }
                return "Paid";
            },
        },
        {
            headerName: "EMAIL RESERVATION STATUS",
            field: "email",
            type: "actions",
            width: 200,
            renderCell: (params) => (
                <LoadingButton
                    loading={load}
                    variant="contained"
                    onClick={() =>
                        emailReservationStatement(
                            params.id,
                            params.row.emailToHotel
                        )
                    }
                >
                    Send
                </LoadingButton>
            ),
        },
        {
            headerName: "GENERATE INVOICE & CHARGE",
            field: "charge",
            type: "actions",
            width: 200,
            renderCell: (params) => (
                <LoadingButton
                    loading={load}
                    variant="contained"
                    disabled={params.row.invoiceId || !params.row.emailToHotel}
                    onClick={() => handleGenerate(params.id)}
                >
                    Send
                </LoadingButton>
            ),
        },
    ];

    const getReservationStatement = useCallback(async () => {
        try {
            setLoading(true);
            const res = await axios.get(
                `${process.env.REACT_APP_API_URL}/reservationStatement/get/${billingId}`
            );
            setStatement(res.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }, [billingId]);

    const handleGenerate = async (generatedId) => {
        try {
            setLoad(true);
            await axios.post("/reservation/generateInvoice", {
                statementId: statement._id,
                generatedId,
            });
            setLoad(false);
            toast.success("Invoice generated successfully");
            await getReservationStatement();
        } catch (error) {
            console.log(error);
            toast.error("Invoice generation has failed");
            setLoad(false);
        }
    };

    const emailReservationStatement = async (generatedId, emailed) => {
        try {
            setLoad(true);
            if (emailed) {
                setLoad(false);
                setSelectedRow(generatedId);
                setOpenConfirmModal(true);
                return;
            }
            await axios.post("/reservation/emailReservationStatement", {
                statementId: statement._id,
                generatedId,
            });
            setLoad(false);
            toast.success("Successfully sent email to Selected Hotel Manager");
            await getReservationStatement();
        } catch (error) {
            console.log(error);
            setLoad(false);
        }
    };

    const handleConfirm = async () => {
        try {
            setOpenConfirmModal(false);
            setLoad(true);
            await axios.post("/reservation/emailReservationStatement", {
                statementId: statement._id,
                generatedId: selectedRow,
            });
            setLoad(false);
            toast.success("Successfully sent email to Selected Hotel Manager");
            await getReservationStatement();
        } catch (error) {
            console.log(error);
            setLoad(false);
        }
    };

    useEffect(() => {
        getReservationStatement();
    }, [getReservationStatement]);

    return (
        <>
            <ConfirmModal
                open={openConfirmModal}
                onConfirm={handleConfirm}
                onClose={() => setOpenConfirmModal(false)}
            />
            <Typography variant="h4" color="initial">
                Billing Statement
            </Typography>
            <Container
                sx={{
                    mt: 2,
                    py: 2,
                    borderRadius: 1,
                    backgroundColor: "white",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
            >
                <Typography variant="h5" color="initial">
                    {statement?.statementName}
                </Typography>
                <DataGrid
                    sx={{ border: 0 }}
                    getRowId={(row) => row._id}
                    autoHeight
                    columns={columns}
                    rows={statement?.statementGenerated || []}
                    loading={loading}
                />
            </Container>
        </>
    );
};

export default ExecuteBillingDetail;
