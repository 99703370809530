import React, {Component} from 'react';
import axios from 'axios';
import DataTable from "../../components/DataTable";
import Graphs from '../../components/ReportingPropertyTypeGraph';
import ReservationBox from '../../components/ReservationBox';
import DatePicker from "react-datepicker";
import { addDays } from 'date-fns';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";

const columns = [
    {
        name: 'RESERVATION NUMBER',
        selector: row => row.bookingID,
        sortable: true,
        wrap: true,
    },
    {
        name: 'SURNAME',
        selector: row => row.lastName,
        sortable: true,
    },
    {
        name: 'PROPERTY',
        selector: row => row.propertyName,
        sortable: true,
        wrap: true,
    },
    {
        name: 'LENGTH OF STAY',
        selector: row => row.duration,
        sortable: true,
    },
    {
        name: 'STATUS',
        selector: row => row.reservationStatus,
        sortable: true,
    },
    {
        name: 'DATE & TIME OF ARRIVAL',
        selector: row => row.reservationStart,
        grow: 2,
    },
    {
        name: 'REVENUE',
        selector: row => row.amount,
        sortable: true,
    },
];

const defaultProps = {
    displayTitle: true,
    displayLegend: true,
    titlePosition: 'top',
    legendPosition: 'top',
    legendAlign: 'center',
}

export default class ReportingPropertyType extends Component{
    state = {
        dateRange: [{
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: 'selection'
        }],
        records: [],
        reservationsArray: [],
        loading: false,
        propertyType: [],
        managerType: '',
    }
    componentDidMount(){
        window.scrollTo({ top: 0, behavior: 'smooth' })
        this.fetchHotelTypes();
        let startDate = this.state.dateRange[0].startDate;
        let endDate = this.state.dateRange[0].endDate;
        this.setState({
            dateRange: [{
                startDate,
                endDate
            }],
        })
    }
    fetchHotelTypes = async () => {
        let token = await localStorage.getItem('token');
        let array = [];
        await axios.post(`${process.env.REACT_APP_API_URL}/admin/commission/list`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then(response => {
            response.data.forEach(element => {
                array.push({
                    name: element.clientTypeName,
                    _id: element._id
                });
            });
            this.setState({
                propertyType: array,
                managerType: array[0]._id
            }, () => {
                this.fetchReservations();
            })
        })
        .catch(error => {
            console.log(error);
        })
    }
    onChange = (e) => {
        const {name, value} = e.target;
        this.setState({
            [name]: value
        },() => {
            this.fetchReservations();
        })
    }
    async fetchReservations() {
        const { dateRange, managerType } = this.state;
        this.setState({ loading: true, records: [], reservationsArray: [] });
        let startDate = moment(dateRange[0].startDate).format('YYYY-MM-DD');
        let endDate = moment(dateRange[0].endDate).format('YYYY-MM-DD');
        const payload = {
            "from": startDate,
            "to": endDate,
            "commission": managerType
        };
        let token = await localStorage.getItem('token');
        await axios.post(`${process.env.REACT_APP_API_URL}/reservation/propertyTypeReporting`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then(response => {
            if (response.status === 200) {
                if(!Object.values(response.data).length){
                    this.setState({loading: false});
                } else {
                    this.setPortfolioReports(response.data);
                }
            }
        })
        .catch(error => {
            console.log(error);
        })
    }
    setPortfolioReports(reports) {
        let reportsArray = [];
        this.setState({ records : []});
        // convert the reservations to array to loop and display the table
        let NoShow = [], bookings = 0, totalRevenue = [], threeHourBooking = [], sixHourBooking = [], twelveHourBooking = [], hotelName = [], reservations = [];
        Object.keys(reports).forEach((element, index) => {
          hotelName.push(element);
          reservations.push(Object.values(reports)[index]);
          bookings += Object.values(reports)[index].length;
          Object.values(reports)[index].forEach((eachElement) => {
            this.setRecords(eachElement);
            totalRevenue.push(eachElement.charge.amount);
            if(eachElement.status === 'No show'){
              NoShow.push(eachElement);
            }
            if(eachElement.duration === 3){
              threeHourBooking.push(eachElement);
            }
            if(eachElement.duration === 6){
              sixHourBooking.push(eachElement);
            }
            if(eachElement.duration === 12){
              twelveHourBooking.push(eachElement);
            }
          })
        });
        reportsArray.push({
          NoShow: NoShow.length,
          avgRate: '$ '+ parseFloat(totalRevenue.reduce((a,b) => a + b) / bookings).toFixed(2),
          bookings: bookings,
          totalRevenue: '$ '+ parseFloat(totalRevenue.reduce((a,b) => a + b)).toFixed(2),
          threeHourBooking: threeHourBooking.length,
          sixHourBooking: sixHourBooking.length,
          twelveHourBooking: twelveHourBooking.length,
          hotelName,
          items: this.getPortfolioReportItems(reservations),
        });
        this.setState({
            loading: false,
            reservationsArray: reportsArray,
        })
    }
    getPortfolioReportItems(detailReports) {
        let newDetails = [];
        detailReports.forEach((element) => {
          element.forEach((data) => {
            newDetails.push({
              guest: data.bookedBy.fullName,
              rate: '$ '+ data.charge.amount,
              bookingLength: data.duration + 'h',
              date: moment(data.createdAt).utc().format("MMM D YYYY"),
              hotelName: data.hotelId.name,
              bookingID: data.bookingId,
              reservationStatus: data.status
            })
          })
        });
        return newDetails;
    }
    setRecords(element){
        const array = this.state.records;
        array.push({
            _id: element._id,
            bookingId: element.bookingId,
            bookingID: <a href={`/reservationDetails/${element._id}`}>{element.bookingId}</a>,
            lastName: element.bookedBy && element.bookedBy.lastName ? element.bookedBy.lastName : this.getLastName(element.bookedBy.fullName),
            name: element.hotelId.name,
            propertyName: <a href={`/editHotel/${element.hotelId._id}`}>{element.hotelId.name}</a>,
            duration: element.duration + 'h',
            reservationStart: moment(element.createdAt).utc().format("MMM D YYYY hh:mm A"),
            amount: '$ ' + parseFloat(element.charge.amount).toFixed(2),
            reservationStatus: element.status
        })
        this.setState({
            records: array
        })
    }
    getLastName(fullName){
        let splitName = fullName.split(" ");
        return splitName[splitName.length - 1];
    }
    dateChange = (item, dateType) => {
        const { dateRange } = this.state;
        if (item) {
            let date = item;
            if (dateType === 'start') {
                this.setState({
                    dateRange: [{
                        startDate: date,
                        endDate: dateRange[0].endDate
                    }],
                }, () => {
                    this.fetchReservations();
                })
            } else {
                if (dateType === 'end') {
                    this.setState({
                        dateRange: [{
                            startDate: dateRange[0].startDate,
                            endDate: date
                        }],
                    }, () => {
                        this.fetchReservations();
                    })
                }
            }
        }
    }
    render(){
        const { history } = this.props
        const {records, loading, propertyType, dateRange, reservationsArray} = this.state;
        return(
            <div>
                <div className="header">
                    <h2><strong>Reporting </strong>Property Chain</h2>
                    <div className="breadcrumb-wrapper">
                        <ol className="breadcrumb">
                            <li>
                                <a href={() => null} onClick={() => history.push("/dashboard")}>TimeFlyz</a>
                            </li>
                            <li className="active">Property Chain</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="">
                            <div className="panel-header ">
                                <div style={{ padding: 8, display: 'flex', flexDirection:'row', justifyContent: 'space-evenly', alignItems:'center' }}>
                                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                        <h3><strong>Property Chain</strong> </h3>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                        <select className="form-control pointer" name="managerType" onChange={this.onChange}>
                                            <option value="" disabled>Select Manager Type</option>
                                            {
                                                propertyType.map(element => {
                                                    return(
                                                        <option value={element._id} key={element._id}>{element.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                        <div className="row" style={{ display: "flex", alignItems: 'center' }}>
                                            <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                                <DatePicker
                                                    selected={dateRange[0].startDate}
                                                    onChange={date => this.dateChange(date, 'start')}
                                                    selectsStart
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="scroll"
                                                    startDate={dateRange[0].startDate}
                                                    endDate={dateRange[0].endDate}
                                                    dateFormat="dd/MM/yyyy"
                                                    
                                                />
                                            </div>
                                            <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" style={{ textAlign: 'center' }}>
                                                <strong> to </strong>
                                            </div>
                                            <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                                <DatePicker
                                                    selected={dateRange[0].endDate}
                                                    onChange={date => this.dateChange(date, 'end')}
                                                    selectsEnd
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    startDate={dateRange[0].startDate}
                                                    endDate={dateRange[0].endDate}
                                                    minDate={dateRange[0].startDate}
                                                    dateFormat="dd/MM/yyyy"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-content pagination2 table-responsive">
                                {
                                    reservationsArray.length > 0 ?
                                    <>  
                                        <div>
                                            <Graphs type="Bar" dataSets={reservationsArray} defaultProps={defaultProps} width={100} height={300} title="Bookings" />
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12' style={{padding: 30}}>
                                                <Graphs type="Line" dataSets={reservationsArray} defaultProps={defaultProps} width={100} height={300} title="Avg Rate" selectedAvgRateDates={dateRange} />
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12' style={{padding: 30}}>
                                                <Graphs type="Doughnut" dataSets={reservationsArray} defaultProps={defaultProps} width={100} height={200} title="No Shows" />
                                            </div>
                                        </div>
                                        <div>
                                            <Graphs type="Line" dataSets={reservationsArray} defaultProps={defaultProps} width={100} height={300} title="3,6,12 Hour rate" selectedDates={dateRange} />
                                        </div>
                                        <ReservationBox  dataSets={reservationsArray} />
                                    </>
                                    :
                                    null
                                }
                                <DataTable columns={columns} data={records} loading={loading} filterDefaultText="Filter By Property Chain" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}