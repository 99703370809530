import React, { Component } from 'react';
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import axios from 'axios';
// import $api from "../../utils/axios";
import io from 'socket.io-client';

const SERVER_URL = process.env.REACT_APP_API_URL;

const notifications = [
    {
        title: "New Property Added",
        time: Date.now()
    }
];
class Header extends Component {
    constructor(props){
        super(props);
        this.state = {
            notifyShow:false,
            showDropdownMyAccount: false,
            messages:[]
        }
        this.wrapperRef = React.createRef();
        this.wrapperMyAccountRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);

        if (!localStorage.getItem('token')) return;
        //this.initSocket();
        this.socket = io(SERVER_URL, {
            transports: ['websocket'],
            query: {
              token: localStorage.getItem('token'),
            }
          });
          if (!this.socket) return;
          this.socket.on('connected', msg => console.log(msg))
    }
    
    componentDidMount() {
        this.socket.on('message', (message) => {
             
            this.setState((prevState) => ({
              messages: [...prevState.messages, message],
            }));
            console.log('piuuuuuuuuuu',this.state.messages);
          });
        document.addEventListener('mousedown', this.handleClickOutside);
        this.getRole();
        this.getNotification();
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({
                notifyShow: false
            })
        }

        if (this.wrapperMyAccountRef && !this.wrapperMyAccountRef.current.contains(event.target)) {
            this.setState({
                showDropdownMyAccount: false
            })
        }
    }
    
    showNotification = async () => {
        const {notifyShow} = this.state;
        await axios.post(`${process.env.REACT_APP_API_URL}/ticket/update-notification`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
        })
            .then(async responsenxt => {
                console.log(responsenxt,"sssssssssssssssss");
                await axios.post(`${process.env.REACT_APP_API_URL}/ticket/list-notification`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    },
                })
                    .then(response => {
                        console.log(response.data.data,"KKKKKKKKKKKKKKKfrommmmm");
                        let readYes = response.data.data.filter(item=> !item.isRead);
                        console.log(readYes,"looooooooooooooooooooooooooooo");
                        this.setState( {messages: response.data.data.map(item=>{
                            return({message:item.name,time:item.createdAt,readYes: readYes.length})
                        })});
                        console.log(readYes,"looooooooooooooooooooooooooooo");
                        console.log('bbbbbbbbbbbbbbbbbbbbb',readYes,this.state.messages);
                        // document.getElementById('username').innerHTML = response.data.data.name
                    })
                    .catch(error => {
                        console.log(error);
                    })
            })
            .catch(error => {
                console.log(error);
            });
            
        if(notifyShow){
            this.setState({
                notifyShow: false
            })
        }else{
            this.setState({
                notifyShow: true
            })
        }
    }

    toggleSidebar(){
        let toggle;
        if(this.props.toggleSidebar){
            toggle = false;
        }else{
            toggle = true;
        }
        this.props.changeSidebar(toggle);
    }

    showDropdown = () => {
        this.setState({
            showDropdownMyAccount: true
        })
    }

    logout(){
        localStorage.removeItem('token');
        this.props.history.push(`/login`);
        // window.location.href="/"
    }

   async getRole() {
        const token = localStorage.getItem('token');
        const decoded = jwtDecode(token);
        // console.log(decoded,"LLLLLLLLLLLLLLL")
        if(decoded.role==='Employee'){
           document.getElementById('username').innerHTML = decoded.firstName
        }else if(decoded.role==='Department'){
            await axios.get(`${process.env.REACT_APP_API_URL}/department/get-department-by-id?departmentId=${decoded.userType}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })
                .then(response => {
                    document.getElementById('username').innerHTML = response.data.data.name
                })
                .catch(error => {
                    console.log(error);
                })
        }else if(decoded.role==='SuperAdmin'){
            document.getElementById('username').innerHTML = 'Admin'
        }
        // this.state.role = decoded.role
    
        // console.log(this.state, "??????")
    }

    async getNotification (){
        

        await axios.post(`${process.env.REACT_APP_API_URL}/ticket/list-notification`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
        })
            .then(response => {
                console.log(response.data.data,"KKKKKKKKKKKKKKK");
                let readYes = response.data.data.filter(item=> !item.isRead);
                console.log(readYes,"looooooooooooooooooooooooooooo");
                this.setState( {messages: response.data.data.map(item=>{
                    return({message:item.name,time:item.createdAt,readYes: readYes.length})
                })});
                console.log(readYes,"looooooooooooooooooooooooooooo");
                console.log('bbbbbbbbbbbbbbbbbbbbb',readYes,this.state.messages);
                // document.getElementById('username').innerHTML = response.data.data.name
            })
            .catch(error => {
                console.log(error);
            })
    }

    render() {
        const {notifyShow, showDropdownMyAccount,messages} = this.state;
        return (
            <div className="topbar">
                <div className="header-left">
                    <div className="topnav">
                        <a className="menutoggle" href={() => null} onClick={() => this.toggleSidebar()} data-toggle="sidebar-collapsed"><span className="menu__handle"><span>Menu</span></span></a>
                    </div>
                </div>

                <div className="header-right">
                    <ul className="header-menu nav navbar-nav">
                        {/* BEGIN NOTIFICATION DROPDOWN */}
                        <li className="dropdown" id="notifications-header">
                            <a href={() => null} data-toggle="dropdown" data-hover="dropdown" data-close-others="true" onClick={this.showNotification}>
                                <i className="icon-bell" />
                                {( messages.length && messages[messages.length - 1].readYes > 0 && <span className="badge badge-danger badge-header">{messages[messages.length - 1].readYes}</span>)}
                            </a>
                            <ul className={notifyShow ? "dropdown-menu show" : "dropdown-menu"} ref={this.wrapperRef}>
                                <li className="dropdown-header clearfix">
                                    <p className="pull-left">Notifications</p>
                                </li>
                                <li>
                                    <ul className="dropdown-menu-list withScroll" data-height={220}>
                                    {
                                            messages.length > 0 && messages.map((data,index) => {
                                                return(
                                                    <li key={index}>
                                                        <a href={() => null}>
                                                            {data.message}
                                                            <span className="dropdown-time">{moment(data.time)
                                .tz("Asia/Kolkata")
                                .format("MMM D YYYY")}</span>
                                                        </a>
                                                    </li>
                                                )
                                            })
                                        }
                                      {(
                                        messages.length === 0 && 
                                            <li>
                                                Sorry, no notifications found.
                                            </li>
                                        
                                      )}  
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        {/* END NOTIFICATION DROPDOWN */}
                        
                        {/* BEGIN USER DROPDOWN */}
                        <li className="dropdown" id="user-header">
                            <a href={() => null} onClick={this.showDropdown} data-toggle="dropdown" data-hover="dropdown" data-close-others="true">
                                <span className="username">Hi, <span id='username'></span></span>
                            </a>
                            <ul className={showDropdownMyAccount ? "dropdown-menu show" : "dropdown-menu"} ref={this.wrapperMyAccountRef}>
                                <li>
                                    <a href={() => null} onClick={() => this.logout()}><i className="icon-power" /><span>Logout</span></a>
                                </li>
                            </ul>

                        </li>
                        {/* END USER DROPDOWN */}
                    </ul>
                </div>
                {/* header-right */}
            </div>

        )
    }
}

export default withRouter(Header);
