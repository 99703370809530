import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useFormik } from "formik";
import moment from "moment";
import * as yup from "yup";
import { toast } from "react-toastify";

import $api from "../../utils/axios";

const validationSchema = yup.object().shape({
  expireDate: yup.string().required(),
});

const DisableAccountModal = ({ isOpen, user, onClose, onComplete }) => {
  const [initialValues, setInitialValues] = useState({ expireDate: null });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: initialValues,
    onSubmit: async (values, helpers) => {
      helpers.setSubmitting(true);
      try {
        await $api
          .put(`/user/disableAccount/${user?._id}`, {
            expireDate: values.expireDate,
          })
          .then((res) => res.data);
        toast.success("Disabled account successfully.");
        onComplete();
      } catch (err) {
        console.log(err);
        toast.error(`An error happened. Error: ${err.message}`);
      }
      helpers.setSubmitting(false);
    },
  });

  const { handleSubmit, setFieldValue, values, errors, isSubmitting } = formik;

  useEffect(() => {
    if (!isOpen) {
      formik.resetForm();
      return;
    }

    setInitialValues({ expireDate: user.expireDate || null });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Disable Account</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: 3 }}>
          To disable account, set expire date below and the user only can login
          until that date.
        </DialogContentText>
        <DatePicker
          autoFocus
          label="Expire Date"
          value={moment(values.expireDate)}
          onChange={(val) =>
            setFieldValue("expireDate", val.format("YYYY-MM-DD"))
          }
          inputFormat="DD/MM/YYYY"
          renderInput={(props) => (
            <TextField
              {...props}
              sx={{ width: "100%" }}
              error={!!errors.expireDate}
              helperText={errors.expireDate}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="inherit" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={isSubmitting}
          loadingIndicator="Submitting…"
          onClick={handleSubmit}
        >
          Disable Account
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DisableAccountModal;
