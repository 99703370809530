import React, { memo } from 'react'
import { map, filter } from 'lodash';
import * as yup from "yup";
import moment from 'moment';
import { Formik } from "formik";
import { Container } from '@mui/system';
import { Autocomplete, Box, Button, Chip, FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useHistory } from 'react-router-dom';
import HotelAutoComplete from '../../../components/Hotel/HotelAutoComplete';
import usePropertyChain from '../../../hooks/usePropertyChain';

const CouponForm = (props) => {
  const { chains } = usePropertyChain();

  const initialValues = props.initialValues || {};

  const onSubmit = props.onSubmit || (() => { });
  const history = useHistory();

  const validationSchema = yup.object().shape({
    code: yup.string().required().length(8),
    value: yup.number().required().min(0).max(100),
    startDate: yup
      .date()
      .required()
      .min(moment().add(-1, 'day').format(), "Start date must be greater than today"),
    endDate: yup
      .date()
      .min(yup.ref("startDate"), "End date must be greater than start date")
      .required(),
    appliedTo: yup
      .string()
      .oneOf(['hotel', 'timeflyz', 'total'], "Invalid type")
      .required(),
    scope: yup
      .string()
      .oneOf(['all', 'chain', 'hotel'])
      .required(),
    scopeChain: yup.array().optional(),
    scopeHotel: yup.array().optional(),
  });

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnBlur={true}
      validateOnChange={true}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        values,
        errors,
      }) => (

        <Container sx={{
          backgroundColor: 'white', mt: 2, py: 2, borderRadius: 1,
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
        }}>
          <div>
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
              <TextField
                variant='outlined'
                type='text'
                label="Code"
                value={values.code || ''}
                onChange={handleChange('code')}
                error={!!errors.code}
                helperText={errors.code}
              />
            </FormControl>
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
              <TextField
                variant='outlined'
                id="outlined-adornment-code"
                type='number'
                label="Value"
                value={values.value || ''}
                InputProps={{
                  endAdornment: <InputAdornment position="start">%</InputAdornment>
                }}
                onChange={handleChange('value')}
                error={!!errors.value}
                helperText={errors.value}
              />
            </FormControl>
          </div>
          <div>
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
              <DatePicker
                showToolbar
                disablePast={true}
                label="Start date"
                value={values.startDate}
                inputFormat="DD/MM/YYYY"
                onChange={(_date) => {
                  setFieldValue('startDate', _date.startOf('day').utcOffset(0, true));
                }}
                renderInput={(params) => <TextField {...params}
                  error={!!errors.startDate}
                  helperText={errors.startDate}
                />}
              />
            </FormControl>
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
              <DatePicker
                showToolbar
                minDate={values.startDate}
                inputFormat="DD/MM/YYYY"
                value={values.endDate}
                label="End date"
                onChange={(_date) => {
                  setFieldValue('endDate', _date.endOf('day').utcOffset(0, true));
                }}
                renderInput={(params) => <TextField {...params}
                  error={!!errors.endDate}
                  helperText={errors.endDate}
                />}
              />
            </FormControl>
          </div>
          <div>
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" error={!!errors.appliedTo}>
              <InputLabel id="demo-simple-select-label">Discount Applied To</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Discount Applied To"
                value={values.appliedTo}
                onChange={handleChange('appliedTo')}
              >
                <MenuItem value={'hotel'}>Hotel Cost</MenuItem>
                <MenuItem value={'timeflyz'}>TimeFlyz Commission</MenuItem>
                <MenuItem value={'total'}>Total</MenuItem>
              </Select>
              <FormHelperText>{errors.appliedTo}</FormHelperText>
            </FormControl>
          </div>
          <div>
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" error={!!errors.scope}>
              <InputLabel id="demo-simple-select-scope-label">Scope</InputLabel>
              <Select
                labelId="demo-simple-select-scope-label"
                id="demo-simple-select-scope"
                label="Scope"
                value={values.scope}
                onChange={handleChange('scope')}
              >
                <MenuItem value={'all'}>All Hotels</MenuItem>
                <MenuItem value={'chain'}>Chain</MenuItem>
                <MenuItem value={'hotel'}>Hotel</MenuItem>
              </Select>
              <FormHelperText>{errors.scope}</FormHelperText>
            </FormControl>
            {values.scope === 'chain' &&
              <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" error={!!errors.scopeChain}>
                <Autocomplete
                  id="demo-simple-select-scope"
                  label="Property Chain"
                  getOptionLabel={(option) => {
                    return typeof option === 'string' ? option : option.name
                  }}
                  filterOptions={(x) => x}
                  value={values.scopeChain}
                  onChange={(event, newValue) => {
                    setFieldValue('scopeChain', newValue);
                  }}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  options={filter(chains, chain => !map(values.scopeChain, '_id').includes(chain._id))}
                  multiple
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {values.scopeChain.map((value) => (
                        <Chip key={value._id} label={value.name} />
                      ))}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Hotels" fullWidth />
                  )}
                  renderOption={(props, option) => {
                    return (
                      <li {...props}>
                        <Typography variant="body" color="text.secondary">
                          {option.name}
                        </Typography>
                      </li>
                    );
                  }}
                />
                <FormHelperText>{errors.scopeChain}</FormHelperText>
              </FormControl>
            }
            {values.scope === 'hotel' &&
              <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined" error={!!errors.scopeHotel}>
                <HotelAutoComplete
                  value={values.scopeHotel}
                  onChange={value =>
                    setFieldValue('scopeHotel', value)
                  }
                />
                <FormHelperText>{errors.scopeHotel}</FormHelperText>
              </FormControl>
            }
          </div>
          <div style={{ marginTop: '15px', padding: '7px' }}>
            <Button variant="outlined" sx={{ mr: 1 }} onClick={() => history.goBack()}>Go Back</Button>
            <Button variant="contained" onClick={handleSubmit}>Submit</Button>
          </div>
        </Container>
      )}
    </Formik>
  )
}


export default memo(CouponForm);