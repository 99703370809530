import React, {useState, useEffect} from 'react';
import { Chart, registerables } from 'chart.js';
import {Bar, Line, Doughnut} from 'react-chartjs-2';
import moment from 'moment';
import { differenceInDays, addDays } from 'date-fns';
Chart.register(...registerables);

export default function VerticalBarChart(props) {
    const {defaultProps, dataSets, height, width, title, selectedDates, selectedAvgRateDates, type} = props;
    const [bookingsArray, setBookingsArray] = useState(null);
    const [noShowArray, setNoShowArray] = useState(null);
    const [avgRateArray, setAvgRateArray] = useState(null);
    const [hourlyBookingsArray, setHourlyBookingsArray] = useState(null);

    useEffect(() => {
        
        // Bar Chart "Bookings" Starts
        function generateBookingsData(){
            let hotelsArray = [];
            let bookingsArray = [];
            dataSets[0].items.forEach(element => {
                if(!hotelsArray.includes(element.hotelName)){
                    hotelsArray.push(element.hotelName);
                    bookingsArray.push({[element.hotelName]:[element]});
                }else{
                    let index = hotelsArray.indexOf(element.hotelName);
                    if(index > -1){
                        bookingsArray[index][element.hotelName].push(element);
                    }
                }
            });
            let dataAttributes = [{
                label: 'Bookings',
                backgroundColor: '#FF4500',
                data: []
            }];
            Object.values(bookingsArray).forEach(data => {
                Object.values(data).forEach(eachData => {
                    dataAttributes[0].data.push(eachData.length);
                });
            });
            let trimHotelNames = hotelsArray.map(element => {
                return `${element.substring(0,15)}...`;
            })
            let datacollection = {
                labels: trimHotelNames,
                datasets: dataAttributes
            }
            setBookingsArray(datacollection)
        }
        // Bar Chart "Bookings" Ends

        // Doughnut Chart "No Shows" Starts
        function generateNoShowData(){
            let datacollection = {
                labels: ['No Show', 'Bookings'],
                datasets: [
                    {
                        label: 'No Show',
                        backgroundColor: ['#2b2e33', '#FF4500'],
                        data: [dataSets[0].NoShow, dataSets[0].bookings]
                    }
                ]
            }
            setNoShowArray(datacollection);
        }
        // Doughnut Chart "No Shows" Ends

        // Line Chart "Avg. Rate" Starts
        function generateAvgRateData(){
            const d1 = new Date(selectedAvgRateDates[0].startDate),
            d2 = new Date(selectedAvgRateDates[0].endDate),
            days = differenceInDays(d2, d1),
            dates = [...Array(days + 1).keys()].map((i) => addDays(d1, i));
            let datesArray = dates.map(element => {
                return moment(element).format('DD MM YYYY');
            });
            let datacollection = {
                labels: datesArray,
                datasets: [
                    {
                        label: 'Avg Rate in ($)',
                        backgroundColor: ['#FF4500'],
                        data: getDateWiseAvgRate(dates),
                        fill: true,
                    }
                ]
            }
            setAvgRateArray(datacollection);
        }

        function getDateWiseAvgRate(dates){
            let arr = [];
            for(let i=0; i<=dates.length-1; i++){
                arr.push(0);
                let reserveRate = [];
                dataSets[0].items.forEach(element => {
                    const date1 = moment(dates[i]).format("YYYY MM DD");
                    const date2 = moment(element.date).format("YYYY MM DD");
                    if(moment(date1).isSame(moment(date2))){
                        reserveRate.push(convertDecimal(element.rate));
                    }
                });
                let avgRate = reserveRate.length > 0 ? parseFloat(reserveRate.reduce((a,b) => a + b) / reserveRate.length).toFixed(2) : 0;
                arr[i] = avgRate;
            }
            return arr;
        }

        function convertDecimal(value){
            let trimValue = value.split(" ");
            return parseInt(trimValue[1]);
        }
        // Pie Chart "Avg. Rate" Ends
        
        // Line Chart "3,6,12 Hour Bookings" Starts
        function generateHourlyRate(){
            const d1 = new Date(selectedDates[0].startDate),
            d2 = new Date(selectedDates[0].endDate),
            days = differenceInDays(d2, d1),
            dates = [...Array(days + 1).keys()].map((i) => addDays(d1, i));
            let datesArray = dates.map(element => {
                return moment(element).format('DD MM YYYY');
            });
            let datacollection = {
                labels: datesArray,
                datasets: [
                    {
                        label: '3 Hour',
                        backgroundColor: 'rgb(255,69,0,0.7)',
                        data: getDateWiseBookings(dates, '3h'),
                        fill: true
                    }, {
                        label: '6 Hour',
                        backgroundColor: 'rgb(220,240,214,0.7)',
                        data: getDateWiseBookings(dates, '6h'),
                        fill: true
                    }, {
                        label: '12 Hour',
                        backgroundColor: 'rgb(43,46,51,0.7)',
                        data: getDateWiseBookings(dates, '12h'),
                        fill: true
                    }
                ]
            }
            setHourlyBookingsArray(datacollection);
        }
        
        function getDateWiseBookings(dates, hour){
            let arr = [];
            for(let i=0; i<=dates.length-1; i++){
                arr.push(0);
                dataSets[0].items.forEach(element => {
                    const date1 = moment(dates[i]).format("YYYY MM DD");
                    const date2 = moment(element.date).format("YYYY MM DD");
                    if(element.bookingLength === hour){
                        if(moment(date1).isSame(moment(date2))){
                            arr[i] = arr[i] + 1;
                        }
                    }
                });
            }
            return arr;
        }
        // Line Chart "3,6,12 Hour Bookings" Ends

        if(dataSets.length > 0){
            generateBookingsData();
            generateNoShowData();
            if(selectedAvgRateDates){
                generateAvgRateData();
            }
            if(selectedDates){
                generateHourlyRate();
            }
        }
    }, [dataSets, selectedDates, selectedAvgRateDates])

    return (
        <div className='row'>
            {
                type === 'Bar' && bookingsArray ? 
                <Bar
                    data={bookingsArray}
                    width={width}
                    height={height}
                    options={{
                        plugins: {
                            title:{
                                display: defaultProps.displayTitle,
                                text: title,
                                position: defaultProps.titlePosition,
                                font:{
                                    size: 20,
                                },
                            },
                            legend:{
                                display: defaultProps.displayLegend,
                                position: defaultProps.legendPosition,
                                align: defaultProps.legendAlign,
                            },
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                    }}
                />
                :
                null
            }

            {
                type === 'Line' && hourlyBookingsArray?
                <Line
                    data={hourlyBookingsArray}
                    width={width}
                    height={height}
                    options={{
                        plugins: {
                            title:{
                                display: defaultProps.displayTitle,
                                text: title,
                                position: defaultProps.titlePosition,
                                font:{
                                    size: 20,
                                },
                            },
                            legend:{
                                display: defaultProps.displayLegend,
                                position: defaultProps.legendPosition,
                                align: defaultProps.legendAlign,
                            },
                        },
                        scales: {
                            y: {
                                beginAtZero: true,
                            }
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                    }}
                />
                :
                null
            }

            {
                type === 'Doughnut' && noShowArray ?
                <Doughnut 
                    data={noShowArray}
                    width={width}
                    height={height}
                    options={{
                        plugins: {
                            title:{
                                display: defaultProps.displayTitle,
                                text: title,
                                position: defaultProps.titlePosition,
                                font:{
                                    size: 20,
                                },
                            },
                            legend:{
                                display: defaultProps.displayLegend,
                                position: defaultProps.legendPosition,
                                align: defaultProps.legendAlign,
                            },
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                    }}
                />
                :
                null
            }

            {
                type === 'Line' && avgRateArray ? 
                <Line
                    data={avgRateArray}
                    width={width}
                    height={height}
                    options={{
                        plugins: {
                            title:{
                                display: defaultProps.displayTitle,
                                text: title,
                                position: defaultProps.titlePosition,
                                font:{
                                    size: 20,
                                },
                            },
                            legend:{
                                display: defaultProps.displayLegend,
                                position: defaultProps.legendPosition,
                                align: defaultProps.legendAlign,
                            },
                        },
                        scales: {
                            y: {
                                beginAtZero: true,
                            }
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                    }}
                />
                :
                null
            }
        </div>
    );
}