import React, { Component } from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import axios from "axios";
import Dialog from '../../components/Dialog';
import { toast } from 'react-toastify';
import Link from '@mui/material/Link';
import { isEmpty } from "lodash";
export default class PrivacyPolicy extends Component {

    state = {
        faqs: [],
        isAddQAModalOpen: false,
        isUpdateModalOpen: false,
        addQnAQuestionInput: '',
        addQnAAnswerInput: '',
        updatingQnAId: '',
        updateQnAQuestionInput: '',
        updateQnAAnswerInput: '',
    }

    componentDidMount() {
        axios.get(`${process.env.REACT_APP_API_URL}/faq/get`, {tag: 'Faq'})
        .then(response => {
            this.setState({
                faqs: response.data.faqs,
            })
        })
        .catch(error => {
            console.log(error);
        })
    }


    addFAQ = async (e) => {
        e.preventDefault();
        if (isEmpty(this.state.addQnAQuestionInput) ||  isEmpty(this.state.addQnAAnswerInput)){
            toast.error('Your question and answer is required');
            return;
        }
        let token = await localStorage.getItem('token');

        await axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/faq`,
            data: {
                q: this.state.addQnAQuestionInput,
                a: this.state.addQnAAnswerInput,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {

            const {message, data} = response.data;

            toast.success(message);

            this.setState({
                faqs: [...this.state.faqs, data],
                isAddQAModalOpen: false,
                addQnAQuestionInput: '',
                addQnAAnswerInput: '',
            });

        }).catch(error => 
            toast.error('Failed to add FAQ')
        );
    }

    deleteFAQ = async (e, qa) => {
        e.preventDefault();

        let token = await localStorage.getItem('token');
        await axios({
            method: "DELETE",
            url: `${process.env.REACT_APP_API_URL}/faq/${qa._id}`,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {
            if (response.status === 200) {
                toast.success("Deleted successfully", {
                    autoClose: 2000,
                });
            }
        }).catch(error => 
            toast.success("Deleted successfully", {
                autoClose: 2000,
            })
        ).finally(() => {
            const faqList = this.state.faqs.filter(faq => faq._id !== qa._id);
            this.setState({
                faqs: faqList
            });
        });
    }
    
    updateFAQ = async (e) => {
        e.preventDefault();

        let token = await localStorage.getItem('token');

        await axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}/faq`,
            data: {
                q: this.state.updateQnAQuestionInput,
                a: this.state.updateQnAAnswerInput,
                _id: this.state.updatingQnAId
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(response => {

            const {message} = response.data;

            toast.success(message);

            const filteredList = this.state.faqs.filter(faq => faq._id !== this.state.updatingQnAId);
            const {updatingQnAId, updateQnAQuestionInput, updateQnAAnswerInput} = this.state;

            this.setState({
                faqs: [...filteredList, {_id: updatingQnAId, q: updateQnAQuestionInput, a: updateQnAAnswerInput}],
                isAddQAModalOpen: false,
                updateQnAQuestionInput: '',
                updateQnAAnswerInput: '',
                updatingQnAId: '',
                isUpdateModalOpen: false
            });

        }).catch(error => 
            toast.error('Failed to update FAQ')
        );
    }

    onAddFAQClick = async (e) => {
        e.preventDefault();
        
        this.setState({
            addQnAQuestionInput: '',
            addQnAAnswerInput: '',
            isAddQAModalOpen: true
        });
    }

    handleAddedQuestionChange = (event) => {
        event.preventDefault();

        const { value } = event.target;
        this.setState({
            addQnAQuestionInput: value
        });
    }

    handleAddedAnswerChange = (event, editor) => {
        const data = editor.getData();
        this.setState({
            addQnAAnswerInput: data
        });
    }

    handleUpdatedQuestionChange = (event) => {
        event.preventDefault();

        const { value } = event.target;
        this.setState({
            updateQnAQuestionInput: value
        });
    }

    handleUpdatedAnswerChange = (event, editor) => {
        const data = editor.getData();
        this.setState({
            updateQnAAnswerInput: data
        });
    }

    onUpdateFAQClick = async (e, faq) => {
        e.preventDefault();

        this.setState({
            updatingQnAId: faq._id,
            updateQnAQuestionInput: faq.q,
            updateQnAAnswerInput: faq.a,
            isUpdateModalOpen: true
        });
    }

    render() {
        const { history } = this.props
        const { faqs, addQnAQuestionInput, addQnAAnswerInput, updateQnAQuestionInput, updateQnAAnswerInput } = this.state;
        return (
            <div>
                <div className="header">
                    <div style={{display:'flex', flexDirection: 'row'}}>
                        <h2><strong>FAQ</strong> Contents</h2>
                        <button className="btn" onClick={this.onAddFAQClick} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 12, backgroundColor: '#FFFFFF00', color: '#C75757'}}><i className="fa fa-plus" style={{color: '#C75757'}}/> Add new FAQ</button>
                    </div>
                    <div className="breadcrumb-wrapper">
                        <ol className="breadcrumb">
                            <li>
                                <a href={() => null} onClick={() => history.push("/dashboard")}>TimeFlyz</a>
                            </li>
                            <li className="active">FAQ</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    {
                        faqs.length > 0 
                        && faqs.map((qa, index) => (<div key={index} className="col-lg-12 col-md-12 col-sm-12 col-xs-12 portlets" style={{backgroundColor: 'white', marginBottom: 20, borderRadius: 10}}>
                            <div className="row" style={{padding: 12}}>
                            <Link href={`/faq/${qa._id}`} color="inherit">
                                <p style={{fontWeight:'bold'}}>{qa.q}</p>
                            </Link>
                               
                            </div>
                        </div>))
                    }
                </div>
                <Dialog isOpen={this.state.isAddQAModalOpen} onClose={(e) => this.setState({ isAddQAModalOpen: false })}>
                    <form onSubmit={this.addFAQ}>
                        <div className="form-group">
                            <input type="text" name='addQnAQuestionInput' value={addQnAQuestionInput} className="form-control" aria-required="true" onChange={this.handleAddedQuestionChange} placeholder="Enter your question" />
                        </div>
                        <div className="form-group">
                            <CKEditor
                                className="summernote bg-white"
                                editor={ClassicEditor}
                                data={addQnAAnswerInput}
                                onInit={editor => {
                                    editor.editing.view.change(writer => {
                                        writer.setStyle(
                                            "height",
                                            "300px",
                                            editor.editing.view.document.getRoot()
                                        );
                                    });
                                }}
                                onChange={this.handleAddedAnswerChange}
                            />
                        </div>
                        <button type="submit" className="btn btn-embossed btn-danger" onClick={this.addFAQ} style={{marginTop: '12px', width: '100%'}}>Add FAQ</button>
                    </form>
                </Dialog>
                <Dialog isOpen={this.state.isUpdateModalOpen} onClose={(e) => this.setState({ isUpdateModalOpen: false })}>
                    <form onSubmit={this.updateFAQ}>
                        <div className="form-group">
                            <input type="text" name='updateQnAQuestionInput' value={updateQnAQuestionInput} className="form-control" aria-required="true" onChange={this.handleUpdatedQuestionChange} placeholder="Enter your question" />
                        </div>
                        <div className="form-group">
                            <CKEditor
                                className="summernote bg-white"
                                editor={ClassicEditor}
                                data={updateQnAAnswerInput}
                                onInit={editor => {
                                    editor.editing.view.change(writer => {
                                        writer.setStyle(
                                            "height",
                                            "300px",
                                            editor.editing.view.document.getRoot()
                                        );
                                    });
                                }}
                                onChange={this.handleUpdatedAnswerChange}
                            />
                        </div>
                        <button type="submit" className="btn btn-embossed btn-danger" onClick={this.updateFAQ} style={{marginTop: '12px', width: '100%'}}>Update FAQ</button>
                    </form>
                </Dialog>
            </div>
        )
    }
}