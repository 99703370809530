import React, { Component } from "react";
import axios from "axios";
import { get, round } from "lodash";
import Loader from "../../components/Loader";
import moment from "moment";
import momenttz from "moment-timezone";
import { toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";
import Progress from "react-progressbar";

export default class ReservationDetails extends Component {
    constructor() {
        super();
        this.state = {
            resevationsDetails: {},
            loading: true,
            blockedUser: [],
            blockLinkedProperties: false,
            alertShow: false,
            alertBoxTitle: "",
            alertBoxType: "",
            alertBoxData: null,
            hotelImage: null,
            width: 0,
            vatgsts: [],
            amount:0,
        };
        window.addEventListener("resize", this.update);
    }
    update = () => {
        this.setState({
            width: window.innerWidth,
        });
    };
    componentDidMount() {
        this.update();
        this.fetchReservations();
        this.fetchHotelDetails();
    }
    onCancel = () => {
        this.setState({
            alertShow: false,
            alertBoxTitle: "",
            alertBoxType: "",
            alertBoxData: null,
        });
    };
    async fetchReservations() {
        const token = localStorage.getItem("token");
        const _responsse = await axios.get(`/vatgst/all`);
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/reservation/${this.props.match.params.reserveID}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        if (response.status === 200) {
            this.setState(
                {
                    vatgsts: _responsse.data,
                    resevationsDetails: response.data,
                    amount: Math.ceil(response.data.charge.amount * response.data.charge.userExchangeRate).toFixed(0),
                    loading: false,
                },
                () => {
                    this.fetchBlockedUserList();
                    this.fetchBannedFromLinkedProperties();
                }
            );
        }
    }

    fetchBannedFromLinkedProperties = async () => {
        const token = localStorage.getItem("token");
        const { resevationsDetails } = this.state;
        await axios
            .post(
                `${process.env.REACT_APP_API_URL}/hotel/getblockInfo`,
                {
                    userId: resevationsDetails.bookedBy._id,
                    hotelId: resevationsDetails.hotelId._id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        blockLinkedProperties: response.data,
                    });
                }
            });
    };

    fetchBlockedUserList = async () => {
        const token = localStorage.getItem("token");
        await axios
            .get(`${process.env.REACT_APP_API_URL}/hotel/list`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    let filterData = response.data.filter((data) => {
                        return (
                            this.state.resevationsDetails.hotelId._id ===
                            data._id
                        );
                    });
                    this.setState({
                        blockedUser: filterData[0].blocked,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    async fetchHotelDetails() {
        const token = localStorage.getItem("token");
        await axios
            .get(`${process.env.REACT_APP_API_URL}/hotel/list`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    let hotelId = this.state.resevationsDetails.hotelId._id;
                    let filterHotelId = response.data.filter(
                        (eachHotelData) => {
                            return eachHotelData._id === hotelId;
                        }
                    );
                    this.setState({
                        hotelImage: filterHotelId[0].photos[0],
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    onChangeThisProperty = async (e) => {
        const { checked } = e.target;
        const { resevationsDetails } = this.state;
        const details = {
            blockUserId: resevationsDetails.bookedBy._id,
            hotel: resevationsDetails.hotelId._id,
            ban: checked,
        };
        const token = localStorage.getItem("token");
        await axios
            .put(
                `${process.env.REACT_APP_API_URL}/hotel/blockSingle`,
                details,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((response) => {
                if (response.data === "OK") {
                    toast.success("Banned Successfully From This Property", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.componentDidMount();
                } else {
                    toast.error("Something went wrong, Please try again...", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    onChangeLinkedProperty = async (e) => {
        const { checked } = e.target;
        const { resevationsDetails } = this.state;
        const details = {
            blockUserId: resevationsDetails.bookedBy._id,
            hotelId: resevationsDetails.hotelId._id,
            ban: checked,
        };
        const token = localStorage.getItem("token");
        await axios
            .put(
                `${process.env.REACT_APP_API_URL}/hotel/blockAllFromOwner`,
                details,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((response) => {
                if (response.data === "OK") {
                    toast.success(
                        "Banned Successfully From Linked Properties",
                        {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                    this.componentDidMount();
                } else {
                    toast.error("Something went wrong, Please try again...", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    refundToCustomer = async (details) => {
        if (!details.refund) {
            const token = localStorage.getItem("token");
            await axios
                .post(
                    `${process.env.REACT_APP_API_URL}/reservation/refund`,
                    { chargeId: details.charge._id },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        toast.success("Refunded to customer successfully", {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.componentDidMount();
                    } else {
                        toast.error(
                            "Something went wrong, Please try again...",
                            {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            }
                        );
                    }
                })
                .catch((error) => {
                    let parseJSON = JSON.parse(error.response.data.error);
                    toast.error(parseJSON.raw.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
        }
    };
    cancelBooking = async (details) => {
        if (details.status !== "Cancelled") {
            const token = localStorage.getItem("token");
            await axios
                .put(
                    `${process.env.REACT_APP_API_URL}/reservation/hotel/${details.hotelId._id}/room/${details.roomId}/reservation/${details._id}`,
                    { status: "Cancelled" },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        toast.success("Reservation Cancelled successfully", {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.componentDidMount();
                    } else {
                        toast.error(
                            "Something went wrong, Please try again...",
                            {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            }
                        );
                    }
                })
                .catch((error) => {
                    toast.error(error.response.data.error, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
        }
    };
    showAlertBox = (value, alertType) => {
        let title = "";
        if (alertType === "Refund") {
            title = `Please confirm you wish to credit the customer for $ ${parseFloat(
                value.charge.amount
            ).toFixed(2)}`;
        }
        if (alertType === "Cancel") {
            title = `Cancel Booking ?`;
        }
        if (alertType === "Review") {
            title = `Remove Review ?`;
        }
        this.setState({
            alertBoxData: value,
            alertBoxType: alertType,
            alertBoxTitle: title,
            alertShow: true,
        });
    };

    confirmFunc() {
        const { alertBoxType, alertBoxData } = this.state;
        this.setState(
            {
                alertShow: false,
            },
            () => {
                if (alertBoxType === "Refund") {
                    this.refundToCustomer(alertBoxData);
                }
                if (alertBoxType === "Cancel") {
                    this.cancelBooking(alertBoxData);
                }
                if (alertBoxType === "Review") {
                    this.removeReview(alertBoxData);
                }
            }
        );
    }

    fetchName(name) {
        let splitName = name.split(" ");
        let fName = "";
        if (splitName.length > 0) {
            fName = splitName[0];
        } else {
            fName = name;
        }
        return fName;
    }

    removeReview = async (reviewData) => {
        const token = localStorage.getItem("token");
        await axios
            .delete(
                `${process.env.REACT_APP_API_URL}/review/${reviewData.reviewId._id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((response) => {
                if (response.status === 200) {
                    toast.success("Review deleted successfully", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.componentDidMount();
                } else {
                    toast.error("Something went wrong, Please try again...", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((error) => {
                toast.error(error.response.data.error, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
    };

    getLocalCurrency = () => {
        return this.state.vatgsts.find(
            (vat) =>
                vat.countryname ===
                this.state.resevationsDetails.hotelId.country
        );
    };

    render() {
        const { history } = this.props;
        const {
            resevationsDetails,
            loading,
            blockedUser,
            blockLinkedProperties,
            alertShow,
            alertBoxTitle,
            hotelImage,
            width,
        } = this.state;
        if (loading) {
            return (
                <div>
                    <div className="header">
                        <h2>
                            <strong>Manage</strong> Reservation Details
                        </h2>
                        <div className="breadcrumb-wrapper">
                            <ol className="breadcrumb">
                                <li>
                                    <a
                                        href={() => null}
                                        onClick={() =>
                                            history.push("/dashboard")
                                        }
                                    >
                                        TimeFlyz
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href={() => null}
                                        onClick={() =>
                                            history.push("/reservations")
                                        }
                                    >
                                        Reservations
                                    </a>
                                </li>
                                <li className="active">Reservation Details</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                            style={{ textAlignLast: "center" }}
                        >
                            <Loader />
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div className="header">
                        <SweetAlert
                            show={alertShow}
                            warning
                            showCancel
                            confirmBtnText="Confirm"
                            confirmBtnBsStyle="danger"
                            title={alertBoxTitle}
                            onConfirm={() => this.confirmFunc()}
                            onCancel={() => this.onCancel()}
                            focusCancelBtn
                        />
                        <h2>
                            <strong>Manage</strong> Reservation Details (ID: {resevationsDetails.bookingId})
                        </h2>
                        <div className="breadcrumb-wrapper">
                            <ol className="breadcrumb">
                                <li>
                                    <a
                                        href={() => null}
                                        onClick={() =>
                                            history.push("/dashboard")
                                        }
                                    >
                                        TimeFlyz
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href={() => null}
                                        onClick={() =>
                                            history.push("/reservations")
                                        }
                                    >
                                        Reservations
                                    </a>
                                </li>
                                <li className="active">Reservation Details</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="panel">
                                <div className="panel-header ">
                                    <div className="row">
                                        <div className="col-sm-12 col-xs-12 col-md-8 col-lg-8">
                                            <h3>
                                                <strong>
                                                    Customer Information
                                                </strong>{" "}
                                            </h3>
                                        </div>
                                        <div
                                            className="col-sm-12 col-xs-12 col-md-4 col-lg-4"
                                            style={{ textAlign: "end" }}
                                        >
                                            <div className="row">
                                                {resevationsDetails.refund ? null : (
                                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-6">
                                                        <button
                                                            className="btn btn-danger btn-block"
                                                            onClick={() =>
                                                                this.showAlertBox(
                                                                    resevationsDetails,
                                                                    "Refund"
                                                                )
                                                            }
                                                            disabled={
                                                                resevationsDetails.refund
                                                            }
                                                        >
                                                            Refund Customer
                                                        </button>
                                                    </div>
                                                )}
                                                {resevationsDetails.status !==
                                                "Cancelled" ? (
                                                    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-6">
                                                        <button
                                                            className="btn btn-primary btn-block"
                                                            onClick={() =>
                                                                this.showAlertBox(
                                                                    resevationsDetails,
                                                                    "Cancel"
                                                                )
                                                            }
                                                            disabled={
                                                                resevationsDetails.status ===
                                                                "Cancelled"
                                                            }
                                                        >
                                                            Cancel Booking
                                                        </button>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-content">
                                    <div className="row">
                                        <div
                                            className="col-lg-8 col-md-8 col-sm-12 col-xs-12"
                                            style={{ borderWidth: 3 }}
                                        >
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                    <h5>First Name</h5>
                                                    <p>
                                                        {resevationsDetails
                                                            .bookedBy.firstName
                                                            ? resevationsDetails
                                                                  .bookedBy
                                                                  .firstName
                                                            : "N/A"}
                                                    </p>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                    <h5>Last Name</h5>
                                                    <p>
                                                        {resevationsDetails
                                                            .bookedBy.lastName
                                                            ? resevationsDetails
                                                                  .bookedBy
                                                                  .lastName
                                                            : "N/A"}
                                                    </p>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                    <h5>Email</h5>
                                                    <p>
                                                        {
                                                            resevationsDetails
                                                                .bookedBy.email
                                                        }
                                                    </p>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                    <h5>Phone</h5>
                                                    <p>
                                                        {resevationsDetails
                                                            .bookedBy.mobile
                                                            ? resevationsDetails
                                                                  .bookedBy
                                                                  .mobile
                                                            : "N/A"}
                                                    </p>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                    <h5>Account Type</h5>
                                                    <p>
                                                        {resevationsDetails
                                                            .bookedBy
                                                            .provider ===
                                                        "Timeflyz"
                                                            ? "Customer"
                                                            : "Guest"}
                                                    </p>
                                                </div>
                                                {resevationsDetails.bookedBy
                                                    .contactEmail && (
                                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                        <h5>Contact Email</h5>
                                                        <p>
                                                            {
                                                                resevationsDetails
                                                                    .bookedBy
                                                                    .contactEmail
                                                            }
                                                        </p>
                                                    </div>
                                                )}
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                    <h5>Booking Device</h5>
                                                    <p>
                                                        {resevationsDetails.bookingDevice}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-lg-4 col-md-4 col-sm-12 col-xs-12"
                                            style={{ borderWidth: 3 }}
                                        >
                                            <div
                                                className="row"
                                                style={{ marginTop: 12 }}
                                            >
                                                <div className="col-sm-8 col-xs-8 col-md-8 col-lg-8">
                                                    <p>
                                                        Ban Customer from{" "}
                                                        <b>this property</b>
                                                    </p>
                                                </div>
                                                <div className="col-sm-4 col-xs-4 col-md-4 col-lg-4">
                                                    <label className="switch switch-green">
                                                        <input
                                                            type="checkbox"
                                                            className="switch-input"
                                                            checked={blockedUser.includes(
                                                                resevationsDetails
                                                                    .bookedBy
                                                                    ._id
                                                            )}
                                                            onChange={
                                                                this
                                                                    .onChangeThisProperty
                                                            }
                                                        />
                                                        <span
                                                            className="switch-label"
                                                            data-on="On"
                                                            data-off="Off"
                                                        />
                                                        <span className="switch-handle" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-8 col-xs-8 col-md-8 col-lg-8 ">
                                                    <p>
                                                        Ban Customer from{" "}
                                                        <b>linked property</b>
                                                    </p>
                                                </div>
                                                <div className="col-sm-4 col-xs-4 col-md-4 col-lg-4">
                                                    <label className="switch switch-green">
                                                        <input
                                                            type="checkbox"
                                                            className="switch-input"
                                                            checked={
                                                                blockLinkedProperties
                                                            }
                                                            onChange={
                                                                this
                                                                    .onChangeLinkedProperty
                                                            }
                                                        />
                                                        <span
                                                            className="switch-label"
                                                            data-on="On"
                                                            data-off="Off"
                                                        />
                                                        <span className="switch-handle" />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-header ">
                                    <h3>
                                        <strong>Property Details</strong>{" "}
                                    </h3>
                                </div>
                                <div className="panel-content">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <h5>Property Name</h5>
                                            <p>
                                                {
                                                    resevationsDetails.hotelId
                                                        .name
                                                }
                                            </p>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <h5>Room Name</h5>
                                            <p>
                                                {
                                                    resevationsDetails.roomId
                                                        .name
                                                }
                                            </p>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <h5>Address</h5>
                                            <p>
                                                {resevationsDetails.hotelId
                                                    .streetAddress +
                                                    " , " +
                                                    resevationsDetails.hotelId
                                                        .city +
                                                    " , " +
                                                    resevationsDetails.hotelId
                                                        .country}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-header ">
                                    <h3>
                                        <strong>Booking Details</strong>{" "}
                                    </h3>
                                </div>
                                <div className="panel-content">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <h5>Date of Arrival</h5>
                                            <p>
                                                {momenttz(
                                                    resevationsDetails.reservationStart
                                                )
                                                    .tz(
                                                        resevationsDetails?.timezone ||
                                                            "Australia/Sydney"
                                                    )
                                                    .format("MMM D YYYY")}
                                            </p>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <h5>Time of Arrival</h5>
                                            <p>
                                                {moment(
                                                    resevationsDetails.reservationStart
                                                )
                                                    .tz(
                                                        resevationsDetails?.timezone ||
                                                            "Australia/Sydney"
                                                    )
                                                    .format("hh:mm A")}
                                            </p>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <h5>Date of Reservation</h5>
                                            <p>
                                                {moment(
                                                    resevationsDetails.createdAt
                                                )
                                                    .tz(
                                                        resevationsDetails?.timezone ||
                                                            "Australia/Sydney"
                                                    )
                                                    .format("MMM D YYYY")}
                                            </p>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <h5>Length of Stay</h5>
                                            <p>
                                                {resevationsDetails.duration}h
                                            </p>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <h5>Revenue (Rounded Display)</h5>
                                            <p>
                                                {get(
                                                    resevationsDetails,
                                                    "charge.userVatgst.shortName",
                                                    "A$"
                                                )}
                                                {this.state.amount}
                                            </p>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <h5>Status</h5>
                                            <p>{resevationsDetails.status}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel-header ">
                                    <h3>
                                        <strong>Payment Details</strong>{" "}
                                    </h3>
                                </div>
                                <div className="panel-content">
                                    {/* <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <h5>Total Rate</h5>
                                            <p>
                                                {get(
                                                    resevationsDetails,
                                                    "charge.userVatgst.shortName",
                                                    "A$"
                                                )}
                                                {parseFloat(
                                                    get(
                                                        resevationsDetails,
                                                        "charge.cost",
                                                        0
                                                    )
                                                ).toFixed(2)}
                                            </p>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <h5>
                                                Total Rate including Payment
                                                Surcharge
                                            </h5>
                                            <p>
                                                {get(
                                                    resevationsDetails,
                                                    "charge.userVatgst.shortName",
                                                    "A$"
                                                )}
                                                {parseFloat(
                                                    get(
                                                        resevationsDetails,
                                                        "charge.total",
                                                        "0"
                                                    )
                                                ).toFixed(2)}
                                            </p>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <h5>Surcharge (2%)</h5>
                                            <p>
                                                {get(
                                                    resevationsDetails,
                                                    "charge.userVatgst.shortName",
                                                    "A$"
                                                )}
                                                {parseFloat(
                                                    get(
                                                        resevationsDetails,
                                                        "charge.surcharge",
                                                        "0"
                                                    )
                                                ).toFixed(2)}
                                            </p>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <h5>Hotel Revenue (excl GST)</h5>
                                            <p>
                                                {get(
                                                    resevationsDetails,
                                                    "charge.userVatgst.shortName",
                                                    "A$"
                                                )}
                                                {parseFloat(
                                                    get(
                                                        resevationsDetails,
                                                        "charge.amount",
                                                        0
                                                    ) -
                                                        get(
                                                            resevationsDetails,
                                                            "charge.commission",
                                                            0
                                                        )
                                                ).toFixed(2)}
                                            </p>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <h5>
                                                TimeFlyz Commission (
                                                {round(
                                                    (get(
                                                        resevationsDetails,
                                                        "charge.commission",
                                                        0
                                                    ) /
                                                        get(
                                                            resevationsDetails,
                                                            "charge.cost",
                                                            0
                                                        )) *
                                                        100,
                                                    2
                                                )}{" "}
                                                %)
                                            </h5>
                                            <p>
                                                {get(
                                                    resevationsDetails,
                                                    "charge.userVatgst.shortName",
                                                    "A$"
                                                )}
                                                {parseFloat(
                                                    get(
                                                        resevationsDetails,
                                                        "charge.commission",
                                                        0
                                                    )
                                                ).toFixed(2)}
                                            </p>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <h5>TimeFlyz Commission GST</h5>
                                            <p>
                                                {get(
                                                    resevationsDetails,
                                                    "charge.userVatgst.shortName",
                                                    "A$"
                                                )}
                                                {parseFloat(
                                                    get(
                                                        resevationsDetails,
                                                        "charge.commissionGST",
                                                        0
                                                    )
                                                ).toFixed(2)}
                                            </p>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <h5>Coupon Code</h5>
                                            <p>
                                                {get(
                                                    resevationsDetails,
                                                    "coupon.code",
                                                    "NA"
                                                )}
                                            </p>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <h5>Hotel Discount</h5>
                                            <p>
                                                {get(
                                                    resevationsDetails,
                                                    "charge.userVatgst.shortName",
                                                    "A$"
                                                )}
                                                {parseFloat(
                                                    get(
                                                        resevationsDetails,
                                                        "charge.hotelDiscount",
                                                        0
                                                    )
                                                ).toFixed(2)}
                                            </p>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <h5>TimeFlyz Discount</h5>
                                            <p>
                                                {get(
                                                    resevationsDetails,
                                                    "charge.userVatgst.shortName",
                                                    "A$"
                                                )}
                                                {parseFloat(
                                                    get(
                                                        resevationsDetails,
                                                        "charge.timeflyzDiscount",
                                                        0
                                                    )
                                                ).toFixed(2)}
                                            </p>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <h5>Payment Type</h5>
                                            <p>
                                                {get(
                                                    resevationsDetails,
                                                    "payAtHotel",
                                                    false
                                                )
                                                    ? "Pay at hotel"
                                                    : "Pay now"}
                                            </p>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <h5>Payment status</h5>
                                            <p>
                                                {resevationsDetails.refund
                                                    ? "Refunded"
                                                    : resevationsDetails.charge
                                                          .status ===
                                                          "charged" ||
                                                      resevationsDetails.charge
                                                          .status === "success"
                                                    ? "Paid"
                                                    : "Not Paid"}
                                            </p>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <h5>Reservation Status Sent</h5>
                                            <p>
                                                {resevationsDetails.chargeStatus ===
                                                    "statement" ||
                                                resevationsDetails.chargeStatus ===
                                                    "charge"
                                                    ? resevationsDetails.statementIssueDate
                                                        ? moment(
                                                              resevationsDetails.statementIssueDate
                                                          )
                                                              .tz(
                                                                  resevationsDetails?.timezone ||
                                                                      "Australia/Sydney"
                                                              )
                                                              .format(
                                                                  "MMM D YYYY"
                                                              )
                                                        : "Yes"
                                                    : "No"}
                                            </p>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <h5>TimeFlyz Billing Deducted</h5>
                                            <p>
                                                {resevationsDetails.chargeStatus ===
                                                "charge"
                                                    ? resevationsDetails.invoiceIssueDate
                                                        ? moment(
                                                              resevationsDetails.invoiceIssueDate
                                                          )
                                                              .tz(
                                                                  resevationsDetails?.timezone ||
                                                                      "Australia/Sydney"
                                                              )
                                                              .format(
                                                                  "MMM D YYYY"
                                                              )
                                                        : "Yes"
                                                    : "No"}
                                            </p>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <h5>
                                                AUD Exchange Rate Conversion
                                            </h5>
                                            <p>
                                                {get(
                                                    resevationsDetails,
                                                    "charge.userExchangeRate",
                                                    1
                                                )}
                                            </p>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <h5>
                                                Total Rate (Hotel Local
                                                Currency)
                                            </h5>
                                            <p>
                                                {
                                                    this.getLocalCurrency()
                                                        .shortName
                                                }
                                                {parseFloat(
                                                    Math.round(
                                                        (get(
                                                            resevationsDetails,
                                                            "charge.cost",
                                                            0
                                                        ) /
                                                            get(
                                                                resevationsDetails,
                                                                "charge.userExchangeRate",
                                                                1
                                                            )) *
                                                            this.getLocalCurrency()
                                                                .exchangeRate
                                                    )
                                                ).toFixed(2)}
                                            </p>
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <h5>Coupon Code</h5>
                                            <p>
                                                {get(
                                                    resevationsDetails,
                                                    "coupon.code",
                                                    "NA"
                                                )}
                                            </p>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <h5>Payment Type</h5>
                                            <p>
                                                {get(
                                                    resevationsDetails,
                                                    "payAtHotel",
                                                    false
                                                )
                                                    ? "Pay at hotel"
                                                    : "Pay now"}
                                            </p>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <h5>Payment status</h5>
                                            <p>
                                                {resevationsDetails.refund
                                                    ? "Refunded"
                                                    : resevationsDetails.charge
                                                          .status ===
                                                          "charged" ||
                                                      resevationsDetails.charge
                                                          .status === "success"
                                                    ? "Paid"
                                                    : "Not Paid"}
                                            </p>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <h5>Reservation Status Sent</h5>
                                            <p>
                                                {resevationsDetails.chargeStatus ===
                                                    "statement" ||
                                                resevationsDetails.chargeStatus ===
                                                    "charge"
                                                    ? resevationsDetails.statementIssueDate
                                                        ? moment(
                                                              resevationsDetails.statementIssueDate
                                                          )
                                                              .tz(
                                                                  resevationsDetails?.timezone ||
                                                                      "Australia/Sydney"
                                                              )
                                                              .format(
                                                                  "MMM D YYYY"
                                                              )
                                                        : "Yes"
                                                    : "No"}
                                            </p>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <h5>TimeFlyz Billing Deducted</h5>
                                            <p>
                                                {resevationsDetails.chargeStatus ===
                                                "charge"
                                                    ? resevationsDetails.invoiceIssueDate
                                                        ? moment(
                                                              resevationsDetails.invoiceIssueDate
                                                          )
                                                              .tz(
                                                                  resevationsDetails?.timezone ||
                                                                      "Australia/Sydney"
                                                              )
                                                              .format(
                                                                  "MMM D YYYY"
                                                              )
                                                        : "Yes"
                                                    : "No"}
                                            </p>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <h5>Stripe Fee</h5>
                                            <p>
                                            {parseFloat(
                                                get(
                                                    resevationsDetails,
                                                    "charge.stripeFee",
                                                    0
                                                )
                                            ).toFixed(2)}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <h5>UserRate</h5>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <h5>HotelRate</h5>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <h5>BaseRate</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <h5>Rate</h5>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <h5>
                                                {get(
                                                    resevationsDetails,
                                                    "charge.userVatgst.shortName",
                                                    "A$"
                                                )}
                                                {parseFloat(
                                                    get(
                                                        resevationsDetails,
                                                        "charge.cost",
                                                        0
                                                    ) * resevationsDetails.charge.userExchangeRate
                                                ).toFixed(2)}
                                            </h5>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <h5>
                                                {get(
                                                    resevationsDetails,
                                                    "charge.hotelVatgst.shortName",
                                                    "A$"
                                                )}
                                                {parseFloat(
                                                    get(
                                                        resevationsDetails,
                                                        "charge.cost",
                                                        0
                                                    ) * resevationsDetails.charge.hotelExchangeRate
                                                ).toFixed(2)}
                                            </h5>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <h5>
                                                A$
                                                {parseFloat(
                                                    get(
                                                        resevationsDetails,
                                                        "charge.cost",
                                                        0
                                                    )
                                                ).toFixed(2)}
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <h5>Surcharge (2%)</h5>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <p><h5>
                                            {get(
                                                    resevationsDetails,
                                                    "charge.userVatgst.shortName",
                                                    "A$"
                                                )}
                                                {parseFloat(
                                                    get(
                                                        resevationsDetails,
                                                        "charge.surcharge",
                                                        0
                                                    ) * resevationsDetails.charge.userExchangeRate
                                                ).toFixed(2)}
                                            </h5></p>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <h5>
                                                {get(
                                                    resevationsDetails,
                                                    "charge.hotelVatgst.shortName",
                                                    "A$"
                                                )}
                                                {parseFloat(
                                                    get(
                                                        resevationsDetails,
                                                        "charge.surcharge",
                                                        0
                                                    ) * resevationsDetails.charge.hotelExchangeRate
                                                ).toFixed(2)}
                                            </h5>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <h5>
                                                A$
                                                {parseFloat(
                                                    get(
                                                        resevationsDetails,
                                                        "charge.surcharge",
                                                        0
                                                    )
                                                ).toFixed(2)}
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <h5>Rate including Payment Surcharge</h5>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <p><h5>
                                                {get(
                                                    resevationsDetails,
                                                    "charge.userVatgst.shortName",
                                                    "A$"
                                                )}
                                                {parseFloat(
                                                    get(
                                                        resevationsDetails,
                                                        "charge.total",
                                                        0
                                                    ) * resevationsDetails.charge.userExchangeRate
                                                ).toFixed(2)}
                                            </h5></p>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <h5>
                                                {get(
                                                    resevationsDetails,
                                                    "charge.hotelVatgst.shortName",
                                                    "A$"
                                                )}
                                                {parseFloat(
                                                    get(
                                                        resevationsDetails,
                                                        "charge.total",
                                                        0
                                                    ) * resevationsDetails.charge.hotelExchangeRate
                                                ).toFixed(2)}
                                            </h5>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <h5>
                                                A$
                                                {parseFloat(
                                                    get(
                                                        resevationsDetails,
                                                        "charge.total",
                                                        0
                                                    )
                                                ).toFixed(2)}
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <h5>TimeFlyz Commission (
                                                {round(
                                                    (get(
                                                        resevationsDetails,
                                                        "charge.commission",
                                                        0
                                                    ) /
                                                        get(
                                                            resevationsDetails,
                                                            "charge.cost",
                                                            0
                                                        )) *
                                                        100,
                                                    2
                                                )}{" "}
                                                %)</h5>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <p><h5>
                                                {get(
                                                    resevationsDetails,
                                                    "charge.userVatgst.shortName",
                                                    "A$"
                                                )}
                                                {parseFloat(
                                                    get(
                                                        resevationsDetails,
                                                        "charge.commission",
                                                        0
                                                    ) * resevationsDetails.charge.userExchangeRate
                                                ).toFixed(2)}
                                            </h5></p>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <h5>
                                                {get(
                                                    resevationsDetails,
                                                    "charge.hotelVatgst.shortName",
                                                    "A$"
                                                )}
                                                {parseFloat(
                                                    get(
                                                        resevationsDetails,
                                                        "charge.commission",
                                                        0
                                                    ) * resevationsDetails.charge.hotelExchangeRate
                                                ).toFixed(2)}
                                            </h5>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <h5>
                                                A$
                                                {parseFloat(
                                                    get(
                                                        resevationsDetails,
                                                        "charge.commission",
                                                        0
                                                    )
                                                ).toFixed(2)}
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <h5>TimeFlyz Commission TAX (
                                                {round(
                                                    (get(
                                                        resevationsDetails,
                                                        "charge.commissionGST",
                                                        0
                                                    ) /
                                                        get(
                                                            resevationsDetails,
                                                            "charge.commission",
                                                            0
                                                        )) *
                                                        100,
                                                    0
                                                )}{" "}
                                                %)</h5>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <p><h5>
                                            {get(
                                                    resevationsDetails,
                                                    "charge.userVatgst.shortName",
                                                    "A$"
                                                )}
                                                {parseFloat(
                                                    get(
                                                        resevationsDetails,
                                                        "charge.commissionGST",
                                                        0
                                                    ) * resevationsDetails.charge.userExchangeRate
                                                ).toFixed(2)}
                                            </h5></p>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <h5>
                                                {get(
                                                    resevationsDetails,
                                                    "charge.hotelVatgst.shortName",
                                                    "A$"
                                                )}
                                                {parseFloat(
                                                    get(
                                                        resevationsDetails,
                                                        "charge.commissionGST",
                                                        0
                                                    ) * resevationsDetails.charge.hotelExchangeRate
                                                ).toFixed(2)}
                                            </h5>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <h5>
                                                A$
                                                {parseFloat(
                                                    get(
                                                        resevationsDetails,
                                                        "charge.commissionGST",
                                                        0
                                                    )
                                                ).toFixed(2)}
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <h5>Hotel Discount</h5>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <p><h5>
                                            {get(
                                                    resevationsDetails,
                                                    "charge.userVatgst.shortName",
                                                    "A$"
                                                )}
                                                {parseFloat(
                                                    get(
                                                        resevationsDetails,
                                                        "charge.hotelDiscount",
                                                        "0"
                                                    ) * resevationsDetails.charge.userExchangeRate
                                                ).toFixed(2)}
                                            </h5></p>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <h5>
                                                {get(
                                                    resevationsDetails,
                                                    "charge.hotelVatgst.shortName",
                                                    "A$"
                                                )}
                                                {parseFloat(
                                                    get(
                                                        resevationsDetails,
                                                        "charge.hotelDiscount",
                                                        0
                                                    ) * resevationsDetails.charge.hotelExchangeRate
                                                ).toFixed(2)}
                                            </h5>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <h5>
                                                A$
                                                {parseFloat(
                                                    get(
                                                        resevationsDetails,
                                                        "charge.hotelDiscount",
                                                        0
                                                    )
                                                ).toFixed(2)}
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <h5>TimeFlyz Discount</h5>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <p><h5>
                                            {get(
                                                    resevationsDetails,
                                                    "charge.userVatgst.shortName",
                                                    "A$"
                                                )}
                                                {parseFloat(
                                                    get(
                                                        resevationsDetails,
                                                        "charge.timeflyzDiscount",
                                                        "0"
                                                    ) * resevationsDetails.charge.userExchangeRate
                                                ).toFixed(2)}
                                            </h5></p>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <h5>
                                                {get(
                                                    resevationsDetails,
                                                    "charge.hotelVatgst.shortName",
                                                    "A$"
                                                )}
                                                {parseFloat(
                                                    get(
                                                        resevationsDetails,
                                                        "charge.timeflyzDiscount",
                                                        0
                                                    ) * resevationsDetails.charge.hotelExchangeRate
                                                ).toFixed(2)}
                                            </h5>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <h5>
                                                A$
                                                {parseFloat(
                                                    get(
                                                        resevationsDetails,
                                                        "charge.timeflyzDiscount",
                                                        0
                                                    )
                                                ).toFixed(2)}
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <h5>Hotel Revenue (excl TAX)</h5>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <p><h5>
                                            {get(
                                                    resevationsDetails,
                                                    "charge.userVatgst.shortName",
                                                    "A$"
                                                )}
                                                {parseFloat(
                                                    (get(
                                                        resevationsDetails,
                                                        "charge.amount",
                                                        0
                                                    ) -
                                                        get(
                                                            resevationsDetails,
                                                            "charge.commission",
                                                            0
                                                        )) * resevationsDetails.charge.userExchangeRate
                                                ).toFixed(2)}
                                            </h5></p>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <h5>
                                                {get(
                                                    resevationsDetails,
                                                    "charge.hotelVatgst.shortName",
                                                    "A$"
                                                )}
                                                {parseFloat(
                                                    (get(
                                                        resevationsDetails,
                                                        "charge.amount",
                                                        0
                                                    ) -
                                                        get(
                                                            resevationsDetails,
                                                            "charge.commission",
                                                            0
                                                        )) * resevationsDetails.charge.hotelExchangeRate
                                                ).toFixed(2)}
                                            </h5>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                            <h5>
                                                A$
                                                {parseFloat(
                                                    (get(
                                                        resevationsDetails,
                                                        "charge.amount",
                                                        0
                                                    ) -
                                                        get(
                                                            resevationsDetails,
                                                            "charge.commission",
                                                            0
                                                        ))
                                                ).toFixed(2)}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                {resevationsDetails.reviewId &&
                                resevationsDetails.reviewId.comment ? (
                                    <div>
                                        <div className="panel-header ">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                    <h3>
                                                        <strong>
                                                            Customer Review
                                                        </strong>{" "}
                                                    </h3>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                    <button
                                                        className="btn btn-danger"
                                                        onClick={() =>
                                                            this.showAlertBox(
                                                                resevationsDetails,
                                                                "Review"
                                                            )
                                                        }
                                                        style={{
                                                            float: "right",
                                                        }}
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="panel-content"
                                            style={{ paddingLeft: 20 }}
                                        >
                                            <div
                                                className="row"
                                                style={{ marginTop: 10 }}
                                            >
                                                {resevationsDetails.reviewId.tags.map(
                                                    (data, index) => {
                                                        return (
                                                            <div
                                                                className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
                                                                key={index}
                                                            >
                                                                <div
                                                                    className="row"
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        alignItems:
                                                                            "center",
                                                                        marginTop: 5,
                                                                    }}
                                                                >
                                                                    <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                                                        <span>
                                                                            {
                                                                                data.key
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                                                        <Progress
                                                                            completed={
                                                                                (data.value /
                                                                                    5) *
                                                                                100
                                                                            }
                                                                            color={
                                                                                "#FF4500"
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                                        <span>
                                                                            {
                                                                                data.value
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                            <div className="review-comment">
                                                <div
                                                    className="row"
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div className="col-lg-1 col-md-1 col-sm-1 col-xs-12">
                                                        <div
                                                            style={{
                                                                width: 50,
                                                                height: 50,
                                                                borderRadius:
                                                                    "50%",
                                                                backgroundColor:
                                                                    "#FF4500",
                                                                color: "#fff",
                                                                display: "flex",
                                                                justifyContent:
                                                                    "center",
                                                                alignItems:
                                                                    "center",
                                                                fontSize: 18,
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            {
                                                                this.fetchName(
                                                                    resevationsDetails
                                                                        .bookedBy
                                                                        .fullName
                                                                )[0]
                                                            }
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="col-lg-11 col-md-11 col-sm-11 col-xs-12"
                                                        style={{
                                                            marginTop: 10,
                                                        }}
                                                    >
                                                        <strong
                                                            style={{
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            {this.fetchName(
                                                                resevationsDetails
                                                                    .bookedBy
                                                                    .fullName
                                                            )}
                                                        </strong>
                                                        <p
                                                            style={{
                                                                fontSize: 10,
                                                            }}
                                                        >
                                                            {moment(
                                                                resevationsDetails
                                                                    .reviewId
                                                                    .createdAt
                                                            ).format(
                                                                "MMM DD YYYY"
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                                <p style={{ padding: 5 }}>
                                                    {
                                                        resevationsDetails
                                                            .reviewId.comment
                                                    }
                                                </p>
                                                {resevationsDetails.reviewId
                                                    .reply ? (
                                                    <div
                                                        className={
                                                            width > 900
                                                                ? "row admin-response"
                                                                : "row admin-response-no-border"
                                                        }
                                                    >
                                                        <div
                                                            className="row"
                                                            style={
                                                                hotelImage
                                                                    ? {
                                                                          display:
                                                                              "flex",
                                                                          justifyContent:
                                                                              "center",
                                                                          alignItems:
                                                                              "center",
                                                                      }
                                                                    : {
                                                                          display:
                                                                              "flex",
                                                                          alignItems:
                                                                              "center",
                                                                      }
                                                            }
                                                        >
                                                            {hotelImage ? (
                                                                <div className="col-lg-1 col-md-1 col-sm-1 col-xs-12">
                                                                    <img
                                                                        src={
                                                                            hotelImage
                                                                        }
                                                                        alt={
                                                                            hotelImage
                                                                        }
                                                                        style={{
                                                                            width: 50,
                                                                            height: 50,
                                                                            borderRadius:
                                                                                "50%",
                                                                            float: "right",
                                                                        }}
                                                                    />
                                                                </div>
                                                            ) : null}
                                                            <div className="col-lg-11 col-md-11 col-sm-11 col-xs-12">
                                                                <strong
                                                                    style={{
                                                                        fontWeight:
                                                                            "bold",
                                                                    }}
                                                                >
                                                                    {
                                                                        resevationsDetails
                                                                            .hotelId
                                                                            .name
                                                                    }
                                                                </strong>
                                                                <p
                                                                    style={{
                                                                        fontSize: 10,
                                                                    }}
                                                                >
                                                                    {moment(
                                                                        resevationsDetails
                                                                            .reviewId
                                                                            .updatedAt
                                                                    ).format(
                                                                        "MMM DD YYYY"
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <p
                                                            style={{
                                                                padding: 5,
                                                            }}
                                                        >
                                                            {
                                                                resevationsDetails
                                                                    .reviewId
                                                                    .reply
                                                            }
                                                        </p>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}
