import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Box, Grid, Button, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import { Container } from "@mui/system";
import moment from "moment";
import { intersection, uniqBy } from "lodash";
import { toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";

import $api from "../../utils/axios";
import DisableAccountModal from "./DisableAccountModal";
import ExportExcel from "./ExportExcel";
import FilterForm from "./FilterForm";
import usePropertyChain from "../../hooks/usePropertyChain";

const Users = () => {
    let history = useHistory();
    const { chains } = usePropertyChain();

    const [allUsers, setAllUsers] = useState([]);
    const [rows, setRows] = useState([]);
    const [disableAccountSelectedUser, setDisableAccountSelectedUser] =
        useState(null);
    const [activeAccountSelectedUser, setActiveAccountSelectedUser] =
        useState(null);
    const [
        resetPasswordAccountSelectedUser,
        setResetPasswordAccountSelectedUser,
    ] = useState(null);
    const [formValues, setFormValues] = useState({});

    const filterUsers = (values = null) => {
        if (values) {
            setFormValues(values);
        }
        const { search, userType, propertyChain, hotel } = values || formValues;
        const newRows = allUsers.filter((item) => {
            // search string check
            if (
                search &&
                !item.email?.toLowerCase()?.includes(search.toLowerCase()) &&
                !item.fullName?.toLowerCase()?.includes(search.toLowerCase())
            )
                return false;

            // user type check
            // admin or customer check
            if (
                (userType === "APP" || userType === "ADMIN") &&
                item.userType !== userType
            )
                return false;
            // manager check
            if (
                userType === "hotel-manager" ||
                userType === "revenue-manager" ||
                userType === "owner"
            ) {
                if (item.userType !== "EMPLOYEE") return false;

                if (
                    userType === "hotel-manager" &&
                    item.accessLevel !== "REGIONALMANAGER"
                )
                    return false;
                if (
                    userType === "revenue-manager" &&
                    item.accessLevel !== "GROUPMANAGER"
                )
                    return false;
                if (userType === "owner" && item.accessLevel !== "OWNER")
                    return false;
            }

            if (
                userType === "guest" &&
                item.accessLevel !== "MANAGER" &&
                item.accessLevel !== "STANDARD"
            )
                return false;

            if (
                propertyChain?.length &&
                !propertyChain.includes(item.commission?._id)
            )
                return false;
            if (
                hotel?.length &&
                intersection(
                    hotel || [],
                    uniqBy(
                        [...item.contacts, ...item.managers, ...item.hotel],
                        (r) => r._id.toString()
                    )?.map((v) => v._id)
                ).length === 0
            )
                return false;
            return true;
        });
        setRows(newRows);
    };

    const closeDisableAccountModal = () => {
        setDisableAccountSelectedUser(null);
    };

    const onDisabledAccount = () => {
        loadAllUsers();
        closeDisableAccountModal();
    };

    const confirmResetPassword = async () => {
        const userData = { ...resetPasswordAccountSelectedUser };
        setResetPasswordAccountSelectedUser(null);
        try {
            const res = await $api.post(`/user/resetPassword`, {
                email: userData.email,
                userId: userData._id,
            });
            if (res.data === "OK") {
                toast.success("Password Reset Email sent Successfully", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                throw new Error("Server error");
            }
        } catch (err) {
            toast.error("Something went wrong, Please try again...", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const confirmActiveAccount = async () => {
        try {
            await $api
                .put(`/user/disableAccount/${activeAccountSelectedUser?._id}`, {
                    expireDate: null,
                })
                .then((res) => res.data);
            toast.success("Reactivate Account successfully.");
        } catch (err) {
            console.log(err);
            toast.error(`An error happened. Error: ${err.message}`);
        }
        loadAllUsers();
        setActiveAccountSelectedUser(null);
    };

    const loadAllUsers = async () => {
        const res = await $api.get("/user/all");
        setAllUsers(res.data);
    };

    useEffect(() => {
        loadAllUsers();
    }, []);

    useEffect(() => {
        filterUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allUsers]);

    const editUser = useCallback(
        (id) => () => {
            history.push(`/users-all/${id}`);
        },
        [history]
    );
    const editRole = useCallback(
        (id) => () => {
            history.push(`/users-all/${id}/role-management`);
        },
        [history]
    );

    const getRoleType = (row) => {
        if (row.userType === "ADMIN") return "ADMIN";
        if (row.userType === "APP") return "CUSTOMER";
        if (row.userType === "EMPLOYEE") {
            if (row.accessLevel === "REGIONALMANAGER") return "Hotel Manager";
            if (row.accessLevel === "GROUPMANAGER") return "Revenue Manager";
            if (row.accessLevel === "OWNER") return "Owner";
        }
        return "GUEST";
    };

    const columns = useMemo(
        () => [
            {
                field: "actions",
                type: "actions",
                width: 80,
                getActions: (params) => {
                    return [
                        <GridActionsCellItem
                            label="Display User Details"
                            onClick={editUser(params.id)}
                            showInMenu
                        />,
                        <GridActionsCellItem
                            label="Role Management"
                            onClick={editRole(params.id)}
                            showInMenu
                        />,
                        params.row.expireDate ? (
                            <GridActionsCellItem
                                label="Reactivate Account"
                                showInMenu
                                onClick={() =>
                                    setActiveAccountSelectedUser(params.row)
                                }
                            />
                        ) : (
                            <GridActionsCellItem
                                label="Disable Account"
                                showInMenu
                                onClick={() =>
                                    setDisableAccountSelectedUser(params.row)
                                }
                            />
                        ),
                        params.row.userType === "APP" ? (
                            <GridActionsCellItem
                                label="View Customer Information"
                                showInMenu
                                onClick={() =>
                                    history.push(
                                        `/customerRecords/${params.row?._id}`
                                    )
                                }
                            />
                        ) : (
                            <></>
                        ),
                        <GridActionsCellItem
                            label="Reset Password"
                            showInMenu
                            onClick={() =>
                                setResetPasswordAccountSelectedUser(params.row)
                            }
                        />,
                    ];
                },
            },
            { headerName: "Email", field: "email", type: "string", width: 180 },
            {
                headerName: "Name",
                field: "fullName",
                type: "string",
                width: 150,
                valueGetter: (params) => {
                    const rowData = params.api.getRow(params.id);
                    return `${rowData.firstName} ${rowData.lastName}`;
                },
                valueFormatter: (params) => {
                    const rowData = params.api.getRow(params.id);
                    return `${rowData.firstName} ${rowData.lastName}`;
                },
            },
            {
                headerName: "Role Type",
                field: "userType",
                type: "string",
                width: 120,
                valueGetter: (params) => {
                    const rowData = params.api.getRow(params.id);
                    return getRoleType(rowData);
                },
                valueFormatter: (params) => {
                    const rowData = params.api.getRow(params.id);
                    return getRoleType(rowData);
                },
            },
            {
                headerName: "Chain",
                field: "commission",
                type: "string",
                width: 80,
                sortable: false,
                valueFormatter: (params) => {
                    const rowData = params.api.getRow(params.id);
                    if (!params.value || rowData.userType !== "EMPLOYEE") {
                        return "";
                    }
                    return params.value?.clientTypeName || "";
                },
            },
            {
                headerName: "Hotel",
                field: "hotel-list",
                type: "string",
                width: 250,
                sortable: false,
                valueGetter: (params) => params.row,
                valueFormatter: (params) => {
                    if (
                        params.value.userType === "ADMIN" ||
                        (params.value.userType === "EMPLOYEE" &&
                            (params.value.accessLevel === "REGIONALMANAGER" ||
                                params.value.accessLevel === "GROUPMANAGER"))
                    ) {
                        return uniqBy(
                            [
                                ...params.value.contacts,
                                ...params.value.managers,
                                ...(params.value.hotel
                                    ? params.value.hotel
                                    : []),
                            ],
                            (r) => r._id.toString()
                        )
                            ?.map((a) => a.name)
                            .join(", ");
                    }
                    return "";
                },
            },
            {
                headerName: "Date User Added",
                field: "createdAt",
                type: "date",
                width: 100,
                valueFormatter: (params) => {
                    if (!params.value) {
                        return "";
                    }
                    return moment(params.value).format("DD/MM/YYYY");
                },
            },
            {
                headerName: "Date User Last Login",
                field: "lastLoggedIn",
                type: "date",
                width: 100,
                valueFormatter: (params) => {
                    if (!params.value) {
                        return "";
                    }
                    return moment(params.value).format("DD/MM/YYYY");
                },
            },
            {
                headerName: "Status",
                field: "status",
                sortable: false,
                valueGetter: (params) => params.row,
                valueFormatter: (params) => {
                    return params.value.expireDate
                        ? new Date(params.value.expireDate) > new Date()
                            ? "Active"
                            : "Not Active"
                        : "Active";
                },
            },
            {
                headerName: "Banned",
                field: "banned",
                type: "string",
                width: 150,
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [editUser, editRole]
    );

    return (
        <Box>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                <Typography variant="h4" color="initial">
                    Users
                </Typography>

                <Box>
                    <ExportExcel data={allUsers} />
                    <Button
                        variant="contained"
                        onClick={() => history.push("/users/create")}
                    >
                        <AddIcon />
                        Create User
                    </Button>
                </Box>
            </Grid>

            <Container
                sx={{
                    backgroundColor: "white",
                    my: 2,
                    pt: 0,
                    borderRadius: 1,
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
            >
                <FilterForm onSubmit={filterUsers} chains={chains} />
                <DataGrid
                    sx={{ border: 0 }}
                    getRowId={(row) => row._id}
                    columns={columns}
                    rows={rows}
                    autoHeight
                    disableColumnFilter
                />
            </Container>

            <DisableAccountModal
                isOpen={!!disableAccountSelectedUser}
                user={disableAccountSelectedUser}
                onClose={closeDisableAccountModal}
                onComplete={onDisabledAccount}
            />
            <SweetAlert
                show={!!resetPasswordAccountSelectedUser}
                warning
                showCancel
                confirmBtnText="Confirm"
                confirmBtnBsStyle="danger"
                title={`Please confirm you wish to reset guest password for ${resetPasswordAccountSelectedUser?.firstName} ${resetPasswordAccountSelectedUser?.lastName}`}
                onConfirm={confirmResetPassword}
                onCancel={() => setResetPasswordAccountSelectedUser(null)}
                focusCancelBtn
            />
            <SweetAlert
                show={!!activeAccountSelectedUser}
                info
                showCancel
                confirmBtnText="Confirm"
                confirmBtnBsStyle="danger"
                title={`Please confirm you wish to active ${activeAccountSelectedUser?.firstName} ${activeAccountSelectedUser?.lastName}'s account.`}
                onConfirm={confirmActiveAccount}
                onCancel={() => setActiveAccountSelectedUser(null)}
                focusCancelBtn
            />
        </Box>
    );
};

export default Users;
