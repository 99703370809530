import Axios from "axios";
import React, { Component } from "react";
import { toast } from 'react-toastify';
import logo from "../../assets/images/BIT_Support_Logo.png";

export default class Login extends Component {
    state = {
        departmentList:[]
    }
    constructor(props) {
        super(props);
        this.state = {
            userEmail: '',
            userPassword: '',
            errorMessage: '',
            errorStatus: false,
            role:''
        }
    }

    componentDidMount() {
        localStorage.removeItem('Credentials');
        this.fetchDepartmentTypes();
    }

    fetchDepartmentTypes = async () => {
        // let token = await localStorage.getItem('token');
        // let array = [];
        await Axios.post(`${process.env.REACT_APP_API_URL}/department/listDepartment`, {}, {
            // headers: {
            //     Authorization: `Bearer ${token}`
            // },
        })
        .then(response => {
            console.log(response.data.department)
            // response.data.forEach(element => {
            //     array.push({
            //         name: element.clientTypeName,
            //         _id: element._id
            //     });
            // });
            // this.setState({
            //     propertyType: array,
            //     managerType: array[0]._id
            // }, () => {
            //     this.fetchReservations();
            // })
            this.setState({departmentList:response.data.department})
        })
        .catch(error => {
            console.log(error);
        })
    }

    onChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
            errorStatus: false
        })
    }

    validate = () => {
        const { userEmail, userPassword,repeatPassword, role } = this.state;
        const regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/

        let isValidated = false;
        let message = '';

        if (userEmail === "") {
            message = "Please enter Your Email Address";
            this.setState({
                errorMessage: message,
                errorStatus: true
            })
        } else if (!regex.test(userEmail.trim())) {
            message = "Please enter a valid Email Address";
            this.setState({
                errorMessage: message,
                errorStatus: true
            })
        } else if (role === '') {
            message = "Please select user role";
            this.setState({
                errorMessage: message,
                errorStatus: true
            })
        } else if(repeatPassword!==userPassword){
            message = "Passwords do not match. Please re-enter your password";
            this.setState({
                errorMessage: message,
                errorStatus: true
            })
        } else if (userPassword === "" || userPassword.length < 6) {
            message = "Please enter Your Password, it must be atlest 6 character";
            this.setState({
                errorMessage: message,
                errorStatus: true
            })
        } else {
            isValidated = true
        }

        return isValidated;
    }

    loginFunc = async (e) => {
        e.preventDefault();
        console.log(this.state)
   
        // console.log(this.state, "state Data")
        const {
            repeatPassword,
            userEmail,
            userFirstName,
            userLastName,
            userDepartment,
            role
        } = this.state;
        if (this.validate()) {
    
            let payload = {
                "email":userEmail,
                "password":repeatPassword,
                "firstName":userFirstName,
                "lastName":userLastName,
                "userType":userDepartment,
                "encryption":true,
                "role":role
            }
            await Axios.post(`${process.env.REACT_APP_API_URL}/user/create`, payload)
                .then(response => {
                    if (response.status === 201) {
                        // localStorage.setItem('Credentials', JSON.stringify(payload));
                        this.props.history.push(`/login`);
                        toast.success('SignUp Successfully', {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        this.setState({
                            errorMessage: "Something wrong. Please try again...",
                            errorStatus: true
                        })
                    }
                })
                .catch(error => {
                    this.setState({
                        errorMessage: error,
                        errorStatus: true
                    })
                })
        }
    }

  

    render() {
        const { departmentList,role,userType } = this.state;
        return (
            <div className="container-fluid" id="login-block">
                <div className="account-wall" style={{ height: window.innerHeight - 1, display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                    <form className="form-signin" onSubmit={this.loginFunc}>
                    <img src={logo} className="user-img" alt={logo} style={{height: 60}} />
                        {
                            this.state.errorStatus
                                ?
                                <p style={{ color: "red" }}>{this.state.errorMessage}.</p>
                                :
                                null
                        }
                        <div className="append-icon">
                            <input type="text" name="userFirstName" className="form-control form-white username" placeholder="First Name" required onChange={this.onChange} />
                            <i className="icon-user" />
                        </div>
                        <div className="append-icon">
                            <input type="text" name="userLastName" className="form-control form-white username" placeholder="Last Name" required onChange={this.onChange} />
                            <i className="icon-user" />
                        </div>
                        <div className="append-icon">
                            <input type="text" name="userEmail" className="form-control form-white username" placeholder="Email" required onChange={this.onChange} />
                            <i className="icon-user" />
                        </div>
                        <div className="append-icon m-b-10">
                            <select className="form-control form-white username" name="role" onChange={this.onChange}>
                                <option value="">Select Type</option>
                                {
                                  ['Employee','Department'].map((element,index) => {
                                        return (
                                            <option value={element} key={index}>{element}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                       { role==='Department' && <div className="append-icon m-b-10">
                            <select className="form-control form-white username" name="userDepartment" onChange={this.onChange}>
                                <option value="">Select Department</option>
                                {
                                  departmentList &&  departmentList.map((element,index) => {
                                        return (
                                            <option value={element._id} key={index}>{element.name}</option>
                                        )
                                    })
                                }
                            </select>
                        </div> }
                        <div className="append-icon">
                            <input type="password" name="userPassword" className="form-control form-white password" placeholder="Password" required onChange={this.onChange} />
                            <i className="icon-lock" />
                        </div>
                        <div className="append-icon m-b-20">
                            <input type="password" name="repeatPassword" className="form-control form-white password" placeholder="Repeat Password" required onChange={this.onChange} />
                            <i className="icon-lock" />
                        </div>
                        <button type="submit" id="submit-form" className="btn btn-lg btn-danger btn-block ladda-button" data-style="expand-left">Sign Up</button>
                    </form>
                </div>
                <p className="account-copyright">
                    
                    {/*<span>If you have already created account, </span><span>please <a href="/sanghamita-paul/helpdesk/Login">Signin</a></span>*/}
                    
                    <span>If you have already created account, </span><span>please <a href="/Login">Signin</a></span>
                </p>
            </div>
        )
    }
}

