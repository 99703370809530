import $api from "../../utils/axios";
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Container } from "@mui/system";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
//import moment from 'moment';
import "./tabs.css";

export default function Archives() {
  const [rows, setRows] = React.useState([]);
  const [currentTab, setCurrentTab] = React.useState();
  const [tabs, setTabs] = React.useState([]);
  const [options, setOptions] = React.useState([]);

  const [hotelname, setHotelname] = React.useState();
  React.useEffect(() => {
    (async () => {
      const resHotel = await $api.get("/archives/hotels");
      const res = await $api.get(`/archives/${resHotel.data[0]._id}`);
      setOptions(resHotel.data);
      setTabs(res.data);
      setRows(res.data[0].availabilities);
      setCurrentTab(res.data[0]._id);
      setHotelname(resHotel.data[0].hotelName);
    })();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = React.useMemo(() => [
    {
      headerName: "Date",
      field: "date",
      type: "date",
      width: 200,
    },
    {
      headerName: "Total Rooms",
      field: "rooms",
      width: 150,
      valueFormatter: (params) => {
        if (!params.value) {
          return "";
        }
        return params.value.name;
      },
    },
    {
      headerName: "Room Available",
      field: "roomAvailability",
      type: "string",
      width: 150,
      valueFormatter: (params) => {
        if (!params.value) {
          return "";
        }
        return params.value.name;
      },
    },
    {
      headerName: "Expedia Rate",
      field: "expediaRate",
      width: 150,
      valueFormatter: (params) => {
        if (!params.value) {
          return "";
        }
        return params.value.name;
      },
    },
    {
      headerName: "Override Rate",
      field: "overnightRate",
      width: 120,
      valueFormatter: (params) => {
        if (!params.value) {
          return "";
        }
        return params.value.name;
      },
    },
    {
      headerName: "3h Rate",
      field: "threeHourRate",
      width: 120,
    },
    {
      headerName: "6h Rate",
      field: "sixHourRate",
      width: 120,
    },
    {
      headerName: "12h Rate",
      field: "twelveHourRate",
      width: 120,
    },
    {
      headerName: "3h Slot",
      field: "availableSlotsThreeHours",
      width: 140,
      renderCell: (params) => <div dangerouslySetInnerHTML={{ __html: params.value }} />,
    },
    {
      headerName: "6h Slot",
      field: "availableSlotsSixHours",
      width: 120,
      renderCell: (params) => <div dangerouslySetInnerHTML={{ __html: params.value }} />,
    },
    {
      headerName: "12h Slot",
      field: "availableSlotsTweleveHours",
      width: 120,
      renderCell: (params) => <div dangerouslySetInnerHTML={{ __html: params.value }} />,
    },
    // {
    //   headerName: 'Created At', field: 'createdAt', type: 'date', width: 120,
    //   valueFormatter: (params) => {
    //     if (!params.value) {
    //       return '';
    //     }
    //     return moment(params.value).format('DD/MM HH:mm');
    //   },
    // },
  ]);

  /*const tabs = [
        {
            id: 1,
            tabTitle: 'Tab 1',
            title: 'Title 1',
            content: 'Las tabs se generan automáticamente a partir de un array de objetos, el cual tiene las propiedades: id, tabTitle, title y content.'
        },
        {
            id: 2,
            tabTitle: 'Tab 2',
            title: 'Title 2',
            content: 'Contenido de tab 2.'
        },
        {
            id: 3,
            tabTitle: 'Tab 3',
            title: 'Title 3',
            content: 'Contenido de tab 3.'
        },
        {
            id: 4,
            tabTitle: 'Tab 4',
            title: 'Title 4',
            content: 'Contenido de tab 4.'
        }
    ];*/

  const handleTabClick = (e) => {
    const indexcurrent = tabs.findIndex((tab) => tab._id === e.target.id);
    setRows(tabs[indexcurrent].availabilities);
    setCurrentTab(e.target.id);
    //setCurrentTab(res.data[0]._id);
  };

  const handleOptionChange = async (event, selectedOption) => {
    let resnew = await $api.get(`/archives/${selectedOption._id}`);
    setTabs(resnew.data);
    setRows(resnew.data[0].availabilities);
    setCurrentTab(resnew.data[0]._id);
    //setCurrentTab(res.data[0]._id);
  };

  return (
    <Box>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
        <Typography variant="h4" color="initial">
          Archives
        </Typography>
        <div className="autoselect">
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={options}
            getOptionLabel={(option) => option.hotelName}
            sx={{ width: 300 }}
            onChange={handleOptionChange}
            renderInput={(params) => <TextField {...params} label={hotelname} />}
          />
        </div>
      </Grid>
      <Container
        sx={{
          backgroundColor: "white",
          my: 2,
          p: 0,
          borderRadius: 1,
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="tabs">
          {tabs.map((tab, i) => (
            <button
              key={i}
              id={tab._id}
              disabled={currentTab === `${tab._id}`}
              onClick={handleTabClick}
            >
              {tab.name}
            </button>
          ))}
        </div>
        <div className="content">
          {tabs.map((tab, i) => (
            <div key={i}>
              {currentTab === `${tab._id}` && (
                <DataGrid
                  sx={{ border: 0 }}
                  getRowId={(row) => row._id}
                  autoHeight
                  getRowHeight={() => "auto"}
                  columns={columns}
                  rows={rows}
                  rowHeight={100}
                />
              )}
            </div>
          ))}
        </div>
      </Container>
    </Box>
  );
}
