import $api from "../../utils/axios";
import { toast } from 'react-toastify';
import React from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Country } from 'country-state-city';

export default function Vatgst() {
  const history = useHistory();
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const res = await $api.get('/vatgst/all');
      setRows(res.data);
    })();
  }, []);

  const handleOptionChange = async (event, selectedOption) => {
    let resnew = await $api.get(`/vatgst/${selectedOption === null ? 'all' : selectedOption.flag}`);
    setRows(resnew.data);
  };

  const handleDeleteClick = async (param) => {
    try {
      const parameters = {
        "vatId": param,
        "isDeleted": true,
      };
      const managerResponse = await $api.post(`${process.env.REACT_APP_API_URL}/vatgst/deletevatgst`, parameters, {
        headers: {
          'Content-type': 'application/json',
        },
      })
      setRows(managerResponse.data);
      toast.success('Record Deleted Successfully');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="header">
        <h2><strong>Manage</strong> Countries</h2>
        <div className="breadcrumb-wrapper">
          <ol className="breadcrumb">
            <li><a href={() => null} onClick={() => history.push("/dashboard")}>TimeFlyz</a>
            </li>
            <li className="active">Country Management</li>
          </ol>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="panel">
            <div className="panel-header ">
              <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                  <h3><strong>Country Listing</strong> </h3>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" style={{ textAlign: 'end' }}>
                  <button onClick={() => history.push("/add-vat")} className="btn btn-primary" rel="noreferrer"><i className="fa fa-plus c-warning" />Add Country</button>
                </div>
              </div>
            </div>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <div>
              </div>
              <div className="autoselect" style={{ position: 'right' }}>
                <Autocomplete
                  disablePortal
                  options={[{ flag: 'all', name: 'View All' }, ...Country.getAllCountries()]}
                  getOptionLabel={(option) => option.name}
                  sx={{ width: 300 }}
                  onChange={handleOptionChange}
                  renderInput={(params) => <TextField {...params} label='View All' />}
                />
              </div>
            </Grid>
            <div className="content">
              <div>
                <table style={{ marginLeft: '50px', marginTop: '20px' }}>
                  <thead>
                    <tr>
                      <th style={{ width: '200px', paddingTop: '10px' }}>COUNTRY NAME</th>
                      <th style={{ width: '200px', paddingTop: '10px' }}>CURRENCY</th>
                      <th style={{ width: '350px', paddingTop: '10px' }}>SHORT CURRENCY</th>
                      <th style={{ width: '250px', paddingTop: '10px' }}>CURRENCY NAME</th>
                      <th style={{ width: '200px', paddingTop: '10px' }}>EXCHANGE RATE</th>
                      <th style={{ width: '200px', paddingTop: '10px' }}>VAT/GST</th>
                      <th style={{ width: '300px', paddingTop: '10px' }}>ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((item, index) => (
                      <tr key={index}>
                        <td style={{ width: '200px', paddingTop: '10px' }}>{item.countryname}</td>
                        <td style={{ width: '200px', paddingTop: '10px' }}>{item.currencyFor}</td>
                        <td style={{ width: '350px', paddingTop: '10px' }}>{item.shortName}</td>
                        <td style={{ width: '250px', paddingTop: '10px' }}>{item.currencyFullName}</td>
                        <td style={{ width: '200px', paddingTop: '10px' }}>{item.exchangeRate}</td>
                        <td style={{ width: '200px', paddingTop: '10px' }}>{item.vat}%</td>
                        <td style={{ width: '300px', paddingTop: '10px' }}><button type="reset" className="cancel btn btn-embossed btn-primary m-b-10 m-r-0" onClick={() => handleDeleteClick(item._id)}>Delete</button> <button onClick={() => history.push(`/edit-vat/${item._id}`)} className="ancel btn btn-embossed btn-primary m-b-10 m-r-0">Edit</button></td>
                      </tr>
                    ))}
                    {rows.length === 0 && (<tr><td style={{ width: '350px', paddingTop: '30px', paddingBottom: '30px' }}><p>Sorry, no records found.</p></td></tr>)}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
