import React, { Component } from "react";
import axios from "axios";
import Pagination from "react-js-pagination";
import moment from "moment";

export default class InvoiceListing extends Component {
    state = {
        checked : false,
        propertyArray: [],
        currentList: [],
        pageLimit: 10,
        pageCount: 0,
        totalCount: 0,
        showingFrom: 0,
        showingTo: 0,
        propertyId: '',
        propertyName:''
    }
    async componentDidMount() {
        let token = await localStorage.getItem('token');
        let propertyId = this.props.match.params.propertyId;
        this.getBillingData(propertyId,token);
        this.getPropertyName(propertyId,token);
    }

    getBillingData = async(propertyId,token) => {
        await axios.post(`${process.env.REACT_APP_API_URL}/invoice`, {"hotelId" : propertyId}, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then(response => {
            const data = response.data.filter(res=>res)
            data.sort((a, b) => b.created - a.created)

            this.setState({
                propertyId,
                propertyArray : data,
                pageCount:  Math.ceil(data.length / this.state.pageLimit),
                totalCount: data.length,
                currentList: data.slice(0, this.state.pageLimit),
                showingFrom: data.length > 0 ? 1 : 0,
                showingTo: data.length > 10 ? 10 : data.length
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    getPropertyName = async(propertyId,token) => {
        await axios.get(`${process.env.REACT_APP_API_URL}/hotel/list`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then(response => {
            let getPropertyDetails = response.data.filter((eachProperty) => {
                return eachProperty._id === propertyId
            })
            this.setState({
                propertyName: getPropertyDetails[0].name
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    handleChange(checked) {
        this.setState({ checked });
    }

    handlePageChange(pageNumber) {
        const {propertyArray,pageLimit} = this.state;
        const offset = (pageNumber - 1) * pageLimit;
        const currentList = propertyArray.slice(offset, offset + pageLimit);
        const showingFrom = offset + 1;
        const showingTo = offset + pageLimit ;
        this.setState({ propertyArray, currentList, activePage: pageNumber, showingFrom, showingTo });
    }

    render() {
        const { history } = this.props
        const {currentList, activePage, totalCount, pageLimit, showingFrom, showingTo, propertyId, propertyName} = this.state;
        return (
            <div>
                <div className="header">
                    <h2><strong>Manage</strong> Properties</h2>
                    <div className="breadcrumb-wrapper">
                        <ol className="breadcrumb">
                            <li>
                                <a href={() => null} onClick={() => history.push("/dashboard")}>TimeFlyz</a>
                            </li> 
                            <li>
                                <a href={() => null} onClick={() => history.push("/property/all")}>Property Management</a>
                            </li>
                            <li className="active">Billing</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="panel">
                            <div className="panel-header ">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                                        <h3><strong>Invoices - {propertyName}</strong> </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-content pagination2 table-responsive">
                                <table className="table table-hover table-dynamic dataTable no-footer">
                                    <thead>
                                        <tr>
                                            <th style={{width: '15%'}}>Invoice #</th>
                                            <th style={{width: '35%'}}>Date</th>
                                            <th style={{width: '20%', textAlign: 'center'}}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            currentList.map((data,index) => {
                                                return(
                                                    data && <tr key={index}>
                                                        <td>{data?.number}</td>
                                                        <td>{moment(data?.created * 1000).utc().format("MMM D YYYY")}</td>
                                                        <td>
                                                            <div className="text-center">
                                                                <a href={`/billingDetails/${propertyId}/${data?.id}`} className="cancel btn btn-embossed btn-danger" style={{textAlign:'center'}}>View Invoice</a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                <div className="row">
                                    <div className="col-md-5" style={{marginTop: 20}}>
                                        <div className="dataTables_info" role="status" aria-live="polite">Showing {showingFrom} to {showingTo} of {totalCount} entries</div>
                                    </div>
                                    <div className="col-md-7">
                                        <Pagination
                                            prevPageText='prev'
                                            nextPageText='next'
                                            firstPageText='first'
                                            lastPageText='last'
                                            activePage={activePage}
                                            itemsCountPerPage={pageLimit}
                                            totalItemsCount={totalCount}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange.bind(this)}
                                            itemClass="page-item"
                                            linkClass="page-link"   
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}