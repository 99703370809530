import $api from "../../utils/axios";
import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { Container } from "@mui/system";
import moment from "moment";

export default function RateGain() {
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const res = await $api.get("/logs/rate-gain");
      setRows(res.data);
    })();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = React.useMemo(() => [
    {
      headerName: "Hotel",
      field: "hotelId",
      type: "string",
      width: 200,
      valueFormatter: (params) => {
        if (!params.value) {
          return "All Auto-Sync Hotels";
        }
        return params.value.name;
      },
    },
    {
      headerName: "Start No",
      field: "startDay",
      type: "number",
      width: 150,
    },
    {
      headerName: "End No",
      field: "endDay",
      type: "number",
      width: 150,
    },
    {
      headerName: "Start",
      field: "start",
      type: "date",
      width: 120,
      valueFormatter: (params) => {
        if (!params.value) {
          return "";
        }
        return moment(params.value).format("DD/MM HH:mm");
      },
    },
    {
      headerName: "End",
      field: "end",
      type: "date",
      width: 120,
      valueFormatter: (params) => {
        if (!params.value) {
          return "";
        }
        return moment(params.value).format("DD/MM HH:mm");
      },
    },
  ]);

  return (
    <Box>
      <h2>
        <strong>Logs</strong> / Rate Gain
      </h2>
      <Container
        sx={{
          backgroundColor: "white",
          my: 2,
          p: 0,
          borderRadius: 1,
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <DataGrid
          sx={{ border: 0 }}
          getRowId={(row) => row._id}
          autoHeight
          columns={columns}
          rows={rows}
        />
      </Container>
    </Box>
  );
}
