import React, { Component } from "react";
import axios from "axios";
import SweetAlert from 'react-bootstrap-sweetalert';
import { toast } from 'react-toastify';
import DataTable from "../../components/DataTable";
import * as XLSX from 'xlsx';
import Pagination from "react-js-pagination";

const columns = [
    {
        name: 'FULL NAME',
        selector: row => row.fullName,
        sortable: true,
        width: '200px',
    },
    {
        name: 'EMAIL',
        selector: row => row.email,
        sortable: true,
        wrap: true,
        width: '300px',
    },
    {
        name: 'USER TYPE',
        selector: row => row.userType,
        width: '200px',
    },
    {
        name: 'ACTION',
        selector: row => row.action,
        grow: 4,
        width: '350px',
    },
];

export default class UserListing extends Component {
    state = {
        records: [],
        loading: true,
        alertShow: false,
        alertBoxTitle: '',
        alertBoxType: '',
        alertBoxData: [],
        pageLimit: 10,
        pageCount: 0,
        totalCount: 0,
        showingFrom: 0,
        showingTo: 0,
        excelData: []
    }

    async componentDidMount() {
        let token = await localStorage.getItem('token');
        await axios.get(`${process.env.REACT_APP_API_URL}/user/employeeList`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then(response => {
            let array = [];
            response.data.forEach((element,index) => {
                let userType = '';
                if (element.accessLevel === "STANDARD") {
                    userType = 'Reservation Contact';
                } else if (element.accessLevel === "MANAGER") {
                    userType = 'Manager';
                } else if (element.accessLevel === "REGIONALMANAGER") {
                    userType = 'Hotel Manager';
                } else if (element.accessLevel === "GROUPMANAGER") {
                    userType = 'Revenue Manager';
                } else {
                    if(element.role === "Employee") {
                    userType = element.role;
                    } else if(element.role === "SuperAdmin") {
                        userType = "Admin";
                    }
                    else {
                        userType = element.userType.name;
                    }
                }
                if(userType !== "Admin"){
                    array.push({
                        id:index+1,
                        fullName: element.fullName,
                        email: element.email,
                        userType,
                        action:
                            <div className="text-center">
                                    <button className="btn btn-danger" onClick={() => this.hotelAdminRedirectLink(element._id)}>Details</button>
                                    <button className="btn btn-primary" onClick={() => this.deleteDepartment(element._id)}>Delete</button>
                                {/* <button className="btn btn-danger" onClick={() => this.showAlertBox(element, 'Reset')}>Reset Password</button> */}
                                {/* <button className="btn btn-primary" onClick={() => this.hotelAdminRedirectLink(element._id)}>Sign into Account</button>
                                <a className="btn btn-default" href={`/property/${element._id}`}>View Properties</a> */}
                            </div>
                    })
                }
            });
            this.setState({
                records: array,
                loading: false,
                pageCount: Math.ceil(array.length / this.state.pageLimit),
                totalCount: array.length,
                currentList: array.slice(0, this.state.pageLimit),
                showingFrom: array.length > 0 ? 1 : 0,
                showingTo: array.length > 10 ? 10 : array.length
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    showAlertBox = (value, alertType) => {
        let title = alertType === 'Reset' ? `Please confirm you wish to reset guest password for ${value.firstName} ${value.lastName}` : `${alertType} ?`;
        this.setState({
            alertBoxData: value,
            alertBoxType: alertType,
            alertBoxTitle: title,
            alertShow: true,
        })
    }

    async handleFileUpload(event){
        const file = event.target.files[0];
        const reader = new FileReader();
        
        reader.onload = async (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          console.log('oooooooooooooooooooooooooo',jsonData);
          //setExcelData(jsonData);
          this.setState({
            excelData: jsonData
        });
        await axios.post(`${process.env.REACT_APP_API_URL}/user/uploadExcelUsers`, { data: jsonData })
        .then((response) => {
            if (response.status === 200) {
                // localStorage.setItem('Credentials', JSON.stringify(payload));
                //this.props.history.push(`/users`);
                toast.success('Users uploaded Successfully', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error('Something went wrong. Please try again.', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch((error) => {
          console.error('Error uploading data', error);
          toast.error(error, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        });      
        };
    
        reader.readAsArrayBuffer(file);
      };
    
      // Function to send the extracted data to the backend
      async uploadToBackend(){
        console.log('excelllllllllllllllllllllllllllllllllllllll',this.state.excelData);
        /*await axios.post(`${process.env.REACT_APP_API_URL}/uploadExcelUsers`, { data: this.state.excelData })
          .then((response) => {
            alert('Data uploaded successfully');
          })
          .catch((error) => {
            console.error('Error uploading data', error);
          });*/
      };

    async hotelAdminRedirectLink(userId) {
        // console.log(userId)
        this.props.history.push(`/users-all/${userId}`);
        // let token = await localStorage.getItem('token');
        // await axios.post(`${process.env.REACT_APP_API_URL}/user/generateToken`, { userId }, {
        //     headers: {
        //         Authorization: `Bearer ${token}`
        //     },
        // })
        // .then(response => {
        //     if (response.status === 200) {
        //         const url = `${process.env.REACT_APP_HOTEL_ADMIN_URL}/superAdminAccess/${response.data.token}/${token}`;
        //         const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        //         if (newWindow) newWindow.opener = null
        //     } else {
        //         toast.error('Something went wrong, Please try again...', {
        //             position: "top-right",
        //             autoClose: 3000,
        //             hideProgressBar: false,
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //             draggable: true,
        //             progress: undefined,
        //         });
        //     }
        // })
        // .catch(error => {
        //     console.log(error);
        //     toast.error('Something went wrong, Please try again...', {
        //         position: "top-right",
        //         autoClose: 3000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //     });
        // })
    }

    handlePageChange(pageNumber) {
        const { records, pageLimit } = this.state;
        const offset = (pageNumber - 1) * pageLimit;
        const currentList = records.slice(offset, offset + pageLimit);
        const showingFrom = offset + 1;
        const showingTo = offset + pageLimit;
        this.setState({ records, currentList, activePage: pageNumber, showingFrom, showingTo });
    }

    deleteDepartment = (array) => {
        this.setState({
            alertShow: true,
            alertBoxData: array,
            alertBoxTitle: 'Please confirm you wish to delete this user ?',
        })
    } 

    onCancel(){
        this.setState({
            alertShow: false,
            alertBoxData: [],
            alertBoxTitle: '',
        })
    }
    confirmFunc = async() => {
        const { alertBoxData } = this.state;
        let token = await localStorage.getItem('token');
        await axios.put(`${process.env.REACT_APP_API_URL}/user/removeUser/${alertBoxData}`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then(response => {
            console.log(":resssssssssssssssssss",response);
            if (response.data === 'OK') {
                toast.success('User Deleted Successfully', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                window.location.reload();
            } else {
                toast.error('Something went wrong, Please try again...', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }

        })
        .catch(error => {
            console.log(error);
        })
    }

    render() {
        const { history, activePage, totalCount, pageLimit,showingFrom, showingTo } = this.props
        const { loading, records, alertShow, alertBoxTitle } = this.state;
        return (
            <div>
                <SweetAlert
                    show={alertShow}
                    warning
                    showCancel
                    confirmBtnText="Confirm"
                    confirmBtnBsStyle="danger"
                    title={alertBoxTitle}
                    onConfirm={() => this.confirmFunc()}
                    onCancel={() => this.onCancel()}
                    focusCancelBtn
                />
                <div className="header">
                    <h2><strong>Manage</strong> Users</h2>
                    <div className="breadcrumb-wrapper">
                        <ol className="breadcrumb">
                            <li>
                                <a href={() => null} onClick={() => history.push("/dashboard")}>HelpDesk</a>
                            </li>
                            <li className="active">Users Management</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="panel">
                            <div className="panel-header ">
                                
                            {/*<a href="/sanghamita-paul/helpdesk/users/create" className="btn btn-primary" rel="noreferrer"><i className="fa fa-plus c-warning" /> Add User</a>*/}

                            <a href="/users/create" className="btn btn-primary" rel="noreferrer"><i className="fa fa-plus c-warning" /> Add User</a>
                            <input type="file" accept=".xlsx, .xls" onChange={this.handleFileUpload.bind(this)} />
                            {/*<button onClick={this.uploadToBackend}>Upload</button>*/}
                            </div>
                            <div className="panel-content pagination2 table-responsive" >
                                <DataTable columns={columns} data={records} loading={loading}  filterDefaultText="Filter By Full Name, Email, User Type" />
                                {/*
                                <div className="row">
                                    <div className="col-md-5" style={{ marginTop: 20 }}>
                                        <div className="dataTables_info" role="status" aria-live="polite">Showing {showingFrom} to {showingTo} of {totalCount} entries</div>
                                    </div>
                                    <div className="col-md-7">
                                        <Pagination
                                            prevPageText='prev'
                                            nextPageText='next'
                                            firstPageText='first'
                                            lastPageText='last'
                                            activePage={activePage}
                                            itemsCountPerPage={pageLimit}
                                            totalItemsCount={totalCount}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange.bind(this)}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    </div>
                                </div>
                                */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}