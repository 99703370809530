import React, { Component } from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import axios from "axios";
import { toast } from "react-toastify";
import Checkbox from "rc-checkbox";
import "rc-checkbox/assets/index.css";
import Rating from "react-rating";
import Tooltip from "@mui/material/Tooltip";
import { AMENITIES } from "../../../constants";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import Dropzone from "react-dropzone";
import SweetAlert from "react-bootstrap-sweetalert";
//import AddHotelDialog from "./Components/AddRoomDialog";
import moment from "moment";
const Imagemaxsize = 10000000000;
const acceptedFileTypes = "image/jpeg, image/png";
const acceptedFiletypesArray = acceptedFileTypes.split(",").map((item) => {
  return item.trim();
});

export default class AddHotel extends Component {
  state = {
    ownerId: "",
    fullName: "",
    email: "",
    password: "",
    hotelName: "",
    hotelDescription: "",
    microStay: [],
    hotelRating: "",
    streetAddress: "",
    city: "",
    country: "",
    postalCode: "",
    state: "",
    isDeleted: false,

    propertyID: "",

    hotelImage: [],
    sameOwnerContact: false,
    sameOwnerManager: false,

    roomRows: [],
    roomName: "",
    bedroom: 0,
    kingBeds: 0,
    queenBeds: 0,
    doubleBeds: 0,
    singleBeds: 0,
    floorRate: "",
    availableRooms: 0,
    overnightRate: "",
    commRate: 0,
    commRateOriginal: 0,
    openingHours: [],

    Amenities: [],
    contactPerson: [],
    managers: [],

    contactFullName: "",
    contactFirstName: "",
    contactLastName: "",
    contactPosition: "",
    contactNumber: "",
    contactEmail: "",

    managerFullName: "",
    managerFirstName: "",
    managerLastName: "",
    managerPosition: "",
    managerNumber: "",
    managerEmail: "",

    fullNameError: "",
    emailError: "",
    passwordError: "",

    hotelMultiImageSrc: [],
    hotelMultiImage: [],

    roomsInDb: [],
    adminToken: "",
    hotelAdminToken: "",

    alertShow: false,
    alertBoxTitle: "",
    alertBoxType: "",
    alertBoxData: null,
    expediaUrl: "",
    showDialog: false,
  };

  async componentDidMount() {
    let token = await localStorage.getItem("token");
    await axios
      .get(`${process.env.REACT_APP_API_URL}/hotel/list`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        let hotelId = this.props.match.params.hotelId;
        let filterHotelId = response.data.filter((eachHotelData) => {
          return eachHotelData._id === hotelId;
        });
        this.setState(
          {
            adminToken: token,
            ownerId: filterHotelId[0].owner?._id,
            fullName: filterHotelId[0].owner?.fullName,
            email: filterHotelId[0].owner?.email,
            isDeleted: filterHotelId[0].isDeleted,

            hotelName: filterHotelId[0].name,
            hotelDescription: filterHotelId[0].description,
            microStay: filterHotelId[0].type,
            hotelRating: filterHotelId[0].rating,
            streetAddress: filterHotelId[0].streetAddress,
            city: filterHotelId[0].city,
            country: filterHotelId[0].country,
            postalCode: filterHotelId[0].postalCode,
            state: filterHotelId[0].state,

            propertyID: filterHotelId[0].propertyId,
            commRate: filterHotelId[0].commissionRate,
            commRateOriginal: filterHotelId[0].commissionRate,
            hotelImage: filterHotelId[0].photos,
            Amenities: filterHotelId[0].amenities,
            roomsInDb: filterHotelId[0].rooms,
            expediaUrl: filterHotelId[0].expediaUrl,
          },
          () => {
            filterHotelId[0].owner && this.fetchHotelAdminToken(filterHotelId[0].owner?._id);
          }
        );
        if (filterHotelId[0].managers.length > 0) {
          this.setState({
            managers: filterHotelId[0].managers,
            managerFirstName: filterHotelId[0].managers[0].firstName,
            managerLastName: filterHotelId[0].managers[0].lastName,
            managerPosition: filterHotelId[0].managers[0].title,
            managerNumber: filterHotelId[0].managers[0].mobile,
            managerEmail: filterHotelId[0].managers[0].email,
          });
        }

        if (filterHotelId[0].contacts.length > 0) {
          this.setState({
            contactPerson: filterHotelId[0].contacts,
            contactFirstName: filterHotelId[0].contacts[0].firstName,
            contactLastName: filterHotelId[0].contacts[0].lastName,
            contactPosition: filterHotelId[0].contacts[0].title,
            contactNumber: filterHotelId[0].contacts[0].mobile,
            contactEmail: filterHotelId[0].contacts[0].email,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  fetchHotelAdminToken = async (ownerID) => {
    const payload = {
      userId: ownerID,
    };
    let token = await localStorage.getItem("token");
    await axios
      .post(`${process.env.REACT_APP_API_URL}/user/generateToken`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            hotelAdminToken: response.data.token,
          });
        } else {
          toast.error("Something went wrong, Please try again...", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong, Please try again...", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  onChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  onSomeChange = (idx) => (e) => {
    const { roomsInDb } = this.state;
    const Flname = e.target.name;
    const Flvalue = e.target.value;

    roomsInDb[idx][Flname] = Flvalue;
    this.setState({ roomsInDb });
  };

  onRoomChange = (idx) => (e) => {
    const { roomRows } = this.state;
    const Flname = e.target.name;
    const Flvalue = e.target.value;

    roomRows[idx][Flname] = Flvalue;
    this.setState({ roomRows });
  };

  onHourChange = (idx, openingHourIndex, typ) => (e) => {
    const { roomRows } = this.state;
    const Flname = e.target.name;
    const Flvalue = e.target.value;
    if (typ === 3) {
      roomRows[idx].setOpeningHoursThree[openingHourIndex][Flname] = Flvalue;
    }
    if (typ === 6) {
      roomRows[idx].setOpeningHoursSix[openingHourIndex][Flname] = Flvalue;
    }
    if (typ === 12) {
      roomRows[idx].setOpeningHoursTwelve[openingHourIndex][Flname] = Flvalue;
    }
    this.setState({ roomRows });
  };

  onSomeHourChange = (idx, openingHourIndex, typ) => (e) => {
    const { roomsInDb } = this.state;
    const Flname = e.target.name;
    const Flvalue = e.target.value;
    if (typ === 3) {
      roomsInDb[idx].setOpeningHoursThree[openingHourIndex][Flname] = Flvalue;
    }
    if (typ === 6) {
      roomsInDb[idx].setOpeningHoursSix[openingHourIndex][Flname] = Flvalue;
    }
    if (typ === 12) {
      roomsInDb[idx].setOpeningHoursTwelve[openingHourIndex][Flname] = Flvalue;
    }
    this.setState({ roomsInDb });
  };

  onSetOpeningHoursChange = (e, idx, typ) => {
    const { roomRows } = this.state;
    const { checked, name } = e.target;
    roomRows[idx][name] = checked;
    //roomRows[idx].setOpeningHoursThree = [];
    //roomRows[idx].setOpeningHoursSix = [];
    //roomRows[idx].setOpeningHoursTwelve = [];
    if (typ === 3) {
      if (checked) {
        roomRows[idx].setOpeningHoursThree = [
          {
            earliestCheckin: "",
            latestCheckin: "",
          },
        ];
      } else {
        roomRows[idx].setOpeningHoursThree = [];
      }
    }
    if (typ === 6) {
      if (checked) {
        roomRows[idx].setOpeningHoursSix = [
          {
            earliestCheckin: "",
            latestCheckin: "",
          },
        ];
      } else {
        roomRows[idx].setOpeningHoursSix = [];
      }
    }
    if (typ === 12) {
      if (checked) {
        roomRows[idx].setOpeningHoursTwelve = [
          {
            earliestCheckin: "",
            latestCheckin: "",
          },
        ];
      } else {
        roomRows[idx].setOpeningHoursTwelve = [];
      }
    }
    this.setState({ roomRows });
  };

  onSetOpeningHoursSomeChange = (e, idx, typ) => {
    const { roomsInDb } = this.state;
    const { checked, name } = e.target;
    roomsInDb[idx][name] = checked;
    if (typ === 3) {
      if (checked) {
        if (roomsInDb[idx].setOpeningHoursThree === undefined || roomsInDb[idx].setOpeningHoursThree.length === 0) {
          roomsInDb[idx].setOpeningHoursThree = [
            {
              earliestCheckin: "",
              latestCheckin: "",
            },
          ];
        }
      } else {
        roomsInDb[idx].setOpeningHoursThree = [];
      }
    }
    if (typ === 6) {
      if (checked) {
        if (roomsInDb[idx].setOpeningHoursSix === undefined || roomsInDb[idx].setOpeningHoursSix.length === 0) {
          roomsInDb[idx].setOpeningHoursSix = [
            {
              earliestCheckin: "",
              latestCheckin: "",
            },
          ];
        }
      } else {
        roomsInDb[idx].setOpeningHoursSix = [];
      }
    }
    if (typ === 12) {
      if (checked) {
        if (roomsInDb[idx].setOpeningHoursTwelve === undefined || roomsInDb[idx].setOpeningHoursTwelve.length === 0) {
          roomsInDb[idx].setOpeningHoursTwelve = [
            {
              earliestCheckin: "",
              latestCheckin: "",
            },
          ];
        }
      } else {
        roomsInDb[idx].setOpeningHoursTwelve = [];
      }
    }
    /*if(checked){
            if(roomsInDb[idx].openingHours !== undefined){
                roomsInDb[idx].openingHours.push({
                    openingHoursStart: '',
                    openingHoursEnd: ''
                })
            }else{
                roomsInDb[idx].openingHours = [{
                    openingHoursStart: '',
                    openingHoursEnd: ''
                }]
            }
        }else{
            roomsInDb[idx].openingHours = [];
        }*/
    this.setState({ roomsInDb });
  };

  onAmenitiesChange = (e) => {
    const { value } = e.target;
    let amenitiesArray = this.state.Amenities;

    if (!amenitiesArray.includes(value)) {
      amenitiesArray.push(value);
    } else {
      var index = amenitiesArray.indexOf(value);
      if (index !== -1) {
        amenitiesArray.splice(index, 1);
      }
    }

    this.setState({
      Amenities: amenitiesArray,
    });
  };

  onContactChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      this.setState({
        sameOwnerContact: true,
      });
    } else {
      this.setState({
        sameOwnerContact: false,
      });
    }
  };

  onManagerChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      this.setState({
        sameOwnerManager: true,
      });
    } else {
      this.setState({
        sameOwnerManager: false,
      });
    }
  };

  validate = () => {
    this.setState({
      fullNameError: "",
      emailError: "",
      passwordError: "",
    });
    const regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

    let fullNameError = "";
    let emailError = "";

    if (this.state.fullName === "") {
      fullNameError = "Please Enter Full Name";
    }
    if (fullNameError) {
      this.setState({ fullNameError });
      return false;
    }

    if (this.state.email === "") {
      emailError = "Please Enter Email";
    } else if (this.state.email && !regex.test(this.state.email.trim())) {
      emailError = "Please enter a valid Email Address";
    }
    if (emailError) {
      this.setState({ emailError });
      return false;
    }

    return true;
  };

  changeRating = (val) => {
    this.setState({
      hotelRating: val,
    });
  };

  handleEditorChange = (event, editor) => {
    const data = editor.getData();
    this.setState({
      hotelDescription: data,
    });
  };

  verifyFile = (files) => {
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > Imagemaxsize) {
        alert("This File Is Too Big");
        return false;
      }

      if (!acceptedFiletypesArray.includes(currentFileType)) {
        alert("This File Is Not Supported");
        return false;
      }
      return true;
    }
  };

  onHandleondrop = (files, rejectedFiles, e) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      this.verifyFile(rejectedFiles);
    }

    if (files && files.length > 0) {
      const isVerified = this.verifyFile(files);
      if (isVerified) {
        const currentFile = Array.from(e.target.files);

        Promise.all(
          currentFile.map((file) => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.addEventListener("load", (ev) => {
                resolve(ev.target.result);
              });
              reader.addEventListener("error", reject);
              reader.readAsDataURL(file);
            });
          })
        ).then(
          (images) => {
            /* Once all promises are resolved, update state with image URI array */
            this.setState({ hotelMultiImageSrc: images, hotelMultiImage: e.target.files });
          },
          (error) => {
            console.log(error);
          }
        );
      }
    }
  };

  handleRemoveimage = (index, name, src) => () => {
    let imgSrc = [];
    let originalImage = [];
    if (name === "hotelMultiImage") {
      imgSrc = [...this.state.hotelMultiImageSrc];
      originalImage = [...this.state.hotelMultiImage];
    }

    imgSrc.splice(index, 1);
    originalImage.splice(index, 1);

    if (name === "hotelMultiImage") {
      this.setState({ hotelMultiImageSrc: imgSrc, hotelMultiImage: originalImage });
    }
  };

  handleAddRow = () => {
    const { roomRows, bedroom, kingBeds, queenBeds, doubleBeds, singleBeds, availableRooms, overnightRate, floorRate, openingHours, closingHours } = this.state;
    //rooms?.forEach((element) => {
      //if (!this.state.roomsInDb.some((item) => item.name === element.roomName)) {
        roomRows.push({ roomName: '', bedroom, kingBeds, queenBeds, doubleBeds, singleBeds, availableRooms, overnightRate, floorRate, openingHours, closingHours, setOpeningHours: true });
      //}
    //});
    this.setState({ roomRows });
  };

  handleRemoveSpecificRow = (idx) => () => {
    const roomRows = [...this.state.roomRows];
    roomRows.splice(idx, 1);
    this.setState({ roomRows });
  };

  deleteImage = async (ImageUri) => {
    let hotelId = this.props.match.params.hotelId;
    const imageUrl = { url: ImageUri };
    let token = await localStorage.getItem("token");

    await axios
      .put(`${process.env.REACT_APP_API_URL}/hotel/${hotelId}/image`, imageUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data === "OK") {
          toast.success("Image deleted Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.componentDidMount();
        } else {
          toast.error("Image deletion failed", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  microStay = (e) => {
    const { value } = e.target;
    let microStayArray = this.state.microStay;

    if (!microStayArray.includes(value)) {
      microStayArray.push(value);
    } else {
      var index = microStayArray.indexOf(value);
      if (index !== -1) {
        microStayArray.splice(index, 1);
      }
    }

    this.setState({
      microStay: microStayArray,
    });
  };

  handleChange = (address) => {
    this.setState({ streetAddress: address });
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        getLatLng(results[0]);
        let city, country, postalCode, state;
        for (let i = 0; i < results[0].address_components.length; i++) {
          if (results[0].address_components[i].types[0] === "locality") {
            city = results[0].address_components[i].long_name;
          }
          if (results[0].address_components[i].types[0] === "country") {
            country = results[0].address_components[i].long_name;
          }
          if (results[0].address_components[i].types[0] === "postal_code") {
            postalCode = results[0].address_components[i].long_name;
          }
          if (results[0].address_components[i].types[0] === "administrative_area_level_1") {
            state = results[0].address_components[i].long_name;
          }
        }
        this.setState({ city, country, postalCode, state });
      })
      .catch((error) => console.error("Error", error));
  };

  renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions }) => (
    <div className="autocomplete-root">
      <input {...getInputProps()} className="form-control" placeholder="Enter the street address here" />
      <div className="autocomplete-dropdown-container">
        {suggestions.map((suggestion, index) => (
          <option {...getSuggestionItemProps(suggestion)} key={index}>
            {suggestion.description}
          </option>
        ))}
      </div>
    </div>
  );

  addRoom = async (token, hotelId) => {
    const { roomRows } = this.state;
    if (roomRows.length > 0) {
      for (let i = 0; i <= roomRows.length - 1; i++) {
        if (!roomRows[i].hasOwnProperty("isSetOpeningHoursThree")) {
          roomRows[i].isSetOpeningHoursThree = false;
        }
        if (!roomRows[i].hasOwnProperty("isSetOpeningHoursSix")) {
          roomRows[i].isSetOpeningHoursSix = false;
        }
        if (!roomRows[i].hasOwnProperty("isSetOpeningHoursTwelve")) {
          roomRows[i].isSetOpeningHoursTwelve = false;
        }
        if (!roomRows[i].hasOwnProperty("setOpeningHoursThree")) {
          roomRows[i].setOpeningHoursThree = [];
        }
        if (!roomRows[i].hasOwnProperty("setOpeningHoursSix")) {
          roomRows[i].setOpeningHoursSix = [];
        }
        if (!roomRows[i].hasOwnProperty("setOpeningHoursTwelve")) {
          roomRows[i].setOpeningHoursTwelve = [];
        }
        if (roomRows[i].roomName === "" || roomRows[i].roomName === null || roomRows[i].roomName === undefined) {
          toast.warn(`Please enter room name. Room not updated.`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        else if (roomRows[i].availableRooms === "" || roomRows[i].availableRooms === null || roomRows[i].availableRooms === undefined) {
          toast.warn(`Please enter available rooms. Room not updated.`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (roomRows[i].overnightRate === "" || roomRows[i].overnightRate === null || roomRows[i].overnightRate === undefined) {
          toast.warn(`Please enter overnight rate. Room not updated.`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (roomRows[i].isSetOpeningHoursThree === false && roomRows[i].isSetOpeningHoursSix === false && roomRows[i].isSetOpeningHoursTwelve === false) {
          toast.warn(`Please select at least one slot for ${roomRows[i].roomName}. Room not updated.`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (
          (roomRows[i].isSetOpeningHoursThree === true && (roomRows[i].setOpeningHoursThree[0].earliestCheckin === "" || roomRows[i].setOpeningHoursThree[0].latestCheckin === "")) ||
          (roomRows[i].isSetOpeningHoursSix === true && (roomRows[i].setOpeningHoursSix[0].earliestCheckin === "" || roomRows[i].setOpeningHoursSix[0].latestCheckin === "")) ||
          (roomRows[i].isSetOpeningHoursTwelve === true && (roomRows[i].setOpeningHoursTwelve[0].earliestCheckin === "" || roomRows[i].setOpeningHoursTwelve[0].latestCheckin === ""))
        ) {
          toast.warn(`Eariest checkin and latest checkin fields are mandatory. Room not updated.`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (
          (roomRows[i].isSetOpeningHoursThree === true &&
            roomRows[i].setOpeningHoursThree[0].earliestCheckin !== "" &&
            roomRows[i].setOpeningHoursThree[0].latestCheckin !== "" &&
            moment(roomRows[i].setOpeningHoursThree[0].latestCheckin, "HH:mm").isBefore(moment(roomRows[i].setOpeningHoursThree[0].earliestCheckin, "HH:mm"))) ||
          (roomRows[i].isSetOpeningHoursSix === true &&
            roomRows[i].setOpeningHoursSix[0].earliestCheckin !== "" &&
            roomRows[i].setOpeningHoursSix[0].latestCheckin !== "" &&
            moment(roomRows[i].setOpeningHoursSix[0].latestCheckin, "HH:mm").isBefore(moment(roomRows[i].setOpeningHoursSix[0].earliestCheckin, "HH:mm"))) ||
          (roomRows[i].isSetOpeningHoursTwelve === true &&
            roomRows[i].setOpeningHoursTwelve[0].earliestCheckin !== "" &&
            roomRows[i].setOpeningHoursTwelve[0].latestCheckin !== "" &&
            moment(roomRows[i].setOpeningHoursTwelve[0].latestCheckin, "HH:mm").isBefore(moment(roomRows[i].setOpeningHoursTwelve[0].earliestCheckin, "HH:mm")))
        ) {
          toast.warn(`Latest checkin should be greater than earliest checkin. Room not updated`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          let parametersRooms = {
            name: roomRows[i].roomName,
            bedrooms: roomRows[i].bedroom,
            kingBeds: roomRows[i].kingBeds,
            queenBeds: roomRows[i].queenBeds,
            doubleBeds: roomRows[i].doubleBeds,
            singleBeds: roomRows[i].singleBeds,
            availableRooms: roomRows[i].availableRooms,
            overnightRate: roomRows[i].overnightRate,
            floorRate: roomRows[i].floorRate,
            openingHours: roomRows[i].openingHours,
            setOpeningHours: roomRows[i].setOpeningHours,
            isSetOpeningHoursThree: roomRows[i].isSetOpeningHoursThree,
            isSetOpeningHoursSix: roomRows[i].isSetOpeningHoursSix,
            isSetOpeningHoursTwelve: roomRows[i].isSetOpeningHoursTwelve,
            setOpeningHoursThree: roomRows[i].setOpeningHoursThree,
            setOpeningHoursSix: roomRows[i].setOpeningHoursSix,
            setOpeningHoursTwelve: roomRows[i].setOpeningHoursTwelve,
          };
          await axios
            .post(
              `${process.env.REACT_APP_API_URL}/hotel/${hotelId}/roomAdd`,
              { room: parametersRooms },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .catch((roomError) => {
              toast.warn(roomError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            });
        }
      }
    }
  };

  updateHotel = async (token, hotelId) => {
    const { hotelName, hotelDescription, microStay, hotelRating, streetAddress, city, country, postalCode, state, Amenities, propertyID, commRate, commRateOriginal, expediaUrl } = this.state;
    let parameters = {
      name: hotelName,
      description: hotelDescription,
      rating: hotelRating,
      type: microStay,
      streetAddress: streetAddress,
      city: city,
      country: country,
      postalCode: postalCode,
      state: state,
      amenities: Amenities,
      propertyId: propertyID,
      id: hotelId,
      commissionRate: commRate,
      overwrite: commRateOriginal !== parseInt(commRate) ? false : true,
      expediaUrl: expediaUrl
    };
    await axios
      .put(`${process.env.REACT_APP_API_URL}/hotel/update`, parameters, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updateHotelImages = async (token, hotelId) => {
    for (let i = 0; i <= this.state.hotelMultiImage.length - 1; i++) {
      var hotelPhotosForm = new FormData();
      hotelPhotosForm.append("photos", this.state.hotelMultiImage[i], this.state.hotelMultiImage[i].name);

      await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/hotel/${hotelId}/images`,
        data: hotelPhotosForm,
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success("Hotel Image Updated Successfully", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  updateRoom = async (token, hotelId) => {
    let { roomsInDb, roomRows } = this.state;
    roomRows.forEach((room) => {
      roomsInDb.push(room);
    });
    if (roomsInDb.length > 0) {
      let countRooms = 0;
      for (let i = 0; i <= roomsInDb.length - 1; i++) {
        if (roomsInDb[i].availableRooms === "" || roomsInDb[i].availableRooms === null || roomsInDb[i].availableRooms === undefined) {
          toast.warn(`Please enter available rooms. Room not updated.`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (roomsInDb[i].overnightRate === "" || roomsInDb[i].overnightRate === null || roomsInDb[i].overnightRate === undefined) {
          toast.warn(`Please enter overnight rate. Room not updated.`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (roomsInDb[i].isSetOpeningHoursThree === false && roomsInDb[i].isSetOpeningHoursSix === false && roomsInDb[i].isSetOpeningHoursTwelve === false) {
          toast.warn(`Please select at least one slot for ${roomsInDb[i].roomName}. Room not updated.`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (
          (roomsInDb[i].isSetOpeningHoursThree === true && (roomsInDb[i].setOpeningHoursThree[0].earliestCheckin === "" || roomsInDb[i].setOpeningHoursThree[0].latestCheckin === "")) ||
          (roomsInDb[i].isSetOpeningHoursSix === true && (roomsInDb[i].setOpeningHoursSix[0].earliestCheckin === "" || roomsInDb[i].setOpeningHoursSix[0].latestCheckin === "")) ||
          (roomsInDb[i].isSetOpeningHoursTwelve === true && (roomsInDb[i].setOpeningHoursTwelve[0].earliestCheckin === "" || roomsInDb[i].setOpeningHoursTwelve[0].latestCheckin === ""))
        ) {
          toast.warn(`Eariest checkin and latest checkin fields are mandatory. Room not updated.`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (
          (roomsInDb[i].isSetOpeningHoursThree === true &&
            roomsInDb[i].setOpeningHoursThree[0].earliestCheckin !== "" &&
            roomsInDb[i].setOpeningHoursThree[0].latestCheckin !== "" &&
            moment(roomsInDb[i].setOpeningHoursThree[0].latestCheckin, "HH:mm").isBefore(moment(roomsInDb[i].setOpeningHoursThree[0].earliestCheckin, "HH:mm"))) ||
          (roomsInDb[i].isSetOpeningHoursSix === true &&
            roomsInDb[i].setOpeningHoursSix[0].earliestCheckin !== "" &&
            roomsInDb[i].setOpeningHoursSix[0].latestCheckin !== "" &&
            moment(roomsInDb[i].setOpeningHoursSix[0].latestCheckin, "HH:mm").isBefore(moment(roomsInDb[i].setOpeningHoursSix[0].earliestCheckin, "HH:mm"))) ||
          (roomsInDb[i].isSetOpeningHoursTwelve === true &&
            roomsInDb[i].setOpeningHoursTwelve[0].earliestCheckin !== "" &&
            roomsInDb[i].setOpeningHoursTwelve[0].latestCheckin !== "" &&
            moment(roomsInDb[i].setOpeningHoursTwelve[0].latestCheckin, "HH:mm").isBefore(moment(roomsInDb[i].setOpeningHoursTwelve[0].earliestCheckin, "HH:mm")))
        ) {
          toast.warn(`Latest checkin should be greater than earliest checkin. Room not updated`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          countRooms++;
          if(roomsInDb[i]._id) {
          await axios
            .put(
              `${process.env.REACT_APP_API_URL}/hotel/${hotelId}/roomUpdate/${roomsInDb[i]._id}`,
              { room: roomsInDb[i] },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((response) => {})
            .catch((roomError) => {
              toast.warn(roomError, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            });
          }
        }
      }
      if (countRooms === roomsInDb.length) {
        toast.success("Room Updated Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
    // eslint-disable-next-line no-lone-blocks
    /*roomRows.forEach((room)=>{{
            roomsInDb.push(room);
        }})*/
    // eslint-disable-next-line no-const-assign
    roomRows = [];

    //this.setState({ roomRows });
  };

  updateHotelAdmin = async (token, hotelId) => {
    const { ownerId, fullName } = this.state;
    const ownerData = {
      contact: {
        fullName: fullName,
      },
    };
    await axios
      .put(`${process.env.REACT_APP_API_URL}/hotel/${hotelId}/employeeUpdate/${ownerId}`, ownerData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((contactError) => {
        console.log(contactError);
      });
  };

  updateContacts = async (token, hotelId) => {
    const { contactFirstName, contactLastName, contactPosition, contactNumber, contactEmail, sameOwnerContact } = this.state;
    if (this.state.contactPerson.length > 0) {
      let contactId = this.state.contactPerson[0]._id;
      const contact = {
        contact: {
          firstName: contactFirstName,
          lastName: contactLastName,
          title: contactPosition,
          mobile: contactNumber,
          owner: sameOwnerContact,
        },
      };
      await axios
        .put(`${process.env.REACT_APP_API_URL}/hotel/${hotelId}/employeeUpdate/${contactId}`, contact, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch((contactError) => {
          console.log(contactError);
        });
    } else {
      // Contacts Starts
      let contact = {
        firstName: contactFirstName,
        lastName: contactLastName,
        title: contactPosition,
        mobile: contactNumber,
        email: contactEmail,
      };

      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/hotel/${hotelId}/contact`,
          { contact, owner: sameOwnerContact },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .catch((contactError) => {
          console.log(contactError);
        });
      // Contacts Ends
    }
  };

  updateManager = async (token, hotelId) => {
    const { managerFirstName, managerLastName, managerPosition, managerNumber, managerEmail, sameOwnerManager } = this.state;
    if (this.state.managers.length > 0) {
      let managerId = this.state.managers[0]._id;
      const manager = {
        contact: {
          firstName: managerFirstName,
          lastName: managerLastName,
          title: managerPosition,
          mobile: managerNumber,
          owner: sameOwnerManager,
        },
      };
      await axios
        .put(`${process.env.REACT_APP_API_URL}/hotel/${hotelId}/employeeUpdate/${managerId}`, manager, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch((contactError) => {
          console.log(contactError);
        });
    } else {
      // Managers Starts
      let manager = {
        firstName: managerFirstName,
        lastName: managerLastName,
        title: managerPosition,
        accessLevel: "MANAGER",
        mobile: managerNumber,
        email: managerEmail,
      };
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/hotel/${hotelId}/manager`,
          { manager, owner: sameOwnerManager },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .catch((managerError) => {
          console.log(managerError);
        });
      // Managers Ends
    }
  };

  submitFunction = async () => {
    //const { fullName, email, password } = this.state;
    const isValid = this.validate();
    if (isValid) {
      let token = await localStorage.getItem("token");
      let hotelId = this.props.match.params.hotelId;

      // Add New Rooms
      await this.addRoom(token, hotelId);

      // Update Hotel Details
      await this.updateHotel(token, hotelId);

      // Update Hotel Images
      await this.updateHotelImages(token, hotelId);

      // Update Rooms Details
      await this.updateRoom(token, hotelId);

      // Update Hotel Admin
      await this.updateHotelAdmin(token, hotelId);

      // Update Contacts
      await this.updateContacts(token, hotelId);

      // Update Manager
      await this.updateManager(token, hotelId);

      toast.success("Hotel Details Updated Successfully", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  hotelDelete = async () => {
    let token = await localStorage.getItem("token");
    let hotelId = this.props.match.params.hotelId;

    await axios
      .get(`${process.env.REACT_APP_API_URL}/reservation/isReserve/${hotelId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (!response.data) {
          this.showAlertBox(hotelId, "Delete");
        } else {
          toast.warn("Reservatons already exist for this hotel and so it cannot be deleted", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteRoomFromDB = async (roomId) => {
    let token = await localStorage.getItem("token");
    let hotelId = this.props.match.params.hotelId;

    await axios
      .put(
        `${process.env.REACT_APP_API_URL}/hotel/${hotelId}/roomUpdate/${roomId}`,
        { room: { isDeleted: true } },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          toast.success("Room Deleted Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.componentDidMount();
        }
      })
      .catch((error) => {
        toast.error("Room Deletion Failed", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(error);
      });
  };

  addSomeMoreOpeningHours = (roomArrayIndex) => {
    let roomInDb = this.state.roomsInDb;
    roomInDb[roomArrayIndex].openingHours.push({
      openingHoursStart: "",
      openingHoursEnd: "",
    });
    this.setState({
      roomsInDb: roomInDb,
    });
  };

  removeSomeOpeningHours = (roomArrayIndex, index) => {
    let roomInDb = this.state.roomsInDb;
    roomInDb[roomArrayIndex].openingHours.splice(index, 1);
    this.setState({
      roomsInDb: roomInDb,
    });
  };

  addMoreOpeningHours = (roomArrayIndex) => {
    let roomInDb = this.state.roomRows;
    roomInDb[roomArrayIndex].openingHours.push({
      openingHoursStart: "",
      openingHoursEnd: "",
    });
    this.setState({
      roomRows: roomInDb,
    });
  };

  removeOpeningHours = (roomArrayIndex, index) => {
    let roomInDb = this.state.roomRows;
    roomInDb[roomArrayIndex].openingHours.splice(index, 1);
    this.setState({
      roomRows: roomInDb,
    });
  };

  hotelAdminRedirectLink() {
    const { adminToken, hotelAdminToken } = this.state;
    const link = `${process.env.REACT_APP_HOTEL_ADMIN_URL}/superAdminAccess/${hotelAdminToken}/${adminToken}`;
    return link;
  }

  showAlertBox = (value, alertType) => {
    let title = `Please confirm you wish to delete this hotel ?`;
    this.setState({
      alertBoxData: value,
      alertBoxType: alertType,
      alertBoxTitle: title,
      alertShow: true,
    });
  };
  onCancel() {
    this.setState({
      alertShow: false,
      alertBoxData: [],
      alertBoxType: "",
      alertBoxTitle: "",
    });
  }
  confirmFunc() {
    const { alertBoxData } = this.state;
    this.setState(
      {
        alertShow: false,
      },
      async () => {
        let token = await localStorage.getItem("token");
        await axios
          .delete(`${process.env.REACT_APP_API_URL}/hotel/${alertBoxData}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            if (response.data === "OK") {
              toast.success("Hotel Deleted Successfully", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              this.props.history.push(`/property/all`);
            } else {
              toast.error("Something went wrong, Please try again...", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    );
  }

  render() {
    const { history } = this.props
    const { hotelMultiImageSrc, alertShow, alertBoxTitle } = this.state;
    return (
      <div>
        <SweetAlert
          show={alertShow}
          warning
          showCancel
          confirmBtnText="Confirm"
          confirmBtnBsStyle="danger"
          title={alertBoxTitle}
          onConfirm={() => this.confirmFunc()}
          onCancel={() => this.onCancel()}
          focusCancelBtn
        />
        <div className="header">
          <h2>
            <strong>Manage</strong> Hotels
          </h2>
          <div className="breadcrumb-wrapper">
            <ol className="breadcrumb">
              <li>
                  <a href={() => null} onClick={() => history.push("/dashboard")}>TimeFlyz</a>
              </li>
              <li>
                  <a href={() => null} onClick={() => history.push("/property/all")}>Property Management</a>
              </li>
              <li className="active">Edit Hotel</li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="panel">
              <div className="panel-header ">
                <div className="row">
                  <div className="col-sm-6 col-xs-12 col-md-8 col-lg-6">
                    <h3>
                      <strong>Hotel Admin Registration</strong>{" "}
                    </h3>
                  </div>
                  <div className="col-sm-6 col-xs-12 col-md-8 col-lg-6">
                    <div className="row">
                      <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6" style={{ textAlign: "end" }}>
                        {this.state.hotelAdminToken ? (
                          <a href={this.hotelAdminRedirectLink()} className="btn btn-embossed btn-block btn-danger" target="_blank" rel="noreferrer">
                            <i className="icon-link" /> Redirect to Hotel Admin
                          </a>
                        ) : (
                          <Tooltip title="The hotel doesn't have an admin." arrow>
                            <span>
                              <a href="/#" className="btn btn-embossed btn-block btn-danger disabled" rel="noreferrer">
                                <i className="icon-link" /> Redirect to Hotel Admin
                              </a>
                            </span>
                          </Tooltip>
                        )}
                      </div>
                      <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6" style={{ textAlign: "end" }}>
                        <button type="button" className="btn btn-embossed btn-block btn-danger" onClick={this.hotelDelete}>
                          <i className="icon-trash" /> Delete Hotel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel-content">
                <div className="form-group">
                  <h5>Full Name</h5>
                  <input type="text" name="fullName" className="form-control" placeholder="Enter the full name here" aria-required="true" onChange={this.onChange} defaultValue={this.state.fullName} />
                  {this.state.fullNameError ? <div className="text-danger">{this.state.fullNameError}</div> : null}
                </div>
                <div className="form-group">
                  <h5>Email</h5>
                  <input type="email" name="email" className="form-control" placeholder="Enter the email here" aria-required="true" onChange={this.onChange} defaultValue={this.state.email} />
                  {this.state.emailError ? <div className="text-danger">{this.state.emailError}</div> : null}
                </div>
                {/*<div className="form-group">
                                        <h5>Password</h5>
                                        <input type="password" name="password" className="form-control" placeholder="Enter the password here" aria-required="true" onChange={this.onChange} />
                                    </div>*/}
              </div>
              <div className="panel-header ">
                <h3>
                  <strong>Add Hotel</strong>{" "}
                </h3>
              </div>
              <div className="panel-content">
                <h5>
                  <strong>DETAILS</strong>
                </h5>
                <hr />
                <div className="form-group">
                  <h5>Hotel Name</h5>
                  <input
                    type="text"
                    name="hotelName"
                    className="form-control"
                    placeholder="Enter the hotel name here"
                    aria-required="true"
                    onChange={this.onChange}
                    defaultValue={this.state.hotelName}
                  />
                </div>
                <div className="form-group">
                  <h5>Description</h5>
                  <CKEditor
                    className="summernote bg-white"
                    editor={ClassicEditor}
                    data={this.state.hotelDescription !== undefined ? this.state.hotelDescription : ""}
                    onInit={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle("height", "150px", editor.editing.view.document.getRoot());
                      });
                    }}
                    onChange={this.handleEditorChange}
                  />
                </div>

                <div className="form-group">
                  <h5>Micro stay</h5>
                  <div className="input-group">
                    <div className="icheck-inline">
                      <label>
                        <Checkbox value="Business" checked={this.state.microStay.includes("Business")} onChange={(val) => this.microStay(val)} data-checkbox="icheckbox_square-blue" /> Business
                      </label>
                      <label>
                        <Checkbox value="Leisure" checked={this.state.microStay.includes("Leisure")} onChange={(val) => this.microStay(val)} data-checkbox="icheckbox_square-blue" /> Leisure
                      </label>
                      <label>
                        <Checkbox value="Transit" checked={this.state.microStay.includes("Transit")} onChange={(val) => this.microStay(val)} data-checkbox="icheckbox_square-blue" /> Transit
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <h5>Star rating</h5>
                  <Rating
                    emptySymbol={<i className="fa fa-star-o" style={{ fontSize: 25, color: "#ffd700" }}></i>}
                    fullSymbol={<i className="fa fa-star" style={{ fontSize: 25, color: "#ffd700" }}></i>}
                    onChange={(val) => this.changeRating(val)}
                    initialRating={this.state.hotelRating}
                    fractions={2}
                  />
                </div>
                <h5>
                  <strong>Location</strong>
                </h5>

                <div className="row">
                  <div className="col-md-12">
                    <h5>Street Address</h5>
                    <PlacesAutocomplete value={this.state.streetAddress} onChange={this.handleChange} onSelect={this.handleSelect}>
                      {this.renderFunc}
                    </PlacesAutocomplete>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <h5>City</h5>
                    <input type="text" name="city" className="form-control" placeholder="Enter the city here" aria-required="true" onChange={this.onChange} defaultValue={this.state.city} />
                  </div>
                  <div className="col-md-6">
                    <h5>Country</h5>
                    <input type="text" name="country" className="form-control" placeholder="Enter the country here" aria-required="true" onChange={this.onChange} defaultValue={this.state.country} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <h5>Postal Code</h5>
                    <input
                      type="text"
                      name="postalCode"
                      className="form-control"
                      placeholder="Enter the postal code here"
                      aria-required="true"
                      onChange={this.onChange}
                      defaultValue={this.state.postalCode}
                    />
                  </div>
                  <div className="col-md-6">
                    <h5>State</h5>
                    <input type="text" name="state" className="form-control" placeholder="Enter the state here" aria-required="true" onChange={this.onChange} defaultValue={this.state.state} />
                  </div>
                </div>
              </div>

              <div className="panel-content">
                <h5>
                  <strong>PROPERTY ID</strong>
                </h5>
                <hr />
                <div className="form-group">
                  <input
                    type="text"
                    name="propertyID"
                    className="form-control"
                    placeholder="Enter the property id here"
                    aria-required="true"
                    onChange={this.onChange}
                    defaultValue={this.state.propertyID}
                  />
                </div>
              </div>

              <div className="panel-content">
                <h5>
                  <strong>COMMISSION RATE (excl GST)</strong>
                </h5>
                <hr />
                <div className="input-group">
                  <input
                    type="number"
                    name="commRate"
                    className="form-control"
                    placeholder="Enter the commission rate here"
                    aria-required="true"
                    onChange={this.onChange}
                    value={this.state.commRate}
                  />
                  <span className="input-group-addon">%</span>
                </div>
              </div>

              <div className="panel-content">
                <h5>
                  <strong>Expedia URL</strong>
                </h5>
                <hr />
                <div className="form-group">
                  <input
                    type="text"
                    name="expediaUrl"
                    className="form-control"
                    placeholder="Enter the Expedia URL here"
                    aria-required="true"
                    onChange={this.onChange}
                    defaultValue={this.state.expediaUrl}
                  />
                </div>
              </div>

              <div className="panel-content">
                <h5>
                  <strong>PHOTOS</strong>
                </h5>
                <hr />
                <div className="form-group">
                  <Dropzone onDrop={this.onHandleondrop.bind(this)} maxSize={Imagemaxsize} accept={acceptedFileTypes} multiple={true}>
                    {({ getRootProps, getInputProps }) => (
                      <section style={{ border: "2px dashed #ef4a23", minHeight: "auto", padding: "10px", textAlign: "center" }}>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} name="hotelPhotos" />
                          <label style={{ marginTop: 25 }}>
                            <div className="dz-message ">
                              <i className="ti-plus text-primary font-26 " />
                              <div className="mt-2 font-15 mb-4" style={{ color: "#474949" }}>
                                Click To Upload Images Here
                              </div>
                            </div>
                          </label>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  {hotelMultiImageSrc.length > 0 || this.state.hotelImage.length > 0 ? (
                    <div style={{ border: "1px solid #ccc", padding: 20, height: "550px", overflowY: "scroll", overflowX: "hidden" }}>
                      <div className="row">
                        {hotelMultiImageSrc &&
                          hotelMultiImageSrc.map((ImageUri, index) => {
                            return (
                              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 mb-4" key={index} style={{ textAlign: "center", padding: 10 }}>
                                <img src={ImageUri} style={{ height: 200, width: "100%" }} alt={ImageUri} />
                                <button
                                  className="btn btn-sm btn-danger btn-block"
                                  style={{ marginLeft: 0, marginTop: 10 }}
                                  onClick={this.handleRemoveimage(index, "hotelMultiImage", "hotelMultiImageSrc")}
                                >
                                  Delete
                                </button>
                              </div>
                            );
                          })}
                        {this.state.hotelImage.map((ImageUri, index) => {
                          return (
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 mb-4" key={index} style={{ textAlign: "center", padding: 10 }}>
                              <img src={ImageUri} style={{ height: 200, width: "100%" }} alt={ImageUri} />
                              <button className="btn btn-sm btn-danger btn-block" style={{ marginLeft: 0, marginTop: 10 }} onClick={() => this.deleteImage(ImageUri)}>
                                Delete
                              </button>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : this.state.imageError ? (
                    <div className="text-danger">{this.state.imageError}</div>
                  ) : null}
                </div>
              </div>

              <div className="panel-content">
                <div className="row">
                  <div className="col-sm-6 col-xs-12 col-md-8 col-lg-6">
                    <h5>
                      <strong>HOTEL MANAGER</strong>
                    </h5>
                  </div>
                  {this.state.contactPerson.length > 0 ? null : (
                    <div className="col-sm-6 col-xs-12 col-md-4 col-lg-6">
                      <div className="input-group" style={{ float: "right" }}>
                        <div className="icheck-inline">
                          <Checkbox name="sameOwnerContact" defaultChecked={this.state.sameOwnerContact} data-checkbox="icheckbox_square-orange" onChange={(val) => this.onContactChange(val)} /> Same
                          as Owner
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <hr />
                {!this.state.sameOwnerContact ? (
                  this.state.contactPerson.length > 0 ? (
                    <>
                      {this.state.contactPerson.map((data, index) => {
                        return (
                          <div key={index}>
                            <div className="form-group">
                              <h5>First Name</h5>
                              <input
                                type="text"
                                name="contactFirstName"
                                className="form-control"
                                placeholder="Enter the first name here"
                                aria-required="true"
                                onChange={this.onChange}
                                defaultValue={data.firstName}
                              />
                            </div>
                            <div className="form-group">
                              <h5>Last Name</h5>
                              <input
                                type="text"
                                name="contactLastName"
                                className="form-control"
                                placeholder="Enter the last name here"
                                aria-required="true"
                                onChange={this.onChange}
                                defaultValue={data.lastName}
                              />
                            </div>
                            {/*<div className="form-group">
                                                                    <h5>Position Title</h5>
                                                                    <input type="text" name="contactPosition" className="form-control" placeholder="Enter the position title here" aria-required="true" onChange={this.onChange} defaultValue={data.title} />
                                                                </div>*/}
                            <div className="row">
                              <div className="col-md-6">
                                <h5>Contact Number</h5>
                                <input
                                  type="number"
                                  name="contactNumber"
                                  className="form-control"
                                  placeholder="Enter the contact number here"
                                  aria-required="true"
                                  onChange={this.onChange}
                                  defaultValue={data.mobile}
                                />
                              </div>
                              <div className="col-md-6">
                                <h5>Email Address</h5>
                                <input
                                  type="email"
                                  name="contactEmail"
                                  className="form-control"
                                  placeholder="Enter the email address here"
                                  aria-required="true"
                                  onChange={this.onChange}
                                  defaultValue={data.email}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <div className="form-group">
                        <h5>First Name</h5>
                        <input type="text" name="contactFirstName" className="form-control" placeholder="Enter the first name here" aria-required="true" onChange={this.onChange} />
                      </div>
                      <div className="form-group">
                        <h5>Last Name</h5>
                        <input type="text" name="contactLastName" className="form-control" placeholder="Enter the last name here" aria-required="true" onChange={this.onChange} />
                      </div>
                      {/*<div className="form-group">
                                                <h5>Position Title</h5>
                                                <input type="text" name="contactPosition" className="form-control" placeholder="Enter the position title here" aria-required="true" onChange={this.onChange} />
                                            </div>*/}
                      <div className="row">
                        <div className="col-md-6">
                          <h5>Contact Number</h5>
                          <input type="number" name="contactNumber" className="form-control" placeholder="Enter the contact number here" aria-required="true" onChange={this.onChange} />
                        </div>
                        <div className="col-md-6">
                          <h5>Email Address</h5>
                          <input type="email" name="contactEmail" className="form-control" placeholder="Enter the email address here" aria-required="true" onChange={this.onChange} />
                        </div>
                      </div>
                    </>
                  )
                ) : null}
              </div>

              <div className="panel-content">
                <div className="row">
                  <div className="col-sm-6 col-xs-12 col-md-8 col-lg-6">
                    <h5>
                      <strong>REVENUE MANAGERS</strong>
                    </h5>
                  </div>
                  {this.state.managers.length > 0 ? null : (
                    <div className="col-sm-6 col-xs-12 col-md-4 col-lg-6">
                      <div className="input-group" style={{ float: "right" }}>
                        <div className="icheck-inline">
                          <Checkbox name="sameOwnerManager" defaultChecked={this.state.sameOwnerManager} data-checkbox="icheckbox_square-blue" onChange={(val) => this.onManagerChange(val)} /> Same as
                          Owner
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <hr />
                {!this.state.sameOwnerManager ? (
                  this.state.managers.length > 0 ? (
                    <>
                      {this.state.managers.map((data, index) => {
                        return (
                          <div key={index}>
                            <div className="form-group">
                              <h5>First Name</h5>
                              <input
                                type="text"
                                name="managerFirstName"
                                className="form-control"
                                placeholder="Enter the first name here"
                                aria-required="true"
                                onChange={this.onChange}
                                defaultValue={data.firstName}
                              />
                            </div>
                            <div className="form-group">
                              <h5>Last Name</h5>
                              <input
                                type="text"
                                name="managerLastName"
                                className="form-control"
                                placeholder="Enter the last name here"
                                aria-required="true"
                                onChange={this.onChange}
                                defaultValue={data.lastName}
                              />
                            </div>
                            <div className="form-group">
                              <h5>Position Title</h5>
                              <input
                                type="text"
                                name="managerPosition"
                                className="form-control"
                                placeholder="Enter the position title here"
                                aria-required="true"
                                onChange={this.onChange}
                                defaultValue={data.title}
                              />
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <h5>Contact Number</h5>
                                <input
                                  type="number"
                                  name="managerNumber"
                                  className="form-control"
                                  placeholder="Enter the contact number here"
                                  aria-required="true"
                                  onChange={this.onChange}
                                  defaultValue={data.mobile}
                                />
                              </div>
                              <div className="col-md-6">
                                <h5>Email Address</h5>
                                <input
                                  type="email"
                                  name="managerEmail"
                                  className="form-control"
                                  placeholder="Enter the email address here"
                                  aria-required="true"
                                  onChange={this.onChange}
                                  defaultValue={data.email}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <div className="form-group">
                        <h5>First Name</h5>
                        <input type="text" name="managerFirstName" className="form-control" placeholder="Enter the first name here" aria-required="true" onChange={this.onChange} />
                      </div>
                      <div className="form-group">
                        <h5>Last Name</h5>
                        <input type="text" name="managerLastName" className="form-control" placeholder="Enter the last name here" aria-required="true" onChange={this.onChange} />
                      </div>
                      <div className="form-group">
                        <h5>Position Title</h5>
                        <input type="text" name="managerPosition" className="form-control" placeholder="Enter the position title here" aria-required="true" onChange={this.onChange} />
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <h5>Contact Number</h5>
                          <input type="number" name="managerNumber" className="form-control" placeholder="Enter the contact number here" aria-required="true" onChange={this.onChange} />
                        </div>
                        <div className="col-md-6">
                          <h5>Email Address</h5>
                          <input type="email" name="managerEmail" className="form-control" placeholder="Enter the email address here" aria-required="true" onChange={this.onChange} />
                        </div>
                      </div>
                    </>
                  )
                ) : null}
              </div>

              <div className="panel-content">
                <h5>
                  <strong>AMENITIES</strong>
                </h5>
                <hr />
                <div className="row">
                  <div className="input-group">
                    <div className="icheck-inline">
                      {AMENITIES.map((data, index) => {
                        return (
                          <div className="col-sm-6 col-xs-12 col-md-4 col-lg-3" key={index} style={{ padding: 10 }}>
                            <Checkbox
                              value={data.label}
                              checked={this.state.Amenities.includes(data.label)}
                              name={data.label}
                              data-checkbox="icheckbox_square-blue"
                              onChange={(val) => this.onAmenitiesChange(val)}
                            />{" "}
                            {data.label}
                            <img src={data.image} alt={data.label} style={{ height: 20, width: 20, float: "right" }} />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>

              <div className="panel-content">
                <div className="row">
                  <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                    <h5>
                      <strong>ROOMS</strong>
                    </h5>
                  </div>
                  <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6" style={{ textAlign: "end" }}>
                    {/*<button
                      type="button"
                      className="btn btn-embossed btn-danger"
                      onClick={() => {
                        this.setState({ showDialog: true });
                      }}
                    >
                      + Rooms
                    </button>*/}
                    <button
                      type="button"
                      className="btn btn-embossed btn-danger"
                      onClick={this.handleAddRow}
                    >
                      + Rooms
                    </button>
                  </div>
                </div>
                <hr />
                {/*<AddHotelDialog
                  roomRows={roomRows}
                  roomsInDb={roomsInDb}
                  expediaUrl={expediaUrl}
                  showDialog={showDialog}
                  onSubmit={(rooms) => {
                    this.handleAddRow(rooms);
                    this.setState({ showDialog: false });
                  }}
                  onClose={() => {
                    this.setState({ showDialog: false });
                  }}
                ></AddHotelDialog>*/}
                {this.state.roomRows.length > 0
                  ? this.state.roomRows.map((item, idx) => {
                      return (
                        <div key={idx} style={idx === 0 ? { backgroundColor: "#F5F5F5", padding: 10 } : { borderTop: "2px solid #ddd", marginTop: 10, backgroundColor: "#F5F5F5", padding: 10 }}>
                          <div style={{ textAlign: "end", marginBottom: 10, marginTop: 10 }}>
                            <button className="btn btn-primary" onClick={this.handleRemoveSpecificRow(idx)}>
                              {" "}
                              Remove
                            </button>
                          </div>
                          <div className="form-group">
                            <h5>Room Name</h5>
                            <input type="text" required name="roomName" className="form-control" placeholder="Enter the room name here" aria-required="true" onChange={this.onRoomChange(idx)} />
                          </div>
                          <div className="row">
                            <div className="col-sm-6 col-xs-12 col-md-2 col-lg-2">
                              <h5>Bedrooms</h5>
                              <input type="number" name="bedroom" className="form-control" defaultValue="0" aria-required="true" onChange={this.onRoomChange(idx)} />
                            </div>
                            <div className="col-sm-6 col-xs-12 col-md-2 col-lg-2">
                              <h5>King beds</h5>
                              <input type="number" name="kingBeds" className="form-control" defaultValue="0" aria-required="true" onChange={this.onRoomChange(idx)} />
                            </div>
                            <div className="col-sm-6 col-xs-12 col-md-2 col-lg-2">
                              <h5>Queen beds</h5>
                              <input type="number" name="queenBeds" className="form-control" defaultValue="0" aria-required="true" onChange={this.onRoomChange(idx)} />
                            </div>
                            <div className="col-sm-6 col-xs-12 col-md-2 col-lg-2">
                              <h5>Double beds</h5>
                              <input type="number" name="doubleBeds" className="form-control" defaultValue="0" aria-required="true" onChange={this.onRoomChange(idx)} />
                            </div>
                            <div className="col-sm-6 col-xs-12 col-md-2 col-lg-2">
                              <h5>Single beds</h5>
                              <input type="number" name="singleBeds" className="form-control" defaultValue="0" aria-required="true" onChange={this.onRoomChange(idx)} />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                              <h5>Available Rooms</h5>
                              <input type="number" name="availableRooms" className="form-control" defaultValue="0" aria-required="true" onChange={this.onRoomChange(idx)} />
                            </div>
                            <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                              <h5>Overnight Rate</h5>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="fa fa-dollar p-0" />
                                </span>
                                <input type="text" name="overnightRate" className="form-control" defaultValue="0" onChange={this.onRoomChange(idx)} />
                              </div>
                            </div>
                            <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                              <h5>Floor Rate</h5>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="fa fa-dollar p-0" />
                                </span>
                                <input type="text" name="floorRate" className="form-control" defaultValue="0" onChange={this.onRoomChange(idx)} />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                              <div className="input-group mt-4">
                                <div className="icheck-inline d-inline" style={{ display: "inline-block", marginRight: "10px" }}>
                                  <Checkbox
                                    checked={item.isSetOpeningHoursThree}
                                    name="isSetOpeningHoursThree"
                                    data-checkbox="icheckbox_square-blue"
                                    onChange={(val) => this.onSetOpeningHoursChange(val, idx, 3)}
                                  />{" "}
                                  3h
                                </div>
                                <div className="icheck-inline d-inline" style={{ display: "inline-block", marginRight: "10px" }}>
                                  <Checkbox
                                    checked={item.isSetOpeningHoursSix}
                                    name="isSetOpeningHoursSix"
                                    data-checkbox="icheckbox_square-blue"
                                    onChange={(val) => this.onSetOpeningHoursChange(val, idx, 6)}
                                  />{" "}
                                  6h
                                </div>
                                <div className="icheck-inline d-inline" style={{ display: "inline-block", marginRight: "10px" }}>
                                  <Checkbox
                                    checked={item.isSetOpeningHoursTwelve}
                                    name="isSetOpeningHoursTwelve"
                                    data-checkbox="icheckbox_square-blue"
                                    onChange={(val) => this.onSetOpeningHoursChange(val, idx, 12)}
                                  />{" "}
                                  12h
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              {item.isSetOpeningHoursThree &&
                                item.setOpeningHoursThree.map((eachHour, eachIndex) => {
                                  return (
                                    <div key={eachIndex} className="row" style={{ marginBottom: "8px" }}>
                                      <div className="col-sm-4 col-xs-12 col-md-4 col-lg-4">
                                        <input
                                          type="time"
                                          name="earliestCheckin"
                                          className="form-control input-sm mb-4"
                                          defaultValue={eachHour.earliestCheckin}
                                          onChange={this.onHourChange(idx, eachIndex, 3)}
                                        />
                                      </div>
                                      <div className="col-sm-3 col-xs-12 col-md-3 col-lg-3 text-center" style={{ marginTop: 5 }}>
                                        <span> to </span>
                                      </div>
                                      <div className="col-sm-4 col-xs-12 col-md-4 col-lg-4">
                                        <input
                                          type="time"
                                          name="latestCheckin"
                                          className="form-control input-sm mb-4"
                                          defaultValue={eachHour.latestCheckin}
                                          onChange={this.onHourChange(idx, eachIndex, 3)}
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                              {item.isSetOpeningHoursSix &&
                                item.setOpeningHoursSix.map((eachHour, eachIndex) => {
                                  return (
                                    <div key={eachIndex} className="row" style={{ marginBottom: "8px" }}>
                                      <div className="col-sm-4 col-xs-12 col-md-4 col-lg-4">
                                        <input
                                          type="time"
                                          name="earliestCheckin"
                                          className="form-control input-sm mb-4"
                                          defaultValue={eachHour.earliestCheckin}
                                          onChange={this.onHourChange(idx, eachIndex, 6)}
                                        />
                                      </div>
                                      <div className="col-sm-3 col-xs-12 col-md-3 col-lg-3 text-center" style={{ marginTop: 5 }}>
                                        <span> to </span>
                                      </div>
                                      <div className="col-sm-4 col-xs-12 col-md-4 col-lg-4">
                                        <input
                                          type="time"
                                          name="latestCheckin"
                                          className="form-control input-sm mb-4"
                                          defaultValue={eachHour.latestCheckin}
                                          onChange={this.onHourChange(idx, eachIndex, 6)}
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                              {item.isSetOpeningHoursTwelve &&
                                item.setOpeningHoursTwelve.map((eachHour, eachIndex) => {
                                  return (
                                    <div key={eachIndex} className="row" style={{ marginBottom: "8px" }}>
                                      <div className="col-sm-4 col-xs-12 col-md-4 col-lg-4">
                                        <input
                                          type="time"
                                          name="earliestCheckin"
                                          className="form-control input-sm mb-4"
                                          defaultValue={eachHour.earliestCheckin}
                                          onChange={this.onHourChange(idx, eachIndex, 12)}
                                        />
                                      </div>
                                      <div className="col-sm-3 col-xs-12 col-md-3 col-lg-3 text-center" style={{ marginTop: 5 }}>
                                        <span> to </span>
                                      </div>
                                      <div className="col-sm-4 col-xs-12 col-md-4 col-lg-4">
                                        <input
                                          type="time"
                                          name="latestCheckin"
                                          className="form-control input-sm mb-4"
                                          defaultValue={eachHour.latestCheckin}
                                          onChange={this.onHourChange(idx, eachIndex, 12)}
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}
                {this.state.roomsInDb.length > 0
                  ? this.state.roomsInDb.map((item, idx) => {
                      return (
                        <div key={idx} style={idx === 0 ? null : { borderTop: "2px solid #ddd", marginTop: 10 }}>
                          <div style={{ textAlign: "end", marginBottom: 10, marginTop: 10 }}>
                            <a href="#/" className="btn btn-primary" onClick={() => this.deleteRoomFromDB(item._id)}>
                              {" "}
                              Remove
                            </a>
                          </div>
                          <div className="form-group">
                            <h5>Room Name</h5>
                            <input
                              type="text"
                              name="name"
                              defaultValue={item.name}
                              className="form-control"
                              placeholder="Enter the room name here"
                              aria-required="true"
                              onChange={this.onSomeChange(idx)}
                            />
                          </div>
                          <div className="row">
                            <div className="col-sm-6 col-xs-12 col-md-2 col-lg-2">
                              <h5>Bedrooms</h5>
                              <input type="number" name="bedrooms" className="form-control" defaultValue={item.bedrooms} aria-required="true" onChange={this.onSomeChange(idx)} />
                            </div>
                            <div className="col-sm-6 col-xs-12 col-md-2 col-lg-2">
                              <h5>King beds</h5>
                              <input type="number" name="kingBeds" className="form-control" defaultValue={item.kingBeds} aria-required="true" onChange={this.onSomeChange(idx)} />
                            </div>
                            <div className="col-sm-6 col-xs-12 col-md-2 col-lg-2">
                              <h5>Queen beds</h5>
                              <input type="number" name="queenBeds" className="form-control" defaultValue={item.queenBeds} aria-required="true" onChange={this.onSomeChange(idx)} />
                            </div>
                            <div className="col-sm-6 col-xs-12 col-md-2 col-lg-2">
                              <h5>Double beds</h5>
                              <input type="number" name="doubleBeds" className="form-control" defaultValue={item.doubleBeds} aria-required="true" onChange={this.onSomeChange(idx)} />
                            </div>
                            <div className="col-sm-6 col-xs-12 col-md-2 col-lg-2">
                              <h5>Single beds</h5>
                              <input type="number" name="singleBeds" className="form-control" defaultValue={item.singleBeds} aria-required="true" onChange={this.onSomeChange(idx)} />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                              <h5>Available Rooms</h5>
                              <input type="number" name="availableRooms" className="form-control" defaultValue={item.availableRooms} aria-required="true" onChange={this.onSomeChange(idx)} />
                            </div>
                            <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                              <h5>Overnight Rate</h5>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="fa fa-dollar p-0" />
                                </span>
                                <input type="text" name="overnightRate" className="form-control" defaultValue={item.overnightRate} onChange={this.onSomeChange(idx)} />
                              </div>
                            </div>
                            <div className="col-sm-6 col-xs-12 col-md-6 col-lg-6">
                              <h5>Floor Rate</h5>
                              <div className="input-group">
                                <span className="input-group-addon">
                                  <i className="fa fa-dollar p-0" />
                                </span>
                                <input type="text" name="floorRate" className="form-control" defaultValue={item.floorRate} onChange={this.onSomeChange(idx)} />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                              <div className="input-group mt-4">
                                <div className="icheck-inline d-inline" style={{ display: "inline-block", marginRight: "10px" }}>
                                  <Checkbox
                                    checked={item.isSetOpeningHoursThree}
                                    name="isSetOpeningHoursThree"
                                    data-checkbox="icheckbox_square-blue"
                                    onChange={(val) => this.onSetOpeningHoursSomeChange(val, idx, 3)}
                                  />{" "}
                                  3h
                                </div>
                                <div className="icheck-inline d-inline" style={{ display: "inline-block", marginRight: "10px" }}>
                                  <Checkbox
                                    checked={item.isSetOpeningHoursSix}
                                    name="isSetOpeningHoursSix"
                                    data-checkbox="icheckbox_square-blue"
                                    onChange={(val) => this.onSetOpeningHoursSomeChange(val, idx, 6)}
                                  />{" "}
                                  6h
                                </div>
                                <div className="icheck-inline d-inline" style={{ display: "inline-block", marginRight: "10px" }}>
                                  <Checkbox
                                    checked={item.isSetOpeningHoursTwelve}
                                    name="isSetOpeningHoursTwelve"
                                    data-checkbox="icheckbox_square-blue"
                                    onChange={(val) => this.onSetOpeningHoursSomeChange(val, idx, 12)}
                                  />{" "}
                                  12h
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              {item.isSetOpeningHoursThree &&
                                item.setOpeningHoursThree !== undefined &&
                                item.setOpeningHoursThree.map((eachHour, eachIndex) => {
                                  return (
                                    <div key={eachIndex} className="row" style={{ marginBottom: "8px" }}>
                                      <div className="col-sm-4 col-xs-12 col-md-4 col-lg-4">
                                        <input
                                          type="time"
                                          name="earliestCheckin"
                                          className="form-control input-sm mb-4"
                                          defaultValue={eachHour.earliestCheckin}
                                          onChange={this.onSomeHourChange(idx, eachIndex, 3)}
                                        />
                                      </div>
                                      <div className="col-sm-3 col-xs-12 col-md-3 col-lg-3 text-center" style={{ marginTop: 5 }}>
                                        <span> to </span>
                                      </div>
                                      <div className="col-sm-4 col-xs-12 col-md-4 col-lg-4">
                                        <input
                                          type="time"
                                          name="latestCheckin"
                                          className="form-control input-sm mb-4"
                                          defaultValue={eachHour.latestCheckin}
                                          onChange={this.onSomeHourChange(idx, eachIndex, 3)}
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                              {item.isSetOpeningHoursSix &&
                                item.setOpeningHoursSix !== undefined &&
                                item.setOpeningHoursSix.map((eachHour, eachIndex) => {
                                  return (
                                    <div key={eachIndex} className="row" style={{ marginBottom: "8px" }}>
                                      <div className="col-sm-4 col-xs-12 col-md-4 col-lg-4">
                                        <input
                                          type="time"
                                          name="earliestCheckin"
                                          className="form-control input-sm mb-4"
                                          defaultValue={eachHour.earliestCheckin}
                                          onChange={this.onSomeHourChange(idx, eachIndex, 6)}
                                        />
                                      </div>
                                      <div className="col-sm-3 col-xs-12 col-md-3 col-lg-3 text-center" style={{ marginTop: 5 }}>
                                        <span> to </span>
                                      </div>
                                      <div className="col-sm-4 col-xs-12 col-md-4 col-lg-4">
                                        <input
                                          type="time"
                                          name="latestCheckin"
                                          className="form-control input-sm mb-4"
                                          defaultValue={eachHour.latestCheckin}
                                          onChange={this.onSomeHourChange(idx, eachIndex, 6)}
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                              {item.isSetOpeningHoursTwelve &&
                                item.setOpeningHoursTwelve !== undefined &&
                                item.setOpeningHoursTwelve.map((eachHour, eachIndex) => {
                                  return (
                                    <div key={eachIndex} className="row" style={{ marginBottom: "8px" }}>
                                      <div className="col-sm-4 col-xs-12 col-md-4 col-lg-4">
                                        <input
                                          type="time"
                                          name="earliestCheckin"
                                          className="form-control input-sm mb-4"
                                          defaultValue={eachHour.earliestCheckin}
                                          onChange={this.onSomeHourChange(idx, eachIndex, 12)}
                                        />
                                      </div>
                                      <div className="col-sm-3 col-xs-12 col-md-3 col-lg-3 text-center" style={{ marginTop: 5 }}>
                                        <span> to </span>
                                      </div>
                                      <div className="col-sm-4 col-xs-12 col-md-4 col-lg-4">
                                        <input
                                          type="time"
                                          name="latestCheckin"
                                          className="form-control input-sm mb-4"
                                          defaultValue={eachHour.latestCheckin}
                                          onChange={this.onSomeHourChange(idx, eachIndex, 12)}
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>

              <div className="text-center  m-t-20">
                <button type="button" className="btn btn-embossed btn-danger" onClick={() => this.submitFunction()}>
                  Update
                </button>
                <button type="reset" className="cancel btn btn-embossed btn-primary m-b-10 m-r-0">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
