import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Multiselect from 'multiselect-react-dropdown';
import DataTable from "../../components/DataTable";

const columns = [
    {
        name: 'PROPERTY NAME',
        selector: row => row.propertyName,
        sortable: true,
        grow: 2,
    },
    {
        name: 'PROPERTY CHAIN',
        selector: row => row.hotelType,
        sortable: true,
        grow: 2,
    },
    {
        name: '3HR',
        selector: row => row.three,
        sortable: true,
    },
    {
        name: '6HR',
        selector: row => row.six,
        sortable: true,
    },
    {
        name: '12HR',
        selector: row => row.twelve,
        sortable: true,
    },
];
export default class Pricing extends Component {
    state = {
        checked: false,
        modalStatus: false,
        updatePricingType: '',
        loading: true,
        options: [],
        selectedList: [],
        selectedValue: [],
        three: 0,
        six: 0,
        twelve: 0,
        selectedListError: '',
        threeError: '',
        sixError: '',
        twelveError: '',
    }

    handleChange(checked) {
        this.setState({ checked });
    }

    async componentDidMount() {
        let token = await localStorage.getItem('token');
        await axios.get(`${process.env.REACT_APP_API_URL}/hotel/list`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then(response => {
            let optionsArray = [];
            response.data.forEach(element => {
                optionsArray.push({
                    _id: element._id,
                    name: element.name,
                    propertyName: <a href={`/editHotel/${element._id}`}>{element.name}</a>,
                    hotelType: element.owner.commission.clientTypeName,
                    three: parseInt(element.overNightRatePercentage.three * 100) + '%',
                    six: parseInt(element.overNightRatePercentage.six * 100) + '%',
                    twelve: parseInt(element.overNightRatePercentage.twelve * 100) + '%',
                })
            });
            this.setState({
                options: optionsArray,
                loading: false
            })
        })
        .catch(error => {
            console.log(error);
            this.setState({
                loading: false
            })
        })

        await axios.post(`${process.env.REACT_APP_API_URL}/admin/commission/list`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then(response => {
            let optionsPropertyType = [];
            response.data.forEach(element => {
                optionsPropertyType.push({
                    id: element._id,
                    name: element.clientTypeName,
                })
            });
            this.setState({
                optionsPropertyType
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    showModal = (updateType) => {
        this.setState({
            updatePricingType: updateType,
            selectedList: [],
            selectedValue: undefined,
            three: 0,
            six: 0,
            twelve: 0,
            selectedListError: '',
            threeError: '',
            sixError: '',
            twelveError: '',
        }, () => {
            this.setState({
                modalStatus: true,
            })
        })
    }

    closeModal = () => {
        window.location.reload();
    }

    onChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }

    validate = () => {
        this.setState({
            selectedListError: '',
            threeError: '',
            sixError: '',
            twelveError: '',
        })
        let selectedListError = '',
            threeError = '',
            sixError = '',
            twelveError = '';

        if (this.state.selectedList.length < 1) {
            selectedListError = "Please Select Property"
        }
        if (selectedListError) {
            this.setState({ selectedListError })
            return false
        }

        if (this.state.three === 0) {
            threeError = "Please Enter 3Hr Rate"
        }
        if (parseFloat(this.state.three) > 100) {
            threeError = "Rate cannot be more than 100%"
        }
        if (threeError) {
            this.setState({ threeError })
            return false
        }

        if (this.state.six === 0) {
            sixError = "Please Enter 6Hr Rate"
        }
        if (parseFloat(this.state.six) > 100) {
            sixError = "Rate cannot be more than 100%"
        }
        if (sixError) {
            this.setState({ sixError })
            return false
        }

        if (this.state.twelve === 0) {
            twelveError = "Please Enter 12Hr Rate"
        }
        if (parseFloat(this.state.twelve) > 100) {
            twelveError = "Rate cannot be more than 100%"
        }
        if (twelveError) {
            this.setState({ twelveError })
            return false
        }

        return true;
    }

    updatePricing = async (e) => {
        e.preventDefault();
        const { selectedList, three, six, twelve } = this.state;
        let token = await localStorage.getItem('token');
        const validate = this.validate();
        if (validate) {
            selectedList.forEach(async (item) => {
                let payload = {
                    "hotelId": item._id,
                    "overNightRatePercentage": {
                        "three": parseFloat(three / 100),
                        "six": parseFloat(six / 100),
                        "twelve": parseFloat(twelve / 100)
                    }
                }
                await axios.post(`${process.env.REACT_APP_API_URL}/hotel/overNight`, payload, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                })
                .then(response => {
                    toast.success(response, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.closeModal();
                })
                .catch(err => {
                    toast.error(err.response.data.error, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                })
            })
        }
    }

    updatePricingType = async (e) => {
        e.preventDefault();
        const { selectedList, three, six, twelve } = this.state;
        let token = await localStorage.getItem('token');
        const validate = this.validate();
        if (validate) {
            selectedList.forEach(async (item) => {
                let payload = {
                    "commissionId": item._id,
                    "overNightRatePercentage": {
                        "three": parseFloat(three / 100),
                        "six": parseFloat(six / 100),
                        "twelve": parseFloat(twelve / 100)
                    }
                }
                await axios.put(`${process.env.REACT_APP_API_URL}/hotel/OvernightUpdateBytype`, payload, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                })
                    .then(response => {
                        if (response.data === 'OK') {
                            toast.success('Overnight Rate Updated Successfully', {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            this.closeModal();
                        } else {
                            toast.error('Something went wrong, Please try again...', {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    })
                    .catch(err => {
                        toast.error(err.response.data.error, {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    })
            })
        }
    }

    render() {
        const { history } = this.props
        const { modalStatus, loading, selectedListError, threeError, sixError, twelveError, updatePricingType, options, optionsPropertyType } = this.state;
        return (
            <div>
                <div className="header">
                    <h2><strong>Manage</strong> Pricing</h2>
                    <div className="breadcrumb-wrapper">
                        <ol className="breadcrumb">
                            <li>
                                <a href={() => null} onClick={() => history.push("/dashboard")}>TimeFlyz</a>
                            </li>
                            <li className="active">Pricing</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="panel">
                            <div className="panel-header ">
                                <div className="row">
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                        <h3><strong>Pricing</strong> </h3>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12" style={{ textAlign: 'end' }}>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                                <button className="btn btn-default btn-block" rel="noreferrer" data-toggle="modal" onClick={() => this.showModal('property')}>Edit Property Pricing</button>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                                <button className="btn btn-default btn-block" rel="noreferrer" data-toggle="modal" onClick={() => this.showModal('propertyType')}>Edit Property Chain Pricing</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-content pagination2 table-responsive">
                                <DataTable columns={columns} data={options} loading={loading} filterDefaultText="Filter By Name, Type, 3HR, 6HR, 12HR" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={modalStatus ? "modal fade in" : "modal fade"} aria-hidden="true" style={modalStatus ? { display: 'block' } : { display: 'none' }}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <form onSubmit={updatePricingType === 'property' ? this.updatePricing : this.updatePricingType}>
                                <div className="modal-header" style={{ background: '#ef4a23', color: '#fff' }}>
                                    <button type="button" className="close" aria-hidden="true" onClick={this.closeModal}><i className="fa fa-times" style={{ fontSize: 18 }} /></button>
                                    <h4 className="modal-title"><strong>Update</strong> pricing</h4>
                                </div>
                                <div className="modal-body">
                                    <label className="control-label col-md-3">{updatePricingType === 'property' ? 'Select Property' : 'Select Property Chain'}</label>
                                    <div className="col-md-9">
                                        <div className="form-group">
                                            <Multiselect
                                                options={updatePricingType === 'property' ? options : optionsPropertyType} // Options to display in the dropdown
                                                selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                                onSelect={(val) => this.setState({ selectedList: val })} // Function will trigger on select event
                                                onRemove={(val) => this.setState({ selectedList: val })} // Function will trigger on remove event
                                                displayValue="name" // Property name to display in the dropdown options
                                                avoidHighlightFirstOption={true}
                                                closeOnSelect={false}
                                            />
                                            {selectedListError ? (<div className="text-danger">{selectedListError}</div>) : null}
                                        </div>
                                    </div>
                                    <label className="control-label col-md-3">3hr Rate</label>
                                    <div className="col-md-9">
                                        <div className="form-group">
                                            <input type="number" className="form-control" placeholder="3hr Rate" name="three" onChange={this.onChange} />
                                            {threeError ? (<div className="text-danger">{threeError}</div>) : null}
                                        </div>
                                    </div>
                                    <label className="control-label col-md-3">6hr Rate</label>
                                    <div className="col-md-9">
                                        <div className="form-group">
                                            <input type="number" className="form-control" placeholder="6hr Rate" name="six" onChange={this.onChange} />
                                            {sixError ? (<div className="text-danger">{sixError}</div>) : null}
                                        </div>
                                    </div>
                                    <label className="control-label col-md-3">12hr Rate</label>
                                    <div className="col-md-9">
                                        <div className="form-group">
                                            <input type="number" className="form-control" placeholder="12hr Rate" name="twelve" onChange={this.onChange} />
                                            {twelveError ? (<div className="text-danger">{twelveError}</div>) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer text-center">
                                    <button type="reset" className="btn btn-primary btn-embossed bnt-square" onClick={this.closeModal}> Cancel</button>
                                    <button type="submit" className="btn btn-danger btn-embossed bnt-square" > Update</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}