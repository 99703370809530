import $api from "../../utils/axios";
import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Grid, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Container } from "@mui/system";
import axios from "axios";
import momenttz from "moment-timezone";
import { toast } from "react-toastify";
import ExportExcel from "./ExportExcel";
import FilterForm from "./FilterForm.js";
import usePropertyChain from "../../hooks/usePropertyChain";

export default function Reservations() {
    const [rows, setRows] = React.useState([]);
    const [originalrows, setOriginals] = React.useState([]);

    const [formValues, setFormValues] = React.useState([]);
    const { chains } = usePropertyChain();
    const [selectedRowIds, setSelectedRowIds] = React.useState([]);
    const filterUsers = async (values = null) => {
        if (values) {
            setFormValues(values);
        }
        const { search, propertyChain, hotel, billing } = values || formValues;
        let arrayhotel = [];
        if (propertyChain?.length) {
            let inputValue = propertyChain;
            await $api
                .post("/hotel/querybyproperty", { inputValue })
                .then((res) => {
                    res.data.data.forEach((element) => {
                        arrayhotel.push(element._id);
                    });
                });
        }
        const newRows = originalrows.filter((item) => {
            // search string check
            if (
                search &&
                !item.name?.toLowerCase()?.includes(search.toLowerCase()) &&
                !item.lastName?.toLowerCase()?.includes(search.toLowerCase()) &&
                !item.firstName?.toLowerCase()?.includes(search.toLowerCase())
            )
                return false;

            // user type check
            // admin or customer check
            if (
                propertyChain?.length &&
                arrayhotel?.length &&
                !arrayhotel.includes(item.hotelID)
            )
                return false;
            if (hotel?.length && !hotel.includes(item.hotelID)) return false;

            if (billing === "yes" && item.chargeStatus !== "charge") {
                return false;
            }
            if (billing === "no" && item.chargeStatus === "charge") {
                return false;
            }
            if (billing === "excluded" && !item.excluded) {
                return false;
            }
            return true;
        });
        setRows(newRows);
    };

    React.useEffect(() => {
        (async () => {
            //const resHotel = await $api.get("/archives/hotels");
            //const res = await $api.get(`/archives/${resHotel.data[0]._id}`);
            let token = localStorage.getItem("token");
            await axios
                .get(
                    `${process.env.REACT_APP_API_URL}/reservation/getReservations`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        const array = response.data.map((element) => ({
                            _id: element._id,
                            bookingId: element.bookingId,
                            bookingID: (
                                <a href={`/reservationDetails/${element._id}`}>
                                    {element.bookingId}
                                </a>
                            ),
                            lastName:
                                element.bookedBy && element.bookedBy.lastName
                                    ? element.bookedBy.lastName
                                    : "N/A",
                            firstName:
                                element.bookedBy && element.bookedBy.firstName
                                    ? element.bookedBy.firstName
                                    : "N/A",
                            name: element.hotelId.name,
                            hotelID: element.hotelId._id,
                            propertyName: (
                                <a href={`/editHotel/${element.hotelId._id}`}>
                                    {element.hotelId.name}
                                </a>
                            ),
                            duration: element.duration + "h",
                            reservationStart: momenttz(element.reservationStart)
                                .tz(element.timezone || "Australia/Sydney")
                                .format("MMM D YYYY hh:mm A"),
                            amount:
                                (element.charge.userVatgst.shortName || "A$") +
                                parseFloat(
                                    element.charge.amount *
                                        element.charge.userExchangeRate
                                ).toFixed(2),
                            baseAmount:
                                "A$" +
                                parseFloat(element.charge.amount).toFixed(2),
                            hotelAmount:
                                (element.charge.hotelVatgst.shortName || "A$") +
                                parseFloat(
                                    element.charge.amount *
                                        element.charge.hotelExchangeRate
                                ).toFixed(2),
                            status: element.status,
                            createdAt: momenttz(element.createdAt)
                                .tz(element.timezone || "Australia/Sydney")
                                .format("MMM D YYYY hh:mm A"),
                            chargeStatus: element.chargeStatus,
                            excluded: element.excluded,
                            billing:
                                element.excluded ? "Excluded" :
                                element.chargeStatus === "charge"
                                    ? element.invoiceIssueDate
                                        ? momenttz(element.invoiceIssueDate)
                                              .tz(
                                                  element?.timezone ||
                                                      "Australia/Sydney"
                                              )
                                              .format("MMM D YYYY")
                                        : "Yes"
                                    : "No",
                            paymentStatus: element.refund
                                ? "Refunded"
                                : element.charge.status === "charged" ||
                                  element.charge.status === "success"
                                ? "Paid"
                                : "Not Paid",
                        }));
                        setOriginals(array);
                        setRows(array);
                    }
                });
            //setOptions(resHotel.data);
            //setHotelname(resHotel.data[0].hotelName);
        })();
    }, []);

    const handleSelection = React.useCallback(
        (selectionModel) => {
            const selectedRows =
                rows
                    ?.filter((row) => selectionModel.includes(row._id))
                    ?.map((row) => row._id) || [];
            setSelectedRowIds(selectedRows);
        },
        [rows]
    );

    const columns = React.useMemo(
        () => [
            {
                headerName: "RESERVATION NUMBER",
                field: "bookingID",
                width: 95,
                renderCell: (params) => {
                    return (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: `<a href='${params.value.props.href}'>${params.value.props.children}</a>`,
                            }}
                        />
                    );
                },
                /*valueFormatter: (params) => {
        console.log("numberrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr");
        console.log(params);
        if (!params.value) {
          return "";
        }
        return params.value.props.children;
      },*/
            },
            {
                headerName: "DATE & TIME OF BOOKING",
                field: "createdAt",
                width: 95,
                valueFormatter: (params) => {
                    if (!params.value) {
                        return "";
                    }
                    return params.value.name;
                },
            },
            {
                headerName: "SURNAME",
                field: "lastName",
                width: 100,
                valueFormatter: (params) => {
                    if (!params.value) {
                        return "";
                    }
                    return params.value.name;
                },
            },
            {
                headerName: "PROPERTY",
                field: "propertyName",
                type: "string",
                width: 200,
                renderCell: (params) => {
                    return (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: `<a href='${params.value.props.href}'>${params.value.props.children}</a>`,
                            }}
                        />
                    );
                },
            },
            {
                headerName: "LENGTH OF STAY",
                field: "duration",
                width: 30,
                valueFormatter: (params) => {
                    if (!params.value) {
                        return "";
                    }
                    return params.value.name;
                },
            },
            {
                headerName: "DATE & TIME OF ARRIVAL",
                field: "reservationStart",
                width: 95,
                valueFormatter: (params) => {
                    if (!params.value) {
                        return "";
                    }
                    return params.value.name;
                },
            },
            {
                headerName: "STATUS",
                field: "status",
                width: 90,
                valueFormatter: (params) => {
                    if (!params.value) {
                        return "";
                    }
                    return params.value.name;
                },
            },
            {
                headerName: "USER RATE",
                field: "amount",
                width: 75,
                align: "right",
                valueFormatter: (params) => {
                    if (!params.value) {
                        return "";
                    }
                    return params.value.name;
                },
            },
            {
                headerName: "HOTEL RATE",
                field: "hotelAmount",
                width: 75,
                align: "right",
                valueFormatter: (params) => {
                    if (!params.value) {
                        return "";
                    }
                    return params.value.name;
                },
            },
            {
                headerName: "BASE RATE",
                field: "baseAmount",
                width: 75,
                align: "right",
                valueFormatter: (params) => {
                    if (!params.value) {
                        return "";
                    }
                    return params.value.name;
                },
            },
            {
                headerName: "TimeFlyz Billing Deducted",
                field: "billing",
                width: 70,
                valueFormatter: (params) => {
                    if (!params.value) {
                        return "";
                    }
                    return params.value.name;
                },
            },
            // {
            //   headerName: 'Created At', field: 'createdAt', type: 'date', width: 120,
            //   valueFormatter: (params) => {
            //     if (!params.value) {
            //       return '';
            //     }
            //     return moment(params.value).format('DD/MM HH:mm');
            //   },
            // },
            {
                headerName: "PAYMENT STATUS",
                field: "paymentStatus",
                width: 70,
                valueFormatter: (params) => {
                    if (!params.value) {
                        return "";
                    }
                    return params.value.name;
                },
            },
        ],
        []
    );

    /*const tabs = [
        {
            id: 1,
            tabTitle: 'Tab 1',
            title: 'Title 1',
            content: 'Las tabs se generan automáticamente a partir de un array de objetos, el cual tiene las propiedades: id, tabTitle, title y content.'
        },
        {
            id: 2,
            tabTitle: 'Tab 2',
            title: 'Title 2',
            content: 'Contenido de tab 2.'
        },
        {
            id: 3,
            tabTitle: 'Tab 3',
            title: 'Title 3',
            content: 'Contenido de tab 3.'
        },
        {
            id: 4,
            tabTitle: 'Tab 4',
            title: 'Title 4',
            content: 'Contenido de tab 4.'
        }
    ];*/

    /*const handleOptionChange = async (event, selectedOption) => {
    let resnew = await $api.get(`/archives/${selectedOption._id}`);
    setRows(resnew.data[0].availabilities);
    //setCurrentTab(res.data[0]._id);
  };*/
    const handleOptionReset = async (event) => {
        window.location.reload();
        //setCurrentTab(res.data[0]._id);
    };
    const handleExclude = React.useCallback(
        async (event) => {
            if (selectedRowIds.length === 0) {
                alert("Please Select Reservations to Exclude");
                return;
            }
            const token = localStorage.getItem("token");
            await axios
                .post(
                    `${process.env.REACT_APP_API_URL}/reservation/excludeReservations`,
                    selectedRowIds,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((response) => {
                    if (response.data === "alreadyProcessed") {
                        toast.warning("Reservation has already been processed - reservation cannot be excluded");
                    } else {
                        toast.success(
                            "Selected reservation(s) have been excluded for reservation statements/invoicing",
                            {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            }
                        );
                        setSelectedRowIds([]);
                        setTimeout(() => window.location.reload(), 3000);
                    }
                })
                .catch((error) => {
                    toast.error(
                        "There was an error while excluding selected reservation(s)",
                        {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        }
                    );
                    console.log(error);
                });
        },
        [selectedRowIds]
    );

    return (
        <Box>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                <Typography variant="h4" color="initial">
                    Reservations
                </Typography>
                <Box>
                    <ExportExcel data={rows} />
                    <Button variant="contained" onClick={handleOptionReset}>
                        Reset
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleExclude}
                        sx={{ ml: 2 }}
                    >
                        Exclude Selected Reservations
                    </Button>
                </Box>
            </Grid>
            {/*<div className="autoselect">
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            label="Property Chains"
            options={options}
            getOptionLabel={(option) => option.hotelName}
            sx={{ width: 300 }}
            onChange={handleOptionChange}
            renderInput={(params) => <TextField {...params} label={hotelname} />}
          />
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={options}
            getOptionLabel={(option) => option.hotelName}
            sx={{ width: 300 }}
            onChange={handleOptionChange}
            renderInput={(params) => <TextField {...params} label={hotelname} />}
          />
  </div>*/}
            <Container
                sx={{
                    backgroundColor: "white",
                    my: 2,
                    pt: 0,
                    borderRadius: 1,
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
            >
                <FilterForm onSubmit={filterUsers} chains={chains} />
                <DataGrid
                    sx={{ border: 0 }}
                    getRowId={(row) => row._id}
                    autoHeight
                    getRowHeight={() => "auto"}
                    columns={columns}
                    rows={rows}
                    rowHeight={100}
                    checkboxSelection
                    onSelectionModelChange={(newSelection) => {
                        handleSelection(newSelection);
                    }}
                />
            </Container>
        </Box>
    );
}
