import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";
import XLSX from "sheetjs-style";
import FileSaver from "file-saver";
import { uniqBy } from "lodash";

const ExportExcel = ({ data }) => {
  const fileName = `Users-${moment().format("YYYY-MM-DD")}`;
  const fileType = `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8`;
  const fileExtension = `.xlsx`;

  const getRoleType = (row) => {
    if (row.userType === "ADMIN") return "ADMIN";
    if (row.userType === "APP") return "CUSTOMER";
    if (row.userType === "EMPLOYEE") {
      if (row.accessLevel === "REGIONALMANAGER") return "Hotel Manager";
      if (row.accessLevel === "GROUPMANAGER") return "Revenue Manager";
      if (row.accessLevel === "OWNER") return "Hotel Owner";
    }
    return "GUEST";
  };

  const getChainName = (row) => {
    if (!row.commission || row.userType !== "EMPLOYEE") return "";
    return row.commission?.clientTypeName || "";
  };

  const getHotelName = (row) => {
    /*if (!row.hotel || row.userType !== "EMPLOYEE") return "";
    return row.hotel?.map((a) => a.name).join(", ");*/

  if (
    row.userType === "ADMIN" ||
    (row.userType === "EMPLOYEE" &&
      (row.accessLevel === "REGIONALMANAGER" ||
      row.accessLevel === "GROUPMANAGER"))
  ) {
    if(row.contacts !== undefined && row.managers !== undefined && row.hotel !== undefined) {
    return uniqBy(
      [
        ...row.contacts,
        ...row.managers,
        ...row.hotel,
      ],
      (r) => r._id.toString()
    )
      ?.map((a) => a.name)
      .join(", ");
    }
    else {
      return "";
    }
  }
  return "";
  };

  const exportToExcel = async () => {
    const excelData = data.map((item) => {
      return {
        Email: item.email,
        "Name": `${item.firstName} ${item.lastName}`,
        //"Phone Number": item.mobile || item.contactNo,
        "Role Type": getRoleType(item),
        Chain: getChainName(item),
        Hotel: getHotelName(item),
        "Date User Added": item.createdAt
          ? moment(item.createdAt).format("DD/MM/YYYY")
          : "",
        "Date User Last Login": item.lastLoggedIn
          ? moment(item.lastLoggedIn).format("DD/MM/YYYY")
          : "",
        "status": item.expireDate
        ? new Date(item.expireDate) > new Date()
          ? "Active"
          : "Not Active"
        : "Active",
        "Banned": item.banned,
      };
    });
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const fileData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(fileData, fileName + fileExtension);
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      sx={{ marginRight: 2 }}
      onClick={exportToExcel}
    >
      <DownloadIcon />
      Export Excel
    </Button>
  );
};

export default ExportExcel;
