import React, { Component } from "react";
import axios from "axios";
import { toast } from 'react-toastify';

export default class TermsOfUSe extends Component {

    state = {
        termsId: '',
        termsTitle: '',
        metaDescription: '',
    }

    componentDidMount() {
        axios.post(`${process.env.REACT_APP_API_URL}/cms/list`, {tag: 'TermsAndConditionsSEO'})
            .then(response => {
                this.setState({
                    termsId: response.data[0]._id,
                    termsTitle: response.data[0].title,
                    metaDescription: response.data[0].description,
                })
            })
            .catch(error => {
                console.log(error);
            })
    }

    onChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleEditorChange = (event, editor) => {
        const data = editor.getData();
        this.setState({
            metaDescription: data
        })
    }

    updateContents = async (e) => {
        e.preventDefault();
        const { termsId, termsTitle, metaDescription } = this.state;

        var contentsForm = new FormData();
        contentsForm.set('title', termsTitle);
        contentsForm.set('description', metaDescription);
        let token = await localStorage.getItem('token');

        await axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}/cms/update/${termsId}`,
            data: contentsForm,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .then(response => {
                toast.success('Contents Updated Successfully', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch(error => {
                console.log(error);
            })
    }

    render() {
        const { history } = this.props
        const { termsTitle, metaDescription } = this.state;
        return (
            <div>
                <div className="header">
                    <h2><strong>Website</strong> Contents</h2>
                    <div className="breadcrumb-wrapper">
                        <ol className="breadcrumb">
                            <li>
                                <a href={() => null} onClick={() => history.push("/dashboard")}>TimeFlyz</a>
                            </li>
                            <li className="active">User Terms & Conditions SEO</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 portlets">
                        <form onSubmit={this.updateContents}>
                            <div className="panel">
                                <div className="panel-content">
                                    <div className="form-group">
                                        <h5>Title</h5>
                                        <input type="text" name="termsTitle" className="form-control" placeholder="Enter the title here" aria-required="true" onChange={this.onChange} defaultValue={termsTitle} />
                                    </div>
                                    <div className="form-group">
                                        <h5>Meta-Description</h5>
                                        <textarea className="form-control" onChange={this.onChange} name="metaDescription" placeholder="Enter the meta-description here" defaultValue={metaDescription} style={{ minHeight: 150 }} />
                                    </div>
                                </div>

                                <div className="text-center  m-t-20">
                                    <button type="submit" className="btn btn-embossed btn-danger" onClick={this.updateContents}>Update</button>
                                    <button type="reset" className="cancel btn btn-embossed btn-primary m-b-10 m-r-0" onClick={() => this.componentDidMount()}>Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}