import React, { Component } from "react";
import axios from "axios";

export default class HotelListing extends Component {

    async componentDidMount(){
        let token = await localStorage.getItem('token');
        await axios.get(`${process.env.REACT_APP_API_URL}/user/hotels`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .catch(error => {
            console.log(error);
        })
    }

    render() {
        const { history } = this.props
        return (
            <div>
                <div className="header">
                    <h2><strong>Manage</strong> Hotels</h2>
                    <div className="breadcrumb-wrapper">
                        <ol className="breadcrumb">
                            <li>
                                <a href={() => null} onClick={() => history.push("/dashboard")}>TimeFlyz</a>
                            </li>
                            <li className="active">Hotel Management</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="panel">
                            <div className="panel-header ">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                                        <h3><strong>Hotels Listing</strong> </h3>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" style={{textAlign: 'end'}}>
                                        <input placeholder="Search" className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="panel-content">
                                <table className="table table-hover table-dynamic dataTable no-footer">
                                    <thead>
                                        <tr>
                                            <th>Hotel Name</th>
                                            <th>Address</th>
                                            <th>Contact</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Quality Apartments Camperdown</td>
                                            <td>23-33 Missenden Road,Camperdown, <br />
                                            New South Wales,Australia,PIN: 2050
                                            </td>
                                            <td>Name: Reservation, <br />
                                                Title: Front Desk,<br />
                                                Contact: 0295576100,<br />
                                                Email: info@qacamperdown.com.au
                                            </td>
                                            <td>
                                                <div className="text-center  m-t-20">
                                                    <a href="/editHotel/5e796e2fe290473c90d84057" className="btn btn-embossed btn-primary btn-block m-b-10">Edit</a>
                                                    <a href="#/" className="cancel btn btn-embossed btn-danger btn-block">Delete</a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Quality Apartments Camperdown</td>
                                            <td>23-33 Missenden Road,Camperdown, <br />
                                            New South Wales,Australia,PIN: 2050
                                            </td>
                                            <td>Name: Reservation, <br />
                                                Title: Front Desk,<br />
                                                Contact: 0295576100,<br />
                                                Email: info@qacamperdown.com.au
                                            </td>
                                            <td>
                                                <div className="text-center  m-t-20">
                                                    <button type="submit" className="btn btn-embossed btn-primary btn-block m-b-10">Edit</button>
                                                    <button type="reset" className="cancel btn btn-embossed btn-danger btn-block">Delete</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}