import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import DataTable from "../../components/DataTable";
import Checkbox from "rc-checkbox";
import "rc-checkbox/assets/index.css";
import SweetAlert from "react-bootstrap-sweetalert";
const mainURL = `${process.env.REACT_APP_HOTEL_ADMIN_URL}/signup/`;
const columns = [
  {
    name: "PROPERTY CHAIN",
    selector: (row) => row.ID,
    sortable: true,
  },
  {
    name: "Total Commission Rate (incl GST)",
    selector: (row) => row.commissionRate,
    sortable: true,
  },
  {
    name: "TimeFlyz Commission Rate (excl GST)",
    selector: (row) => row.commissionRateNotGST,
    sortable: true,
  },
  {
    name: "TimeFlyz Commission Rate (GST)",
    selector: (row) => row.commissionRateGST,
    sortable: true,
  },
  {
    name: "URL",
    selector: (row) => row.commissionUrl,
    wrap: true,
    sortable: true,
  },
  {
    name: "ACTION",
    selector: (row) => row.action,
  },
];

const viewPropertiesColumn = [
  {
    name: "PROPERTY",
    selector: (row) => row.propertyName,
    sortable: true,
  },
];

const viewAllPropertiesColumn = [
  {
    name: "PROPERTY",
    selector: (row) => row.propertyNameWithCheckbox,
    sortable: true,
  },
];
export default class propertyType extends Component {
  state = {
    modalStatus: false,
    modalUpdateStatus: false,
    hotelTypeId: "",
    hotelTypeName: "",
    commissionRate: 0,
    customURL: "",
    hotelTypeNameError: "",
    commissionRateError: "",
    commissionRateNotGSTError: "",
    commissionRateGSTError: "",
    loading: true,
    records: [],
    properties: [],
    viewPropertyLoading: false,
    fName: "",
    lName: "",
    email: "",
    managerType: "",
    fNameError: "",
    lNameError: "",
    emailError: "",
    managerTypeError: "",
    regionalManagerSelectedPropertyError: "",
    modalRegionalManagerPropertiesList: false,
    regionalManagerSelectedProperty: [],
    viewAllPropertyLoading: false,
    alertShow: false,
    alertBoxTitle: "",
    alertBoxData: null,
  };

  componentDidMount() {
    this.fetchHotelTypes();
  }

  deleteChain = async () => {
    let token = await localStorage.getItem("token");
    await axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API_URL}/admin/commission/${this.state.alertBoxData.clientTypeName}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Deleted successfully", {
            onClose: () => {
              const newRecords = this.state.records.filter(
                (chain) => chain._id !== this.state.alertBoxData._id
              );
              this.setState({
                records: newRecords,
              });
              this.onCancel();
            },
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        toast.error(error, {
          autoClose: 2000,
        });
        this.onCancel();
      });
  };
  onCancel = () => {
    this.setState({
      alertShow: false,
      alertBoxTitle: "",
      alertBoxData: null,
    });
  };
  showAlertBox = (e, value) => {
    e.preventDefault();
    this.setState({
      alertBoxData: value,
      alertBoxTitle: "Do you want to delete this property chain?",
      alertShow: true,
    });
  };
  fetchHotelTypes = async () => {
    let token = await localStorage.getItem("token");
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/admin/commission/list`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const array = [];
        response.data.forEach((element) => {
          array.push({
            _id: element._id,
            ID: <a href={`/property/all?commissionID=${element._id}`}>{element.clientTypeName}</a>,
            clientTypeName: element.clientTypeName,
            commissionRate: element.commissionRate,
            commissionRateNotGST: element.commissionRateNotGST,
            commissionRateGST: element.commissionRateGST,
            commissionUrl: mainURL + element.clientTypeName,
            action: (
              <div className="text-center">
                <a
                  href="#/"
                  className="btn btn-embossed btn-primary"
                  style={{ width: 100 }}
                  onClick={() =>
                    this.showUpdateModal(
                      element._id,
                      element.clientTypeName,
                      element.commissionRate,
                      element.commissionRateNotGST,
                      element.commissionRateGST,
                      element.commissionUrl
                    )
                  }
                >
                  Edit
                </a>
                <button
                  className="cancel btn btn-embossed btn-primary m-b-10 m-r-0"
                  onClick={(event) => this.showAlertBox(event, element)}
                >
                  <i className="fa fa-trash" style={{ color: "#FFFFFF" }} />
                </button>
              </div>
            ),
          });
        });
        this.setState({
          loading: false,
          records: array,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        console.log(error);
      });
  };

  showModal = () => {
    this.setState({
      hotelTypeName: "",
      modalStatus: true,
    });
  };

  closeModal = () => {
    this.setState({
      hotelTypeId: "",
      hotelTypeName: "",
      commissionRate: 0,
      commissionRateNotGST: 0,
      commissionRateGST: 0,
      customURL: "",
      modalStatus: false,
    });
  };

  showUpdateModal = (typeId, name, rate, commissionRateNotGST, commissionRateGST, URL) => {
    this.setState(
      {
        hotelTypeId: typeId,
        hotelTypeName: name,
        commissionRate: rate,
        commissionRateNotGST,
        commissionRateGST,
        customURL: URL.split("/")[4],
        properties: [],
      },
      () => {
        this.setState({
          modalUpdateStatus: true,
        });
      }
    );
  };

  closeUpdateModal = () => {
    this.setState({
      modalUpdateStatus: false,
    });
  };

  onChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  validate = () => {
    this.setState({
      hotelTypeNameError: "",
      commissionRateError: "",
      commissionRateNotGSTError: "",
      commissionRateGSTError: "",
    });
    let hotelTypeNameError = "",
      commissionRateError = "",
      commissionRateNotGSTError = "",
      commissionRateGSTError = "";

    if (this.state.hotelTypeName === "") {
      hotelTypeNameError = "Please Enter Property Chain Name";
    }
    if (hotelTypeNameError) {
      this.setState({ hotelTypeNameError });
      return false;
    }

    if (this.state.commissionRate === 0) {
      commissionRateError = "Please Enter Commission Rate";
    }
    if (commissionRateError) {
      this.setState({ commissionRateError });
      return false;
    }

    if (this.state.commissionRateNotGST === 0) {
      commissionRateNotGSTError = "Please Enter Commission Rate";
    }
    if (commissionRateNotGSTError) {
      this.setState({ commissionRateNotGSTError });
      return false;
    }

    if (this.state.commissionRateGST === 0) {
      commissionRateGSTError = "Please Enter Commission Rate";
    }
    if (commissionRateGSTError) {
      this.setState({ commissionRateGSTError });
      return false;
    }
    return true;
  };

  saveHotelType = async (e) => {
    e.preventDefault();
    const validate = this.validate();
    let token = await localStorage.getItem("token");
    if (validate) {
      const { hotelTypeName, commissionRate, commissionRateNotGST, commissionRateGST } = this.state;
      let saveData = {
        clientTypeName: hotelTypeName,
        commissionRate,
        commissionRateNotGST,
        commissionRateGST,
        commissionUrl: mainURL + hotelTypeName,
      };
      await axios
        .post(`${process.env.REACT_APP_API_URL}/admin/commission`, saveData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.setState({
            modalStatus: false,
            hotelTypeId: "",
            hotelTypeName: "",
            commissionRate: 0,
            commissionRateNotGST: 0,
            commissionRateGST: 0,
            customURL: "",
          });
          toast.success("Added Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.componentDidMount();
        })
        .catch((error) => {
          console.log(error);
          toast.error("Something went wrong. Please try again", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  updateHotelType = async (e) => {
    e.preventDefault();
    const validate = this.validate();
    let token = await localStorage.getItem("token");
    if (validate) {
      const {
        hotelTypeId,
        hotelTypeName,
        commissionRate,
        commissionRateNotGST,
        commissionRateGST,
      } = this.state;
      let updateData = {
        id: hotelTypeId,
        clientTypeName: hotelTypeName,
        commissionRate,
        commissionRateNotGST,
        commissionRateGST,
        commissionUrl: mainURL + hotelTypeName,
      };
      await axios
        .put(`${process.env.REACT_APP_API_URL}/admin/commission`, updateData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.setState({
            modalUpdateStatus: false,
            hotelTypeId: "",
            hotelTypeName: "",
            commissionRate: 0,
            commissionRateNotGST: 0,
            commissionRateGST: 0,
            customURL: "",
          });
          toast.success("Updated Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.componentDidMount();
        })
        .catch((error) => {
          console.log(error);
          toast.error("Something went wrong. Please try again", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  showProperties = async (hotelType) => {
    this.setState({ viewPropertyLoading: true, viewAllPropertyLoading: true });
    const { hotelTypeId } = this.state;
    let token = await localStorage.getItem("token");
    await axios
      .get(`${process.env.REACT_APP_API_URL}/hotel/list`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const array = [];
        let sortArray = response.data.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        sortArray.forEach((element) => {
          if (element.commission && hotelTypeId === element.commission._id) {
            array.push({
              propertyId: element._id,
              name: element.name,
              propertyName: <a href={`/editHotel/${element._id}`}>{element.name}</a>,
              propertyNameWithCheckbox: (
                <div className="text-center">
                  <div className="input-group">
                    <div className="icheck-inline">
                      <label>
                        <Checkbox
                          value={element._id}
                          defaultChecked={this.state.regionalManagerSelectedProperty.includes(
                            element._id
                          )}
                          onChange={this.regionalManagerProperty}
                          data-checkbox="icheckbox_square-blue"
                        />
                        &nbsp; &nbsp;{element.name}
                      </label>
                    </div>
                  </div>
                </div>
              ),
            });
          }
        });
        this.setState({
          properties: array,
          viewPropertyLoading: false,
          viewAllPropertyLoading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  regionalManagerProperty = (e) => {
    const { value } = e.target;
    let regionalManagerSelectedProperty = this.state.regionalManagerSelectedProperty;

    if (!regionalManagerSelectedProperty.includes(value)) {
      regionalManagerSelectedProperty.push(value);
    } else {
      var index = regionalManagerSelectedProperty.indexOf(value);
      if (index !== -1) {
        regionalManagerSelectedProperty.splice(index, 1);
      }
    }

    this.setState({
      regionalManagerSelectedProperty,
    });
  };
  addManagerShowModal = (typeId) => {
    this.setState(
      {
        hotelTypeId: typeId,
        modalUpdateStatus: false,
        properties: [],
      },
      () => {
        this.setState({
          modalManagerStatus: true,
        });
      }
    );
  };

  closeManagerStatus = () => {
    this.setState({
      fName: "",
      lName: "",
      email: "",
      managerType: "",
      fNameError: "",
      lNameError: "",
      emailError: "",
      managerTypeError: "",
      regionalManagerSelectedPropertyError: "",
      regionalManagerSelectedProperty: [],
      properties: [],
      modalRegionalManagerPropertiesList: false,
      modalManagerStatus: false,
    });
  };

  validateManager = () => {
    this.setState({
      fNameError: "",
      lNameError: "",
      emailError: "",
      managerTypeError: "",
      regionalManagerSelectedPropertyError: "",
    });
    const regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

    let fNameError = "";
    let lNameError = "";
    let emailError = "";
    let managerTypeError = "";
    let regionalManagerSelectedPropertyError = "";

    if (this.state.fName === "") {
      fNameError = "Please Enter First Name";
    }
    if (fNameError) {
      this.setState({ fNameError });
      return false;
    }

    if (this.state.lName === "") {
      lNameError = "Please Enter Last Name";
    }
    if (lNameError) {
      this.setState({ lNameError });
      return false;
    }

    if (this.state.email === "") {
      emailError = "Please Enter Email";
    } else if (!regex.test(this.state.email.trim())) {
      emailError = "Please enter a valid Email Address";
    }
    if (emailError) {
      this.setState({ emailError });
      return false;
    }

    if (this.state.managerType === "") {
      managerTypeError = "Please Select Manager Type";
    }
    if (managerTypeError) {
      this.setState({ managerTypeError });
      return false;
    }

    if (
      this.state.managerType === "REGIONALMANAGER" &&
      this.state.regionalManagerSelectedProperty.length < 1
    ) {
      regionalManagerSelectedPropertyError = "Please Select Hotels For Manager";
    }
    if (regionalManagerSelectedPropertyError) {
      this.setState({ regionalManagerSelectedPropertyError });
      return false;
    }
    return true;
  };

  addManager = async (e) => {
    e.preventDefault();
    const isValid = this.validateManager();

    if (isValid) {
      let token = await localStorage.getItem("token");
      const { fName, lName, email, hotelTypeId, managerType, regionalManagerSelectedProperty } =
        this.state;
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/user/createManager`,
          {
            firstName: fName,
            lastName: lName,
            email,
            commission: hotelTypeId,
            accessLevel: managerType,
            hotelId: regionalManagerSelectedProperty,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          toast.success("Manager Added Successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          window.location.reload();
        })
        .catch((error) => {
          toast.error(error.response.data.error, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  showAllProperties() {
    this.setState(
      {
        modalManagerStatus: false,
        modalRegionalManagerPropertiesList: true,
        regionalManagerSelectedProperty: [],
        fNameError: "",
        lNameError: "",
        emailError: "",
        managerTypeError: "",
        regionalManagerSelectedPropertyError: "",
      },
      () => {
        this.showProperties(this.state.hotelTypeName);
      }
    );
  }

  closeShowAllProperties = () => {
    this.setState({
      modalManagerStatus: true,
      modalRegionalManagerPropertiesList: false,
    });
  };

  render() {
    const { history } = this.props
    const {
      alertShow,
      alertBoxTitle,
      modalStatus,
      modalUpdateStatus,
      hotelTypeName,
      commissionRate,
      commissionRateNotGST,
      commissionRateGST,
      hotelTypeId,
      hotelTypeNameError,
      commissionRateError,
      commissionRateNotGSTError,
      commissionRateGSTError,
      loading,
      records,
      viewPropertyLoading,
      properties,
      modalRegionalManagerPropertiesList,
      modalManagerStatus,
      fName,
      lName,
      email,
      managerType,
      fNameError,
      lNameError,
      emailError,
      managerTypeError,
      viewAllPropertyLoading,
      regionalManagerSelectedPropertyError,
      regionalManagerSelectedProperty,
    } = this.state;
    return (
      <div>
        <SweetAlert
          show={alertShow}
          warning
          showCancel
          confirmBtnText="Confirm"
          confirmBtnBsStyle="danger"
          title={alertBoxTitle}
          onConfirm={() => this.deleteChain()}
          onCancel={() => this.onCancel()}
          focusCancelBtn
        />
        <div className="header">
          <h2>
            <strong>Manage </strong>Property Chain
          </h2>
          <div className="breadcrumb-wrapper">
            <ol className="breadcrumb">
              <li>
                <a href={() => null} onClick={() => history.push("/dashboard")}>TimeFlyz</a>
              </li>
              <li className="active">Property Chain</li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="panel">
              <div className="panel-header ">
                <div className="row" style={{ padding: 10 }}>
                  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <h3>
                      <strong>Property Chain</strong>
                    </h3>
                  </div>
                  <div
                    className="col-lg-4 col-md-4 col-sm-12 col-xs-12"
                    style={{ textAlign: "end" }}
                  >
                    <a
                      href="#/"
                      className="btn btn-primary"
                      rel="noreferrer"
                      data-toggle="modal"
                      onClick={this.showModal}
                    >
                      <i className="fa fa-plus c-warning" /> Add Property Chain
                    </a>
                  </div>
                </div>
              </div>
              <div className="panel-content pagination2 table-responsive">
                <DataTable
                  columns={columns}
                  data={records}
                  loading={loading}
                  filterDefaultText="Filter By Property Chain"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className={modalStatus ? "modal fade in" : "modal fade"}
          aria-hidden="true"
          style={modalStatus ? { display: "block" } : { display: "none" }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <form onSubmit={this.saveHotelType}>
                <div className="modal-header" style={{ background: "#ef4a23", color: "#fff" }}>
                  <button
                    type="button"
                    className="close"
                    aria-hidden="true"
                    onClick={this.closeModal}
                  >
                    <i className="fa fa-times" style={{ fontSize: 18 }} />
                  </button>
                  <h4 className="modal-title">
                    <strong>New</strong> property chain
                  </h4>
                </div>
                <div className="modal-body">
                  <label className="control-label col-md-4">Property Chain Name</label>
                  <div className="col-md-8">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Property Chain Name"
                        name="hotelTypeName"
                        onChange={this.onChange}
                      />
                      {hotelTypeNameError ? (
                        <div className="text-danger">{hotelTypeNameError}</div>
                      ) : null}
                    </div>
                  </div>
                  <label className="control-label col-md-4">Total Commission Rate (incl GST)</label>
                  <div className="col-md-8">
                    <div className="form-group">
                      <input
                        step="0.01"
                        type="number"
                        className="form-control"
                        placeholder="TimeFlyz Commission Rate (excl GST)"
                        name="commissionRate"
                        onChange={this.onChange}
                      />
                      {commissionRateError ? (
                        <div className="text-danger">{commissionRateError}</div>
                      ) : null}
                    </div>
                  </div>
                  <label className="control-label col-md-4">
                    TimeFlyz Commission Rate (excl GST)
                  </label>
                  <div className="col-md-8">
                    <div className="form-group">
                      <input
                        step="0.01"
                        type="number"
                        className="form-control"
                        placeholder="Total Commission Rate (incl GST)"
                        name="commissionRateNotGST"
                        onChange={this.onChange}
                      />
                      {commissionRateNotGSTError ? (
                        <div className="text-danger">{commissionRateNotGSTError}</div>
                      ) : null}
                    </div>
                  </div>
                  <label className="control-label col-md-4">TimeFlyz Commission Rate (GST)</label>
                  <div className="col-md-8">
                    <div className="form-group">
                      <input
                        step="0.01"
                        type="number"
                        className="form-control"
                        placeholder="TimeFlyz Commission Rate (GST)"
                        name="commissionRateGST"
                        onChange={this.onChange}
                      />
                      {commissionRateGSTError ? (
                        <div className="text-danger">{commissionRateGSTError}</div>
                      ) : null}
                    </div>
                  </div>
                  <label className="control-label col-md-4">Custom URL</label>
                  <div className="col-md-8">
                    <b>{mainURL + hotelTypeName}</b>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="reset"
                    className="btn btn-primary btn-embossed bnt-square"
                    onClick={this.closeModal}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-danger btn-embossed bnt-square">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          className={modalUpdateStatus ? "modal fade in" : "modal fade"}
          aria-hidden="true"
          style={modalUpdateStatus ? { display: "block" } : { display: "none" }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <form onSubmit={this.updateHotelType}>
                <div className="modal-header" style={{ background: "#ef4a23", color: "#fff" }}>
                  <button
                    type="button"
                    className="close"
                    aria-hidden="true"
                    onClick={this.closeUpdateModal}
                  >
                    <i className="fa fa-times" style={{ fontSize: 18 }} />
                  </button>
                  <h4 className="modal-title">
                    <strong>Update</strong> property chain
                  </h4>
                </div>
                <div className="modal-body" style={{ minHeight: 150 }}>
                  <label className="control-label col-md-4">Property Chain Name</label>
                  <div className="col-md-8">
                    <div className="form-group">
                      {hotelTypeName === "Other" ? (
                        <span>{hotelTypeName}</span>
                      ) : (
                        <>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Property Chain Name"
                            name="hotelTypeName"
                            onChange={this.onChange}
                            value={hotelTypeName}
                          />
                          {hotelTypeNameError ? (
                            <div className="text-danger">{hotelTypeNameError}</div>
                          ) : null}
                        </>
                      )}
                    </div>
                  </div>
                  <label className="control-label col-md-4">Total Commission Rate (incl GST)</label>
                  <div className="col-md-8">
                    <div className="form-group">
                      <input
                        step="0.01"
                        type="number"
                        className="form-control"
                        placeholder="TimeFlyz Commission Rate (excl GST)"
                        name="commissionRate"
                        onChange={this.onChange}
                        value={commissionRate}
                      />
                      {commissionRateError ? (
                        <div className="text-danger">{commissionRateError}</div>
                      ) : null}
                    </div>
                  </div>
                  <label className="control-label col-md-4">
                    TimeFlyz Commission Rate (excl GST)
                  </label>
                  <div className="col-md-8">
                    <div className="form-group">
                      <input
                        step="0.01"
                        type="number"
                        className="form-control"
                        placeholder="Total Commission Rate (incl GST)"
                        name="commissionRateNotGST"
                        onChange={this.onChange}
                        value={commissionRateNotGST}
                      />
                      {commissionRateNotGSTError ? (
                        <div className="text-danger">{commissionRateNotGSTError}</div>
                      ) : null}
                    </div>
                  </div>
                  <label className="control-label col-md-4">TimeFlyz Commission Rate (GST)</label>
                  <div className="col-md-8">
                    <div className="form-group">
                      <input
                        step="0.01"
                        type="number"
                        className="form-control"
                        placeholder="TimeFlyz Commission Rate (GST)"
                        name="commissionRateGST"
                        onChange={this.onChange}
                        value={commissionRateGST}
                      />
                      {commissionRateGSTError ? (
                        <div className="text-danger">{commissionRateGSTError}</div>
                      ) : null}
                    </div>
                  </div>
                  <label className="control-label col-md-4">Custom URL</label>
                  <div className="col-md-8">
                    <b>{mainURL + hotelTypeName}</b>
                  </div>
                </div>
                <div className="modal-footer">
                  {properties.length > 0 ? (
                    <a
                      href="#/"
                      rel="noreferrer"
                      className="control-label col-md-4"
                      style={{ marginTop: 8 }}
                      onClick={() => this.setState({ properties: [] })}
                    >
                      Hide Properties
                    </a>
                  ) : (
                    <a
                      href="#/"
                      rel="noreferrer"
                      className="control-label col-md-4"
                      style={{ marginTop: 8 }}
                      onClick={() => this.showProperties(hotelTypeName)}
                    >
                      View Properties
                    </a>
                  )}
                  <a
                    href="#/"
                    rel="noreferrer"
                    className="control-label col-md-2 btn btn-default btn-embossed bnt-square"
                    style={{ textAlign: "center" }}
                    onClick={() => this.addManagerShowModal(hotelTypeId)}
                  >
                    Add Manager
                  </a>
                  <button
                    type="reset"
                    className="btn btn-primary btn-embossed bnt-square"
                    onClick={this.closeUpdateModal}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-danger btn-embossed bnt-square">
                    Update
                  </button>
                </div>
              </form>
              <div style={{ minHeight: 10 }}>
                {properties.length > 0 ? (
                  <div
                    className="panel-content pagination2 table-responsive"
                    style={{ height: 250 }}
                  >
                    <DataTable
                      columns={viewPropertiesColumn}
                      data={properties}
                      loading={viewPropertyLoading}
                      filterDefaultText="Filter By Property Chain"
                      searchBox={false}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div
          className={modalManagerStatus ? "modal fade in" : "modal fade"}
          aria-hidden="true"
          style={modalManagerStatus ? { display: "block" } : { display: "none" }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <form onSubmit={this.addManager}>
                <div className="modal-header" style={{ background: "#ef4a23", color: "#fff" }}>
                  <button
                    type="button"
                    className="close"
                    aria-hidden="true"
                    onClick={this.closeManagerStatus}
                  >
                    <i className="fa fa-times" style={{ fontSize: 18 }} />
                  </button>
                  <h4 className="modal-title">
                    <strong>Add</strong> Manager
                  </h4>
                </div>
                <div
                  className="modal-body"
                  style={
                    regionalManagerSelectedProperty.length > 0
                      ? { minHeight: 380 }
                      : { minHeight: 150 }
                  }
                >
                  <label className="control-label col-md-4">First Name</label>
                  <div className="col-md-8">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter First Name"
                        name="fName"
                        onChange={this.onChange}
                        value={fName}
                      />
                      {fNameError ? <div className="text-danger">{fNameError}</div> : null}
                    </div>
                  </div>
                  <label className="control-label col-md-4">Last Name</label>
                  <div className="col-md-8">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Last Name"
                        name="lName"
                        onChange={this.onChange}
                        value={lName}
                      />
                      {lNameError ? <div className="text-danger">{lNameError}</div> : null}
                    </div>
                  </div>
                  <label className="control-label col-md-4">Email</label>
                  <div className="col-md-8">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Email"
                        name="email"
                        onChange={this.onChange}
                        value={email}
                      />
                      {emailError ? <div className="text-danger">{emailError}</div> : null}
                    </div>
                  </div>
                  <label className="control-label col-md-4">Manager Type</label>
                  <div className="col-md-8">
                    <div className="form-group">
                      <select
                        className="form-control pointer"
                        name="managerType"
                        onChange={this.onChange}
                        defaultValue={managerType}
                      >
                        <option value="" disabled>
                          Select Manager Type
                        </option>
                        <option value="GROUPMANAGER">Revenue Manager</option>
                        <option value="REGIONALMANAGER">Hotel Manager</option>
                      </select>
                      {managerTypeError ? (
                        <div className="text-danger">{managerTypeError}</div>
                      ) : null}
                    </div>
                  </div>
                  {managerType === "REGIONALMANAGER" ? (
                    <>
                      <label className="control-label col-md-4">Hotels List</label>
                      <div className="col-md-8">
                        <div className="form-group">
                          <a
                            href="#/"
                            rel="noreferrer"
                            className="control-label btn btn-default btn-embossed bnt-square"
                            style={{ textAlign: "center" }}
                            onClick={() => this.showAllProperties()}
                          >
                            Show Hotel List
                          </a>
                          {regionalManagerSelectedPropertyError ? (
                            <div className="text-danger">
                              {regionalManagerSelectedPropertyError}
                            </div>
                          ) : null}
                        </div>
                        {regionalManagerSelectedProperty.length > 0 ? (
                          <ul style={{ height: 120, overflowY: "scroll" }}>
                            {properties.map((data, index) => {
                              return regionalManagerSelectedProperty.includes(data.propertyId) ? (
                                <li key={index}>{data.name}</li>
                              ) : null;
                            })}
                          </ul>
                        ) : null}
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="modal-footer">
                  <button
                    type="reset"
                    className="btn btn-primary btn-embossed bnt-square"
                    onClick={this.closeManagerStatus}
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-danger btn-embossed bnt-square">
                    Add
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          className={modalRegionalManagerPropertiesList ? "modal fade in" : "modal fade"}
          aria-hidden="true"
          style={modalRegionalManagerPropertiesList ? { display: "block" } : { display: "none" }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header" style={{ background: "#ef4a23", color: "#fff" }}>
                <button
                  type="button"
                  className="close"
                  aria-hidden="true"
                  onClick={this.closeShowAllProperties}
                >
                  <i className="fa fa-times" style={{ fontSize: 18 }} />
                </button>
                <h4 className="modal-title">
                  <strong>Select</strong> hotels
                </h4>
              </div>
              <div className="modal-body">
                <div className="panel-content pagination2 table-responsive" style={{ height: 400 }}>
                  <DataTable
                    columns={viewAllPropertiesColumn}
                    data={properties}
                    loading={viewAllPropertyLoading}
                    filterDefaultText="Filter By Property Chain"
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="reset"
                  className="btn btn-primary btn-embossed bnt-square"
                  onClick={this.closeShowAllProperties}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-danger btn-embossed bnt-square"
                  onClick={this.closeShowAllProperties}
                >
                  Add Selected Hotels
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
