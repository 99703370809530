import React, { Component } from "react";
import axios from 'axios';
import { toast } from "react-toastify";
import moment from "moment";
import DataTable from "../../components/DataTable";

const columns = [
    {
        name: 'CREATED DATE',
        selector: row => row.createdAt,
        sortable: true,
    },
    {
        name: 'NAME',
        selector: row => row.fullName,
        sortable: true,
    },
    {
        name: 'EMAIL',
        selector: row => row.email,
        sortable: true,
    },
    {
        name: 'PHONE',
        selector: row => row.contactNo,
        sortable: true,
    },
    {
        name: 'STATUS',
        selector: row => row.status,
        sortable: true,
    },
    {
        name: 'EXPEDIA URL',
        selector: row => row.url,
    },
];

export default class ExpediaHotelURLError extends Component {
    state = {
        loading: true,
        records: [],
    }

    componentDidMount() {
        this.fetchExpediaHotelError();
    }

    fetchExpediaHotelError = async () => {
        let token = await localStorage.getItem('token');
        await axios.get(`${process.env.REACT_APP_API_URL}/report`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
            .then(response => {
                const array = [];
                response.data.forEach(element => {
                    if(element.user){
                        array.push({
                            _id: element._id,
                            createdAt: moment(element.createdAt).utc().format("MMM D YYYY"),
                            fullName: element.user.fullName,
                            email: element.user.email,
                            contactNo: element.user.contactNo,
                            status: element.status,
                            url: <a href={element.url} target="_blank" rel="noreferrer" className="btn btn-embossed btn-danger" style={{ width: 100 }}>See Link</a>
                        })
                    }
                });
                this.setState({
                    records: array,
                    loading: false,
                })
            })
            .catch(error => {
                console.log(error);
            })
    }
    createMarkup = (html) => {
        return {
            __html: html,
        };
    };

    toggleStatus = async (expediaID, currentStatus) => {
        let token = await localStorage.getItem('token');
        let parameters = {
            "status": currentStatus === 'SOLVED' ? 'ERROR' : 'SOLVED',
        };
        await axios.put(`${process.env.REACT_APP_API_URL}/report/${expediaID}`, parameters, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then(response => {
            if (response.status === 200) {
                this.componentDidMount();
                toast.success('Status Changed Successfully', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch(error => {
            toast.error('Something went wrong', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            console.log(error);
        })
    }

    render() {
        const { history } = this.props
        const { loading, records } = this.state;
        return (
            <div>
                <div className="header">
                    <h2><strong>Expedia </strong> Hotel Error</h2>
                    <div className="breadcrumb-wrapper">
                        <ol className="breadcrumb">
                            <li>
                                <a href={() => null} onClick={() => history.push("/dashboard")}>TimeFlyz</a>
                            </li>
                            <li className="active">Expedia Hotel Error</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="panel">
                            <div className="panel-header ">
                                <div className="row" style={{ padding: 10 }}>
                                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                                        <h3><strong>Expedia Hotel Error</strong> </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-content pagination2 table-responsive">
                                <DataTable columns={columns} data={records} loading={loading} filterDefaultText="Filter By Name, Email, Phone, Status" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}