import { useState, useMemo, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Logo from "../../../assets/images/BIT_Support_Icon.png";
import { jwtDecode } from "jwt-decode";

const Sidebar = ({ toggleSidebar }) => {
    const history = useHistory();
    const [activeSidebar, setActiveSidebar] = useState("");
    const [role, setRole] = useState("");
    const pageName = window.location.pathname.split("/");

    const handleActiveSidebar = (title) => {
        setActiveSidebar((prev) => (prev === title ? "" : title));
    };

    const websiteAllActiveStatus = useMemo(
        () =>
            activeSidebar === "websiteAllSidebar" ||
            activeSidebar === "contactUsSidebar" ||
            activeSidebar === "userTrmsSidebar" ||
            activeSidebar === "hotelTermsSidebar" ||
            activeSidebar === "privacySidebar" ||
            activeSidebar === "aboutSidebar" ||
            activeSidebar === "pressRoomSidebar" ||
            pageName[1] === "contactUs" ||
            pageName[1] === "terms" ||
            pageName[1] === "terms-hotel" ||
            pageName[1] === "privacy" ||
            pageName[1] === "faq" ||
            pageName[1] === "about" ||
            pageName[1] === "pressRoom",
        [activeSidebar, pageName]
    );

    const handleRedirect = (route) => {
        history.push(route);
    };

    const logout = () => {
        localStorage.removeItem("token");
        handleRedirect("/login");
    };

    const getRole = () => {
        const token = localStorage.getItem('token');
        const decoded = jwtDecode(token);
        setRole(decoded.role)

        if(decoded.role==='SuperAdmin'){
            document.getElementById('usernameside').innerHTML = 'Admin'
        } else {
           document.getElementById('usernameside').innerHTML = decoded.firstName
        }
        // this.state.role = 
    }

    const lockScreen = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/user/me`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.status === 200) {
                console.log(response, "responseData");
                if(response.data?.userType?.name) {
                localStorage.setItem("departmentType", response.data.userType.name);
                } 
                else if(response.data?.role === "SuperAdmin") {
                localStorage.setItem("departmentType", "Admin");  
                }
                else {
                localStorage.setItem("departmentType", "Employee");  
                }
                // localStorage.removeItem("token");
                // history.push("/lock");
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        lockScreen();
        getRole();
        if (pageName[1] === "dashboard") {
            setActiveSidebar("");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageName[1]]);

    return (
        <div
            className="sidebar"
            style={{ display: toggleSidebar ? "block" : "none" }}
        >

            <div className="logopanel">
                <span
                    className="cursor-pointer"
                    onClick={() => {
                        handleActiveSidebar("");
                        handleRedirect("/dashboard");
                    }}
                >
                    <img src={Logo} alt="Logo" style={{ height: "125%" }} />
                </span>
            </div>
            <div className="sidebar-inner">
                <div className="sidebar-top">
                    <div className="userlogged clearfix">
                        <h4 style={{ textAlign: "center" }}>Hello <span id='usernameside'></span></h4>
                    </div>
                </div>
                <ul className="nav nav-sidebar enable-scroll">
                    {/* Property Chain Section Ends */}
                    {
                    (role === 'Department' || role === 'Employee') &&
                    <li
                        className={
                            activeSidebar === "appImagesAllSidebar" ||
                                pageName[1] === "users"
                                ? "nav-parent nav-active active"
                                : "nav-parent nav-active"
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() =>
                                //handleActiveSidebar("appImagesAllSidebar")
                                handleRedirect("/users/change-password")
                            }
                        >
                            <i className="fa fa-building"></i>
                            <span>Change Password </span>
                            <span className="fa arrow"></span>
                        </a>
                        </li>
                        }
                        <li
                        className={
                            activeSidebar === "appImagesAllSidebar" ||
                                pageName[1] === "all-tickets" ||
                                pageName[1] === "add-ticket" ||
                                pageName[1] === "view-ticket"
                                ? "nav-parent nav-active active"
                                : "nav-parent nav-active"
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() =>
                                //handleActiveSidebar("appImagesAllSidebar")
                                handleRedirect("/all-tickets")
                            }
                        >
                            <i className="fa fa-building"></i>
                            <span>Ticket Management </span>
                            <span className="fa arrow"></span>
                        </a>
                    </li>
                    {
                       role === 'Department' &&
                        <>
                        <li
                        className={
                            activeSidebar === "appImagesAllSidebar" ||
                                pageName[1] === "all-mytickets" ||
                                pageName[1] === "add-myticket" ||
                                pageName[1] === "view-myticket"
                                ? "nav-parent nav-active active"
                                : "nav-parent nav-active"
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() =>
                                //handleActiveSidebar("appImagesAllSidebar")
                                handleRedirect("/all-mytickets")
                            }
                        >
                            <i className="fa fa-building"></i>
                            <span>My Tickets </span>
                            <span className="fa arrow"></span>
                        </a>
                    </li>
                    </>
                    }
                    {role === 'SuperAdmin' &&
                        <>
                            <li
                                className={
                                    activeSidebar === "appImagesAllSidebar" ||
                                        pageName[1] === "all-departments" ||
                                        pageName[1] === "add-department" ||
                                        pageName[1] === "edit-department"
                                        ? "nav-parent nav-active active"
                                        : "nav-parent nav-active"
                                }
                            >
                                <a
                                    href={() => null}
                                    onClick={() =>
                                        //handleActiveSidebar("appImagesAllSidebar")
                                        handleRedirect("/all-departments")
                                    }
                                >
                                    <i className="fa fa-building"></i>
                                    <span>Department Management </span>
                                    <span className="fa arrow"></span>
                                </a>
                            </li>

                            <li
                                className={
                                    activeSidebar === "appImagesAllSidebar" ||
                                        pageName[1] === "users" ||
                                        pageName[1] === "users-all"
                                        ? "nav-parent nav-active active"
                                        : "nav-parent nav-active"
                                }
                            >
                                <a
                                    href={() => null}
                                    onClick={() =>
                                        //handleActiveSidebar("appImagesAllSidebar")
                                        handleRedirect("/users")
                                    }
                                >
                                    <i className="fa fa-building"></i>
                                    <span>Users Management </span>
                                    <span className="fa arrow"></span>
                                </a>
                            </li>
                            <li
                        className={
                            activeSidebar === "appImagesAllSidebar" ||
                                pageName[1] === "all-useradmins" ||
                                pageName[1] === "add-useradmins"
                                ? "nav-parent nav-active active"
                                : "nav-parent nav-active"
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() =>
                                //handleActiveSidebar("appImagesAllSidebar")
                                handleRedirect("/all-useradmins")
                            }
                        >
                            <i className="fa fa-building"></i>
                            <span>Admin Management </span>
                            <span className="fa arrow"></span>
                        </a>
                    </li>
                        </>

                    }
                    {/*
                    <li
                        className={
                            pageName[1] === "all-tickets" ||
                            pageName[1] === "add-ticket"
                                ? "active"
                                : ""
                        }
                        style={
                            activeSidebar === "appImagesAllSidebar" ||
                            pageName[1] === "all-tickets" ||
                            pageName[1] === "add-ticket" ||
                            pageName[1] === "all-images"
                                ? { display: "block" }
                                : { display: "none" }
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                handleRedirect("/all-tickets");
                            }}
                        >
                            <span>Tickets</span>
                        </a>
                    </li>
                    
                    <li
                        className={pageName[1] === "all-images" ? "active" : ""}
                        style={
                            activeSidebar === "appImagesAllSidebar" ||
                            pageName[1] === "all-tickets" ||
                            pageName[1] === "add-ticket" ||
                            pageName[1] === "all-images"
                                ? { display: "block" }
                                : { display: "none" }
                        }
                    >
                        <a
                            href={() => null}
                            onClick={() => {
                                handleRedirect("/all-images");
                            }}
                        >
                            <span> More for You</span>
                        </a>
                    </li>
                    */}
                </ul>


                <ul className="nav nav-sidebar enable-scroll">


                </ul>


            </div>
        </div>
    );
};

export default Sidebar;
