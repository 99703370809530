module.exports = {
    AMENITIES: [
        {
            label: 'Restaurant',
            value: false,
            image: 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/restaurant.png',
        },
        {
            label: 'Bar',
            value: false,
            image: 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/bar.png',
        },
        {
            label: 'Room service',
            value: false,
            image: 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/meal.png',
        },
        {
            label: 'Kitchen',
            value: false,
            image: 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/kitchen.png',
        },
        {
            label: 'Fitness centre',
            value: false,
            image: 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/fitness+centre.png',
        },
        {
            label: 'Free Wifi',
            value: false,
            image: 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/wifi.png',
        },
        {
            label: 'Elevator',
            value: false,
            image: 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/elevator.png',
        },
        {
            label: 'Business centre',
            value: false,
            image: 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/business+centre.png',
        },
        {
            label: 'Sauna',
            value: false,
            image: 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/sauna.png',
        },
        {
            label: 'Salon',
            value: false,
            image: 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/salon.png',
        },
        {
            label: 'Laundry',
            value: false,
            image: 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/laundry.png',
        },
        {
            label: 'Accessible rooms',
            value: false,
            image: 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/accessible+rooms.png',
        },
        {
            label: 'Rooftop',
            value: false,
            image: 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/rooftop.png',
        },
        {
            label: 'Parking',
            value: false,
            image: 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/parking.png',
        },
        {
            label: 'Airport Shuttle',
            value: false,
            image: 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/airport+shuttle.png',
        },
        {
            label: '24 hour reception',
            value: false,
            image: 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/reception.png',
        },
        {
            label: 'Air conditioning',
            value: false,
            image: 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/air+conditioner.png',
        },
        {
            label: 'Money exchange',
            value: false,
            image: 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/money+exchange.png',
        },
        {
            label: 'Smoking zone',
            value: false,
            image: 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/smoking.png',
        },
        {
            label: 'Toiletries',
            value: false,
            image: 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/toiletries.png',
        },
        {
            label: 'Pool',
            value: false,
            image: 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/pool.png',
        },
        {
            label: 'Tea & Coffee',
            value: false,
            image: 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/Tea_%26_Coffee.png',
        },
        {
            label: 'Housekeeping',
            value: false,
            image: 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/housekeeping.png',
        },
        {
            label: 'Ensuite',
            value: false,
            image: 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/Ensuite.png',
        },
        {
            label: 'Mini Bar',
            value: false,
            image: 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/minibar.png',
        },
        {
            label: 'Microwave',
            value: false,
            image: 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/microwave.png',
        },
        {
            label: 'BBQ',
            value: false,
            image: 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/BBQ.png',
        },
        {
            label: 'Kitchenette',
            value: false,
            image: 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/kitchenette.png',
        },
        {
            label: 'Television',
            value: false,
            image: 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/television.png',
        },
        {
            label: 'Balcony',
            value: false,
            image: 'https://tf-qa-assets.s3-ap-southeast-2.amazonaws.com/amenities-icons/balcony.png',
        },
    ],
    TICKET_STATES: [
        {
            title:"Open",
            id:1,
            color: 'lightgreen'
        },
        {
           title:"In Progress",
           id:2,
           color: 'lightred'
        },
        {
            title:"Resolved",
            id:3,
            color: 'lightred'
        },
        {
            title:"Closed",
            id:4,
            color: 'lightred'
        }
    ],
    PRIORITY_LEVELS: [
        {
            title:"Low",
            id:1
        },
        {
           title:"Medium",
           id:2
        },
        {
            title:"High",
            id:3
        },
        {
            title:"Urgent",
            id:4
        }
    ],

}