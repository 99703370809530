import React, { Component } from 'react'
import $api from "../../utils/axios";
//import Autocomplete from "@mui/material/Autocomplete";
import moment from 'moment-timezone';
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import { toast } from 'react-toastify';
import TextField from "@mui/material/TextField";
import Dropzone from "react-dropzone";
import DOMPurify from 'dompurify';
import { TICKET_STATES } from '../../constants';
import {
  MenuItem,
  Select,
  InputLabel,
  FormControl
} from "@mui/material";

const acceptedFileTypes = 'image/jpeg, image/png'


export default class ViewMyTicket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hotelMultiImageSrc: [],
      data:{},
      contactDescription: '',
      hotelMultiImageSrc: [],
      hotelMultiImage: [],
      ticketStatus: '',
      listTicketDiscussion:{}
    };
  }

  async componentDidMount() {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    const listTicketDiscussion = await $api.post("/ticket/listTicketDiscussion",{ticketId: params.get('ticketId')});
    this.setState({
      listTicketDiscussion: listTicketDiscussion.data.ticket,
    });
    const resHotel = await $api.post("/department/listDepartment");

    console.log('fetchhhhh',listTicketDiscussion.data.ticket);
    this.setState({
        hotelOptions: resHotel.data.department,
        hotelSelectName: resHotel.data.department[0].name,
        hotelSelectId: resHotel.data.department[0]._id
    })

    $api.get(`ticket/get-ticket-by-id?ticketId=${params.get('ticketId')}`).then(response => {
      console.log(response.data.data,"resData")
      this.setState({
        data: response.data.data,
        ticketStatus: response.data.data.status,
        // hotelMultiImageSrc:[{ImageUri:response.data.data.file.replace('//','/')}]
      });
    })
      .catch(error => {
        this.setState({
          error: error.message
        });
      });
  }

  handleEditorChange = (event, editor) => {
    const data = editor.getData();
    this.setState({
        contactDescription: data
    })
}

handleFilterStatusChange = async (event, selectedOption) => {
  console.log('mmmmmmmmmmmmmmmmmm',selectedOption);
  this.setState({
    //ticketStatus: selectedOption ? selectedOption.title : this.state.ticketStatus,
    ticketStatus: selectedOption.props.value ? selectedOption.props.value : this.state.ticketStatus,
})

}

submitFunction = async (e) => {
  e.preventDefault();

  const { hotelSelectName, hotelSelectId, contactTitle, contactDescription,priorityLevels,data } = this.state;
  /*if(this.state.hotelMultiImage.length === 0) {
      e.preventDefault();
  } else {*/
  //for (let i = 0; i <= this.state.hotelMultiImage.length - 1; i++) {
  var hotelPhotosForm = new FormData();
  hotelPhotosForm.append('ticketId', data._id);
  hotelPhotosForm.append('message', contactDescription);
  hotelPhotosForm.append('ticketStatus', this.state.ticketStatus);

  if (this.state.hotelMultiImage.length > 0) {
      hotelPhotosForm.append('discussionImage', this.state.hotelMultiImage[0]);
  }
  //}
  /*let parameters = {
      "title": contactTitle,
      "message": contactDescription,
      "departmentId": hotelSelectId,
  };*/
  console.log('subbbbbbbb');
  console.log(this.state.hotelMultiImage[0]);
  console.log(hotelPhotosForm);
  await axios.post(`${process.env.REACT_APP_API_URL}/ticket/manageTicket`, hotelPhotosForm, {
      headers: {
          'Content-type': 'application/json',
      },
  })
      .then(hotelResponse => {
         
          toast.success('Post added regarding this ticket Successfully', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });
          this.props.history.push('/all-mytickets')
          /*if (hotelResponse.status === "201" || hotelResponse.status === 201) {

              for (let i = 0; i <= this.state.hotelMultiImage.length - 1; i++) {
                  var hotelPhotosForm = new FormData();
                  hotelPhotosForm.append('photos', this.state.hotelMultiImage[i], this.state.hotelMultiImage[i].name);

                  await axios({
                      method: 'post',
                      url: `${process.env.REACT_APP_API_URL}/tickets/${hotelResponse.data._id}/ticketimages`,
                      data: hotelPhotosForm,
                      headers: {
                          Accept: 'application/json',
                          'Content-Type': 'multipart/form-data'
                      }
                  })
                  .then(managerResponse => {
                      toast.success('Ticket Added Successfully', {
                          position: "top-right",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                      });
                  })
                  .catch(error => {
                      console.log(error);
                  })
              }

              
          }*/
      })
      .catch(err => {
          toast.error(err, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });
      });
  //}
}

  render() {
    const { history } = this.props
    const { hotelMultiImageSrc,data, contactDescription, ticketStatus, listTicketDiscussion } = this.state;
    return (
      <>
        <div className="header">
          <h2><strong>Manage</strong> Tickets</h2>
          <div className="breadcrumb-wrapper">
            <ol className="breadcrumb">
              <li><a href={() => null} onClick={() => history.push("/dashboard")}>HelpDesk</a>
              </li>
              <li><a href={() => null} onClick={() => history.push("/all-mytickets")}>Ticket Management</a>
              </li>
              <li className="active">View Ticket</li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <form onSubmit={this.submitFunction}>
            <div className="panel">
              <div className="panel-header ">
                <h3><strong>View Ticket</strong> </h3>
              </div>
              <div className="panel-content">
                <div className="form-group">
                  <h2><strong>{data?.title}</strong></h2>
                  <h6><strong>{moment(data.createdAt)
                                .tz("Asia/Kolkata")
                                .format("MMM D YYYY hh:mm A")}</strong></h6>
                  <p style={{marginTop: '25px'}} dangerouslySetInnerHTML={{ __html: data?.message }}></p>
                  {(data?.ticketImage && <img src={data?.ticketImage} style={{ height: 200, width: '50%', marginTop: '15px' }} alt={data?.ticketImage} />)}
                </div>
                <div className="form-group">
                {
                                            listTicketDiscussion && Array.isArray(listTicketDiscussion) && listTicketDiscussion.map((data, index) => {
                                                return (
                                                    data && <tr key={index}>
                                                        <td style={{padding: '10px'}}>
                                                         <h5><strong>{`${data?.department_info?.name || ''} ${data.user_info?.firstName}`}: </strong></h5>
                                                        </td>
                                                        <td style={{padding: '10px'}}><h6><strong>{moment(data.createdAt)
                                .tz("Asia/Kolkata")
                                .format("MMM D YYYY hh:mm A")}</strong></h6>
                                <p dangerouslySetInnerHTML={{ __html: data?.message }}></p></td>
                                                    </tr>
                                                )
                                            })
                                        }
                </div>
                <div className="form-group">
                  <CKEditor
                    className="summernote bg-white"
                    editor={ClassicEditor}
                    data={contactDescription}
                    onInit={editor => {
                      editor.editing.view.change(writer => {
                        writer.setStyle(
                          "height",
                          "300px",
                          editor.editing.view.document.getRoot()
                        );
                      });
                    }}
                    onChange={this.handleEditorChange}
                  />
                </div>
              </div>
              {
                /*
              <div className="panel-content">
                <h5><strong>PHOTOS</strong></h5>
                <hr />
                <div className="form-group">
                  <div style={{ border: "1px solid #ccc", padding: 20, height: '550px', overflowY: 'scroll', overflowX: 'hidden' }}>
                        <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 mb-4"style={{ textAlign: 'center', padding: 10 }}>
                                  <img src={data?.ticketImage} style={{ height: 200, width: '100%' }} alt={data?.ticketImage} />
                                </div>
                        </div>
                      </div>
                </div>
              </div>
              */
              }
              <div className="panel-content">
                                <div className="form-group">
                                  {/*
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={TICKET_STATES}
                                            getOptionLabel={(TICKET_STATES) => TICKET_STATES.title}
                                            sx={{ width: 300, backgroundColor: ticketStatus === 'Open'? 'green' : 'red' }}
                                            onChange={this.handleFilterStatusChange.bind(this)}
                                            renderInput={(params) => <TextField {...params} label={ticketStatus} />
                                            
                                        
                                        }
                                        />
                                        */}
                                                              <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={ticketStatus || null}
                                                                        sx={{ width: 300, backgroundColor: ticketStatus === 'Open'? '#ff6666' : ticketStatus === 'In Progress'? 'orange' : ticketStatus === 'Resolved'? 'yellow' : 'lightgreen' }}
                                                                        onChange={this.handleFilterStatusChange.bind(this)}
                                                                    >
                                                                        {
                                                                            TICKET_STATES.map((item, index) => <MenuItem value={item.title} key={index}>{item.title}</MenuItem>)
                                                                        }
                                                                        {/* <MenuItem value={"OPEN"}>Open</MenuItem>
                                                                        <MenuItem value={"PENDING"}>Pending</MenuItem> */}

                                                                    </Select>
                                </div>
                                </div>
              <div className="panel-content">
              <button type="submit" className="btn btn-embossed btn-danger">Send</button>
              <button type="button" className="cancel btn btn-embossed btn-primary m-b-10 m-r-0" onClick={() => history.push("/all-mytickets")}>Cancel</button>
              </div>
            </div>
            </form>
          </div>
        </div>
      </>
    )
  }
}


