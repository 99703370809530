import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import DataTable from "../../components/DataTable";

const columns = [
    {
        name: 'PROPERTY ID',
        selector: row => row.missingPropertyId,
        sortable: true,
    },
    {
        name: 'PROPERTY NAME',
        selector: row => row.name,
        sortable: true,
    },
];

export default class ReportMissingPropertyID extends Component {
    state = {
        checked: false,
        loading: true,
        editID: false,
        records: [],
        updateRecords: [],
        propIDArray: [],
    }

    handleChange(checked) {
        this.setState({ checked });
    }

    componentDidMount() {
        this.getPropertyList();
    }

    async getPropertyList() {
        let token = await localStorage.getItem('token');
        await axios.get(`${process.env.REACT_APP_API_URL}/hotel/list`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then(response => {
            const array = [];
            response.data.forEach(element => {
                if(!element.propertyId){
                    array.push({
                        missingPropertyId: this.state.editID ? 
                            <div className="text-center">
                                <input type="number" name={element._id} className="form-control" placeholder="Property Id" aria-required="true" onChange={(this.onChange)} />
                            </div>
                            :
                            element.propertyId,
                        name: element.name,
                        hotelId: element._id,
                    })
                }
            });
            this.setState({
                records: array,
                loading: false
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    onChange = (e) => {
        const {name, value} = e.target;
        let data = this.state.updateRecords;
        let propIDArray = this.state.propIDArray;
        if(!propIDArray.includes(name)){
            propIDArray.push(name);
            let propertyName = this.state.records.filter(element => {
                return element.hotelId === name;
            });
            data.push({
                hotelId : name,
                propertyId: value,
                propertyName: propertyName[0].name          
            })
        } else {
            let findIndex = propIDArray.indexOf(name);
            data[findIndex].propertyId = value
        }
        this.setState({
            propIDArray,
            updateRecords: data
        })
    }

    async updateChanges(){
        const {updateRecords} = this.state;
        if(updateRecords.length > 0) {
            let token = await localStorage.getItem('token');
            await axios.post(`${process.env.REACT_APP_API_URL}/property/addNew`, updateRecords , {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            })
            .then(response => {
                if(response.status === 200){
                    toast.success('Changes Saved Successfully', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.getPropertyList();
                }
            })
            .catch(error => {
                toast.error('Something went wrong', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                console.log(error);
            })
        } else {
            this.getPropertyList();
        }
    }

    render() {
        const { history } = this.props
        const { loading, records, editID } = this.state;
        return (
            <div>
                <div className="header">
                    <h2><strong>Reporting</strong></h2>
                    <div className="breadcrumb-wrapper">
                        <ol className="breadcrumb">
                            <li>
                                <a href={() => null} onClick={() => history.push("/dashboard")}>TimeFlyz</a>
                            </li>
                            <li className="active">Report Listing</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="panel">
                            <div className="panel-header ">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                                        <h3><strong>Missing Property ID Listing</strong> </h3>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" style={{ textAlign: 'end' }}>
                                        {
                                            editID ? 
                                            <button className="btn btn-embossed btn-danger" onClick={() => this.setState({ editID : false }, () => this.updateChanges())}>Save Changes</button>
                                            :
                                            <button className="btn btn-embossed btn-primary" onClick={() => this.setState({ editID : true }, () => this.getPropertyList())}>Edit</button>
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="panel-content pagination2 table-responsive">
                                <DataTable columns={columns} data={records} loading={loading} filterDefaultText="Filter By ID, Name" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}