import React, { Component } from "react";
import searchBannerImage from "../../assets/images/download.jpg";
import Dropzone from "react-dropzone";
import axios from "axios";
import { toast } from 'react-toastify';

const Imagemaxsize = 10000000000;
const acceptedFileTypes = 'image/jpeg, image/png'
const acceptedFiletypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() });

export default class ContentManage extends Component {

    state = {
        searchSectionId: '',
        searchSectionTitle: '',
        beenefitsSectionTitle1: '',
        beenefitsSectionDescription1: '',
        beenefitsSectionTitle2: '',
        beenefitsSectionDescription2: '',
        beenefitsSectionTitle3: '',
        beenefitsSectionDescription3: '',
        rtsId1: '',
        rtsImageUri1: '',
        rtsTitle1: '',
        rtsDescription1: '',
        rtsId2: '',
        rtsImageUri2: '',
        rtsTitle2: '',
        rtsDescription2: '',
        rtsId3: '',
        rtsImageUri3: '',
        rtsTitle3: '',
        rtsDescription3: '',
        enjoyMicrostaySectionId: '',
        enjoyMicrostaySectionTitle: '',

        searchSectionTitleError: '',
        beenefitsSectionTitle1Error: '',
        beenefitsSectionDescription1Error: '',
        beenefitsSectionTitle2Error: '',
        beenefitsSectionDescription2Error: '',
        beenefitsSectionTitle3Error: '',
        beenefitsSectionDescription3Error: '',
        rtsTitle1Error: '',
        rtsDescription1Error: '',
        rtsTitle2Error: '',
        rtsDescription2Error: '',
        rtsTitle3Error: '',
        rtsDescription3Error: '',
        enjoyMicrostaySectionTitleError: '',

        searchMultiImageSrc: [],
        searchMultiImage: [],
        enjoyMicrostayMultiImageSrc: [],
        enjoyMicrostayMultiImage: [],
        beenefitsSectionImage1Src: [],
        beenefitsSectionImage1: [],
        beenefitsSectionImage2Src: [],
        beenefitsSectionImage2: [],
        beenefitsSectionImage3Src: [],
        beenefitsSectionImage3: [],
        rtsImage1Src: [],
        rtsImage1: [],
        rtsImage2Src: [],
        rtsImage2: [],
        rtsImage3Src: [],
        rtsImage3: [],
        imgSrc: [],
        originalImage: [],

        searchSection: [],
        benefitsSection1: [],
        benefitsSection2: [],
        benefitsSection3: [],
        reasonForTravelSection1: [],
        reasonForTravelSection2: [],
        reasonForTravelSection3: [],
        enjoyMicrostaySection: []

    }

    componentDidMount(){
        axios.post(`${process.env.REACT_APP_API_URL}/cms/list`, {tag: 'SEARCH BAR SECTION'})
        .then(response => {
            this.setState({
                searchSectionId: response.data[0]._id,
                searchSectionTitle: response.data[0].title,
                searchSection: response.data[0].photos,
            })
        })
        .catch(error => {
            console.log(error);
        })

        axios.post(`${process.env.REACT_APP_API_URL}/cms/list`, {tag: 'BENEFITS SECTION'})
        .then(response => {
            this.setState({
                beenefitsSectionId1: response.data[0]._id,
                beenefitsSectionTitle1: response.data[0].title,
                beenefitsSectionDescription1: response.data[0].description,
                beenefitsSectionImageUri1: response.data[0].photos,
                beenefitsSectionImage1Src: response.data[0].photos,
                beenefitsSectionId2: response.data[1]._id,
                beenefitsSectionTitle2: response.data[1].title,
                beenefitsSectionDescription2: response.data[1].description,
                beenefitsSectionImageUri2: response.data[1].photos,
                beenefitsSectionImage2Src: response.data[1].photos,
                beenefitsSectionId3: response.data[2]._id,
                beenefitsSectionTitle3: response.data[2].title,
                beenefitsSectionDescription3: response.data[2].description,
                beenefitsSectionImageUri3: response.data[2].photos,
                beenefitsSectionImage3Src: response.data[2].photos,
            })
        })
        .catch(error => {
            console.log(error);
        })

        axios.post(`${process.env.REACT_APP_API_URL}/cms/list`, {tag: 'REASON FOR TRAVEL SECTION'})
        .then(response => {
            this.setState({
                rtsId1: response.data[0]._id,
                rtsImageUri1: response.data[0].photos,
                rtsTitle1: response.data[0].title,
                rtsImage1Src: response.data[0].photos,
                rtsDescription1: response.data[0].description,
                rtsId2: response.data[1]._id,
                rtsImageUri2: response.data[1].photos,
                rtsTitle2: response.data[1].title,
                rtsImage2Src: response.data[1].photos,
                rtsDescription2: response.data[1].description,
                rtsId3: response.data[2]._id,
                rtsImageUri3: response.data[2].photos,
                rtsTitle3: response.data[2].title,
                rtsImage3Src: response.data[2].photos,
                rtsDescription3: response.data[2].description,
            })
        })
        .catch(error => {
            console.log(error);
        })

        axios.post(`${process.env.REACT_APP_API_URL}/cms/list`, {tag: 'ENJOY MICROSTAY SECTION'})
        .then(response => {
            this.setState({
                enjoyMicrostaySectionId: response.data[0]._id,
                enjoyMicrostaySectionTitle: response.data[0].title,
                enjoyMicrostaySection: response.data[0].photos
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    verifyFile = (files) => {
        if (files && files.length > 0) {
            const currentFile = files[0];
            const currentFileType = currentFile.type
            const currentFileSize = currentFile.size
            if (currentFileSize > Imagemaxsize) {
                alert("This File Is Too Big")
                return false
            }

            if (!acceptedFiletypesArray.includes(currentFileType)) {
                alert("This File Is Not Supported")
                return false
            }
            return true
        }
    }

    onHandleondrop = (files, rejectedFiles, e) => {
        if (rejectedFiles && rejectedFiles.length > 0) {
            this.verifyFile(rejectedFiles)
        }

        if (files && files.length > 0) {
            const isVerified = this.verifyFile(files)
            if (isVerified) {
                const currentFile = Array.from(e.target.files);

                Promise.all(currentFile.map(file => {
                    return (new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.addEventListener('load', (ev) => {
                            resolve(ev.target.result);
                        });
                        reader.addEventListener('error', reject);
                        reader.readAsDataURL(file);
                    }));
                }))
                    .then(images => {
                        /* Once all promises are resolved, update state with image URI array */
                        this.setState({ searchMultiImageSrc: images, searchMultiImage: e.target.files })
                    }, error => {
                        console.log(error);
                    });
            }
        }
    }

    onHandleondropEnjoyMicrostay = (files, rejectedFiles, e) => {
        if (rejectedFiles && rejectedFiles.length > 0) {
            this.verifyFile(rejectedFiles)
        }

        if (files && files.length > 0) {
            const isVerified = this.verifyFile(files)
            if (isVerified) {
                const currentFile = Array.from(e.target.files);

                Promise.all(currentFile.map(file => {
                    return (new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.addEventListener('load', (ev) => {
                            resolve(ev.target.result);
                        });
                        reader.addEventListener('error', reject);
                        reader.readAsDataURL(file);
                    }));
                }))
                    .then(images => {
                        /* Once all promises are resolved, update state with image URI array */
                        this.setState({ enjoyMicrostayMultiImageSrc: images, enjoyMicrostayMultiImage: e.target.files })
                    }, error => {
                        console.log(error);
                    });
            }
        }
    }

    handleChange = (e) => {
        const { name, files } = e.target;
        if (files && files.length > 0) {
            const isVerified = this.verifyFile(files)
            if (isVerified) {
                const currentFile = Array.from(files);


                Promise.all(currentFile.map(file => {
                    return (new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.addEventListener('load', (ev) => {
                            resolve(ev.target.result);
                        });
                        reader.addEventListener('error', reject);
                        reader.readAsDataURL(file);
                    }));
                }))
                    .then(images => {
                        /* Once all promises are resolved, update state with image URI array */
                        if (name === 'beenefitsSectionImage1') {
                            this.setState({ beenefitsSectionImage1Src: images, [name]: files })
                        }
                        if (name === 'beenefitsSectionImage2') {
                            this.setState({ beenefitsSectionImage2Src: images, [name]: files })
                        }
                        if (name === 'beenefitsSectionImage3') {
                            this.setState({ beenefitsSectionImage3Src: images, [name]: files })
                        }

                        if (name === 'rtsImage1') {
                            this.setState({ rtsImage1Src: images, [name]: files })
                        }
                        if (name === 'rtsImage2') {
                            this.setState({ rtsImage2rc: images, [name]: files })
                        }
                        if (name === 'rtsImage3') {
                            this.setState({ rtsImage3Src: images, [name]: files })
                        }

                    }, error => {
                        console.log(error);
                    });
            }
        }
    }

    handleRemoveimage = (index, name, src) => () => {
        let imgSrc = [];
        let originalImage = [];
        if (name === 'searchMultiImage') {
            imgSrc = [...this.state.searchMultiImageSrc];
            originalImage = [...this.state.searchMultiImage];
        }
        if (name === 'beenefitsSectionImage1') {
            imgSrc = [...this.state.beenefitsSectionImage1Src];
            originalImage = [...this.state.beenefitsSectionImage1];
        }
        if (name === 'beenefitsSectionImage2') {
            imgSrc = [...this.state.beenefitsSectionImage2Src];
            originalImage = [...this.state.beenefitsSectionImage2];
        }
        if (name === 'beenefitsSectionImage3') {
            imgSrc = [...this.state.beenefitsSectionImage3Src];
            originalImage = [...this.state.beenefitsSectionImage3];
        }
        if (name === 'rtsImage1') {
            imgSrc = [...this.state.rtsImage1Src];
            originalImage = [...this.state.rtsImage1];
        }
        if (name === 'rtsImage2') {
            imgSrc = [...this.state.rtsImage2Src];
            originalImage = [...this.state.rtsImage2];
        }
        if (name === 'rtsImage3') {
            imgSrc = [...this.state.rtsImage3Src];
            originalImage = [...this.state.rtsImage3];
        }
        if (name === 'enjoyMicrostayMultiImage') {
            imgSrc = [...this.state.enjoyMicrostayMultiImageSrc];
            originalImage = [...this.state.enjoyMicrostayMultiImage];
        }

        imgSrc.splice(index, 1);
        originalImage.splice(index, 1);

        if (name === 'searchMultiImage') {
            this.setState({ searchMultiImageSrc: imgSrc, searchMultiImage: originalImage });
        }
        if (name === 'beenefitsSectionImage1') {
            this.setState({ beenefitsSectionImage1Src: imgSrc, beenefitsSectionImage1: originalImage });
        }
        if (name === 'beenefitsSectionImage2') {
            this.setState({ beenefitsSectionImage2Src: imgSrc, beenefitsSectionImage2: originalImage });
        }
        if (name === 'beenefitsSectionImage3') {
            this.setState({ beenefitsSectionImage3Src: imgSrc, beenefitsSectionImage3: originalImage });
        }
        if (name === 'rtsImage1') {
            this.setState({ rtsImage1Src: imgSrc, rtsImage1: originalImage });
        }
        if (name === 'rtsImage2') {
            this.setState({ rtsImage2Src: imgSrc, rtsImage2: originalImage });
        }
        if (name === 'rtsImage3') {
            this.setState({ rtsImage3Src: imgSrc, rtsImage3: originalImage });
        }
        if (name === 'enjoyMicrostayMultiImage') {
            this.setState({ enjoyMicrostayMultiImageSrc: imgSrc, enjoyMicrostayMultiImage: originalImage });
        }
    }

    onChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    validate = () => {
        let searchSectionTitleError = '';
        let beenefitsSectionTitle1Error = '';
        let beenefitsSectionDescription1Error = '';
        let beenefitsSectionTitle2Error = '';
        let beenefitsSectionDescription2Error = '';
        let beenefitsSectionTitle3Error = '';
        let beenefitsSectionDescription3Error = '';
        let rtsTitle1Error = '';
        let rtsDescription1Error = '';
        let rtsTitle2Error = '';
        let rtsDescription2Error = '';
        let rtsTitle3Error = '';
        let rtsDescription3Error = '';
        let enjoyMicrostaySectionTitleError = '';

        if (this.state.searchSectionTitle === '') {
            searchSectionTitleError = "Please Enter Title"
        }
        if (searchSectionTitleError) {
            this.setState({ searchSectionTitleError })
            return false
        }

        if (this.state.beenefitsSectionTitle1 === '') {
            beenefitsSectionTitle1Error = "Please Enter Title"
        }
        if (beenefitsSectionTitle1Error) {
            this.setState({ beenefitsSectionTitle1Error })
            return false
        }

        if (this.state.beenefitsSectionDescription1 === '') {
            beenefitsSectionDescription1Error = "Please Enter Description"
        }
        if (beenefitsSectionDescription1Error) {
            this.setState({ beenefitsSectionDescription1Error })
            return false
        }

        if (this.state.beenefitsSectionTitle2 === '') {
            beenefitsSectionTitle2Error = "Please Enter Title"
        }
        if (beenefitsSectionTitle2Error) {
            this.setState({ beenefitsSectionTitle2Error })
            return false
        }

        if (this.state.beenefitsSectionDescription2 === '') {
            beenefitsSectionDescription2Error = "Please Enter Description"
        }
        if (beenefitsSectionDescription2Error) {
            this.setState({ beenefitsSectionDescription2Error })
            return false
        }

        if (this.state.beenefitsSectionTitle3 === '') {
            beenefitsSectionTitle3Error = "Please Enter Title"
        }
        if (beenefitsSectionTitle3Error) {
            this.setState({ beenefitsSectionTitle3Error })
            return false
        }

        if (this.state.beenefitsSectionDescription3 === '') {
            beenefitsSectionDescription3Error = "Please Enter Description"
        }
        if (beenefitsSectionDescription3Error) {
            this.setState({ beenefitsSectionDescription3Error })
            return false
        }

        if (this.state.rtsTitle1 === '') {
            rtsTitle1Error = "Please Enter Title"
        }
        if (rtsTitle1Error) {
            this.setState({ rtsTitle1Error })
            return false
        }

        if (this.state.rtsDescription1 === '') {
            rtsDescription1Error = "Please Enter Description"
        }
        if (rtsDescription1Error) {
            this.setState({ rtsDescription1Error })
            return false
        }

        if (this.state.rtsTitle2 === '') {
            rtsTitle2Error = "Please Enter Title"
        }
        if (rtsTitle2Error) {
            this.setState({ rtsTitle2Error })
            return false
        }

        if (this.state.rtsDescription2 === '') {
            rtsDescription2Error = "Please Enter Description"
        }
        if (rtsDescription2Error) {
            this.setState({ rtsDescription2Error })
            return false
        }

        if (this.state.rtsTitle3 === '') {
            rtsTitle3Error = "Please Enter Title"
        }
        if (rtsTitle3Error) {
            this.setState({ rtsTitle3Error })
            return false
        }

        if (this.state.rtsDescription3 === '') {
            rtsDescription3Error = "Please Enter Description"
        }
        if (rtsDescription3Error) {
            this.setState({ rtsDescription3Error })
            return false
        }

        if (this.state.enjoyMicrostaySectionTitle === '') {
            enjoyMicrostaySectionTitleError = "Please Enter Title"
        }
        if (enjoyMicrostaySectionTitleError) {
            this.setState({ enjoyMicrostaySectionTitleError })
            return false
        }

        return true
    }

    deleteImage = async (name, Id) => {
        let token = await localStorage.getItem('token');
        await axios.put(`${process.env.REACT_APP_API_URL}/cms/remove/${Id}`, { "photos": name }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(response => {
                if (response.data === 'OK') {
                    toast.success('Image deleted Successfully', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    this.componentDidMount();
                } else {
                    toast.error('Image deletion failed', {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    updateSearchData = async (token) => {
        const { searchSectionId, searchSectionTitle, searchMultiImage } = this.state;

        if (searchMultiImage.length > 0) {
            for (let i = 0; i <= searchMultiImage.length - 1; i++) {
                var searchSectionForm = new FormData();
                searchSectionForm.set('title', searchSectionTitle);
                searchSectionForm.append('photos', searchMultiImage[i]);

                await axios({
                    method: 'put',
                    url: `${process.env.REACT_APP_API_URL}/cms/update/${searchSectionId}`,
                    data: searchSectionForm,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .catch(error => {
                        console.log(error);
                    })
            }
        } else {
            var searchSectionFormWithoutImage = new FormData();
            searchSectionFormWithoutImage.set('title', searchSectionTitle);

            await axios({
                method: 'put',
                url: `${process.env.REACT_APP_API_URL}/cms/update/${searchSectionId}`,
                data: searchSectionFormWithoutImage,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
                .catch(error => {
                    console.log(error);
                })
        }

    }

    updateBenefitsData = async (token) => {
        const { beenefitsSectionId1, beenefitsSectionTitle1, beenefitsSectionDescription1, beenefitsSectionImage1, beenefitsSectionId2, beenefitsSectionTitle2, beenefitsSectionDescription2, beenefitsSectionImage2, beenefitsSectionId3, beenefitsSectionTitle3, beenefitsSectionDescription3, beenefitsSectionImage3 } = this.state;

        var benefitsSectionForm1 = new FormData();
        benefitsSectionForm1.set('title', beenefitsSectionTitle1);
        benefitsSectionForm1.set('description', beenefitsSectionDescription1);
        benefitsSectionForm1.append('photos', beenefitsSectionImage1[0]);

        var benefitsSectionForm2 = new FormData();
        benefitsSectionForm2.set('title', beenefitsSectionTitle2);
        benefitsSectionForm2.set('description', beenefitsSectionDescription2);
        benefitsSectionForm2.append('photos', beenefitsSectionImage2[0]);

        var benefitsSectionForm3 = new FormData();
        benefitsSectionForm3.set('title', beenefitsSectionTitle3);
        benefitsSectionForm3.set('description', beenefitsSectionDescription3);
        benefitsSectionForm3.append('photos', beenefitsSectionImage3[0]);

        await axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}/cms/update/${beenefitsSectionId1}`,
            data: benefitsSectionForm1,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .catch(error => {
                console.log(error);
            })

        await axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}/cms/update/${beenefitsSectionId2}`,
            data: benefitsSectionForm2,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .catch(error => {
                console.log(error);
            })

        await axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}/cms/update/${beenefitsSectionId3}`,
            data: benefitsSectionForm3,
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
            .catch(error => {
                console.log(error);
            })
    }

    updateReasonForTravelData = async (token) => {
        const { rtsId1, rtsImage1, rtsTitle1, rtsDescription1, rtsId2, rtsImage2, rtsTitle2, rtsDescription2, rtsId3, rtsImage3, rtsTitle3, rtsDescription3 } = this.state;

        var rtsSectionForm1 = new FormData();
        rtsSectionForm1.set('title', rtsTitle1);
        rtsSectionForm1.set('description', rtsDescription1);
        rtsSectionForm1.append('photos', rtsImage1[0]);

        var rtsSectionForm2 = new FormData();
        rtsSectionForm2.set('title', rtsTitle2);
        rtsSectionForm2.set('description', rtsDescription2);
        rtsSectionForm2.append('photos', rtsImage2[0]);

        var rtsSectionForm3 = new FormData();
        rtsSectionForm3.set('title', rtsTitle3);
        rtsSectionForm3.set('description', rtsDescription3);
        rtsSectionForm3.append('photos', rtsImage3[0]);

        await axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}/cms/update/${rtsId1}`,
            data: rtsSectionForm1,
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
            .catch(error => {
                console.log(error);
            })

        await axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}/cms/update/${rtsId2}`,
            data: rtsSectionForm2,
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
            .catch(error => {
                console.log(error);
            })

        await axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}/cms/update/${rtsId3}`,
            data: rtsSectionForm3,
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
            }
        })
            .catch(error => {
                console.log(error);
            })
    }

    updateEnjoyMicroStayData = async (token) => {
        const { enjoyMicrostaySectionId, enjoyMicrostaySectionTitle, enjoyMicrostayMultiImage } = this.state;

        if (enjoyMicrostayMultiImage.length > 0) {

            for (let i = 0; i <= enjoyMicrostayMultiImage.length - 1; i++) {
                var enjoyMicroStaySectionForm = new FormData();
                enjoyMicroStaySectionForm.set('title', enjoyMicrostaySectionTitle);
                enjoyMicroStaySectionForm.append('photos', enjoyMicrostayMultiImage[i]);

                await axios({
                    method: 'put',
                    url: `${process.env.REACT_APP_API_URL}/cms/update/${enjoyMicrostaySectionId}`,
                    data: enjoyMicroStaySectionForm,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(response => {
                        if (response.data === 'OK') {
                            toast.success('Contents Updated Successfully', {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        } else {
                            toast.error('Contents Update Failed', {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
        } else {
            var enjoyMicroStaySectionFormWithoutImage = new FormData();
            enjoyMicroStaySectionFormWithoutImage.set('title', enjoyMicrostaySectionTitle);

            await axios({
                method: 'put',
                url: `${process.env.REACT_APP_API_URL}/cms/update/${enjoyMicrostaySectionId}`,
                data: enjoyMicroStaySectionFormWithoutImage,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            })
                .then(response => {
                    if (response.data === 'OK') {
                        toast.success('Contents Updated Successfully', {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        }

    }

    onUpdateContent = async (e) => {
        e.preventDefault();
        const isValid = this.validate();
        if (isValid) {

            let token = await localStorage.getItem('token');
            // SEARCH SECTION STARTS 
            await this.updateSearchData(token);

            // BENEFITS SECTION STARTS 
            await this.updateBenefitsData(token);

            // REASON FOR TRAVEL SECTIONS STARTS
            await this.updateReasonForTravelData(token);

            // ENJOY MICROSTAY SECTIONS STARTS
            await this.updateEnjoyMicroStayData(token);
        }
    }

    render() {
        const { history } = this.props
        const { searchMultiImageSrc, searchSection, enjoyMicrostaySection, beenefitsSectionImage1Src, beenefitsSectionImage2Src, beenefitsSectionImage3Src, rtsImage1Src, rtsImage2Src, rtsImage3Src, enjoyMicrostayMultiImageSrc, searchSectionId, searchSectionTitle, beenefitsSectionId1, beenefitsSectionImageUri1, beenefitsSectionTitle1, beenefitsSectionDescription1, beenefitsSectionId2, beenefitsSectionImageUri2, beenefitsSectionTitle2, beenefitsSectionDescription2, beenefitsSectionId3, beenefitsSectionImageUri3, beenefitsSectionTitle3, beenefitsSectionDescription3, rtsId1, rtsImageUri1, rtsTitle1, rtsDescription1, rtsId2, rtsImageUri2, rtsTitle2, rtsDescription2, rtsId3, rtsImageUri3, rtsTitle3, rtsDescription3, enjoyMicrostaySectionId, enjoyMicrostaySectionTitle, searchSectionTitleError, beenefitsSectionTitle1Error, beenefitsSectionDescription1Error, beenefitsSectionTitle2Error, beenefitsSectionDescription2Error, beenefitsSectionTitle3Error, beenefitsSectionDescription3Error, rtsTitle1Error, rtsDescription1Error, rtsTitle2Error, rtsDescription2Error, rtsTitle3Error, rtsDescription3Error, enjoyMicrostaySectionTitleError } = this.state;

        return (
            <div>
                <div className="header">
                    <h2><strong>Website</strong> Homepage</h2>
                    <div className="breadcrumb-wrapper">
                        <ol className="breadcrumb">
                            <li>
                                <a href={() => null} onClick={() => history.push("/dashboard")}>TimeFlyz</a>
                            </li>
                            <li className="active">Homepage</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 portlets">
                        <form onSubmit={this.onUpdateContent}>
                            <div className="panel">
                                <div className="panel-content" style={styles.borderOutside}>
                                    <div className="panel-controls">
                                        <h3 className="bold">Search bar section</h3>
                                    </div>
                                    <div className="form-group">
                                        <h5>Title</h5>
                                        <input type="text" name="searchSectionTitle" className="form-control" placeholder="Enter the title here" value={searchSectionTitle} aria-required="true" onChange={this.onChange} />
                                        {searchSectionTitleError ? (<div className="text-danger">{searchSectionTitleError}</div>) : null}
                                    </div>
                                    <div className="form-group">
                                        <h5>Banner Image</h5>
                                        <Dropzone onDrop={this.onHandleondrop.bind(this)} maxSize={Imagemaxsize} accept={acceptedFileTypes} multiple={true}>
                                            {({ getRootProps, getInputProps }) => (
                                                <section style={{ border: "2px dashed #1663ff", minHeight: "auto", padding: "10px", textAlign: "center" }}>

                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} name="searchMultiImage" />
                                                        <label style={{ marginTop: 25 }}>
                                                            <div className="dz-message ">
                                                                <i className="ti-plus text-primary font-26 " />
                                                                <div className="mt-2 font-15 mb-4" style={{ color: '#0463a7' }}>Click To Upload Images Here</div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                        {
                                            searchMultiImageSrc.length > 0 || searchSection.length > 0 ?
                                                <div style={{ border: "1px solid #ccc", padding: "10px" }}>
                                                    <div className="row">
                                                        {
                                                            searchMultiImageSrc && searchMultiImageSrc.map((ImageUri, index) => {
                                                                return (
                                                                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb-4" key={index} style={{ textAlign: 'center', padding: 10 }}>
                                                                        <img src={ImageUri} style={{ width: '100%', }} alt={ImageUri} />
                                                                        <button className="btn btn-sm btn-danger btn-block" style={{ marginLeft: 0, marginTop: 10 }} onClick={this.handleRemoveimage(index, "searchMultiImage", "searchMultiImageSrc")}>Delete</button>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            searchSection.map((ImageUri, index) => {
                                                                return (
                                                                    <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb-4" key={index} style={{ textAlign: 'center', padding: 10 }}>
                                                                        <img src={ImageUri} style={{ width: '100%' }} alt={ImageUri} />
                                                                        <button className="btn btn-sm btn-danger btn-block" style={{ marginLeft: 0, marginTop: 10 }} onClick={() => this.deleteImage(ImageUri, searchSectionId)}>Delete</button>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                this.state.imageError ? (<div className="text-danger">{this.state.imageError}</div>) : null
                                        }
                                    </div>
                                </div>

                                <div className="panel-content" style={styles.borderOutside}>
                                    <div className="panel-controls">
                                        <h3 className="bold">Benefits Section</h3>
                                    </div>
                                    <div className="form-group">
                                        <h5>Title</h5>
                                        <input type="text" name="beenefitsSectionTitle1" className="form-control" placeholder="Enter the title here" value={beenefitsSectionTitle1} aria-required="true" onChange={this.onChange} />
                                        {beenefitsSectionTitle1Error ? (<div className="text-danger">{beenefitsSectionTitle1Error}</div>) : null}
                                    </div>
                                    <div className="form-group">
                                        <h5>Description</h5>
                                        <input type="text" name="beenefitsSectionDescription1" className="form-control" placeholder="Enter the description here" value={beenefitsSectionDescription1} aria-required="true" onChange={this.onChange} />
                                        {beenefitsSectionDescription1Error ? (<div className="text-danger">{beenefitsSectionDescription1Error}</div>) : null}
                                    </div>
                                    <div className="form-group">
                                        <h5>Banner Icon</h5>
                                        <div className="file">
                                            <div className="option-group">
                                                <input type="file" className="custom-file" name="beenefitsSectionImage1" onChange={this.handleChange.bind(this)} required="" aria-required="true" />
                                            </div>
                                        </div>
                                        <div style={{ border: "1px solid #ccc", padding: "10px" }}>
                                            <div className="row">
                                                {
                                                    beenefitsSectionImage1Src.length > 0 ?
                                                        beenefitsSectionImage1Src.map((ImageUri, index) => {
                                                            return (
                                                                <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb-4" key={index} style={{ textAlign: 'center', padding: 10 }}>
                                                                    <img src={ImageUri} alt="banner-images" style={{ width: '20%' }} />
                                                                    {
                                                                        beenefitsSectionImageUri1 !== '' ?
                                                                            <button className="btn btn-sm btn-danger btn-block" style={{ marginLeft: 0, marginTop: 10 }} onClick={() => this.deleteImage(ImageUri, beenefitsSectionId1)}>Delete</button>
                                                                            :
                                                                            <button className="btn btn-sm btn-danger btn-block" style={{ marginLeft: 0, marginTop: 10 }} onClick={this.handleRemoveimage(index, "beenefitsSectionImage1", "beenefitsSectionImage1Src")}>Delete</button>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        <div className="col-lg-3 mb-3" style={{ textAlign: 'center' }}>
                                                            <img src={searchBannerImage} alt="banner-images" style={{ width: 200 }} />
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="form-group">
                                        <h5>Title</h5>
                                        <input type="text" name="beenefitsSectionTitle2" className="form-control" placeholder="Enter the title here" value={beenefitsSectionTitle2} aria-required="true" onChange={this.onChange} />
                                        {beenefitsSectionTitle2Error ? (<div className="text-danger">{beenefitsSectionTitle2Error}</div>) : null}
                                    </div>
                                    <div className="form-group">
                                        <h5>Description</h5>
                                        <input type="text" name="beenefitsSectionDescription2" className="form-control" placeholder="Enter the description here" value={beenefitsSectionDescription2} aria-required="true" onChange={this.onChange} />
                                        {beenefitsSectionDescription2Error ? (<div className="text-danger">{beenefitsSectionDescription2Error}</div>) : null}
                                    </div>
                                    <div className="form-group">
                                        <h5>Banner Icon</h5>
                                        <div className="file">
                                            <div className="option-group">
                                                <input type="file" className="custom-file" name="beenefitsSectionImage2" onChange={this.handleChange.bind(this)} required="" aria-required="true" />
                                            </div>
                                        </div>
                                        <div style={{ border: "1px solid #ccc", padding: "10px" }}>
                                            <div className="row">
                                                {
                                                    beenefitsSectionImage2Src.length > 0 ?
                                                        beenefitsSectionImage2Src.map((ImageUri, index) => {
                                                            return (
                                                                <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb-4" key={index} style={{ textAlign: 'center', padding: 10 }}>
                                                                    <img src={ImageUri} alt="banner-images" style={{ width: '20%' }} />
                                                                    {
                                                                        beenefitsSectionImageUri2 !== '' ?
                                                                            <button className="btn btn-sm btn-danger btn-block" style={{ marginLeft: 0, marginTop: 10 }} onClick={() => this.deleteImage(ImageUri, beenefitsSectionId2)}>Delete</button>
                                                                            :
                                                                            <button className="btn btn-sm btn-danger btn-block" style={{ marginLeft: 0, marginTop: 10 }} onClick={this.handleRemoveimage(index, "beenefitsSectionImage2", "beenefitsSectionImage2Src")}>Delete</button>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        <div className="col-lg-3 mb-3" style={{ textAlign: 'center' }}>
                                                            <img src={searchBannerImage} alt="banner-images" style={{ width: 200 }} />
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="form-group">
                                        <h5>Title</h5>
                                        <input type="text" name="beenefitsSectionTitle3" className="form-control" placeholder="Enter the title here" value={beenefitsSectionTitle3} aria-required="true" onChange={this.onChange} />
                                        {beenefitsSectionTitle3Error ? (<div className="text-danger">{beenefitsSectionTitle3Error}</div>) : null}
                                    </div>
                                    <div className="form-group">
                                        <h5>Description</h5>
                                        <input type="text" name="beenefitsSectionDescription3" className="form-control" placeholder="Enter the description here" value={beenefitsSectionDescription3} aria-required="true" onChange={this.onChange} />
                                        {beenefitsSectionDescription3Error ? (<div className="text-danger">{beenefitsSectionDescription3Error}</div>) : null}
                                    </div>
                                    <div className="form-group">
                                        <h5>Banner Icon</h5>
                                        <div className="file">
                                            <div className="option-group">
                                                <input type="file" className="custom-file" name="beenefitsSectionImage3" onChange={this.handleChange.bind(this)} required="" aria-required="true" />
                                            </div>
                                        </div>
                                        <div style={{ border: "1px solid #ccc", padding: "10px" }}>
                                            <div className="row">
                                                {
                                                    beenefitsSectionImage3Src.length > 0 ?
                                                        beenefitsSectionImage3Src.map((ImageUri, index) => {
                                                            return (
                                                                <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb-4" key={index} style={{ textAlign: 'center', padding: 10 }}>
                                                                    <img src={ImageUri} alt="banner-images" style={{ width: '20%' }} />
                                                                    {
                                                                        beenefitsSectionImageUri3 !== '' ?
                                                                            <button className="btn btn-sm btn-danger btn-block" style={{ marginLeft: 0, marginTop: 10 }} onClick={() => this.deleteImage(ImageUri, beenefitsSectionId3)}>Delete</button>
                                                                            :
                                                                            <button className="btn btn-sm btn-danger btn-block" style={{ marginLeft: 0, marginTop: 10 }} onClick={this.handleRemoveimage(index, "beenefitsSectionImage3", "beenefitsSectionImage3Src")}>Delete</button>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        <div className="col-lg-3 mb-3" style={{ textAlign: 'center' }}>
                                                            <img src={searchBannerImage} alt="banner-images" style={{ width: 200 }} />
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="panel-content" style={styles.borderOutside}>
                                    <div className="panel-controls">
                                        <h3 className="bold">Reason for travel section</h3>
                                    </div>
                                    <div className="form-group">
                                        <h5>Title</h5>
                                        <input type="text" name="rtsTitle1" className="form-control" placeholder="Enter the title here" required="" value={rtsTitle1} aria-required="true" onChange={this.onChange} />
                                        {rtsTitle1Error ? (<div className="text-danger">{rtsTitle1Error}</div>) : null}
                                    </div>
                                    <div className="form-group">
                                        <h5>Description</h5>
                                        <input type="text" name="rtsDescription1" className="form-control" placeholder="Enter the description here" value={rtsDescription1} required="" aria-required="true" onChange={this.onChange} />
                                        {rtsDescription1Error ? (<div className="text-danger">{rtsDescription1Error}</div>) : null}
                                    </div>
                                    <div className="form-group">
                                        <h5>Banner Image</h5>
                                        <div className="file">
                                            <div className="option-group">
                                                <input type="file" className="custom-file" name="rtsImage1" onChange={this.handleChange.bind(this)} required="" aria-required="true" />
                                            </div>
                                        </div>
                                        <div style={{ border: "1px solid #ccc", padding: "10px" }}>
                                            <div className="row">
                                                {
                                                    rtsImage1Src.length > 0 ?
                                                        rtsImage1Src.map((ImageUri, index) => {
                                                            return (
                                                                <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb-4" key={index} style={{ textAlign: 'center', padding: 10 }}>
                                                                    <img src={ImageUri} alt="banner-images" style={{ width: '100%' }} />
                                                                    {
                                                                        rtsImageUri1 !== '' ?
                                                                            <button className="btn btn-sm btn-danger btn-block" style={{ marginLeft: 0, marginTop: 10 }} onClick={() => this.deleteImage(ImageUri, rtsId1)}>Delete</button>
                                                                            :
                                                                            <button className="btn btn-sm btn-danger btn-block" style={{ marginLeft: 0, marginTop: 10 }} onClick={this.handleRemoveimage(index, "rtsImage1", "rtsImage1Src")}>Delete</button>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        <div className="col-lg-3 mb-3" style={{ textAlign: 'center' }}>
                                                            <img src={searchBannerImage} alt="banner-images" style={{ width: 200 }} />
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="form-group">
                                        <h5>Title</h5>
                                        <input type="text" name="rtsTitle2" className="form-control" placeholder="Enter the title here" required="" value={rtsTitle2} aria-required="true" onChange={this.onChange} />
                                        {rtsTitle2Error ? (<div className="text-danger">{rtsTitle2Error}</div>) : null}
                                    </div>
                                    <div className="form-group">
                                        <h5>Description</h5>
                                        <input type="text" name="rtsDescription2" className="form-control" placeholder="Enter the description here" required="" value={rtsDescription2} aria-required="true" onChange={this.onChange} />
                                        {rtsDescription2Error ? (<div className="text-danger">{rtsDescription2Error}</div>) : null}
                                    </div>
                                    <div className="form-group">
                                        <h5>Banner Image</h5>
                                        <div className="file">
                                            <div className="option-group">
                                                <input type="file" className="custom-file" name="rtsImage2" onChange={this.handleChange.bind(this)} required="" aria-required="true" />
                                            </div>
                                        </div>
                                        <div style={{ border: "1px solid #ccc", padding: "10px" }}>
                                            <div className="row">
                                                {
                                                    rtsImage2Src.length > 0 ?
                                                        rtsImage2Src.map((ImageUri, index) => {
                                                            return (
                                                                <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb-4" key={index} style={{ textAlign: 'center', padding: 10 }}>
                                                                    <img src={ImageUri} alt="banner-images" style={{ width: '100%' }} />
                                                                    {
                                                                        rtsImageUri2 !== '' ?
                                                                            <button className="btn btn-sm btn-danger btn-block" style={{ marginLeft: 0, marginTop: 10 }} onClick={() => this.deleteImage(ImageUri, rtsId2)}>Delete</button>
                                                                            :
                                                                            <button className="btn btn-sm btn-danger btn-block" style={{ marginLeft: 0, marginTop: 10 }} onClick={this.handleRemoveimage(index, "rtsImage2", "rtsImage2Src")}>Delete</button>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        <div className="col-lg-3 mb-3" style={{ textAlign: 'center' }}>
                                                            <img src={searchBannerImage} alt="banner-images" style={{ width: 200 }} />
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="form-group">
                                        <h5>Title</h5>
                                        <input type="text" name="rtsTitle3" className="form-control" placeholder="Enter the title here" required="" value={rtsTitle3} aria-required="true" onChange={this.onChange} />
                                        {rtsTitle3Error ? (<div className="text-danger">{rtsTitle3Error}</div>) : null}
                                    </div>
                                    <div className="form-group">
                                        <h5>Description</h5>
                                        <input type="text" name="rtsDescription3" className="form-control" placeholder="Enter the description here" required="" value={rtsDescription3} aria-required="true" onChange={this.onChange} />
                                        {rtsDescription3Error ? (<div className="text-danger">{rtsDescription3Error}</div>) : null}
                                    </div>
                                    <div className="form-group">
                                        <h5>Banner Image</h5>
                                        <div className="file">
                                            <div className="option-group">
                                                <input type="file" className="custom-file" name="rtsImage3" onChange={this.handleChange.bind(this)} required="" aria-required="true" />
                                            </div>
                                        </div>
                                        <div style={{ border: "1px solid #ccc", padding: "10px" }}>
                                            <div className="row">
                                                {
                                                    rtsImage3Src.length > 0 ?
                                                        rtsImage3Src.map((ImageUri, index) => {
                                                            return (
                                                                <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 mb-4" key={index} style={{ textAlign: 'center', padding: 10 }}>
                                                                    <img src={ImageUri} alt="banner-images" style={{ width: '100%' }} />
                                                                    {
                                                                        rtsImageUri3 !== '' ?
                                                                            <button className="btn btn-sm btn-danger btn-block" style={{ marginLeft: 0, marginTop: 10 }} onClick={() => this.deleteImage(ImageUri, rtsId3)}>Delete</button>
                                                                            :
                                                                            <button className="btn btn-sm btn-danger btn-block" style={{ marginLeft: 0, marginTop: 10 }} onClick={this.handleRemoveimage(index, "rtsImage3", "rtsImage3Src")}>Delete</button>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        <div className="col-lg-3 mb-3" style={{ textAlign: 'center' }}>
                                                            <img src={searchBannerImage} alt="banner-images" style={{ width: 200 }} />
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="panel-content" style={styles.borderOutside}>
                                    <div className="panel-controls">
                                        <h3 className="bold">Enjoy Microstay Section</h3>
                                    </div>
                                    <div className="form-group">
                                        <h5>Title</h5>
                                        <input type="text" name="enjoyMicrostaySectionTitle" className="form-control" placeholder="Enter the title here" value={enjoyMicrostaySectionTitle} required="" aria-required="true" onChange={this.onChange} />
                                        {enjoyMicrostaySectionTitleError ? (<div className="text-danger">{enjoyMicrostaySectionTitleError}</div>) : null}
                                    </div>
                                    <div className="form-group">
                                        <h5>Banner Image</h5>
                                        <Dropzone onDrop={this.onHandleondropEnjoyMicrostay.bind(this)} maxSize={Imagemaxsize} accept={acceptedFileTypes} multiple={true}>
                                            {({ getRootProps, getInputProps }) => (

                                                <section style={{ border: "2px dashed #1663ff", minHeight: "auto", padding: "10px", textAlign: "center" }}>

                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} name="enjoyMicrostayMultiImage" />
                                                        <label style={{ marginTop: 25 }}>
                                                            <div className="dz-message ">
                                                                <i className="ti-plus text-primary font-26 " />
                                                                <div className="mt-2 font-15 mb-4" style={{ color: '#0463a7' }}>Click To Upload Images Here</div>
                                                            </div>
                                                        </label>

                                                    </div>

                                                </section>
                                            )}
                                        </Dropzone>
                                        {
                                            enjoyMicrostayMultiImageSrc.length > 0 || enjoyMicrostaySection.length > 0 ?
                                                <div style={{ border: "1px solid #ccc", padding: "10px" }}>
                                                    <div className="row">
                                                        {
                                                            enjoyMicrostayMultiImageSrc.length > 0 && enjoyMicrostayMultiImageSrc.map((ImageUri, index) => {
                                                                return (
                                                                    <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-4" key={index} style={{ textAlign: 'center', padding: 10 }}>
                                                                        <img src={ImageUri} style={{ width: '100%', }} alt={ImageUri} />
                                                                        <button className="btn btn-sm btn-danger btn-block" style={{ marginLeft: 0, marginTop: 10 }} onClick={this.handleRemoveimage(index, "enjoyMicrostayMultiImage", "enjoyMicrostayMultiImageSrc")}>Delete</button>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            enjoyMicrostaySection.map((ImageUri, index) => {
                                                                return (
                                                                    <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-4" key={index} style={{ textAlign: 'center', padding: 10 }}>
                                                                        <img src={ImageUri} style={{ width: '100%', }} alt={ImageUri} />
                                                                        <button className="btn btn-sm btn-danger btn-block" style={{ marginLeft: 0, marginTop: 10 }} onClick={() => this.deleteImage(ImageUri, enjoyMicrostaySectionId)}>Delete</button>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                this.state.imageError ? (<div className="text-danger">{this.state.imageError}</div>) : null
                                        }
                                    </div>
                                    <hr />
                                </div>
                                <div className="text-center  m-t-20">
                                    <button type="submit" className="btn btn-embossed btn-danger" onClick={this.onUpdateContent}>Update</button>
                                    <button type="reset" className="cancel btn btn-embossed btn-primary m-b-10 m-r-0">Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

var styles = {
    borderOutside: {
        border: "1px solid #ccc",
        margin: 20
    },
}