import $api from "../../utils/axios";
import React, { Component } from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import axios from "axios";
import { toast } from 'react-toastify';
import 'rc-checkbox/assets/index.css';
import Dropzone from "react-dropzone";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { PRIORITY_LEVELS } from '../../constants';

const Imagemaxsize = 1024 * 1024;
const acceptedFileTypes = 'image/jpeg, image/png'
const acceptedFiletypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() });

export default class AddTicket extends Component {
    state = {
        fullName: '',
        email: '',
        password: '',
        hotelName: '',
        hotelDescription: '',
        microStay: [],
        hotelRating: '',
        streetAddress: '',
        city: '',
        country: '',
        postalCode: '',
        state: '',
        commRate: 0,

        Amenities: [],

        sameOwnerContact: false,
        sameOwnerManager: false,

        contactFullName: '',
        contactFirstName: '',
        contactLastName: '',
        contactPosition: '',
        contactNumber: '',
        contactEmail: '',

        managerFullName: '',
        managerFirstName: '',
        managerLastName: '',
        managerPosition: '',
        managerNumber: '',
        managerEmail: '',

        fullNameError: '',
        emailError: '',
        passwordError: '',

        propertyID: '',

        hotelMultiImageSrc: [],
        hotelMultiImage: [],
        hotelOptions: [],
        hotelSelectName: '',
        hotelSelectId: '',
        contactTitle: '',
        contactDescription: '',
        priorityLevels:'Low'
    }
    async componentDidMount() {
        let tokenLoad = localStorage.getItem("token");
        const resHotel = await $api.post("/department/listDepartment");
        // console.log('aaaaaaaaaaaaaa');
        // console.log(resHotel.data);
        this.setState({
            hotelOptions: resHotel.data.department,
            hotelSelectName: resHotel.data.department[0].name,
            hotelSelectId: resHotel.data.department[0]._id
        })
        await axios
            .get(`${process.env.REACT_APP_API_URL}/user/me`, {
                headers: {
                    Authorization: `Bearer ${tokenLoad}`,
                },
            })
            .then((response) => {
                this.setState({
                    email: response.data.email,
                    fullName: `${response.data.firstName} ${response.data.lastName}`
                });

            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleOptionChange = async (event, selectedOption) => {
        this.setState({
            hotelSelectName: selectedOption ? selectedOption.name : '',
            hotelSelectId: selectedOption ? selectedOption._id : ''
        })
        //setCurrentTab(res.data[0]._id);
    };

    handlePriorityLevelsChange = async (event, selectedOption) => {
        this.setState({
            priorityLevels: selectedOption? selectedOption?.title:''
        })
        //setCurrentTab(res.data[0]._id);
    };

    onChange = async (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }

    onAmenitiesChange = (e) => {
        const { value } = e.target;
        let amenitiesArray = this.state.Amenities;

        if (!amenitiesArray.includes(value)) {
            amenitiesArray.push(value)
        } else {
            var index = amenitiesArray.indexOf(value)
            if (index !== -1) {
                amenitiesArray.splice(index, 1);
            }
        }

        this.setState({
            Amenities: amenitiesArray
        })
    }

    onContactChange = (e) => {
        const { checked } = e.target;
        if (checked) {
            this.setState({
                sameOwnerContact: true
            })
        } else {
            this.setState({
                sameOwnerContact: false
            })
        }
    }

    onManagerChange = (e) => {
        const { checked } = e.target;
        if (checked) {
            this.setState({
                sameOwnerManager: true
            })
        } else {
            this.setState({
                sameOwnerManager: false
            })
        }
    }

    validate = () => {
        this.setState({
            fullNameError: '',
            emailError: '',
            passwordError: ''
        })
        const regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

        let fullNameError = '';
        let emailError = '';
        let passwordError = '';

        if (this.state.fullName === '') {
            fullNameError = "Please Enter Full Name"
        }
        if (fullNameError) {
            this.setState({ fullNameError })
            return false
        }

        if (this.state.email === '') {
            emailError = "Please Enter Email"
        } else if (!regex.test(this.state.email.trim())) {
            emailError = "Please enter a valid Email Address"
        }
        if (emailError) {
            this.setState({ emailError })
            return false
        }

        if (this.state.password === '') {
            passwordError = "Please Enter Password"
        }
        if (passwordError) {
            this.setState({ passwordError })
            return false
        }
        return true
    }

    changeRating = (val) => {
        this.setState({
            hotelRating: val
        })
    }

    handleEditorChange = (event, editor) => {
        const data = editor.getData();
        this.setState({
            contactDescription: data
        })
    }

    verifyFile = (files) => {
        if (files && files.length > 0) {
            const currentFile = files[0];
            const currentFileType = currentFile.type
            const currentFileSize = currentFile.size
            if (currentFileSize > Imagemaxsize) {
                //alert("This File Is Too Big")
                return false
            }

            if (!acceptedFiletypesArray.includes(currentFileType)) {
                //alert("This File Is Not Supported")
                return false
            }
            return true
        }
    }

    onHandleondrop = (files, rejectedFiles, e) => {
        if (rejectedFiles && rejectedFiles.length > 0) {
            this.verifyFile(rejectedFiles)
        }

        if (files && files.length > 0) {
            const isVerified = this.verifyFile(files)
            if (isVerified) {
                const currentFile = Array.from(e.target.files);

                Promise.all(currentFile.map(file => {
                    return (new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.addEventListener('load', (ev) => {
                            resolve(ev.target.result);
                        });
                        reader.addEventListener('error', reject);
                        reader.readAsDataURL(file);
                    }));
                }))
                    .then(images => {
                        /* Once all promises are resolved, update state with image URI array */
                        this.setState({ hotelMultiImageSrc: images, hotelMultiImage: e.target.files })
                    }, error => {
                        console.log(error);
                    });
            } else {
                toast.error("Please upload file within 1 MB", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    }

    handleRemoveimage = (index, name, src) => () => {
        let imgSrc = [];
        let originalImage = [];
        if (name === 'hotelMultiImage') {
            imgSrc = [...this.state.hotelMultiImageSrc];
            originalImage = [...this.state.hotelMultiImage];
        }

        imgSrc.splice(index, 1);
        originalImage.splice(index, 1);

        if (name === 'searchMultiImage') {
            this.setState({ hotelMultiImageSrc: imgSrc, hotelMultiImage: originalImage });
        }
    }

    microStay = (e) => {
        const { value } = e.target;
        let microStayArray = this.state.microStay;

        if (!microStayArray.includes(value)) {
            microStayArray.push(value)
        } else {
            var index = microStayArray.indexOf(value)
            if (index !== -1) {
                microStayArray.splice(index, 1);
            }
        }

        this.setState({
            microStay: microStayArray
        })
    }

    handleChange = address => {
        this.setState({ streetAddress: address });
    };

    renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions }) => (
        <div className="autocomplete-root">
            <input {...getInputProps()} className="form-control" placeholder="Enter the street address here" />
            <div className="autocomplete-dropdown-container">
                {suggestions.map((suggestion, index) => (
                    <option {...getSuggestionItemProps(suggestion)} key={index}>
                        {suggestion.description}
                    </option>
                ))}
            </div>
        </div>
    )

    submitFunction = async (e) => {
        e.preventDefault();

        const { hotelSelectName, hotelSelectId, contactTitle, contactDescription,priorityLevels } = this.state;
        /*if(this.state.hotelMultiImage.length === 0) {
            e.preventDefault();
        } else {*/
        //for (let i = 0; i <= this.state.hotelMultiImage.length - 1; i++) {
        var hotelPhotosForm = new FormData();
        hotelPhotosForm.append('title', contactTitle);
        hotelPhotosForm.append('message', contactDescription);
        hotelPhotosForm.append('departmentId', hotelSelectId);
        hotelPhotosForm.append('priorityLevels', priorityLevels);
        if (this.state.hotelMultiImage.length > 0) {
            hotelPhotosForm.append('ticketImage', this.state.hotelMultiImage[0]);
        }
        //}
        /*let parameters = {
            "title": contactTitle,
            "message": contactDescription,
            "departmentId": hotelSelectId,
        };*/
        console.log('subbbbbbbb');
        console.log(this.state.hotelMultiImage[0]);
        console.log(hotelPhotosForm);
        await axios.post(`${process.env.REACT_APP_API_URL}/ticket/createTicket`, hotelPhotosForm, {
            headers: {
                'Content-type': 'application/json',
            },
        })
            .then(hotelResponse => {
               
                toast.success('Ticket Added Successfully', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.history.push('/add-ticket')
                /*if (hotelResponse.status === "201" || hotelResponse.status === 201) {

                    for (let i = 0; i <= this.state.hotelMultiImage.length - 1; i++) {
                        var hotelPhotosForm = new FormData();
                        hotelPhotosForm.append('photos', this.state.hotelMultiImage[i], this.state.hotelMultiImage[i].name);

                        await axios({
                            method: 'post',
                            url: `${process.env.REACT_APP_API_URL}/tickets/${hotelResponse.data._id}/ticketimages`,
                            data: hotelPhotosForm,
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then(managerResponse => {
                            toast.success('Ticket Added Successfully', {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        })
                        .catch(error => {
                            console.log(error);
                        })
                    }

                    
                }*/
            })
            .catch(err => {
                toast.error(err, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        //}
    }

    render() {
        const { history } = this.props
        const { hotelMultiImageSrc, contactTitle, contactDescription } = this.state;
        return (
            <div>
                <div className="header">
                    <h2><strong>Manage</strong> Tickets</h2>
                    <div className="breadcrumb-wrapper">
                        <ol className="breadcrumb">
                            <li><a href={() => null} onClick={() => history.push("/dashboard")}>HelpDesk</a>
                            </li>
                            <li><a href={() => null} onClick={() => history.push("/all-tickets")}>Ticket Management</a>
                            </li>
                            <li className="active">Add Ticket</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                        <form onSubmit={this.submitFunction}>
                            <div className="panel">
                                <div className="panel-header ">
                                    <h3><strong>Add Ticket</strong> </h3>
                                </div>
                                <div className="panel-content">
                                    <div className="form-group">
                                        <h5>Select Department</h5>
                                        <hr />
                                        <div className="form-group">
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={this.state.hotelOptions}
                                                getOptionLabel={(option) => option.name}
                                                sx={{ width: 300 }}
                                                onChange={this.handleOptionChange}
                                                renderInput={(params) => <TextField {...params} label={this.state.hotelSelectName} />}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <h5>Title</h5>
                                        <input type="text" name="contactTitle" className="form-control" placeholder="Enter the title here" aria-required="true" onChange={this.onChange} defaultValue={contactTitle} />
                                    </div>
                                    <div className="form-group">
                                        <h5>Description</h5>
                                        <CKEditor
                                            className="summernote bg-white"
                                            editor={ClassicEditor}
                                            data={contactDescription}
                                            onInit={editor => {
                                                editor.editing.view.change(writer => {
                                                    writer.setStyle(
                                                        "height",
                                                        "300px",
                                                        editor.editing.view.document.getRoot()
                                                    );
                                                });
                                            }}
                                            onChange={this.handleEditorChange}
                                        />
                                    </div>
                                </div>
                                <div className="panel-content">
                                    <h5><strong>PHOTOS</strong></h5>
                                    <hr />
                                    <div className="form-group">
                                        <Dropzone onDrop={this.onHandleondrop.bind(this)} accept={acceptedFileTypes} multiple={false}>
                                            {({ getRootProps, getInputProps }) => (
                                                <section style={{ border: "2px dashed #ef4a23", minHeight: "auto", padding: "10px", textAlign: "center" }}>

                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} name="hotelPhotos" />
                                                        <label style={{ marginTop: 25 }}>
                                                            <div className="dz-message ">
                                                                <i className="ti-plus text-primary font-26 " />
                                                                <div className="mt-2 font-15 mb-4" style={{ color: '#474949' }}>Click To Upload Images Here</div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                        {
                                            hotelMultiImageSrc.length > 0 ?
                                                <div style={{ border: "1px solid #ccc", padding: 20, height: '550px', overflowY: 'scroll', overflowX: 'hidden' }}>
                                                    <div className="row">
                                                        {
                                                            hotelMultiImageSrc && hotelMultiImageSrc.map((ImageUri, index) => {
                                                                return (
                                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 mb-4" key={index} style={{ textAlign: 'center', padding: 10 }}>
                                                                        <img src={ImageUri} style={{ height: 200, width: '100%' }} alt={ImageUri} />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                this.state.imageError || hotelMultiImageSrc.length === 0 ? (<div className="text-danger" color="red">*Please upload valid image and image max size should be 1 MB, e.g. 1800 x 1200</div>) : null
                                        }
                                    </div>
                                </div>
                                <div className="panel-content">
                                    <div className="form-group">
                                        <h5>Priority Levels</h5>
                                        {/* <hr /> */}
                                        <div className="form-group">
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={PRIORITY_LEVELS}
                                                getOptionLabel={(PRIORITY_LEVELS) => PRIORITY_LEVELS.title}
                                                sx={{ width: 300 }}
                                                onChange={this.handlePriorityLevelsChange}
                                                renderInput={(params) => <TextField {...params} label={this.state.priorityLevels} />}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="text-center  m-t-20">
                                    <button type="submit" className="btn btn-embossed btn-danger">Submit</button>
                                    <button type="button" className="cancel btn btn-embossed btn-primary m-b-10 m-r-0" onClick={() => history.push("/all-tickets")}>Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
