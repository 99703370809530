import React, { Component } from "react";
import $api from "../../utils/axios";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Pagination from "react-js-pagination";
import Autocomplete from "@mui/material/Autocomplete";
import { jwtDecode } from "jwt-decode";
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import {
    MenuItem,
    Select,
    InputLabel,
    FormControl
} from "@mui/material";

import { TICKET_STATES } from '../../constants';

export default class InvoiceListing extends Component {
    state = {
        checked: false,
        propertyArray: [],
        currentList: [],
        pageLimit: 10,
        pageCount: 0,
        totalCount: 0,
        showingFrom: 0,
        showingTo: 0,
        propertyId: '',
        propertyName: '',
        options: [],
        hotelname: '',
        ticketText: '',
        addedAtBeginning: false,
        statusTicket: 'View All',
        statusOptions: [{title:"View All",id:0},...TICKET_STATES],
        departmentType: ''
        // statusOptions: ['View All', 'Open', 'Pending', 'Close']
    }
    async componentDidMount() {
        let token = await localStorage.getItem('token');
        this.getDepartments();
        this.getBillingData(token);
        this.getRole();
        //this.getPropertyName(propertyId,token);
    }
    getDepartments = async () => {
        const resHotel = await $api.post("/department/listDepartment");
        // console.log(resHotel,"Ffff")
        // return;
        const newElement = [{ _id: '0', name: 'View All' }, ...resHotel.data.department];
        //resHotel.data.data.unshift({_id: '0', name: 'View All'});
        if (!this.state.addedAtBeginning) {
            //setOptions(newElement);
            //setAddedAtBeginning(true); // Mark that the operation has been done
            this.setState({
                options: newElement,
                addedAtBeginning: true
            });
        }
        if (this.state.options && this.state.addedAtBeginning === true) {
            //const res = await $api.get(`/tickets/${options[0]._id}`);}
            const res = await $api.post(`/ticket/listTicketDepartment`, {
                role: ''
            });
      
            //setRows(res.data.ticket);
            //setHotelname(options[0].name);
            this.setState({
                currentList: res.data.ticket.slice(0, this.state.pageLimit),
                hotelname: this.state.options[0].name
            });
            this.setState({
                options: newElement,
                addedAtBeginning: true
            });
        }
        // console.log('bbbbbbbbbbbbbbbbbbbbb');
        // console.log(this.state.options);      
    }

    getBillingData = async (token) => {
        await axios.post(`${process.env.REACT_APP_API_URL}/ticket/listTicketDepartment`, { role: "" }, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
            .then(response => {
                console.log(response)
                const data = response.data.ticket.filter(res => res)
                data.sort((a, b) => b.created - a.created)

                this.setState({
                    propertyArray: data,
                    pageCount: Math.ceil(data.length / this.state.pageLimit),
                    totalCount: data.length,
                    currentList: data.slice(0, this.state.pageLimit),
                    showingFrom: data.length > 0 ? 1 : 0,
                    showingTo: data.length > 10 ? 10 : data.length
                })
            })
            .catch(error => {
                console.log(error);
            })
    }

    handleOptionChange = async (event, selectedOption) => {
        // console.log(selectedOption)
        // return;
        // if (selectedOption !== null) {
        //     let resnew = await $api.get(`/tickets/${selectedOption._id}`);
        //     //setRows(resnew.data);
        //     this.setState({
        //         currentList: resnew.data.slice(0, this.state.pageLimit),
        //     });
        //     if (resnew.data.length === 0) {
        //         //setticketText('Sorry, no records found.');
        //         this.setState({
        //             ticketText: 'Sorry, no records found.',
        //         });
        //     }
        // }
        // if (selectedOption === null) {
        //     let resnew = await $api.get(`/tickets/0`);
        //     this.setState({
        //         currentList: resnew.data.slice(0, this.state.pageLimit),
        //     });
        //     if (resnew.data.length === 0) {
        //         this.setState({
        //             ticketText: 'Sorry, no records found.',
        //         });
        //     }
        // }
        // setCurrentTab(res.data[0]._id);

        // console.log(selectedOption.name)
        const res = await $api.post(`/ticket/listTicketDepartment`, {
            
            role: selectedOption.name === 'View All' ? '' : selectedOption.name,
            status:this.state.statusTicket === 'View All' ? '' : this.state.statusTicket
        });
        /*this.setState({
            currentList: res.data.ticket.slice(0, this.state.pageLimit),
            hotelname: selectedOption.name
        });*/
        this.setState({
            propertyArray: res.data.ticket,
            pageCount: Math.ceil(res.data.ticket.length / this.state.pageLimit),
            totalCount: res.data.ticket.length,
            currentList: res.data.ticket.slice(0, this.state.pageLimit),
            showingFrom: res.data.ticket.length > 0 ? 1 : 0,
            showingTo: res.data.ticket.length > 10 ? 10 : res.data.ticket.length,
            hotelname: selectedOption.name
        })
        //   this.setState({
        //     options: newElement,
        //     addedAtBeginning: true
        //   });

    };

    handleFilterStatusChange = async (event, selectedOption) => {
       try {
        console.log('nnnnnnnnnnnnnnnnnnnnnnnnnnn');
        console.log(selectedOption);
        const res = await $api.post(`/ticket/listTicketDepartment`, {
            status: selectedOption.title === 'View All' ? '' : selectedOption.title,
            role:this.state.hotelname === 'View All'?'':this.state.hotelname
        });
        // console.log(res.data.ticket)
        /*this.setState({
            currentList:  res.data.ticket.slice(0, this.state.pageLimit),
            status:selectedOption.title
            // hotelname: selectedOption
        });*/

        this.setState({
            propertyArray: res.data.ticket,
            pageCount: Math.ceil(res.data.ticket.length / this.state.pageLimit),
            totalCount: res.data.ticket.length,
            currentList: res.data.ticket.slice(0, this.state.pageLimit),
            showingFrom: res.data.ticket.length > 0 ? 1 : 0,
            showingTo: res.data.ticket.length > 10 ? 10 : res.data.ticket.length,
            status: selectedOption.title
        })
       } catch (error) {
          console.log(error,"LLLLLLLLLLLLLLLLLLLLLLLLLLLL")
       }
 
    }

    handleStatusChange = async (data, selectedData, myData) => {

        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/ticket/change-ticket-status`, {
                ticketId: data._id,
                ticketStatus: selectedData.target.value
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                },
            }).then(resFrom => {
                /*const res = await $api.post(`/ticket/listTicket`, {
                    status: selectedData === 'View All' ? '' : selectedData.title,
                    role:this.state.hotelname === 'View All'?'':this.state.hotelname
                });
        
                this.setState({
                    propertyArray: res.data.ticket,
                    pageCount: Math.ceil(res.data.ticket.length / this.state.pageLimit),
                    totalCount: res.data.ticket.length,
                    currentList: res.data.ticket.slice(0, this.state.pageLimit),
                    showingFrom: res.data.ticket.length > 0 ? 1 : 0,
                    showingTo: res.data.ticket.length > 10 ? 10 : res.data.ticket.length
                    //status: selectedData.title
                });*/
                // this.getBillingData(localStorage.getItem('token'));
                toast.success("Ticket status updated successfully!", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                //this.props.history.push("/all-tickets"); 
                window.location.reload();
            })
        } catch (error) {
            console.log(error)
        }

    }

    /*getPropertyName = async(propertyId,token) => {
        await axios.get(`${process.env.REACT_APP_API_URL}/hotel/list`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then(response => {
            let getPropertyDetails = response.data.filter((eachProperty) => {
                return eachProperty._id === propertyId
            })
            this.setState({
                propertyName: getPropertyDetails[0].name
            })
        })
        .catch(error => {
            console.log(error);
        })
    }*/

    handleChange(checked) {
        this.setState({ checked });
    }

    handlePageChange(pageNumber) {
        const { propertyArray, pageLimit } = this.state;
        const offset = (pageNumber - 1) * pageLimit;
        const currentList = propertyArray.slice(offset, offset + pageLimit);
        const showingFrom = offset + 1;
        const showingTo = offset + pageLimit;
        this.setState({ propertyArray, currentList, activePage: pageNumber, showingFrom, showingTo });
    }

    getRole() {
        const token = localStorage.getItem('token');
        const decoded = jwtDecode(token);
        this.state.role = decoded.role;
        this.state.departmentType = localStorage.getItem('departmentType');
        console.log(this.state.departmentType,localStorage.getItem('departmentType'), "??????hhhhhhhhhhhhhhhhhhh")
    }

    render() {
        const { history } = this.props
        const { currentList, activePage, totalCount, pageLimit, showingFrom, showingTo, options, hotelname, statusOptions, propertyArray, statusTicket, departmentType } = this.state;
        return (
            <div>

                <div className="header">
                    <h2><strong>Manage</strong> Tickets {departmentType !== "Employee" && `/${departmentType}`}</h2>
                    <div className="breadcrumb-wrapper">
                        <ol className="breadcrumb">
                            <li>
                                <a href={() => null} onClick={() => history.push("/dashboard")}>HelpDesk</a>
                            </li>
                            <li className="active">Tickets</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="panel">
                            <div className="panel-header ">
                                {<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                    <a href={() => null} onClick={() => history.push("/add-myticket")} className="btn btn-primary" rel="noreferrer"><i className="fa fa-plus c-warning" /> Add Ticket</a>
                                </div>}
                                <div className="row">
                                    {(this.state.role === 'SuperAdmin' || this.state.role === 'Employee') && <div className="autoselect" style={{ marginRight: '5px', position: 'right', marginLeft: '5px', marginTop: '5px' }}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={options}
                                            getOptionLabel={(option) => option.name}
                                            sx={{ width: 300 }}
                                            onChange={this.handleOptionChange.bind(this)}
                                            renderInput={(params) => <TextField {...params} label={hotelname} />}
                                        />
                                    </div>}
                                     
                                     <div className="autoselect" style={{ position: 'right', marginLeft: '5px', marginTop: '5px', marginRight: '5px' }}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={statusOptions}
                                            getOptionLabel={(statusOptions) => statusOptions.title}
                                            sx={{ width: 300 }}
                                            onChange={this.handleFilterStatusChange.bind(this)}
                                            renderInput={(params) => <TextField {...params} label={statusTicket} />
                                            
                                        
                                        }
                                        />
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="panel-content pagination2 table-responsive">
                                <table className="table table-hover table-dynamic dataTable no-footer">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '20%' }}>Ticket Title</th>
                                            <th style={{ width: '20%' }}>Ticket Description</th>
                                            <th style={{ width: '20%' }}>From</th>
                                            <th style={{ width: '25%' }}>Created At</th>
                                            <th style={{ width: '15%' }}>Action</th>
                                            <th style={{ width: '40%' }}>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            currentList.length > 0 && currentList.map((data, index) => {
                                                return (
                                                    data && <tr data-label="Ticket Title" key={index}>
                                                        <td data-label="Ticket Description">{data.title}</td>
                                                        <td data-label="From">{data.message.replace(/<\/?[^>]+(>|$)/g, "").length <= 100 ? data.message.replace(/<\/?[^>]+(>|$)/g, "") : data.message.replace(/<\/?[^>]+(>|$)/g, "").substring(0, 100) + '...'}</td>
                                                        <td data-label="Created At">{`${data.userId.firstName} ${data.userId.lastName}`}</td>
                                                        <td>{moment(data.createdAt)
                                .tz("Asia/Kolkata")
                                .format("MMM D, YYYY h:mm A")}</td>
                                                        <td data-label="Action"><button type="button" className="btn btn-embossed btn-danger" onClick={() => history.push(`/view-myticket?ticketId=${data?._id}`)}>View</button></td>
                                                        {/*(this.state.role === 'Department' || this.state.role === 'SuperAdmin') &&

                                                            <td>
                                                                <FormControl fullWidth>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={data.status}
                                                                        sx={{ backgroundColor: data.status === 'Open'? '#ff6666' : data.status === 'In Progress'? 'orange' : data.status === 'Resolved'? 'yellow' : 'lightgreen'}}
                                                                        onChange={this.handleStatusChange.bind(this, data)}
                                                                    >
                                                                        {
                                                                            TICKET_STATES.map((item, index) => <MenuItem value={item.title} key={index}>{item.title}</MenuItem>)
                                                                        }
                                                                      

                                                                    </Select>
                                                                </FormControl>
                                                            </td>
                                                            */
                                                        }

                                                       
                                                            <td data-label="Status"><button type="button" class="btn btn-embossed" style={{ backgroundColor: data.status === 'Open'? '#ff6666' : data.status === 'In Progress'? 'orange' : data.status === 'Resolved'? 'yellow' : 'lightgreen'}}>{data.status}</button></td>
                                                        


                                                    </tr>
                                                )
                                            })
                                        }
                                        {
                                        currentList.length === 0 && (<tr><td style={{ width: '35%' }}>Sorry, no records found.</td></tr>)
                                        }
                                    </tbody>
                                </table>
                                <div className="row">
                                    <div className="col-md-5" style={{ marginTop: 20 }}>
                                        <div className="dataTables_info" role="status" aria-live="polite">Showing {showingFrom} to {showingTo} of {totalCount} entries</div>
                                    </div>
                                    <div className="col-md-7">
                                        <Pagination
                                            prevPageText='prev'
                                            nextPageText='next'
                                            firstPageText='first'
                                            lastPageText='last'
                                            activePage={activePage}
                                            itemsCountPerPage={pageLimit}
                                            totalItemsCount={totalCount}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange.bind(this)}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}