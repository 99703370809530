import axios from "axios";
import React, { Component } from "react";
import logo from "../../assets/images/TimeFlyz Logo White.png"

export default class Lockscreen extends Component {
    constructor(props){
        super(props);
        this.state = {
            userEmail: '',
            userPassword: '',
            errorMessage: '',
            errorStatus: false
        }
    }

    componentDidMount(){
        localStorage.removeItem('Credentials');
    }

    onChange = (e) => {
        const {name, value} = e.target;
        this.setState({
            [name] : value,
            errorStatus: false
        })
    }

    validate = () => {
        const {userEmail,userPassword} = this.state;
        const regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/

        let isValidated = false;
        let message = '';

        if (userEmail === "") {
            message = "Please enter Your Email Address";
            this.setState({
                errorMessage: message,
                errorStatus: true
            })
        }else if (!regex.test(userEmail.trim())) {
            message = "Please enter a valid Email Address";
            this.setState({
                errorMessage: message,
                errorStatus: true
            })
        }else if (userPassword === "" || userPassword.length < 6) {
            message = "Please enter Your Password, it must be atlest 6 character";
            this.setState({
                errorMessage: message,
                errorStatus: true
            })
        }else{
            isValidated = true
        }

        return isValidated;
    }

    loginFunc = async(e) => {
        e.preventDefault();
        const email = await localStorage.getItem('userEmail');
        this.setState({
            userEmail : email
        })
        const {userEmail, userPassword} = this.state;
        if (this.validate()) {
            let payload = {
                "email": userEmail,
                "password": userPassword
            }
            await axios.post(`${process.env.REACT_APP_API_URL}/user/adminLogin`, payload)
            .then(response => {
                if(response.status === 200){
                    localStorage.setItem('Credentials', JSON.stringify(payload));
                    localStorage.removeItem('userEmail');
                    window.location.href = '/otpVerify';
                }else{
                    this.setState({
                        errorMessage: "Email or Password didn't match. Please try again...",
                        errorStatus: true
                    })
                }
            })
            .catch(error => {
                this.setState({
                    errorMessage: "Email or Password didn't match. Please try again...",
                    errorStatus: true
                })
            })
        }
    }

    render() {
        return (
            <div className="container" id="login-block">
                <div className="account-wall"  style={{height: window.innerHeight, display: "flex", justifyContent: 'center', alignItems: 'center'}}>
                    <form className="form-signin" onSubmit={this.loginFunc}>
                        <img src={logo} className="user-img" alt={logo} style={{height: 60}} />
                        <h2 style={{color: '#fff', textAlign:'center'}}>Welcome back, <strong>Admin</strong>!</h2>
                        <p style={{color: '#fff', textAlign:'center'}}>Enter your password to go to dashboard.</p>
                        {
                            this.state.errorStatus
                            ?
                                <p style={{ color: "red" }}>{this.state.errorMessage}.</p>
                            :
                                null
                        }
                        <div className="append-icon m-b-20">
                            <input type="password" name="userPassword" className="form-control form-white password" placeholder="Password" required onChange={this.onChange}/>
                            <i className="icon-lock" />
                        </div>
                        <button type="submit" id="submit-form" className="btn btn-lg btn-danger btn-block ladda-button" data-style="expand-left">Sign In</button>
                    </form>
                </div>
                <p className="account-copyright">
                </p>
            </div>


        )
    }
}