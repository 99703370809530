import React, { Component } from "react";
//import axios from "axios";

export default class EditProperty extends Component {
    state = {
        propertyId : '',
        propertyName : '',
    }
    render() {
        const { history } = this.props
        return (
            <div>
                <div className="header">
                    <h2><strong>Manage</strong> Properties</h2>
                    <div className="breadcrumb-wrapper">
                        <ol className="breadcrumb">
                            <li>
                                <a href={() => null} onClick={() => history.push("/dashboard")}>TimeFlyz</a>
                            </li>
                            <li>
                                <a href={() => null} onClick={() => history.push("/hotels")}>Property Management</a>
                            </li>
                            <li className="active">Edit Property</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                        <form onSubmit={this.submitFunction}>
                            <div className="panel">
                                <div className="panel-content">
                                    <div className="form-group">
                                        <h5>Property ID</h5>
                                        <input type="text" name="propertyId" className="form-control" placeholder="Enter the property Id here" aria-required="true" onChange={this.onChange} />
                                        {this.state.propertyId ? (<div className="text-danger">{this.state.propertyId}</div>) : null}
                                    </div>
                                    <div className="form-group">
                                        <h5>Property Name</h5>
                                        <input type="text" name="propertyName" className="form-control" placeholder="Enter the property name here" aria-required="true" onChange={this.onChange} />
                                        {this.state.propertyName ? (<div className="text-danger">{this.state.propertyName}</div>) : null}
                                    </div>
                                </div>
                                <div className="text-center  m-t-20">
                                    <button type="submit" className="btn btn-embossed btn-danger">Submit</button>
                                    <button type="reset" className="cancel btn btn-embossed btn-primary m-b-10 m-r-0">Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
