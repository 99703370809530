import Axios from "axios";
import React, { Component } from "react";
import OtpInput from 'react-otp-input';
import logo from "../../assets/images/TimeFlyz Logo White.png"

export default class OTPVerification extends Component {
    constructor(props){
        super(props);
        this.state = {
            otp: '',
            errorMessage: '',
            errorStatus: false,
        }
    }

    async componentDidMount(){
        let userCredential = await localStorage.getItem('Credentials');
        let parseCredential = JSON.parse(userCredential);
        if(parseCredential === null){
            window.location.href = '/';
        }
    }

    handleChange = otp => this.setState({ otp });

    validate = () => {
        const {otp} = this.state;

        let isValidated = false;
        let message = '';

        if (otp === "") {
            message = "Please enter the OTP that is sent to your mobile number";
            this.setState({
                errorMessage: message,
                errorStatus: true
            })
        }else{
            isValidated = true
        }

        return isValidated;
    }

    loginFunc = async(e) => {
        e.preventDefault();
        const {otp} = this.state;
        let userCredential = await localStorage.getItem('Credentials');
        let parseCredential = JSON.parse(userCredential);
        if(parseCredential !== null){
            if (this.validate()) {
                let payload = {
                    "email": parseCredential.email,
                    "otp": otp
                }
                await Axios.post(`${process.env.REACT_APP_API_URL}/user/otpVerification`, payload)
                .then(response => {
                    if(response.status === 200){
                        localStorage.setItem('token', response.data.token);
                        localStorage.removeItem('Credentials');
                        this.props.history.push(`/dashboard`);
                    }else{
                        this.setState({
                            errorMessage: "Email or Password didn't match. Please try again...",
                            errorStatus: true
                        })
                    }
                })
                .catch(error => {
                    console.log(error)
                })
            }
        }else{
            window.location.href = '/'; 
        }
        
    }

    resendOTP = async(e) => {
        e.preventDefault();
        let userCredential = await localStorage.getItem('Credentials');
        let parseCredential = JSON.parse(userCredential);
        if (parseCredential !== null) {
            let payload = {
                "email": parseCredential.email,
                "password": parseCredential.password
            }
            await Axios.post(`${process.env.REACT_APP_API_URL}/user/adminLogin`, payload)
            .then(response => {
                if(response.status === 200){
                    localStorage.setItem('Credentials', JSON.stringify(payload));
                    window.location.href = '/otpVerify';
                }else{
                    this.setState({
                        errorMessage: "Email or Password didn't match. Please try again...",
                        errorStatus: true
                    })
                }
            })
            .catch(error => {
                this.setState({
                    errorMessage: "Email or Password didn't match. Please try again...",
                    errorStatus: true
                })
            })
        }else{
            window.location.href = '/';
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="account-wall" style={{height: window.innerHeight, display: "flex", justifyContent: 'center', alignItems: 'center'}} >
                    <form className="form-signin otp-form" onSubmit={this.loginFunc}>
                        <img src={logo} className="user-img" alt={logo} style={{height: 60}} />
                        <h3 style={{color:'#fff', textAlign: 'center'}}>2-Factor Authentication</h3>
                        <p style={{color:'#fff', textAlign:'center'}}>Please enter the 4-digit PIN that has been sent to your mobile number</p>
                        {
                            this.state.errorStatus
                            ?
                                <p style={{ color: "red" }}>{this.state.errorMessage}.</p>
                            :
                                null
                        }
                        <OtpInput
                            value={this.state.otp}
                            onChange={this.handleChange}
                            numInputs={4}
                            separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                            className="otpInputBox mb-20"
                        />
                        <button type="submit" id="submit-form" className="btn btn-lg btn-danger btn-block">Sign In</button>
                        <a href="#/" onClick={this.resendOTP} style={{color:'#fff', fontSize:16, display:'flex', justifyContent:'center'}} >Resend Code</a>
                    </form>
                </div>
                <p className="account-copyright">
                </p>
            </div>
        )
    }
}