import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(async config => {
  const token = await localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = 'Bearer ' + token;
  }
  return config;
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const { status, data } = error.response;
    console.log('status, data', status, data);
    return Promise.reject(data?.error || data?.message || error?.message || error);
  },
);

export default axios;
