import React, { Component } from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import axios from "axios";
import { toast } from 'react-toastify';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import Rating from 'react-rating';
import { AMENITIES } from "../../../constants";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import Dropzone from "react-dropzone";


const Imagemaxsize = 10000000000;
const acceptedFileTypes = 'image/jpeg, image/png'
const acceptedFiletypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() });

export default class AddHotel extends Component {
    constructor(props){
    super(props);
    this.state = {
        fullName: '',
        email: '',
        password: '',
        hotelName: '',
        hotelDescription: '',
        microStay: [],
        hotelRating: '',
        streetAddress: '',
        city: '',
        country: '',
        postalCode: '',
        state: '',
        commRate: 0,

        Amenities: [],

        sameOwnerContact: false,
        sameOwnerManager: false,

        contactFullName: '',
        contactFirstName: '',
        contactLastName: '',
        contactPosition: '',
        contactNumber: '',
        contactEmail: '',

        managerFullName: '',
        managerFirstName: '',
        managerLastName: '',
        managerPosition: '',
        managerNumber: '',
        managerEmail: '',

        fullNameError: '',
        emailError: '',
        passwordError: '',

        propertyID: '',

        hotelMultiImageSrc: [],
        hotelMultiImage: [],
        expediaUrl: '',
    }
}
    async componentDidMount() {
        let tokenLoad = await localStorage.getItem("token");
        await axios
          .get(`${process.env.REACT_APP_API_URL}/user/me`, {
            headers: {
              Authorization: `Bearer ${tokenLoad}`,
            },
          })
          .then((response) => {
           this.setState({
            email: response.data.email,
            fullName: `${response.data.firstName} ${response.data.lastName}`
          });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    onChange = async (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }

    onAmenitiesChange = (e) => {
        const { value } = e.target;
        let amenitiesArray = this.state.Amenities;

        if (!amenitiesArray.includes(value)) {
            amenitiesArray.push(value)
        } else {
            var index = amenitiesArray.indexOf(value)
            if (index !== -1) {
                amenitiesArray.splice(index, 1);
            }
        }

        this.setState({
            Amenities: amenitiesArray
        })
    }

    onContactChange = (e) => {
        const { checked } = e.target;
        if(checked){
            this.setState({
                sameOwnerContact: true
            })
        }else{
            this.setState({
                sameOwnerContact: false
            })
        }
    }

    onManagerChange = (e) => {
        const { checked } = e.target;
        if(checked){
            this.setState({
                sameOwnerManager: true
            })
        }else{
            this.setState({
                sameOwnerManager: false
            })
        }
    }

    validate = () => {
        this.setState({
            fullNameError: '',
            emailError: '',
            passwordError: ''
        })
        const regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

        let fullNameError = '';
        let emailError = '';
        let passwordError = '';

        if (this.state.fullName === '') {
            fullNameError = "Please Enter Full Name"
        }
        if (fullNameError) {
            this.setState({ fullNameError })
            return false
        }

        if (this.state.email === '') {
            emailError = "Please Enter Email"
        } else if (!regex.test(this.state.email.trim())) {
            emailError = "Please enter a valid Email Address"
        }
        if (emailError) {
            this.setState({ emailError })
            return false
        }

        if (this.state.password === '') {
            passwordError = "Please Enter Password"
        }
        if (passwordError) {
            this.setState({ passwordError })
            return false
        }
        return true
    }

    changeRating = (val) => {
        this.setState({
            hotelRating: val
        })
    }

    handleEditorChange = (event, editor) => {
        const data = editor.getData();
        this.setState({
            hotelDescription: data
        })
    }

    verifyFile = (files) => {
        if (files && files.length > 0) {
            const currentFile = files[0];
            const currentFileType = currentFile.type
            const currentFileSize = currentFile.size
            if (currentFileSize > Imagemaxsize) {
                //alert("This File Is Too Big")
                return false
            }

            if (!acceptedFiletypesArray.includes(currentFileType)) {
                //alert("This File Is Not Supported")
                return false
            }
            return true
        }
    }

    onHandleondrop = (files, rejectedFiles, e) => {
        if (rejectedFiles && rejectedFiles.length > 0) {
            this.verifyFile(rejectedFiles)
        }

        if (files && files.length > 0) {
            const isVerified = this.verifyFile(files)
            if (isVerified) {
                const currentFile = Array.from(e.target.files);

                Promise.all(currentFile.map(file => {
                    return (new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.addEventListener('load', (ev) => {
                            resolve(ev.target.result);
                        });
                        reader.addEventListener('error', reject);
                        reader.readAsDataURL(file);
                    }));
                }))
                    .then(images => {
                        /* Once all promises are resolved, update state with image URI array */
                        this.setState({ hotelMultiImageSrc: images, hotelMultiImage: e.target.files })
                    }, error => {
                        console.log(error);
                    });
            }
        }
    }

    handleRemoveimage = (index, name, src) => () => {
        let imgSrc = [];
        let originalImage = [];
        if (name === 'hotelMultiImage') {
            imgSrc = [...this.state.hotelMultiImageSrc];
            originalImage = [...this.state.hotelMultiImage];
        }

        imgSrc.splice(index, 1);
        originalImage.splice(index, 1);

        if (name === 'searchMultiImage') {
            this.setState({ hotelMultiImageSrc: imgSrc, hotelMultiImage: originalImage });
        }
    }

    microStay = (e) => {
        const { value } = e.target;
        let microStayArray = this.state.microStay;

        if (!microStayArray.includes(value)) {
            microStayArray.push(value)
        } else {
            var index = microStayArray.indexOf(value)
            if (index !== -1) {
                microStayArray.splice(index, 1);
            }
        }

        this.setState({
            microStay: microStayArray
        })
    }

    handleChange = address => {
        this.setState({ streetAddress: address });
    };

    handleSelect = address => {
        geocodeByAddress(address)
            .then(results => {
                getLatLng(results[0]);
                let city, country, postalCode, state;
                for (let i = 0; i < results[0].address_components.length; i++) {
                    if (results[0].address_components[i].types[0] === "locality") {
                        city = results[0].address_components[i].long_name
                    }
                    if (results[0].address_components[i].types[0] === "country") {
                        country = results[0].address_components[i].long_name
                    }
                    if (results[0].address_components[i].types[0] === "postal_code") {
                        postalCode = results[0].address_components[i].long_name
                    }
                    if (results[0].address_components[i].types[0] === "administrative_area_level_1") {
                        state = results[0].address_components[i].long_name
                    }
                }
                this.setState({ city, country, postalCode, state })
            })
            .catch(error => console.error('Error', error));
    };

    renderFunc = ({ getInputProps, getSuggestionItemProps, suggestions }) => (
        <div className="autocomplete-root">
            <input {...getInputProps()} className="form-control" placeholder="Enter the street address here" />
            <div className="autocomplete-dropdown-container">
                {suggestions.map((suggestion, index) => (
                    <option {...getSuggestionItemProps(suggestion)} key={index}>
                        {suggestion.description}
                    </option>
                ))}
            </div>
        </div>
    )

    submitFunction = async (e) => {
        e.preventDefault();
        const { fullName, email, password } = this.state;
        //const isValid = this.validate();

        let [firstName, ...lastName] = fullName.split(' ');
        lastName = lastName.join(' ');
        await axios.post(`${process.env.REACT_APP_API_URL}/user/checkAdmin`,
            {
                firstName,
                lastName,
                email,
                password,
                userType: 'ADMIN',
                accessLevel: "OWNER"
            },
            {
                headers: {
                    'Content-type': 'application/json',
                },
            }
        )
        .then(async response => {
            if (response.status === "201" || response.status === 201) {
                if ((this.state.contactFirstName === "" || this.state.contactLastName === "" || this.state.contactPosition === "" || this.state.contactNumber === "" || this.state.contactEmail === "") && this.state.sameOwnerContact === false) {
                    toast.error("Please enter hotel manager contact information.", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else if ((this.state.managerFirstName === "" || this.state.managerLastName === "" || this.state.managerPosition === "" || this.state.managerNumber === "" || this.state.managerEmail === "") && this.state.sameOwnerManager === false) {
                    toast.error("Please enter Revenue manager contact information.", {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                let tokenUser = response.data.token;
                const { hotelName, hotelDescription, microStay, hotelRating, streetAddress, city, country, postalCode, state, Amenities, propertyID, commRate, expediaUrl } = this.state;
                let parameters = {
                    "name": hotelName,
                    "description": hotelDescription,
                    "rating": hotelRating,
                    "type": microStay,
                    "streetAddress": streetAddress,
                    "city": city,
                    "country": country,
                    "postalCode": postalCode,
                    "state": state,
                    "amenities": Amenities,
                    "propertyId": propertyID,
                    "commissionRate": commRate,
                    "overwrite": false,
                    "expediaUrl": expediaUrl,
                };
                await axios.post(`${process.env.REACT_APP_API_URL}/hotel`, parameters, {
                    headers: {
                        Authorization: `Bearer ${tokenUser}`
                    },
                })
                .then(async hotelResponse => {
                    if (hotelResponse.status === "201" || hotelResponse.status === 201) {
                        // Contacts Starts
                        const { contactFirstName, contactLastName, contactPosition, contactNumber, contactEmail, sameOwnerContact } = this.state;
                        let contact = {
                            "firstName": contactFirstName,
                            "lastName": contactLastName,
                            "title": contactPosition,
                            "mobile": contactNumber,
                            "email": contactEmail
                        };

                        await axios.post(`${process.env.REACT_APP_API_URL}/hotel/${hotelResponse.data._id}/contact`, {contact, "owner": sameOwnerContact}, {
                            headers: {
                                Authorization: `Bearer ${tokenUser}`
                            },
                        })
                        .catch(contactError => {
                            toast.error(`${contactError}, hence Hotel Manager details not been added successfully.`, {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        })
                        // Contacts Ends


                        // Managers Starts
                        const { managerFirstName, managerLastName, managerPosition, managerNumber, managerEmail, sameOwnerManager } = this.state;
                        let manager = {
                            "firstName": managerFirstName,
                            "lastName": managerLastName,
                            "title": managerPosition,
                            "accessLevel": "MANAGER",
                            "mobile": managerNumber,
                            "email": managerEmail
                        };
                        await axios.post(`${process.env.REACT_APP_API_URL}/hotel/${hotelResponse.data._id}/manager`, {manager, "owner": sameOwnerManager}, {
                            headers: {
                                Authorization: `Bearer ${tokenUser}`
                            },
                        })
                        .catch(managerError => {
                            toast.error(`${managerError}, hence Revenue Manager details not been added successfully.`, {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        })
                        // Managers Ends

                        for (let i = 0; i <= this.state.hotelMultiImage.length - 1; i++) {
                            var hotelPhotosForm = new FormData();
                            hotelPhotosForm.append('photos', this.state.hotelMultiImage[i], this.state.hotelMultiImage[i].name);

                            await axios({
                                method: 'post',
                                url: `${process.env.REACT_APP_API_URL}/hotel/${hotelResponse.data._id}/images`,
                                data: hotelPhotosForm,
                                headers: {
                                    Authorization: `Bearer ${tokenUser}`,
                                    Accept: 'application/json',
                                    'Content-Type': 'multipart/form-data'
                                }
                            })
                            .catch(error => {
                                toast.error(error, {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                            })
                        }
                        toast.success('Hotel Added Successfully', {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        this.props.history.push("/property/all");                         
                    }
                })
                .catch(hotelError => {
                    toast.error(hotelError, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                })
            }
            }
        })
        .catch(err => {
            toast.error(err, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });

    }

    render() {
        const { history } = this.props
        const { hotelMultiImageSrc } = this.state;
        return (
            <div>
                <div className="header">
                    <h2><strong>Manage</strong> Hotels</h2>
                    <div className="breadcrumb-wrapper">
                        <ol className="breadcrumb">
                            <li>
                                <a href={() => null} onClick={() => history.push("/dashboard")}>TimeFlyz</a>
                            </li>
                            <li>
                                <a href={() => null} onClick={() => history.push("/property/all")}>Property Management</a>
                            </li>
                            <li className="active">Add Hotel</li>
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                        <form onSubmit={this.submitFunction}>
                            <div className="panel">
                                <div className="panel-header ">
                                    <h3><strong>Hotel Admin Registration</strong> </h3>
                                </div>
                                <div className="panel-content">
                                    <div className="form-group">
                                        <h5>Full Name</h5>
                                        <input type="text" name="fullName" value={this.state.fullName} className="form-control" placeholder="Enter the full name here" aria-required="true" onChange={this.onChange} readOnly />
                                        {this.state.fullNameError ? (<div className="text-danger">{this.state.fullNameError}</div>) : null}
                                    </div>
                                    <div className="form-group">
                                        <h5>Email</h5>
                                        <input type="email" name="email" value={this.state.email} className="form-control" placeholder="Enter the email here" aria-required="true" onChange={this.onChange} readOnly />
                                        {this.state.emailError ? (<div className="text-danger">{this.state.emailError}</div>) : null}
                                    </div>
                                </div>
                                <div className="panel-header ">
                                    <h3><strong>Add Hotel</strong> </h3>
                                </div>
                                <div className="panel-content">
                                    <h5><strong>DETAILS</strong></h5>
                                    <hr />
                                    <div className="form-group">
                                        <h5>Hotel Name</h5>
                                        <input type="text" name="hotelName" className="form-control" placeholder="Enter the hotel name here" aria-required="true" onChange={this.onChange} />
                                    </div>
                                    <div className="form-group">
                                        <h5>Description</h5>
                                        <CKEditor
                                            className="summernote bg-white"
                                            editor={ClassicEditor}
                                            onInit={editor => {
                                                editor.editing.view.change(writer => {
                                                    writer.setStyle(
                                                        "height",
                                                        "150px",
                                                        editor.editing.view.document.getRoot()
                                                    );
                                                });
                                            }}
                                            onChange={this.handleEditorChange}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <h5>Micro stay</h5>
                                        <div className="input-group">
                                            <div className="icheck-inline">
                                                <label>
                                                    <Checkbox value="Business" onChange={(val) => this.microStay(val)} data-checkbox="icheckbox_square-blue" /> Business
                                                </label>
                                                <label>
                                                    <Checkbox value="Leisure" onChange={(val) => this.microStay(val)} data-checkbox="icheckbox_square-blue" /> Leisure
                                                </label>
                                                <label>
                                                    <Checkbox value="Transit" onChange={(val) => this.microStay(val)} data-checkbox="icheckbox_square-blue" /> Transit
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <h5>Star rating</h5>
                                        <Rating
                                            emptySymbol={<i className="fa fa-star-o" style={{ fontSize: 25, color: '#ffd700' }}></i>}
                                            fullSymbol={<i className="fa fa-star" style={{ fontSize: 25, color: '#ffd700' }}></i>}
                                            onChange={(val) => this.changeRating(val)}
                                            initialRating={this.state.hotelRating}
                                            fractions={2}
                                        />
                                    </div>
                                    <h5><strong>Location</strong></h5>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <h5>Street Address</h5>
                                            <PlacesAutocomplete value={this.state.streetAddress} onChange={this.handleChange} onSelect={this.handleSelect}>
                                                {this.renderFunc}
                                            </PlacesAutocomplete>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h5>City</h5>
                                            <input type="text" name="city" className="form-control" placeholder="Enter the city here" aria-required="true" onChange={this.onChange} defaultValue={this.state.city} />
                                        </div>
                                        <div className="col-md-6">
                                            <h5>Country</h5>
                                            <input type="text" name="country" className="form-control" placeholder="Enter the country here" aria-required="true" onChange={this.onChange} defaultValue={this.state.country} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h5>Postal Code</h5>
                                            <input type="text" name="postalCode" className="form-control" placeholder="Enter the postal code here" aria-required="true" onChange={this.onChange} defaultValue={this.state.postalCode} />
                                        </div>
                                        <div className="col-md-6">
                                            <h5>State</h5>
                                            <input type="text" name="state" className="form-control" placeholder="Enter the state here" aria-required="true" onChange={this.onChange} defaultValue={this.state.state} />
                                        </div>
                                    </div>
                                </div>

                                <div className="panel-content">
                                    <h5><strong>PROPERTY ID</strong></h5>
                                    <hr />
                                    <div className="form-group">
                                        <input type="text" name="propertyID" className="form-control" placeholder="Enter the property id here" aria-required="true" onChange={this.onChange} defaultValue={this.state.propertyID} />
                                    </div>
                                </div>

                                <div className="panel-content">
                                    <h5><strong>COMMISSION RATE (excl GST)</strong></h5>
                                    <hr />
                                    <div className="input-group">
                                        <input type="number" name="commRate" className="form-control" placeholder="Enter the commission rate here" aria-required="true" onChange={this.onChange} defaultValue={this.state.commRate} />
                                        <span className="input-group-addon">%</span>
                                    </div>
                                </div>

                                <div className="panel-content">
                                    <h5><strong>Expedia URL</strong></h5>
                                    <hr />
                                    <div className="form-group">
                                        <input type="text" name="expediaUrl" className="form-control" placeholder="Enter the Expedia URL here" aria-required="true" onChange={this.onChange} defaultValue={this.state.expediaUrl} />
                                    </div>
                                </div>

                                <div className="panel-content">
                                    <h5><strong>PHOTOS</strong></h5>
                                    <hr />
                                    <div className="form-group">
                                        <Dropzone onDrop={this.onHandleondrop.bind(this)} maxSize={Imagemaxsize} accept={acceptedFileTypes} multiple={true}>
                                            {({ getRootProps, getInputProps }) => (
                                                <section style={{ border: "2px dashed #ef4a23", minHeight: "auto", padding: "10px", textAlign: "center" }}>

                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} name="hotelPhotos" />
                                                        <label style={{ marginTop: 25 }}>
                                                            <div className="dz-message ">
                                                                <i className="ti-plus text-primary font-26 " />
                                                                <div className="mt-2 font-15 mb-4" style={{ color: '#474949' }}>Click To Upload Images Here</div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                        {
                                            hotelMultiImageSrc.length > 0 ?
                                                <div style={{ border: "1px solid #ccc", padding: 20, height:'550px', overflowY: 'scroll', overflowX: 'hidden' }}>
                                                    <div className="row">
                                                        {
                                                            hotelMultiImageSrc && hotelMultiImageSrc.map((ImageUri, index) => {
                                                                return (
                                                                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 mb-4" key={index} style={{ textAlign: 'center', padding: 10 }}>
                                                                        <img src={ImageUri} style={{ height: 200, width: '100%' }} alt={ImageUri} />
                                                                        <button className="btn btn-sm btn-danger btn-block" style={{ marginLeft: 0, marginTop: 10 }} onClick={this.handleRemoveimage(index, "hotelMultiImage", "hotelMultiImageSrc")}>Delete</button>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                this.state.imageError ? (<div className="text-danger">{this.state.imageError}</div>) : null
                                        }
                                    </div>
                                </div>

                                <div className="panel-content">
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-12 col-md-8 col-lg-6">
                                            <h5><strong>HOTEL MANAGER</strong></h5>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-6">
                                            <div className="input-group" style={{float:'right'}}>
                                                <div className="icheck-inline">
                                                    <Checkbox name="sameOwnerContact" defaultChecked={this.state.sameOwnerContact} data-checkbox="icheckbox_square-orange" onChange={(val) => this.onContactChange(val)} /> Same as Owner
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.state.sameOwnerContact ?
                                        null
                                    :
                                        <>
                                            <hr />
                                            <div className="form-group">
                                                <h5>First Name</h5>
                                                <input type="text" name="contactFirstName" className="form-control" placeholder="Enter the first name here" aria-required="true" onChange={this.onChange} />
                                                {this.state.fullNameError ? (<div className="text-danger">{this.state.fullNameError}</div>) : null}
                                            </div>
                                            <div className="form-group">
                                                <h5>Last Name</h5>
                                                <input type="text" name="contactLastName" className="form-control" placeholder="Enter the last name here" aria-required="true" onChange={this.onChange} />
                                                {this.state.fullNameError ? (<div className="text-danger">{this.state.fullNameError}</div>) : null}
                                            </div>
                                            <div className="form-group">
                                                <h5>Position Title</h5>
                                                <input type="text" name="contactPosition" className="form-control" placeholder="Enter the position title here" aria-required="true" onChange={this.onChange} />
                                                {this.state.emailError ? (<div className="text-danger">{this.state.emailError}</div>) : null}
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <h5>Contact Number</h5>
                                                    <input type="number" name="contactNumber" className="form-control" placeholder="Enter the contact number here" aria-required="true" onChange={this.onChange} />
                                                </div>
                                                <div className="col-md-6">
                                                    <h5>Email Address</h5>
                                                    <input type="email" name="contactEmail" className="form-control" placeholder="Enter the email address here" aria-required="true" onChange={this.onChange} />
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>

                                <div className="panel-content">
                                    <div className="row">
                                        <div className="col-sm-6 col-xs-12 col-md-8 col-lg-6">
                                            <h5><strong>REVENUE MANAGERS</strong></h5>
                                        </div>
                                        <div className="col-sm-6 col-xs-12 col-md-4 col-lg-6">
                                            <div className="input-group" style={{float:'right'}}>
                                                <div className="icheck-inline">
                                                    <Checkbox name="sameOwnerManager" defaultChecked={this.state.sameOwnerManager} data-checkbox="icheckbox_square-blue" onChange={(val) => this.onManagerChange(val)} /> Same as Owner
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.state.sameOwnerManager ?
                                        null
                                        :
                                        <>
                                            <hr />
                                            <div className="form-group">
                                                <h5>First Name</h5>
                                                <input type="text" name="managerFirstName" className="form-control" placeholder="Enter the first name here" aria-required="true" onChange={this.onChange} />
                                                {this.state.fullNameError ? (<div className="text-danger">{this.state.fullNameError}</div>) : null}
                                            </div>
                                            <div className="form-group">
                                                <h5>Last Name</h5>
                                                <input type="text" name="managerLastName" className="form-control" placeholder="Enter the last name here" aria-required="true" onChange={this.onChange} />
                                                {this.state.fullNameError ? (<div className="text-danger">{this.state.fullNameError}</div>) : null}
                                            </div>
                                            <div className="form-group">
                                                <h5>Position Title</h5>
                                                <input type="text" name="managerPosition" className="form-control" placeholder="Enter the position title here" aria-required="true" onChange={this.onChange} />
                                                {this.state.emailError ? (<div className="text-danger">{this.state.emailError}</div>) : null}
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <h5>Contact Number</h5>
                                                    <input type="number" name="managerNumber" className="form-control" placeholder="Enter the contact number here" aria-required="true" onChange={this.onChange} />
                                                </div>
                                                <div className="col-md-6">
                                                    <h5>Email Address</h5>
                                                    <input type="email" name="managerEmail" className="form-control" placeholder="Enter the email address here" aria-required="true" onChange={this.onChange} />
                                                </div>
                                            </div>
                                        </>

                                    }
                                    
                                </div>

                                <div className="panel-content">
                                    <h5><strong>AMENITIES</strong></h5>
                                    <hr />
                                    <div className="row">
                                        <div className="input-group">
                                            <div className="icheck-inline">
                                                {
                                                    AMENITIES.map((data, index) => {
                                                        return (
                                                            <div className="col-sm-6 col-xs-12 col-md-4 col-lg-3" key={index} style={{ padding: 10 }}>
                                                                <Checkbox value={data.label} name={data.label} data-checkbox="icheckbox_square-blue" onChange={(val) => this.onAmenitiesChange(val)} /> {data.label}
                                                                <img src={data.image} alt={data.label} style={{ height: 20, width: 20, float: 'right' }} />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="text-center  m-t-20">
                                    <button type="submit" className="btn btn-embossed btn-danger">Submit</button>
                                    <button type="reset" className="cancel btn btn-embossed btn-primary m-b-10 m-r-0">Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
