import React, { Component } from "react";
import { DataGrid } from '@mui/x-data-grid';
import $api from '../../utils/axios'
import { Container } from '@mui/system';
const columns = [
    { headerName: 'CONTACT NAME', field: 'name', type: 'string', width: 200, },
    {
      headerName: 'CONTACT EMAIL', field: 'email', type: 'string', width: 200,
    },
    {
      headerName: 'CONTACT HELP', field: 'help', type: 'string', width: 200,
    },
];



export default class propertyType extends Component {
  state = {
    records: [],
  }

  componentDidMount() {
    this.fetchContacts();
  }


  fetchContacts= async () => {
    try {
      const response = await $api.get(`${process.env.REACT_APP_API_URL}/contact/list`)
      const array = [];
      response.data.forEach(element => {
        array.push({
          _id: element._id,
          name: element.name,
          email: element.email,
          comment: element.comment,
        })
      });
      this.setState({
        records: array
      });
    }
    catch(error){
      console.log(error);
    }
  }
  
  render() {
    const { history } = this.props
    const { records} = this.state;
    return (
      <div>
        <div className="header">
          <h2><strong>Manage </strong>ContactUs List</h2>
          <div className="breadcrumb-wrapper">
            <ol className="breadcrumb">
              <li>
                  <a href={() => null} onClick={() => history.push("/dashboard")}>TimeFlyz</a>
              </li>
              <li className="active">ContactUs List</li>
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="panel">
              <div className="panel-header ">
                <div className="row" style={{ padding: 10 }}>
                  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <h3><strong>ContactUs List</strong> </h3>
                  </div>
                </div>
              </div>
              <div className="panel-content pagination2 table-responsive">
                <Container sx={{
                  backgroundColor: 'white',
                  my: 2,
                  p: 0,
                  borderRadius: 1,
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                }}>
                  <DataGrid sx={{ border: 0 }} getRowId={(row) => row._id} autoHeight columns={columns} rows={records} />
                </Container>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    )

  }
}