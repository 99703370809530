import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import DataTable from "../../components/DataTable";
import Loader from '../../components/Loader';

const columns = [
    {
        name: 'Transaction Date',
        selector: row => row.transDate,
        sortable: true,
    },
    {
        name: 'Inv #',
        selector: row => row.invId,
        sortable: true,
        wrap: true,
    },
    {
        name: 'Property',
        selector: row => row.propertyName,
        sortable: true,
        wrap: true,
    },
    {
        name: 'Payment Method',
        selector: row => row.payMethod,
    },
    {
        name: 'Reason for Decline',
        selector: row => row.reasonDecline,
        grow: 2,
        wrap: true,
    },
    {
        name: 'Amount Processed',
        selector: row => row.amount,
    },
];

export default class MonthlyFailedPayments extends Component {
    state = {
        records: [],
        loading: true,
    }

    componentDidMount() {
        this.fetchReporting();
    }

    fetchReporting = async () => {
        let token = await localStorage.getItem('token');
        await axios.get(`${process.env.REACT_APP_API_URL}/monthlyFailureReport`, {
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then(response => {
            if (response.status === 200) {
                const array = [];
                response.data.forEach(element => {
                    array.push({
                        _id: element.hotelId,
                        transDate: moment(element.createdAt).utc().format("MMM D YYYY hh:mm A"),
                        invId: element.InvId,
                        name: element.name,
                        propertyName: <a href={`/editHotel/${element.hotelId}`}>{element.name}</a>,
                        payMethod: element.paymentMethod,
                        reasonDecline: element.reason,
                        amount: `$ ` + parseInt(element.amount / 100)
                    })
                });
                this.setState({
                    records: array,
                    loading: false
                })
            }
        })
        .catch(error => {
            console.log(error);
        })
    }
    render() {
        const { history } = this.props
        const { loading, records } = this.state;
        if (loading) {
            return (
                <div>
                    <div className="header">
                        <h2><strong>Monthly Billing Payment Fails</strong></h2>
                        <div className="breadcrumb-wrapper">
                            <ol className="breadcrumb">
                                 <li>
                                    <a href={() => null} onClick={() => history.push("/dashboard")}>TimeFlyz</a>
                                </li>
                                <li className="active">Monthly Billing Payment Fails</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ textAlignLast: 'center' }}>
                            <Loader />
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <div className="header">
                        <h2><strong>Monthly Billing Payment Fails</strong></h2>
                        <div className="breadcrumb-wrapper">
                            <ol className="breadcrumb">
                                <li>
                                    <a href={() => null} onClick={() => history.push("/dashboard")}>TimeFlyz</a>
                                </li>
                                <li className="active">Monthly Billing Payment Fails</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="panel">
                                <div className="panel-header ">
                                    <h3><strong>Monthly Billing Payment Fails</strong> </h3>
                                </div>
                                <div className="panel-content pagination2 table-responsive">
                                    <DataTable columns={columns} data={records} loading={loading} filterDefaultText="Filter By Date, Inv #, Property" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}