import React, { useState } from 'react'
import PropTypes from "prop-types";

import "../../assets/global/plugins/dropzone/dropzone.min.css";
import "../../assets/global/plugins/input-text/style.min.css";
import "../../assets/global/plugins/bootstrap-datepicker/css/bootstrap-datepicker3.min.css";
import "../../assets/global/plugins/select2/dist/css/select2.min.css";
import "../../assets/global/css/style.css";
import "../../assets/global/css/theme.css";
import "../../assets/global/css/ui.css";
import "../../assets/admin/layout1/css/layout.css";
import "./style.css";

import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';

const deviceWidth = window.innerWidth > 650 ? true : false;

export default function AuthLayout({ children }) {
    const [toggleSidebar, setToggleSidebar] = useState(deviceWidth);
    
    const sidebarAction = (val) => {
        setToggleSidebar(val);
    }
    
    return (
        <>
            <div className={toggleSidebar ? "fixed-topbar fixed-sidebar theme-sdtl color-default sidebar-show" : "fixed-topbar fixed-sidebar theme-sdtl color-default sidebar-collapsed"}>
                <Sidebar toggleSidebar={toggleSidebar} />
                <div className="main-content">
                    <Header toggleSidebar={toggleSidebar} changeSidebar={(val) => sidebarAction(val)} />
                    <div className="page-content" style={{minHeight:'600px'}}>
                        {children}
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}

AuthLayout.propTypes = {
    children: PropTypes.element.isRequired
};

